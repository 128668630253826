import React from "react";

import FieldSelector from "../../shared/form/components/FieldSelector";
import {
  AddedReasonCRUDDependencies,
  CreateAddedReason,
  DeleteAddedReason,
  UpdateAddedReason,
} from "./services/service.addedReasons";
import { AddedReasonInterface } from "./models/AdedReasonTypes";
import { useAddedReasonForm } from "./services/useAddedReasonForm";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../session/store/sessionContext";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";

type props = CUEntityFormPropsType<AddedReasonInterface> & { isDataUpdated?: boolean };

const CUAddedReasonForm: React.FC<props> = ({ item, actionType, isDataUpdated }) => {
  const { tagFP } = useSessionContext();
  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateAddedReason,
      update: UpdateAddedReason,
      delete: DeleteAddedReason,
    },
    actionType,
    AddedReasonCRUDDependencies
  );

  //UI
  const { lang } = useLangLabels();

  const { uiModel, handleSubmit, resetForm } = useAddedReasonForm(tagFP, item);

  const ui = (() => {
    const formName = lang.addedReasons.plural;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName.toLowerCase()}`,
          variant: "button",
          mode: "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: "iconButton",
          mode: "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: AddedReasonInterface) => {
    action(data);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUAddedReasonForm;
