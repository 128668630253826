
import axios, { AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { apiCall, mutateApiCall } from '../../../shared/axios/axios-config';
import { PrivateQuery, PrivateQueryData, PrivateQueryTagFPInterface } from '../../../shared/globals/utilsGlobalTypes';
import { useStatusProcessor } from '../../../shared/queries/StatusProcessor';
import { queryClient, useMutation, useQuery } from '../../../shared/react-query/react-query-conf';
import { LubricantInterface } from '../../lubricant/model/LubricantInterface';
import { useSessionContext } from '../../session/store/sessionContext';
import { useToken } from '../../session/store/sessionStore';
import { ContainersInterface } from '../ContainersInterfaces';

export const ContainerCRUDDependencies = ['PlantAux', 'AllContainersByTagFP', 'ContainersByLubricantTypeAndLubricant']

//--------------------------
//AXIOS
//--------------------------


export const CreateContainer = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/CreateContainer',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
    
}

export const DeleteContainer = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/DeleteContainer',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}


export const UpdateContainer = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/UpdateContainer',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const ContainersByTagFP = ({tagFP,token}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method:'GET',
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP:tagFP!
        },
        url:'/ContainersByTagFP',
    })
}

export const ContainersByLubricantTypeAndLubricant = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method:'POST',
        headers:{
            Authorization:`Bearer ${token}`,
        },
        url:'/ContainersByLubricantTypeAndLubricant',
        data
    })

}



/* 
--------------------------
QUERYS
--------------------------
*/

export const useAllContainersByTagFP = () => {
    const {currentPlant} = useSessionContext()
    const token = useToken()
    const queryTagFP = currentPlant?.tagFP  
    return useQuery<ContainersInterface[] | []>({
        queryKey:'AllContainersByTagFP',
        queryFn:()=>ContainersByTagFP({tagFP:queryTagFP!,token:token!}),
        enabled:!!token && !!queryTagFP
    })
}

export const useContainersByLubricantTypeAndLubricant = (lubricant?:LubricantInterface) => {
    const token = useToken()
    const data = useMemo(()=>({
        lubricant:lubricant?.lubricant,
        tagFP:lubricant?.tagFP,
        lubricantType:lubricant?.type
    }),[lubricant])

    return useQuery({
        queryKey:'ContainersByLubricantTypeAndLubricant',
        queryFn:()=>ContainersByLubricantTypeAndLubricant({data,token:token!}),
        enabled: !!token && !!lubricant
    })
}


/* 
--------------------------
Mutations
--------------------------
*/

export const useCreateContainer = () => {
    const token = useToken()

    const query = useMutation(CreateContainer,{
        onSuccess:()=>{
            ContainerCRUDDependencies.forEach((dependency)=>{
                queryClient.invalidateQueries(dependency)
            })
        }
    })

    const status = useStatusProcessor(query)
    
    const createContainer = (data:ContainersInterface) => {
       return query.mutate({
           token:token!,
           data
       })
    }

    return{
        createContainer,
        ...query,
        ...status,
    }
}

export const useUpdateContainer = () => {
    const token = useToken()
    const query = useMutation(UpdateContainer,{
        onSuccess:()=>{
            ContainerCRUDDependencies.forEach((dependency)=>{
                queryClient.invalidateQueries(dependency)
            })
        }
    })

    const status = useStatusProcessor(query)

    const updateContainer = (data:ContainersInterface) => {
        return  query.mutate({
            data,
            token:token!
        })
    }
        
    return {
        updateContainer,
        ...query, 
        ...status}
}

export const useDeleteContainer = () => {
    const token = useToken()
    const query = useMutation(DeleteContainer,{
        onSuccess:()=>{
            ContainerCRUDDependencies.forEach((dependency)=>{
                queryClient.invalidateQueries(dependency)
            })
        }
    })
    
    const status =  useStatusProcessor(query)
    const deleteContainer = (data:ContainersInterface) => {
        return  query.mutate({
            data,
            token:token!
        })
    }
        
    return {
        deleteContainer,
        ...query,
        ...status
    }

}