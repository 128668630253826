import { FlexContainer } from "../../../../../components/containers";
import { CircularProgress, Divider, Typography } from "@material-ui/core";
import TgdPopover from "../../../../../components/popovers/TgdPopover";
import { QueryStatus } from "react-query";
import { CustomIcon } from "../../../../../components/buttons/CustomIcon";

interface CardTitleProps {
  title: string;
  popoverTitle?: string;
  status?: QueryStatus;
  popoverChild?: React.ReactNode;
}

const CardTitle = ({ popoverChild, title, popoverTitle, status }: CardTitleProps) => {
  return (
    <Typography variant="h6"  style={{fontSize: "16px", fontWeight: "normal"}}>
      <FlexContainer justify="space-between" align="center" padding={"6px 24px 6px 16px"}>
        {title}
        <div>
          <TgdPopover mode="hover" title={popoverTitle || ""} hoverChildren={popoverChild}>
            {status && status === "loading" ? (
              <CircularProgress size="20px" />
            ) : (
              <CustomIcon icon="info" style={{fontSize: "20px", margin: "0px", display: "flex", alignItems: "center"}} />
            )}
          </TgdPopover>
        </div>
      </FlexContainer>
      <Divider />
    </Typography>
  );
};

export default CardTitle;
