import React from "react";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import { useClickPosition } from "../../../../shared/globals/hooks/useClickPosition";
import { equipmentType, LubricationPointInterface } from "../../model/lubricationPointsInterface";
import { useUpdateLubricationPointState } from "../../services/lubricationPointsAdapters";
import ModalButton from "../../../../components/buttons/ModalButton";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { useSnackbar } from "../../../../components/snackbar/hooks/useSnackbar";
import { ButtonVariant } from "../../../../components/buttons/types/CustomButtonTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  item: LubricationPointInterface;
  tagTGD: string;
  type: equipmentType;
  variant?: ButtonVariant;
}
const DesactivateActivateEquipment: React.FC<Props> = ({ item, tagTGD, type, variant }) => {
  const { activateLubricationPoint, desactivateLubricationPoint, queryData } =
    useUpdateLubricationPointState();
  const { status: queryStatus, message, error } = useStatusProcessor(queryData);
  const { position, getClickPosition } = useClickPosition();

  const { setData } = useSnackbar();
  const { register, handleSubmit, errors } = useForm();

  const { lang } = useLangLabels();

  const title = ` ${type === "component" ? lang.lubricationPoints.component : ""} ${
    type === "equipment" ? lang.lubricationPoints.equipment : ""
  } ${type === "element" ? lang.lubricationPoints.element : ""}`;

  const handleActivate = (e: any) => {
    if (item?.tagTGD) {
      getClickPosition(e, () => {
        if (type === "component") {
          activateLubricationPoint({ lowReason: "", tagTGD: item.tagTGD });
        } else {
          if (window.confirm(lang.lubricationPoints.youAreActivatingAllPoints)) {
            activateLubricationPoint({ lowReason: "", tagTGD: item.tagTGD });
          }
        }
      });
    }
  };

  const equipmentType = () => {
    switch (type) {
      case "equipment":
        return lang.lubricationPoints.youAreDecommissioningEquipment;
      case "element":
        return lang.lubricationPoints.youAreDecommissioningElement;
      case "component":
        return lang.lubricationPoints.youAreDecommissioningComponent;
    }
  };

  const submit = (form: { lowReason: string }) => {
    const info = equipmentType();
    const alert = window.confirm(`${info}`);
    if (alert) {
      desactivateLubricationPoint({
        tagFP: item.tagFP,
        tagTGD,
        lowReason: form.lowReason,
      });
    }
  };

  useEffect(() => {
    if (position.positionX !== "" || position.positionY !== "") {
      if (queryData.status === "error") {
        setData({
          modal: true,
          errors: `${lang.lubricationPoints.thereWasAnErrorActivating} ${title}`,
          severity: "error",
          position: position,
        });
      }
      if (queryData.status === "success") {
        setData({
          modal: true,
          errors: message,
          severity: "success",
          position: position,
        });
      }
    }
  }, [queryData.status, queryStatus, message]);

  const activatePopoverTitle =
    lang.selectedLanguage === "en"
      ? `${lang.lubricationPoints.activateThis} ${title.toLowerCase()} ${lang.lubricationPoints.again.toLowerCase()}`
      : `${lang.lubricationPoints.activateAgain} ${title.toLowerCase()}`;

  const activate = (
    <CustomButton
      status={queryStatus}
      popoverTitle={activatePopoverTitle}
      icon={"power"}
      variant={variant || "iconButton"}
      action={(e) => handleActivate(e)}
    />
  );

  const desactivate = (
    <ModalButton
      icon="off"
      popoverTitle={`${lang.actionTypes.desactivate} ${title.toLowerCase()}`}
      variant={variant || "iconButton"}
      title={`${lang.actionTypes.desactivate} ${title.toLowerCase()}`}
    >
      {queryData.status === "error" && (
        <Alert severity="error">{lang.messages.anErrorHasOccurred}</Alert>
      )}
      {queryData.status === "success" && (
        <Alert severity="success">{lang.lubricationPoints.equipmentHasBeenDecommissioned}</Alert>
      )}

      <FlexContainer flexDirection="column">
        <br />
        <TextField
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
          })}
          name="lowReason"
          placeholder={lang.iotDevices.form.label.lowReason}
          defaultValue={item?.lowReason}
          variant="outlined"
          label={errors?.lowReason?.message || lang.iotDevices.form.label.lowReason}
          color={errors?.lowReason?.message ? "primary" : "secondary"}
          size="small"
        />
        <CustomButton status={queryData.status} action={handleSubmit(submit)}>
          {" "}
          {lang.lubricationPoints.dismantle}{" "}
        </CustomButton>
      </FlexContainer>
    </ModalButton>
  );

  return (
    <>
      {type === "component" ? (
        item.state !== true ? (
          activate
        ) : (
          desactivate
        )
      ) : (
        <>
          {activate}
          {desactivate}
        </>
      )}
    </>
  );
};

export default React.memo(DesactivateActivateEquipment);
