import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TablePagination, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CheckBox } from "@material-ui/icons";
import FlexContainer from "../containers/FlexContainer";
import DynamicFilter from "../filters/DynamicFilter/DynamicFilter";
import { useDynamicFilter } from "../filters/DynamicFilter/services/useDynamicFilter";
import { equipmentType } from "../../domains/lubricationPoints/model/lubricationPointsInterface";
import { GridModelInterface } from "../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

export interface TgdTableInterface {
  label: string;
  atribute: string;
  component?: React.ReactNode;
}

interface Props {
  items: any;
  type: equipmentType | "route" | "supply" | "new" | "permission";
  headerAction?: React.ReactNode;
  itemActions?: any;
  headerTitle?: string;
  title?: string;
  height?: string | undefined | null;
  columns: TgdTableInterface[];
  selectable?: boolean;
  gridModel?: GridModelInterface;
}

const TgdTable: React.FC<Props> = ({
  height,
  items,
  type,
  headerAction,
  headerTitle,
  itemActions,
  title,
  columns,
  selectable,
  gridModel,
}) => {
  const { lang } = useLangLabels();

  const rows = items || [];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);

  const dynamicFilterProps = useDynamicFilter(items, gridModel);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <FlexContainer align="center" justify="space-between">
        <Typography variant="h6">{headerTitle}</Typography>
        <DynamicFilter {...dynamicFilterProps} />
      </FlexContainer>
      <br />
      <br />
      {dynamicFilterProps.filtered?.length < 1 ? (
        <>
          <Alert severity="warning">{lang.table.noResults}</Alert>
          <br />
        </>
      ) : (
        <TableContainer style={{ height: height || "45vh" }} component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {selectable && (
                  <TableCell>
                    {" "}
                    <CheckBox />
                  </TableCell>
                )}
                {columns &&
                  columns.map(({ label }, index: number) => (
                    <TableCell key={index}>{label}</TableCell>
                  ))}
                {type === "component" && (
                  <>
                    <TableCell>{lang.routes.plural}</TableCell>
                    <TableCell>{lang.lubricationPoints.supplies}</TableCell>
                  </>
                )}
                {itemActions && (
                  <TableCell align="right" width={160}>
                    {lang.words.more}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {dynamicFilterProps.filtered
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row: any, index: number) => (
                  <TableRow key={index}>
                    {columns.map(({ atribute, component }, index: number) => (
                      <TableCell key={atribute + index + 123}>
                        {component || row[`${atribute}`]}
                      </TableCell>
                    ))}

                    <TableCell align="right">{itemActions && itemActions(row)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[8, 25, 100, 200, 1000]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default React.memo(TgdTable);
