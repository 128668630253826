import React, { useEffect, useState } from 'react';
import {Paper, Toolbar, Typography,makeStyles, TextField} from '@material-ui/core'

import TgdList from './TgdList';
import { useSearchAll } from '../../shared/globals/hooks/useSearchAll';
import PageContainer from '../containers/PageContainer';
import UpdatePersonForm from '../../domains/person/components/UpdatePersonForm';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { CustomIconType } from '../buttons/types/CustomButtonTypes';
 
const useStyles = makeStyles((theme) => ({
    FactoryListTolbar:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        padding:'0px 16px'
    },
    relative:{
        position:'relative',
        height:'calc(100vh - 19vh)',
        overflowX:'hidden',
    },
    MuiAccordionRoot:{
        boxShadow:'none',
        margin:'0',
        padding:0,
    }
  }));



interface Props{
    icon:CustomIconType
    status:string
    items:any | undefined
    customButtons?:(item:any) => React.ReactNode
    title:string
    children?:React.ReactNode
    dropDownChildren?:(item:any)=>React.ReactNode
}
const EntityList:React.FC<Props> = ({icon,status,items,customButtons,title,children,dropDownChildren}) => {
    const classes = useStyles()
    const {lang} = useLangLabels()
    const {search,handleSearch,filteredItems} = useSearchAll(items)
    
    return (
        <Paper className={classes.relative}>
            {children}
                <Toolbar className={classes.FactoryListTolbar}>
                    <Typography variant='h6' > {title}</Typography>
                    <TextField 
                        
                        style={{width:'300px',padding:'0 32px'}}
                        value={search}
                        type='search' 
                        onChange={handleSearch} 
                        placeholder={`${lang.filters.searchByTerm}..`} 
                    />
                </Toolbar> 
                <TgdList
                    status={status}
                    icon={icon}
                    items={filteredItems || []}
                    customButtons={customButtons}
                >
                    {(item)=>dropDownChildren && dropDownChildren(item)}
                </TgdList>
        </Paper>
    );
}

export default EntityList;
