import { useUser } from "../../../domains/session/store/sessionStore";
import { getDate } from "../../globals/utils/utils";
import { queryClient, useQuery } from "../react-query-conf";
import { getLocalStorage, setLocalStorage } from "../../localStorage/localStorage";


type LocalStorageConfig = {
    unique?:boolean
    enabled?:boolean
    defaultUserEmail?:string
} 

/**
 * data will only be available for a day period
 */
 const getDailyLocalState = <TResult,> (key:string,initialState:TResult) => {

    //localData
    const localData = getLocalStorage<TResult>(key);
    const date = getDate().nowDate()


    if (initialState){
        if(date !== localData?.date){
            setLocalStorage(key,initialState)
            return initialState
        }else{
            return localData.value
        }
    }else{
        return localData?.value
    }
}

export const useQueryPersistanceState = <TResult,>(key:string,initialState?:TResult,config?:LocalStorageConfig):[TResult | undefined,(item:any)=>void] => {
    
    //instance to set enable timing to fetch 
    const _enabled = config? config.enabled : true
    const _unique = config ? config.unique : false
    //user that localstorage data belongs to
    const {data:user} = useUser()
    
    //Current date
    const date = getDate().nowDate()

    //Key with User belongning
    const _user = config?.defaultUserEmail ? config.defaultUserEmail : user?.email
    const _Key = _unique 
        ? (user && key && `${key}-${_user}`)
        : key

    const {data} = useQuery({
        queryKey:_Key!,
        queryFn:()=>getDailyLocalState(_Key!,initialState),
        enabled:!!date && !!_enabled,
        refetchOnWindowFocus:false
    })
    
    const setValue = (value:TResult) => {
        setLocalStorage(_Key!,value)
        queryClient.setQueryData(_Key!,value)
    }

    return [data, setValue];
}
