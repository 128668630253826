import axios,{ AxiosRequestConfig } from'axios'
import { apiCall, mutateApiCall } from '../../../shared/axios/axios-config';
import { MutateResponse } from '../../../shared/axios/models/MutateResponse';
import { getAllResources } from '../../../shared/axios/utils';
import { PrivateQuery, PrivateQueryData } from '../../../shared/globals/utilsGlobalTypes';
import { useStatusProcessor } from '../../../shared/queries/StatusProcessor';
import { useMutation, useQuery } from '../../../shared/react-query/react-query-conf';
import { useSessionContext } from '../../session/store/sessionContext';
import { useToken, useUser } from '../../session/store/sessionStore';
import { NotificationInterface } from '../models/NotificationInterface';
//services





//AXIOS REPO
//-------------------------



export const  updateNotification = ({data,token}:PrivateQueryData<any>) => {
    return mutateApiCall({
        method: "POST",
        url:`/UpdateNotificationState`,
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}

export const createNotification = ({data,token}:PrivateQueryData<any>) => {
    return mutateApiCall({
        method: "POST",
        headers:{
            Authorization:`Bearer ${token}`
        },
        url:`/CreateNotification`,
        data
    })
}


//react-query HOOkS
//-------------------------

export const useUnReadedNotifications = () => {
    const token = useToken()
    const queryKey = 'Notifications'
    return useQuery({
        queryKey:queryKey,
        queryFn:()=>getAllResources(queryKey, {token:token!}),
        enabled:!!token,
        refetchInterval:60000,
    })
}

export const useReadedNotifications = () => {
    const token = useToken()
    const queryKey = 'NotificationsReaded'
    return useQuery({
        queryKey:queryKey,
        queryFn:()=>getAllResources(queryKey, {token:token!}),
        enabled:!!token,
        refetchInterval:60000,
    })
}

export const useAddNotification = () => {
    const query = useMutation(createNotification)
    const status = useStatusProcessor(query)
    return {
        ...query,
        ...status
    }
}


//CUSTOM HJOOKS
//-------------------------

export const useReadUnreadNotification = ()=>{

    const {user,token} = useSessionContext()
    const {data:notifications,refetch:refetchNotifications} = useUnReadedNotifications()
    const {refetch:refetchUnreadedNotif} = useReadedNotifications()

    const refetch = () => {
        refetchNotifications()
        refetchUnreadedNotif()
    }
    const {mutate} = useMutation(updateNotification,{
        onSuccess: () => refetch()
    })

    const readOne = (item:NotificationInterface) => {
        let data = {
            email:user?.email,
            notificationId:item.id,
            state:'Leido'
        }
        mutate({data,token:token!})
    }
    
    const unReadOne = (item:NotificationInterface) =>{
        let data = {
            email:user?.email,
            notificationId:item.id,
            state:'Sin leer'
        }
        mutate({data,token:token!})
    } 

    const readAll = () =>{
        notifications.forEach((item:NotificationInterface) => {
            readOne(item)
        });
    }

    return {
        readOne, unReadOne, readAll
    }
    
}