import React from "react";
import { FlexContainer } from "../../../../components/containers";
import CustomButton from "../../../../components/buttons/CustomButton";
import { CircularProgress } from "@material-ui/core";
import { language } from "../../../../shared/lang/services/useLanguages";
import { ViewKeys } from "../../../../shared/calendar/hooks/useCalendar";

interface CalendarToolProps {
  lang: language;
  onTodayClick: () => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  isToday: boolean;
  dateText: string | undefined;
  handleViewChange: (newView: "day" | "week" | "month" | "work_week" | "agenda") => void;
  isLoading: boolean;
  viewOptions: (
    | {
        id: "day";
        label: string;
      }
    | {
        id: "week";
        label: string;
      }
    | {
        id: "month";
        label: string;
      }
  )[];
  view: string;
}

export const CalendarToolbar = ({
  lang,
  onTodayClick,
  onPrevClick,
  onNextClick,
  isToday,
  dateText,
  handleViewChange,
  isLoading,
  viewOptions,
  view,
}: CalendarToolProps) => {
  return (
    <FlexContainer width={"100%"} height={"50px"} justify="space-between">
      <FlexContainer gap={"36px"} height={"30px"}>
        <CustomButton action={onTodayClick} color={isToday ? "secondary" : "inherit"}>
          {lang.dates.today}
        </CustomButton>
        <FlexContainer>
          <CustomButton
            styleProps={{ width: "30px", backgroundColor: "#e0e0e0" }}
            variant="iconButton"
            icon={"arrowLeft"}
            action={onPrevClick}
          />
          <FlexContainer
            padding={"0 8px"}
            background={"#002F6C"}
            color="white"
            align="center"
            justify="center"
            width={"260px"}
            borderRadius={"4px"}
          >
            <p>{dateText}</p>
          </FlexContainer>
          <CustomButton
            styleProps={{ width: "30px", backgroundColor: "#e0e0e0" }}
            variant="iconButton"
            icon={"arrowRight"}
            action={onNextClick}
          />
        </FlexContainer>

        {isLoading && (
          <FlexContainer align="center">
            <CircularProgress size={20} color="secondary" />
          </FlexContainer>
        )}
      </FlexContainer>

      <FlexContainer margin={"0 0 20px 0"} gap={"16px"}>
        {viewOptions.map(({ id, label }) => (
          <CustomButton
            key={id}
            variant="button"
            action={() => handleViewChange(id)}
            {...(id === view
              ? {
                  color: "secondary",
                }
              : {
                  color: "inherit",
                })}
          >
            {label}
          </CustomButton>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};
