import { useEffect } from "react";
import {
  AppBar,
  CircularProgress,
  Toolbar,
  Typography,
  Badge,
} from "@material-ui/core";
import theme from "../../../assets/theme";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import FeatureStatusContainer, {
  useFeatures,
} from "../../../shared/config/features/FeatureStatusContainer";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import AllowUsers from "../../../shared/config/UserPermissions/AllowedUser";
import ComponentMode from "../../../components/componentMode/ComponentMode";
import CustomButton from "../../../components/buttons/CustomButton";
import PageContainer from "../../../components/containers/PageContainer";
import StatusAndMessage from "../../../shared/queries/errors/components/StatusAndMessage";
import FlexContainer from "../../../components/containers/FlexContainer";
import InstrumentSyncBoxIcon from "./InstrumentSyncBoxIcon";
import { useSessionContext } from "../../../domains/session/store/sessionContext";
import {
  invalidateCollectorsData,
  useCollectorSyncStatus,
  useSetSyncBoxDailySync,
  useSyncCollectorDatabases,
} from "./services/syncService";

export const syncPanelDrawerWidth = 40;

const syncBoxStatusColor = (colectorSyncsStatus: boolean | null | undefined) => {
  switch (colectorSyncsStatus) {
    case true:
      return theme.palette.success.light;

    case false:
      return theme.palette.warning.light;

    case null:
      return theme.palette.error.light;

    case undefined:
      return theme.palette.grey[400];
  }
};

const InstrumentDeviceSyncPanel = () => {
  const { tagFP, currentPlant } = useSessionContext();
  const { lang } = useLangLabels();
  const { isOpen, toggleModal } = useModal();
  const features = useFeatures();

  const { isLoading, updateSyncDataBase, updateSyncDataBaseStatus, updateResult, updateTime } =
    useSetSyncBoxDailySync();

  const {
    collectorsSyncStatus,
    leftCollectorsSync,
    pendingColectorsSync,
    AllPendingSyncs,
  } = useCollectorSyncStatus(currentPlant?.tagFP);

  const _colectorSyncsStatus = collectorsSyncStatus();
  const _leftColectorsSync = leftCollectorsSync();
  const _pendingColectorsSync = pendingColectorsSync() || [];
  const badgeVisibility = AllPendingSyncs && AllPendingSyncs?.length > 0 ? false : true;

  const { syncCollectorsData, status } = useSyncCollectorDatabases(tagFP);


  useEffect(() => {
    if (isOpen) {
      invalidateCollectorsData();
    }
  }, [isOpen]);

  return (
    <OnlineOfflineMode reverse hidden>
      <AllowUsers allowedUserTypes={["S", "O"]}>
        <FeatureStatusContainer hidden feature={features.collectorFeature}>
          <Badge invisible={badgeVisibility} variant="dot" color="primary">
            {!currentPlant?.tagFP || isLoading ? (
              <CircularProgress size="20px" />
            ) : (
              <ComponentMode
                {...{
                  mode: "drawer",
                  icon: "sync",
                  variant: "iconButton",
                  popoverTitle: lang.collectorSync.title,
                  drawerProps: {
                    open: isOpen,
                    onClose: toggleModal,
                    anchor: "right",
                    styles: {
                      drawerWidth: `${syncPanelDrawerWidth}vw`,
                      color: "#000000",
                    },
                  },
                }}
              >
                <AppBar {...{ position: "relative" }}>
                  <Toolbar>
                    <CustomButton
                      action={toggleModal}
                      icon={"close"}
                      variant="iconButton"
                      color="inherit"
                    />
                    <Typography variant="h6">{lang.collectorSync.title}</Typography>
                  </Toolbar>
                </AppBar>

                <PageContainer>
                  {["success", "error"].includes(updateSyncDataBaseStatus) && (
                    <PageContainer padding={"24px 0"}>
                      <StatusAndMessage
                        {...{
                          status: updateSyncDataBaseStatus === "success" ? "success" : "error",
                          error: "",
                          message:
                            updateResult &&
                            `${lang.collectorSync.updatedSyncBoxData}: ${updateTime}`,
                        }}
                      />
                    </PageContainer>
                  )}
                  <FlexContainer align="center" justify="center">
                    <InstrumentSyncBoxIcon
                      pendingSyncs={AllPendingSyncs || []}
                      colectorSyncsStatus={_colectorSyncsStatus}
                      leftColectorsSync={_leftColectorsSync}
                      syncBoxStatusColor={"#6392ff"}
                      pendincColectorsSync={_pendingColectorsSync}
                      actionStatus={status}
                      actionUpdateStatus={updateSyncDataBaseStatus}
                      handleUpdateSyncClick={updateSyncDataBase}
                      handleSyncClick={syncCollectorsData}
                    />
                  </FlexContainer>
                </PageContainer>
              </ComponentMode>
            )}
          </Badge>
        </FeatureStatusContainer>
      </AllowUsers>
    </OnlineOfflineMode>
  );
};

export default InstrumentDeviceSyncPanel;
