import React from "react";
import FieldSelector from "../../shared/form/components/FieldSelector";
import { PermissionInterface } from "./model/PermissionInterface";
import {
  CreatePermission,
  DeletePermission,
  PermissionCRUDDependencies,
  UpdatePermission,
} from "./services/service.permission";
import { usePermissionForm } from "./services/usePermissionsForm";
import { useSessionContext } from "../session/store/sessionContext";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";
import { CrudFormType } from "../../shared/form/types/FormTypes";

type CUSectorFormProps = CUEntityFormPropsType<PermissionInterface> & { isDataUpdated?: boolean };

const CUPermissionForm: React.FC<CUSectorFormProps> = ({
  item,
  actionType,
  icon,
  variant,
  mode,
  isDataUpdated,
}) => {
  const { tagFP } = useSessionContext();
  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreatePermission,
      update: UpdatePermission,
      delete: DeletePermission,
    },
    actionType,
    PermissionCRUDDependencies
  );

  //UI
  const { lang } = useLangLabels();

  const { uiModel, handleSubmit, resetForm } = usePermissionForm(tagFP, item);

  const ui = (() => {
    const formName = lang.permissions.plural;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: icon || "add",
          title: `${lang.actionTypes.create} ${formName.toLowerCase()}`,
          variant: variant || "button",
          mode: mode || "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: icon || "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: variant || "iconButton",
          mode: mode || "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: PermissionInterface) => {
    action(data);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUPermissionForm;
