/**
 * nested object
 * flat key : Ex: (string.string.string)
 * recusrsive navigate searching the last level
 * return value of nested object key
 */

export const getValueFromFlatObjectKey = (object: any, flatKey: string) => {
  const keys = flatKey.split(".");
  const keysLength = keys.length;

  const accessObject = (object: any, key: string): any => {
    const keyPosition = keys.indexOf(key);
    const nextKey = keys[keyPosition + 1];

    if (!object) return undefined;

    if (keysLength - 1 === keyPosition) {
      return object[key];
    } else {
      if (object) {
        return accessObject(object[key], nextKey);
      }
      return undefined;
    }
  };

  const result = accessObject(object, keys[0]);

  return result;
};
