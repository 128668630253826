import CustomButton from "../../../components/buttons/CustomButton";
import { FlexContainer } from "../../../components/containers";
import { usePagination } from "./hooks/usePagination";
import { TextField, Typography } from "@material-ui/core";
import { PaginationOptions } from "./types/PaginationTypes";
import { Autocomplete } from "@material-ui/lab";
import { useAutocompleteStyles } from "../../../components/autocomplete/useAutocompleteStyle";

interface PaginationProps {
  paginationOptions: PaginationOptions;
  totalItems: number;
  selectedData: any;
}

const getValidNumber = (value: any) => {
  if (typeof value === "number" || (typeof value === "string" && !isNaN(parseFloat(value)))) {
    const number = Number(value);
    if (number > 0) {
      return number;
    }
  }
  return undefined;
};

const Pagination = ({ paginationOptions, totalItems: count, selectedData }: PaginationProps) => {
  const {
    pageIndex,
    totalItems,
    pageSize,
    totalPages,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    handlePageIndexChange,
    handlePageSizeChange,
  } = usePagination(paginationOptions, count);
  const classes = useAutocompleteStyles({});

  const handleValueChange = (value: string) => {
    const valueToSet = getValidNumber(value);

    if (valueToSet) {
      if (valueToSet > 5000) {
        handlePageSizeChange(5000);
      } else {
        handlePageSizeChange(valueToSet);
      }
    } else {
      handlePageSizeChange(pageSize);
    }
  };

  return (
    <>
      {totalPages ? (
        <FlexContainer flexWrap="wrap" gap="48px">
          <FlexContainer align="center">
            Rows per page: {"  "}
            <Autocomplete
              value={pageSize.toString()}
              onChange={(e, value) => handleValueChange(value)}
              options={["200", "500", "1000", "2000"]}
              renderInput={(params) => <TextField {...params} />}
              style={{ width: "72px", flexGrow: 1 }}
              disableClearable={true}
              classes={{
                option: classes.option,
              }}
              freeSolo={true}
            />
          </FlexContainer>
          <FlexContainer align="center">
            {`${selectedData?.length}-${pageSize + " "} of ${" " + totalItems}`}
          </FlexContainer>
          <FlexContainer align="center">
            <Typography variant="body1"> Page </Typography>
            <TextField
              size="small"
              color="secondary"
              variant="standard"
              type="number"
              value={pageIndex + 1}
              onChange={(e) => handlePageIndexChange(parseInt(e.target.value) - 1)}
              style={{
                width: "56px",
              }}
            />
            <Typography variant="body1"> {`of ${totalPages}`} </Typography>
          </FlexContainer>
          <FlexContainer align="center" gap="8px">
            <CustomButton
              variant="chip"
              color="inherit"
              action={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </CustomButton>
            <CustomButton variant="chip" color="inherit" action={nextPage} disabled={!canNextPage}>
              {">"}
            </CustomButton>
          </FlexContainer>
        </FlexContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
