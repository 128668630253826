import { Typography } from "@material-ui/core";
import React from "react";
import FlexContainer from "../../../components/containers/FlexContainer";
import { Divider } from "../../../components/Divider";
import TgdTextField from "../../../components/fields/TextField/TgdTextField";
import DefaultFormLayout from "../../../components/form/DefaultFormLayout/DefaultFormLayout";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { errorsLog } from "../../../shared/globals/utils/utils";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CollectorInterface } from "../models/CollectorInterfaces";
import { useCreateUpdateCollector } from "../services/collectorsServices";
import CollectorStatus from "./CollectorStatus";
import { useCarouselModal } from "../../../components/containers/modal/CarouselModal/hooks/useCarouselModal";
import CarouselModal from "../../../components/containers/modal/CarouselModal/CarouselModal";
import { CustomIconType } from "../../../components/buttons/types/CustomButtonTypes";

type createUpdateCollectorType = {
  tagFP: string;
  mode?: "modal" | "content";
} & (
  | {
      type: CrudFormType.create;
      items?: CollectorInterface[];
    }
  | {
      type: CrudFormType.update;
      items: CollectorInterface[];
    }
);

const CreateUpdateCollectorForm: React.FC<createUpdateCollectorType> = ({
  tagFP,
  type,
  items,
}) => {
  const { lang } = useLangLabels();

  const title = lang.actionTypes[type] + " " + lang.collectors.singular;
  const icon: CustomIconType = type === CrudFormType.create ? "add" : "edit";

  const CarrousellModalProps = useCarouselModal({ items });

  const {
    handleCreateUpdateCollector,
    query: { status, message, error },
    errors,
    setValue,
    handleSubmit,
    form,
    reset,
  } = useCreateUpdateCollector({
    tagFP,
    type,
    item: CarrousellModalProps.currentItem,
  });

  errorsLog(errors);

  const Content = (
    <>
      {type === CrudFormType.create ? (
        <TgdTextField
          name="name"
          label={lang.collectors.form.name}
          value={form.name}
          defaultValue={form.name}
          error={errors?.name?.message}
          onChange={(value: any) => setValue("name", value)}
        />
      ) : null}
      <TgdTextField
        name="serialNumber"
        label={lang.collectors.form.serialNumber}
        value={form.serialNumber}
        defaultValue={form.serialNumber}
        error={errors?.serialNumber?.message}
        onChange={(value: any) => setValue("serialNumber", value)}
      />
    </>
  );

  return (
    <>
      <CarouselModal
        title={title}
        buttonIcon={icon}
        buttonVariant={type === CrudFormType.update ? "iconButton" : undefined}
        buttonPopoverTitle={
          lang.actionTypes[type] + " " + lang.collectors.singular
        }
        carouselProps={CarrousellModalProps.carouselProps}
        showUndefinedError={
          type === CrudFormType.create ? false : CarrousellModalProps.showError
        }
      >
        <DefaultFormLayout
          buttonVariant="iconButton"
          componentMode="content"
          onClose={reset}
          fullwidth
          buttonAction={handleSubmit(handleCreateUpdateCollector)}
          buttonText={lang.actionTypes[type]}
          popoverTitle={title}
          {...{ queryStatus: status, alertStatusMessage:message, error, buttonIcon:icon }}
          resetForm={reset}
        >
          {type === CrudFormType.update && CarrousellModalProps.currentItem && (
            <>
              <Typography>{CarrousellModalProps.currentItem.id}</Typography>
              <Typography>{CarrousellModalProps.currentItem.name}</Typography>
              <FlexContainer align={"center"}>
                Estado:{" "}
                <CollectorStatus collector={CarrousellModalProps.currentItem} />
              </FlexContainer>
              <Divider />
              <br />
            </>
          )}
          {Content}
        </DefaultFormLayout>
      </CarouselModal>
    </>
  );
};

export default CreateUpdateCollectorForm;
