import React, { useEffect } from "react";
import { useDeleteCriticalityVerification } from "./services/service.criticality";
import { CircularProgress } from "@material-ui/core";

import CustomButton from "../../components/buttons/CustomButton";
import { CriticalityInterface } from "./models/criticalityInterface";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface Props {
  item: CriticalityInterface;
}
const DeleteCriticityForm: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();

  const { errors, status: deleteStatus, validate, query } = useDeleteCriticalityVerification();

  const { status, message } = useStatusProcessor(query);
  const { position, getClickPosition } = useClickPosition();

  const { setData } = useSnackbar();

  useEffect(() => {
    if (deleteStatus === "error") {
      const componentsError = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";
      setData({
        modal: true,
        errors: `${lang.criticalities.criticalityCannotBeRemoved}: ${componentsError}`,
        severity: "warning",
        position: position,
      });
    }

    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position: position,
      });
    }
  }, [deleteStatus, status, errors]);

  return status === "loading" ? (
    <CircularProgress size="20px" />
  ) : (
    <CustomButton
      variant="iconButton"
      status={status}
      icon="delete"
      popoverTitle={lang.crudActions.delete + " " + lang.criticalities.title.singular}
      action={(e) => getClickPosition(e, () => validate(item))}
    />
  );
};

export default React.memo(DeleteCriticityForm);
