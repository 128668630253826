import React from "react";
import { useRoutesByTagFP } from "../services/service.routes";
import DeleteRoute from "../DeleteRoute";
import AssignCancelMultipleRoutes from "../components/AssignCancelMultipleRoutes";
import CreateRouteForm from "../components/CreateRouteForm";
import { useRoutesGridModel } from "../lang/useRoutesGridModel";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface ManageRoutesPageProps {
  virtualGridWidth: string;
}

const ManageRoutesPage: React.FC<ManageRoutesPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: routes } = useRoutesByTagFP();

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        title: lang.routes.plural,
        entityName: lang.routes.plural,
        gridModel: useRoutesGridModel(),
        items: routes,
        headerActions: (item: any) => (
          <>
            <AssignCancelMultipleRoutes />
            <CreateRouteForm />
          </>
        ),
        itemActions: (item) => <DeleteRoute item={item} />,
      }}
    />
  );
};

export default React.memo(ManageRoutesPage);
