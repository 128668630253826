import React from "react";

import FieldSelector from "../../shared/form/components/FieldSelector";
import {
  CreateElement,
  DeleteElement,
  ElementCRUDDependencies,
  UpdateElement,
} from "./services/service.elements";
import { useElementForm } from "./services/useElementForm";
import { ElementInterface } from "./models/ElementInterface";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";
import { useSessionContext } from "../session/store/sessionContext";

type CUElementFormProps = CUEntityFormPropsType<ElementInterface> & { isDataUpdated?: boolean };

const CUElementForm: React.FC<CUElementFormProps> = ({
  item,
  actionType,
  icon,
  variant,
  mode,
  isDataUpdated,
}) => {
  const { tagFP } = useSessionContext();
  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateElement,
      update: UpdateElement,
      delete: DeleteElement,
    },
    actionType,
    ElementCRUDDependencies
  );

  //UI
  const { lang } = useLangLabels();

  const { uiModel, handleSubmit, resetForm } = useElementForm(tagFP, item);

  const ui = (() => {
    const formName = lang.elements.title.singular;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: icon || "add",
          title: `${lang.actionTypes.create} ${formName.toLowerCase()}`,
          variant: variant || "button",
          mode: mode || "modal",
        };
        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: icon || "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: variant || "iconButton",
          mode: mode || "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: ElementInterface) => {
    action(data);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUElementForm;
