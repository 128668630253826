import React from "react";
import RouterTabs, { useRouteTabProps } from "../../../../components/containers/tabs/RouterTabs";
import { useSessionContext } from "../../../session/store/sessionContext";
import useTabsMenuConfig from "./hooks/useTabsMenuConfig";

const ColectorRoutesPage: React.FC<{}> = () => {
  const { user } = useSessionContext();
  const tabsMenuConfig = useTabsMenuConfig(user);

  const urlDimention = (() => {
    if (user?.type === "S") return 4;
    if (user?.type === "O") return 3;
    if (user?.type === "A") return 0;
    if (user?.type === "SA") return 0;
    if (user?.type === "AC") return 0;
    else return 0;
  })();

  return <RouterTabs {...useRouteTabProps(urlDimention, tabsMenuConfig)} />;
};

export default React.memo(ColectorRoutesPage);
