import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../shared/calendar/calendar.css";

import { Paper } from "@material-ui/core";
import FlexContainer from "../../../components/containers/FlexContainer";
import { Calendar, momentLocalizer, Views, EventProps } from "react-big-calendar";
import moment from "moment";
import { useMemo } from "react";
import { useCalendarRoutesByPeriod } from "../../../domains/routes/services/service.routes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

import "moment/locale/es";
import { useCalendar } from "../../../shared/calendar/hooks/useCalendar";
import CalendarEvent, { EventItem } from "./components/CalendarEvent";
import { CalendarToolbar } from "./components/CalendarToolbar";

const CalendarPage = () => {
  const { lang } = useLangLabels();

  const selectedLanguage = lang.selectedLanguage;
  moment.locale(selectedLanguage);

  const localizer = momentLocalizer(moment);

  const {
    viewOptions,
    view,
    date,
    handleViewChange,
    handleDateChange,
    getEvents,
    onNextClick,
    onPrevClick,
    onTodayClick,
    isToday,
    dateText,
  } = useCalendar({ selectedLanguage });

  const firstDate = moment(date).startOf("month").subtract(1, "weeks").format("YYYY-MM-DD");
  const secondDate = moment(date).endOf("month").add(1, "weeks").format("YYYY-MM-DD");

  const { data: weekRoutes, isLoading } = useCalendarRoutesByPeriod(firstDate, secondDate);

  const events = useMemo(() => getEvents(weekRoutes), [weekRoutes]);

  const components = {
    event: ({ event }: EventProps<EventItem>) => <CalendarEvent event={event} date={date} />,
  };

  return (
    <Paper elevation={3} style={{ padding: "24px", height: "82vh" }}>
      <FlexContainer style={{ height: "100%" }} flexDirection="column">
        <CalendarToolbar
          lang={lang}
          onTodayClick={onTodayClick}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          isToday={isToday}
          dateText={dateText}
          handleViewChange={handleViewChange}
          isLoading={isLoading}
          viewOptions={viewOptions}
          view={view}
        />
        <Calendar
          events={events}
          style={{ width: "100%", height: "calc(100% - 58px)" }}
          localizer={localizer}
          defaultView={Views.WEEK}
          // Components
          components={components}
          // Toolbar
          toolbar={false}
          date={date}
          view={view}
          onView={handleViewChange}
          onNavigate={handleDateChange}
          tooltipAccessor={null}
          // Time
          min={moment("00:00", "HH:mm").toDate()}
          max={moment("12:00", "HH:mm").toDate()}
        />
      </FlexContainer>
    </Paper>
  );
};

export default CalendarPage;
