import { useCarrousell } from "../../../toolbar/hooks/useCarrousell";
import { CarouselModalHookProps } from "../types/CarouselModalTypes";

export const useCarouselModal = ({ items }: CarouselModalHookProps) => {
  const itemCount = items?.length || 0;
  const carouselProps = useCarrousell(itemCount);
  const currentItem = items && items[carouselProps.current];

  const CarouselModalProps = {
    currentItem: currentItem,
    carouselProps: carouselProps,
    showError: !(itemCount > 0),
  };

  return CarouselModalProps;
};
