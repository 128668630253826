import React, { useState } from "react";

import {
  Popover,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TypographyProps,
  PopoverOrigin,
} from "@material-ui/core";
import { useEffect } from "react";
import FlexContainer from "../containers/FlexContainer";
import theme from "../../assets/theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: (props: any) =>
        props.padding === undefined ? theme.spacing(1) : theme.spacing(props.padding),
    },
  })
);

interface Props {
  currentTargetEvent?: HTMLButtonElement | HTMLDivElement | null;
  width?: string;
  mode?: "hover";
  title: string;
  padding?: number;
  children: React.ReactNode;
  hoverChildren?: React.ReactNode;
  open?: boolean;
  handleClose?: () => void;
  typography?: TypographyProps;
  positionAnchorOrigin?: PopoverOrigin;
  positionTransformOrigin?: PopoverOrigin;
  strongTitle?: boolean;
}

const TgdPopover: React.FC<Props> = ({
  padding,
  mode,
  title,
  children,
  currentTargetEvent,
  handleClose,
  typography,
  hoverChildren,
  positionAnchorOrigin,
  positionTransformOrigin,
  width,
  strongTitle,
}) => {
  const defaultAnchorOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "left",
  };

  const defaultTransformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "left",
  };

  const classes = useStyles({
    padding,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null);

  useEffect(() => {
    setAnchorEl(currentTargetEvent);
  }, [currentTargetEvent]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {mode && mode === "hover" && (
        <>
          <div
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handlePopoverClose}
          >
            {children}
          </div>

          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={positionAnchorOrigin || defaultAnchorOrigin}
            transformOrigin={positionTransformOrigin || defaultTransformOrigin}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>{strongTitle ? <strong>{title}</strong> : title}</Typography>
            {hoverChildren && hoverChildren}
          </Popover>
        </>
      )}

      {!mode && open !== undefined && (
        <>
          <Popover
            onClose={handleClose}
            id={open ? "simple-popover" : undefined}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <FlexContainer padding={`${theme.spacing(3)}px`} flexDirection="column" width={width}>
              <Typography {...(typography && typography)}>{title}</Typography>
              {children}
            </FlexContainer>
          </Popover>
        </>
      )}
    </>
  );
};

export default React.memo(TgdPopover);
