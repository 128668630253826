import React from "react";
import FlexContainer from "../../../components/containers/FlexContainer";
import { ListItem, ListItemIcon, Table, TableBody, TableHead } from "@material-ui/core";
import { LubricationPointInterface } from "../../../domains/lubricationPoints/model/lubricationPointsInterface";
import { CustomTableCell } from "./CustomTableCell";
import CriticalityFlags from "../../../domains/infoLubricationPoints/components/CriticalityFlags";
import { ListItemText } from "@mui/material";
import theme from "../../../assets/theme";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CustomIcon } from "../../../components/buttons/CustomIcon";

interface EquipmentDetailProps {
  lubricationPoint: LubricationPointInterface | null | undefined;
}

export const EquipmentDetail: React.FC<EquipmentDetailProps> = ({ lubricationPoint }) => {
  const { lang } = useLangLabels();
  const headStyling = { backgroundColor: "#e6e6e6" };

  return (
    <FlexContainer width={"100%"} justify="center" align="center">
      <FlexContainer
        flexDirection="column"
        gap={"0px"}
        width={"100%"}
        justify="center"
        align="center"
        padding="16px"
      >
        <FlexContainer padding={"0px 0px 8px"}>
          <ListItem>
            <ListItemIcon>
              <CustomIcon
                style={{
                  color: lubricationPoint?.info?.inaccessible
                    ? theme?.palette?.error.main
                    : theme?.palette?.success.main,
                }}
                icon={lubricationPoint?.info?.inaccessible ? "error" : "success"}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                lubricationPoint?.info?.inaccessible
                  ? lang.infoLubricationPoints.inaccessible
                  : lang.infoLubricationPoints.accesible
              }
            />
          </ListItem>
          <ListItem>
            {lubricationPoint && (
              <CriticalityFlags mode="icons" lubricationPoint={lubricationPoint} fontSize={12} />
            )}
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CustomIcon
                style={{
                  color: lubricationPoint?.info?.labelReplacement
                    ? theme?.palette?.error.main
                    : theme?.palette?.success.main,
                }}
                icon={lubricationPoint?.info?.labelReplacement ? "error" : "success"}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                lubricationPoint?.info?.labelReplacement
                  ? lang.lubricationPoints.helpers.needLabelChange
                  : lang.lubricationPoints.helpers.labelInCorrectCondition
              }
            ></ListItemText>
          </ListItem>
        </FlexContainer>

        <Table>
          <TableHead style={headStyling}>
            <CustomTableCell>{lang.lubricationPoints.equipment}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.element}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.component}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.brand}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.capacity}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.function}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.label}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.location}</CustomTableCell>
          </TableHead>
          <TableBody>
            <CustomTableCell>{lubricationPoint?.equipment}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.element}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.component}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.brand}</CustomTableCell>
            <CustomTableCell>
              {lubricationPoint?.capacity} {lubricationPoint?.measureUnit}
            </CustomTableCell>
            <CustomTableCell>{lubricationPoint?.function}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.labelId}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.location}</CustomTableCell>
          </TableBody>
        </Table>
        <Table>
          <TableHead style={headStyling}>
            <CustomTableCell>{lang.lubricantForm.singular}</CustomTableCell>
            <CustomTableCell>{lang.lubricantForm.type}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.model}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.observations}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.oilAnalysis}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.review}</CustomTableCell>
            <CustomTableCell>{lang.routes.plural}</CustomTableCell>
            <CustomTableCell>{lang.lubricationPoints.suggestedQuantity}</CustomTableCell>
          </TableHead>
          <TableBody>
            <CustomTableCell>{lubricationPoint?.lubricant}</CustomTableCell>
            <CustomTableCell>
              {lubricationPoint?.lubricantType} {lubricationPoint?.measureUnit}
            </CustomTableCell>
            <CustomTableCell>{lubricationPoint?.model}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.observations}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.oilAnalysis}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.review}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.routes}</CustomTableCell>
            <CustomTableCell>{lubricationPoint?.suggestedQuantity}</CustomTableCell>
          </TableBody>
        </Table>
      </FlexContainer>
    </FlexContainer>
  );
};
