import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

export const useBrandGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    id: { label: lang.globalentityValues.id },
    brand: { label: lang.instruments.brand },
  };

  return gridModel;
};
