import React, { useEffect, useMemo } from "react"
import { Hidden, TextField, Typography } from "@material-ui/core"
import CustomButton, { ButtonColorType, ButtonVariant } from "../../components/buttons/CustomButton"
import FlexContainer from "../../components/containers/FlexContainer"
import xlsxImage from '../../assets/img/xlsx-file-miniature.png'
import { useLangLabels } from "../../shared/lang/services/useLangLabels"
import { usePerson } from "../session/store/sessionStore"
import { useSendEmailWithAttachment } from "./services/service.email"
import { useForm } from "react-hook-form"
import { useExportXlsx } from "../../shared/xlsx/useExportXlsx"
import { htmlAnyGridArea } from "./emailTemplates/htmlAnyGridAttachment"
import { blobToBase64, errorsLog } from "../../shared/globals/utils/utils"
import ComponentMode from "../../components/componentMode/ComponentMode"
import StatusAndMessage from "../../shared/queries/errors/components/StatusAndMessage"
import { capitalize } from "../../shared/globals/utils/capitalize"
import SelectArea from "../areas/components/SelectArea"
import HandleUndefined from "../../components/containers/handleUndefined/HandleUndefined"

export const XlsxMiniatureFile:React.FC<{fileTitle:string}> = React.memo(({fileTitle}) => {
    return(
        <FlexContainer align='center'>
        {/* <Paper style={{width:'40px', height:'40px'}} elevation={3}> */}
            <img style={{width:'35px'}} src={xlsxImage} alt='xlsx archive miniature' />
        {/* </Paper> */}
        <Typography variant='body1'>{fileTitle}.xlsx</Typography>
    </FlexContainer>
    )
})


interface Props{
    items?:any[]
    attachmentTitle?:string
    tagFP?:string
    variant?:ButtonVariant
    color?:ButtonColorType
}

const SendEmailWithAttachment:React.FC<Props> = ({items, attachmentTitle, tagFP, variant, color}) => {

    const {lang} = useLangLabels()
    const {data:person} = usePerson()
    const {sendEmail,query:{status,error,message,clearStatus}}= useSendEmailWithAttachment()
    const {register, setValue ,watch, errors,reset } = useForm()
    const {file} = useExportXlsx(items || [],attachmentTitle) 

    const emailFooter = useMemo(()=> 
        `${lang.sendEmail.att} ${capitalize(person?.name)}. ${lang.sendEmail.charge }`
    , [lang.sendEmail.att, lang.sendEmail.charge, person?.name])

    const submit = (file:any) => {
        (file !== undefined ) &&
        sendEmail({
            attachment:file,
            htmlEmailBody:htmlAnyGridArea({
                content:watch('body'),
                footer:emailFooter
            }) || '',
            fileName:attachmentTitle || 'unnamed',
            emailAddress:watch('emailAddress')
        })
    } 

    const handleSend = ()=> {
        blobToBase64(file,submit)
    }

    useEffect(()=> {
        register({name:'emailAddress',value:''},{required:true})
    },[register])

    useEffect(()=>{
        //Reset Form
        status=== 'success' && reset()
    },[status])

    errorsLog(errors)

    return (
        !items || items.length < 1
        ? <HandleUndefined callback={()=>console.log('holi')} showError={true}>
            {(callback)=><CustomButton action ={callback} icon={'email'} variant='iconButton' color="secondary" />}
        </HandleUndefined>
        :   <ComponentMode icon={'email'} variant={variant ||'button'} buttonColor={color || 'secondary'} popoverTitle={lang.sendEmail.title} mode='popover'>
                <StatusAndMessage status={status} error={error} message={message} reset={clearStatus} />
                <br/>
                {tagFP && <SelectArea complete onChange={(value:any)=>setValue('emailAddress',value.email)} tagFP={tagFP} />}
                <Typography variant='body2'>{lang.sendEmail.recipient}: <br/> <strong>{watch('emailAddress')}</strong></Typography>
                <TextField
                    multiline
                    minRows={'8'}
                    maxRows={'12'}
                    variant='outlined'
                    size='small'
                    label={lang.sendEmail.emailBody}
                    name={'body'}
                    inputRef={register()}
                />
                <Typography>{emailFooter}</Typography>
                <XlsxMiniatureFile fileTitle={attachmentTitle || 'unnamed'}/>
                <CustomButton status={status} action={handleSend} icon='send' />
        </ComponentMode> 
    );
}

export default React.memo(SendEmailWithAttachment);


