import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'

const PageContainer = styled('div')`
    position:relative;
    backgroundColor:${(props:CSSProperties)=>props.backgroundColor || 'unset'};
    padding:${(props:CSSProperties)=>props.padding || '24px'};
    width:${(props:CSSProperties)=>props.width || ''};
    box-sizing:border-box;
`

export default React.memo(PageContainer)