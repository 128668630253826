import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { InputComponentTypes } from "../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../shared/form/useForm";
import { ModelInterface } from "../types/modelTypes";

export const useModelForm = (model?: ModelInterface) => {
  const { lang } = useLangLabels();

  const modelForm = useForm<ModelInterface>({
    id: {
      value: model?.id || "",
      component: InputComponentTypes.none,
    },
    model: {
      label: lang.instruments.model,
      value: model?.model || "",
      placeholder: lang.instruments.model,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
  });

  return modelForm;
};
