import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { capitalize } from "../../../shared/globals/utils/capitalize";
import { useUser } from "../../session/store/sessionStore";
import { enumTgdTypes } from "../models/types";
import { usePlantType } from "../services/service.types";
import CUtypeForm from "../CUtypeForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

type Props = {
  tagFP: string;
  defaultValue?: string;
  onChange: (value: any) => void;
  extendInput?: boolean;
  by?: {
    key: string;
    value: string;
  };
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
};

const SelectTypeBy: React.FC<Props> = ({
  tagFP,
  defaultValue,
  onChange,
  by,
  error,
  extendInput,
  disabled,
  clearable,
}) => {
  const showTitle = "name";
  const { data: user } = useUser();
  const { data: plantType } = usePlantType(tagFP, by?.value || enumTgdTypes.equipment);

  const { lang } = useLangLabels();
  const byValue = (by?.value as enumTgdTypes) || enumTgdTypes.equipment;
  const label = lang.enumTgdTypes[byValue];

  return (
    <SimpleAutocompleteSelect
      {...{
        key: defaultValue,
        error,
        items: plantType || [],
        defaultValue,
        label,
        onChange,
        extendInput,
        showTitle,
        disabled,
        clearable: clearable ?? false,
      }}
    >
      {user?.type !== "O" && (
        <CUtypeForm defaultType={by?.value as enumTgdTypes} actionType={CrudFormType.create} />
      )}
    </SimpleAutocompleteSelect>
  );
};

export default React.memo(SelectTypeBy);
