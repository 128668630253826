import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLubricationPointsGridModel } from "../lang/useLubricationPointsGridModel";

interface ElementsGridProps {
  virtualGridWidth: string;
  elements: any[];
}

export const ElementsGrid = ({ virtualGridWidth, elements }: ElementsGridProps) => {
  const { lang } = useLangLabels();

  const lubricationPointsGridModel = useLubricationPointsGridModel();

  return (
    <VirtualGrid
      {...{
        title: lang.elements.title.plural,
        entityName: "assets-tab-" + lang.elements.title.plural,
        documentExportTitle: lang.elements.title.plural,
        width: virtualGridWidth,
        items: elements,
        gridModel: lubricationPointsGridModel,
      }}
    />
  );
};
