import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

export const useIncomeGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    id: { label: lang.globalentityValues.id },
    admissionDate: { label: lang.incomeForm.admissionDate },
    lotNumber: { label: lang.incomeForm.lotNumber },
    referrer: { label: lang.incomeForm.referrer },
    lubricant: { label: lang.lubricantForm.singular },
    quantity: {
      label: `${lang.incomeForm.quantity} (${lang.incomeForm.measurementUnits})`,
    },
    date: { label: lang.globalentityValues.date },
    tagFP: { label: lang.globalentityValues.tagFP },
  };

  return gridModel;
};
