import React from "react";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../../session/store/sessionContext";
import CULubricantForm from "../CULubricantForm";
import DeleteLubricantform from "../DeleteLubricantform";
import { useLubricantsGridModel } from "../lang/useLubricantsGridModel";
import { useAllLubricantsByPlant } from "../services/service.lubricants";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageLubricantsPageprops {
  virtualGridWidth: string;
}

const ManageLubricantsPage: React.FC<ManageLubricantsPageprops> = ({
  virtualGridWidth,
}) => {
  const { tagFP } = useSessionContext();
  const { lang } = useLangLabels();
  const { data: lubricants, isFetching } = useAllLubricantsByPlant();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item) => (
        <CULubricantForm
          item={item}
          tagFP={tagFP}
          actionType={CrudFormType.update}
          isDataUpdated={!isFetching}
        />
      ),
    },
  ];

  return (
    <VirtualGrid
      width={virtualGridWidth}
      gridModel={useLubricantsGridModel()}
      entityName={lang.lubricantForm.plural}
      items={lubricants || []}
      title={lang.lubricantForm.plural}
      carrousellComponents={carrousellActions}
      headerActions={(item: any) => (
        <CULubricantForm tagFP={tagFP} actionType={CrudFormType.create} />
      )}
      itemActions={(item) => <DeleteLubricantform item={item} />}
    />
  );
};
export default React.memo(ManageLubricantsPage);
