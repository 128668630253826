import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import PageContainer from "../../PageContainer";
import { DialogTitle } from "./components/DialogTitle";
import { TGDModalProps } from "./types/TGDModalTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const TGDModal: React.FC<TGDModalProps> = ({
  children,
  title,
  open,
  handleClose,
  fullWidth,
  fullScreen,
  showDialogActions = true,
  defaultButton,
  footerActions,
  header,
  disableBackdropClick,
  disableCloseButton,
  disableEscapeKeyDown,
}) => {
  const { lang } = useLangLabels();

  const closeModal = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "backdropClick") {
      if (disableBackdropClick) {
        return;
      }
      handleClose && handleClose();
    } else {
      handleClose && handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      onClose={closeModal}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth="md"
      style={{ zIndex: 1300 }}
    >
      {(title || header) && (
        <DialogTitle>
          {title && <PageContainer> {title}</PageContainer>}
          {header && header}
        </DialogTitle>
      )}

      <DialogContent dividers>{children}</DialogContent>

      {showDialogActions && (
        <DialogActions>
          {defaultButton ? (
            defaultButton
          ) : (
            <Button
              autoFocus
              onClick={handleClose}
              variant="outlined"
              disabled={disableCloseButton}
            >
              {lang.words.close}
            </Button>
          )}
          {footerActions && footerActions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default React.memo(TGDModal);
