import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { TgdTypes } from "../models/types";

interface SelectColorProps {
  onChange: (value: any) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

const SelectTypes: React.FC<SelectColorProps> = ({ onChange, defaultValue, error, disabled }) => {
  const { lang } = useLangLabels();
  const label = lang.types.form.type;

  const options = TgdTypes.map((value) => ({
    label: lang.enumTgdTypes[value.label].toUpperCase(),
  }));

  return (
    <SimpleAutocompleteSelect
      {...{
        items: options,
        showTitle: "label",
        onChange,
        defaultValue,
        error,
        label,
        disabled,
      }}
    />
  );
};

export default SelectTypes;
