import styled from "@emotion/styled";
import theme from "../../../../../assets/theme";
import { ModalDialogProps } from "../types/ModalTypes";

const shadowColor = "rgba(0, 0, 0, 0.8)";

export const ModalDialog = styled("div")<ModalDialogProps>`
  top: 0;
  left: 0px;
  display: ${({ isOpen }) => (isOpen ? "unset" : "none")};
  z-index: 5000;
  background: ${theme.palette.background.paper};
  position: relative;
  box-shadow: ${shadowColor} 0px 7px 29px 0px;
  overflow-y: scroll;
  height: ${({ height }) => (height ? height : "70vh")};
  width: ${({ width }) => (width ? width : "60vw")};
  transition: all 0.17s ease-in;
  cursor: default;
`;
