import { QueryStatus } from "react-query";
import { SVGSyncPanelIconType } from "../InstrumentSyncBoxIcon";
import { SVGHoverGroup } from "./SVGHoverGroup";

interface SyncBoxProps {
  backgroundColor: string;
  actionStatus: QueryStatus;
}

export const SyncBox: React.FC<SyncBoxProps> = ({
  backgroundColor,
  actionStatus,
}) => {
  return (
    <SVGHoverGroup
      type="syncBoxStatus"
      actionStatus={actionStatus}
    >
      {/* Background */}
      <path
        className="syncbox-background"
        d="M309,108.16A1.54,1.54,0,0,0,309,108a3.12,3.12,0,0,0-.18-.42c0-.07-.1-.12-.14-.19a4.15,4.15,0,0,0-.27-.32,1.56,1.56,0,0,0-.2-.16l-.22-.19-.12,0-.08,0L232.8,69.12a2.46,2.46,0,0,0-2.24,0l-74.94,37.47-.09,0-.11,0a1.81,1.81,0,0,0-.21.18L155,107a2.43,2.43,0,0,0-.24.3,1.77,1.77,0,0,0-.16.22,2.21,2.21,0,0,0-.16.35,2.77,2.77,0,0,0-.1.26,2.42,2.42,0,0,0-.07.47c0,.06,0,.12,0,.18v89.93a2.5,2.5,0,0,0,1.38,2.24l74.94,37.47s.08,0,.12,0a2.48,2.48,0,0,0,1,.23,2.52,2.52,0,0,0,1-.23s.08,0,.12,0L307.74,201a2.5,2.5,0,0,0,1.38-2.24V108.82A2.39,2.39,0,0,0,309,108.16Z"
      />

      {/* Box */}
      <path
        className="syncbox-border"
        d="M309,108.16A1.54,1.54,0,0,0,309,108a3.12,3.12,0,0,0-.18-.42c0-.07-.1-.12-.14-.19a4.15,4.15,0,0,0-.27-.32,1.56,1.56,0,0,0-.2-.16l-.22-.19-.12,0-.08,0L232.8,69.12a2.46,2.46,0,0,0-2.24,0l-74.94,37.47-.09,0-.11,0a1.81,1.81,0,0,0-.21.18L155,107a2.43,2.43,0,0,0-.24.3,1.77,1.77,0,0,0-.16.22,2.21,2.21,0,0,0-.16.35,2.77,2.77,0,0,0-.1.26,2.42,2.42,0,0,0-.07.47c0,.06,0,.12,0,.18v89.93a2.5,2.5,0,0,0,1.38,2.24l74.94,37.47s.08,0,.12,0a2.48,2.48,0,0,0,1,.23,2.52,2.52,0,0,0,1-.23s.08,0,.12,0L307.74,201a2.5,2.5,0,0,0,1.38-2.24V108.82A2.39,2.39,0,0,0,309,108.16Zm-77.35-34L301,108.82,231.68,143.5l-69.36-34.68Zm-72.44,38.72,69.94,35v84.34l-69.94-35Zm74.94,119.32V147.84l69.94-35v84.35Z"
      />

      {/* SyncBox Text*/}
      <g>
        <path
          className="syncbox-text"
          d="M249.58,161.65q0-.68-.48-.81a4.38,4.38,0,0,0-1.68.08,13.34,13.34,0,0,1-2,.22c-1.66,0-2.49-.67-2.49-2.09a4.56,4.56,0,0,1,.62-2.19,8.62,8.62,0,0,1,1.76-2.16,11.35,11.35,0,0,1,2.54-1.75,6.53,6.53,0,0,1,2.48-.77,2.15,2.15,0,0,1,1.7.54,2.39,2.39,0,0,1,.6,1.76l-3.07,1.57c0-.52-.16-.84-.48-1a1.71,1.71,0,0,0-1.3.23,3.91,3.91,0,0,0-1.3,1,1.93,1.93,0,0,0-.48,1.17.61.61,0,0,0,.53.63,5.35,5.35,0,0,0,1.85-.12,9.27,9.27,0,0,1,2.18-.2,2,2,0,0,1,2.07,2.24,5.27,5.27,0,0,1-1.26,3.28,10.33,10.33,0,0,1-3.47,2.73,7.46,7.46,0,0,1-2.82.87,2.29,2.29,0,0,1-1.89-.56,2.59,2.59,0,0,1-.64-1.91l3.09-1.57c0,.68.18,1.1.53,1.24a2.4,2.4,0,0,0,1.73-.39,3.68,3.68,0,0,0,1.21-.95A1.84,1.84,0,0,0,249.58,161.65Z"
        />
        <path
          className="syncbox-text"
          d="M258.92,153.05l2.36-6.75,3.33-1.7-4.12,10.3v4.59l-3.13,1.59v-4.59l-4.11-6.1,3.31-1.69Z"
        />
        <path
          className="syncbox-text"
          d="M276.22,151.48,273.15,153l-4.53-5.65v8l-3.08,1.57V144.13l3.08-1.57,4.52,5.65v-8l3.08-1.56Z"
        />
        <path
          className="syncbox-text"
          d="M288.51,140.88a6.94,6.94,0,0,1-.72,2.73,9.24,9.24,0,0,1-1.84,2.52,11.06,11.06,0,0,1-2.72,2c-1.68.86-3,1-4,.38s-1.45-1.9-1.45-3.9v-.63a10.35,10.35,0,0,1,.65-3.62,10,10,0,0,1,1.89-3.14,9.51,9.51,0,0,1,2.84-2.22c1.55-.79,2.8-1,3.74-.68a2.66,2.66,0,0,1,1.61,2.56l-3.07,1.56c0-.76-.22-1.22-.59-1.37a2.16,2.16,0,0,0-1.69.35,3.55,3.55,0,0,0-1.68,1.72,7.36,7.36,0,0,0-.57,3V143a4.5,4.5,0,0,0,.52,2.66c.34.41.93.4,1.77,0a4.46,4.46,0,0,0,1.62-1.33,3.33,3.33,0,0,0,.6-1.9Z"
        />
        <path
          className="syncbox-text"
          d="M243,190V177.21l4.6-2.34a6.41,6.41,0,0,1,3.75-1c.86.16,1.29.82,1.29,2a4.31,4.31,0,0,1-.46,1.93,5.62,5.62,0,0,1-1.34,1.72,1.64,1.64,0,0,1,1.53.2,1.82,1.82,0,0,1,.54,1.48,5.5,5.5,0,0,1-1.19,3.43,10.16,10.16,0,0,1-3.53,2.76Zm3.09-9,1.6-.82a4,4,0,0,0,1.44-1.11,2.06,2.06,0,0,0,.43-1.28c0-.54-.15-.85-.46-.93a2.49,2.49,0,0,0-1.5.41l-1.51.77Zm0,2v3.06l2-1a3.53,3.53,0,0,0,1.28-1,2.17,2.17,0,0,0,.45-1.31c0-1.06-.53-1.33-1.59-.79Z"
        />
        <path
          className="syncbox-text"
          d="M265.41,172.44a10.15,10.15,0,0,1-.69,3.66,10.34,10.34,0,0,1-2,3.22,9.86,9.86,0,0,1-2.91,2.26,5.47,5.47,0,0,1-2.9.73,2.53,2.53,0,0,1-2-1.14,5.22,5.22,0,0,1-.75-2.83v-.72a10.12,10.12,0,0,1,.69-3.67,10.41,10.41,0,0,1,2-3.22,10,10,0,0,1,2.93-2.28,5.37,5.37,0,0,1,2.9-.7,2.56,2.56,0,0,1,2,1.19,5.22,5.22,0,0,1,.71,2.91Zm-3.13,1.06a3.76,3.76,0,0,0-.64-2.57c-.42-.44-1-.46-1.82-.06-1.55.79-2.36,2.36-2.45,4.73v.94a4,4,0,0,0,.62,2.57c.42.46,1,.48,1.86.07a4,4,0,0,0,1.8-1.91,7.48,7.48,0,0,0,.64-3.18Z"
        />
        <path
          className="syncbox-text"
          d="M271.58,166.89l2.09-5.32,3.52-1.79-3.57,8.16,3.67,4.58-3.56,1.81-2.15-3.24-2.15,5.43-3.55,1.81,3.66-8.31L266,165.49l3.51-1.79Z"
        />
      </g>
    </SVGHoverGroup>
  );
};
