import React, { useEffect, useMemo, useState } from "react";
import SelectSimpleEntity from "../../../components/autocomplete/old/SelectSimpleEntity";
import { StockInterface } from "../models/StockInterface";
import { useStockAreaCategorySubcategory } from "../services/service.stock";
import { SubCategoryInterface } from "../sub-categories/subCategoryInterface";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  subcategory: SubCategoryInterface;
  error: string;
  handleSelect: (item: StockInterface) => void;
}

const SelectStockProduct: React.FC<Props> = ({ subcategory, error, handleSelect }) => {
  const { lang } = useLangLabels();

  const queryData = useMemo(() => {
    if (!!subcategory) {
      return {
        categoryArea: subcategory?.categoryArea,
        category: subcategory?.category,
        subcategory: subcategory?.subcategory,
        tagFP: subcategory?.tagFP,
      };
    }

    return undefined;
  }, [subcategory]);

  const [state, setstate] = useState();
  const { data: stockItems } = useStockAreaCategorySubcategory(queryData);
  const seletedOption = stockItems?.filter((item) => item.product === state)[0];

  useEffect(() => {
    if (seletedOption) {
      handleSelect(seletedOption);
    }
  }, [state]);

  return (
    <SelectSimpleEntity
      entityList={stockItems || []}
      showTitle="product"
      onChange={setstate}
      label={error || lang.select.items}
      color={error ? "primary" : "secondary"}
    />
  );
};

export default React.memo(SelectStockProduct);
