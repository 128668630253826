import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Toolbar,
  Divider,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import DraftsIcon from "@material-ui/icons/Drafts";
import MarkunreadIcon from "@material-ui/icons/Markunread";
import IconButton from "@material-ui/core/IconButton";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  useAddNotification,
  useReadedNotifications,
  useReadUnreadNotification,
  useUnReadedNotifications,
} from "../../domains/notifications/services/notificationServices";
import { useToken, useUser } from "../../domains/session/store/sessionStore";
import { useDate } from "../../shared/date/useDate";
import { useFactoriesByUser } from "../../domains/factories/services/service.factory";
import StatusAndMessage from "../../shared/queries/errors/components/StatusAndMessage";
import { FactoryInterface } from "../../domains/factories/model/FactoryInterface";
import CustomButton from "../../components/buttons/CustomButton";
import Form from "../../components/form/Form";
import PageContainer from "../../components/containers/PageContainer";
import TGDModal from "../../components/containers/modal/TGDModal/TGDModal";
import { NotificationInterface } from "../../domains/notifications/models/NotificationInterface";
import ResourceList from "../../components/grids/ResourceList";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

const useStyles = makeStyles((theme) => ({
  HeaaderNotifPage: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "auto",
  },
  form: {
    width: "100%",
  },
}));

const AddNotificationForm: React.FC<{}> = ({}) => {
  const { data: factoriesByUser } = useFactoriesByUser();
  const { lang } = useLangLabels();
  const classes = useStyles();
  const { mutate, status, message, error } = useAddNotification();
  const token = useToken();
  const { nowDate } = useDate();

  const [newNotification, setNewNotification] = useState({
    title: "",
    body: "",
    global: false,
    tagF: "",
    tagFP: "",
    releaseDate: nowDate(),
    readingLevel: 4,
  });

  const handleChange = (e: any) => {
    setNewNotification({ ...newNotification, [e.target.name]: e.target.value });
  };

  const handleDate = (e: any) => {
    let badDateFormat = e.target.value.split("-");
    let date = `${badDateFormat[2]}-${badDateFormat[1]}-${badDateFormat[0]}`;
    setNewNotification({ ...newNotification, [e.target.name]: date });
  };

  const handleSubmit = () => {
    mutate({
      data: newNotification,
      token,
    });
  };

  return status === "loading" ? (
    <CircularProgress />
  ) : (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          borderTop: "solid 1px #d3d3d3",
          padding: "24px 0",
          marginBottom: "16px",
          borderBottom: "solid 1px #d3d3d3",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <StatusAndMessage {...{ status, message, error }} />
        <br />
        <Typography variant="h5">{lang.client.newNotification}</Typography>
        <br />

        <div style={{ display: "flex", gap: "24px" }}>
          <TextField
            size="small"
            label="Título"
            type="text"
            name="title"
            value={newNotification.title}
            required
            onChange={handleChange}
          />

          <div>
            <InputLabel id="demo-simple-select-label">{lang.client.selectAFactory}</InputLabel>
            <Select
              className={classes.form}
              name="tagF"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newNotification.tagF}
              onChange={handleChange}
              label={lang.client.selectAFactory}
            >
              {factoriesByUser &&
                factoriesByUser.map((item: FactoryInterface, index: number) => (
                  <MenuItem value={item.tagF} key={index}>
                    {item.tagF}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <TextField
            type="date"
            label={lang.client.publicationDate}
            name="releaseDate"
            className={classes.form}
            onChange={handleDate}
            InputProps={{
              startAdornment: <InputAdornment position="start">|</InputAdornment>,
            }}
          />
        </div>

        <br />
        <TextField
          className={classes.form}
          multiline
          rowsMax={20}
          label={lang.client.message}
          onChange={handleChange}
          name="body"
          value={newNotification.body}
          required
        />
        <br />

        <CustomButton type="submit" {...{ status, message, error }} icon="send" color="secondary" />
      </div>
    </Form>
  );
};

const NotificationsPage: React.FC<{}> = ({}) => {
  const {lang} = useLangLabels();

  // State and querys
  const classes = useStyles();
  const { data: user } = useUser();
  const { data: notifications } = useUnReadedNotifications();
  const { data: readedNotifications } = useReadedNotifications();

  // Actions
  const { readOne, unReadOne, readAll } = useReadUnreadNotification();
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  // Component to pass data
  const UnreadButton = (item: any) => {
    return (
      <>
        <IconButton onClick={() => unReadOne(item)} color="primary">
          <MarkunreadIcon />
        </IconButton>
      </>
    );
  };

  const ReadButton = (item: any) => {
    return (
      <>
        <IconButton onClick={() => readOne(item)} color="secondary">
          <DraftsIcon />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <PageContainer padding="0px">
        <div>
          <Toolbar
            style={{ display: "flex", justifyContent: "space-between" }}
            className={classes.HeaaderNotifPage}
          >
            <Typography variant="h5">{lang.client.notifications}</Typography>
            <div>
              {user && user?.type !== "C" && user?.type !== "S" && user?.type !== "O" && (
                <Button
                  style={{ margin: "0 16px" }}
                  onClick={handleModal}
                  variant="contained"
                  color="secondary"
                  startIcon={<AddAlertIcon />}
                >
                  {lang.client.newNotification}
                </Button>
              )}
              <Button onClick={readAll} variant="contained" color="secondary" startIcon={<DraftsIcon />}>
                {lang.client.readAll}
              </Button>
            </div>
          </Toolbar>
        </div>
        <br />

        {notifications &&
          readedNotifications &&
          notifications.length === 0 &&
          readedNotifications.length === 0 && (
            <>
              <Paper style={{ padding: "24px" }}>
                <Typography>{lang.client.emptyNotificationTray}</Typography>
              </Paper>
              <br />
              <br />
            </>
          )}

        {notifications && (
          <>
            <Typography>{lang.client.unread}</Typography>
            <br />
            {notifications.map((item: NotificationInterface, index: number) => (
              <ResourceList
                key={index}
                state={item}
                handleState={readOne}
                children={ReadButton}
                toggleButton={<ExpandMoreIcon />}
                actionEdit={() => readOne(item)}
              />
            ))}
          </>
        )}

        <Divider variant="middle" />
        <br />

        {readedNotifications && (
          <>
            <Typography>{lang.client.read}</Typography>
            <br />
            {readedNotifications.map((item: NotificationInterface, index: number) => (
              <ResourceList
                key={index}
                state={item}
                handleState={readOne}
                children={UnreadButton}
                toggleButton={<ExpandMoreIcon />}
                actionEdit={() => readOne(item)}
              />
            ))}
          </>
        )}
      </PageContainer>
      <TGDModal open={open} handleClose={handleModal}>
        <AddNotificationForm />
      </TGDModal>
    </>
  );
};

export default NotificationsPage;
