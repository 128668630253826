import React, { useEffect } from "react";

import { CircularProgress } from "@material-ui/core";

import CustomButton from "../../components/buttons/CustomButton";

import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { useDeleteContainer } from "./services/service.containers";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface Props {
  item: any;
  typeLabel: string;
}

const DeleteContainerForm: React.FC<Props> = ({ typeLabel, item }) => {
  const { lang } = useLangLabels();

  const type = typeLabel;
  const { deleteContainer, status, message, error } = useDeleteContainer();
  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (status === "error") {
      setData({
        modal: true,
        errors: `${error}`,
        severity: "error",
        position: position,
      });
    }

    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const submit = (item: any) => {
    deleteContainer(item);
  };

  if (status === "loading") return <CircularProgress size="20px" />;
  return (
    <>
      <CustomButton
        popoverTitle={`${lang.crudActions.delete} ${type}`}
        variant="iconButton"
        icon="delete"
        action={(e) => getClickPosition(e, () => submit(item))}
      />
    </>
  );
};

export default DeleteContainerForm;
