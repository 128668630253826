import React from "react";
import { Alert } from "@material-ui/lab";
import { DEV_MODE } from "../appConfig";
import { useLangLabels } from "../../lang/services/useLangLabels";
import PageContainer from "../../../components/containers/PageContainer";

export interface Featureinterface {
  state: boolean;
  message: string;
}

type FeaturesType = {
  [key: string]: Featureinterface;
};

export const useFeatures = () => {
  const { lang } = useLangLabels();
  const features: FeaturesType = {
    testingPageComponent: {
      state: DEV_MODE,
      message: "",
    },
    calendarPage: {
      //manage calendar feature
      state: DEV_MODE,
      message: "proximamente un calendario",
    },
    sugestedQuantity: {
      state: true,
      message: "pronto estará disponible",
    },
    collectorFeature: {
      //manage collector config page on plant configs & manage sync panel on navbar component
      state: true,
      message: lang.messages.features.collectorConfig,
    },
    supervisorSurvey: {
      //manage supervisos hability to see like lubricator user ('O')
      state: true,
      message: lang.messages.features.supervisorSurvey,
    },
    supervisorClientVisor: {
      //Manage supervisor hability to see client dashboard from a plant
      state: true,
      message: lang.messages.features.supervisorClientVisor,
    },
  };
  return features;
};

interface Props {
  feature: Featureinterface | undefined;
  hidden?: boolean;
}

const FeatureStatusContainer: React.FC<Props> = ({ children, feature, hidden }) => {
  const { state, message } = feature || { state: true, message: "" };

  return (
    <>
      {state ? (
        children
      ) : hidden ? null : (
        <PageContainer>
          <Alert severity="warning">{message}</Alert>
        </PageContainer>
      )}
    </>
  );
};

export default FeatureStatusContainer;
