import { useEffect, useState } from "react";

export const useSearchAll = (items:any[] | undefined | null) => {
    const [filteredItems, setFilteredItems] = useState(items);
    const [search,setSearch] = useState('')
    const keys = items && items.length > 0 && Object.keys(items[0]) 

    useEffect(()=>{
        if(items){
            if(search !== ''){
                const searched = items.filter(item => {
                    const isMatch = keys && keys.reduce((acc:boolean,k:string)=>{
                        if(acc === true) 
                            return true
                        if(typeof item[k] === 'number')
                            return item[k].toString().indexOf(search) === -1 ? false : true
                        if(typeof item[k] === 'string') 
                            return item[k].toLowerCase().indexOf(search) === -1 ? false : true
                        else 
                            return false
                    },false)
                    return isMatch
                })
                setFilteredItems(searched)
            }
            if(search === ''){setFilteredItems(items)}
        }
    },[search,items])

    const handleSearch = (e:any) =>{
        setSearch(e.target.value?.toLowerCase())
    }

    const setSearchValue = (value:string) => {
        setSearch(value.toLowerCase())
    }

    return{search,handleSearch,filteredItems,setSearchValue}
}