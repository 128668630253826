import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";
import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import CustomButton from "../../buttons/CustomButton";
import ComponentMode from "../../componentMode/ComponentMode";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface ColumnFilterPanel {
  labels: string[];
  onChange: (label: string) => void;
  hiddenColumns: { [key: string]: boolean };
  gridModel: GridModelInterface;
  handleActivateDesactivateAllColumns: () => void;
}

const FilterColumnsPanel: React.FC<ColumnFilterPanel> = ({
  labels,
  onChange,
  hiddenColumns,
  gridModel,
  handleActivateDesactivateAllColumns,
}) => {
  const { lang } = useLangLabels();

  return (
    <>
      <ComponentMode mode="popover" popoverTitle={lang.filters.filterColumns} icon="columns">
        <CustomButton
          action={handleActivateDesactivateAllColumns}
          variant="chip"
          color="secondary"
          icon="toggleOff"
          title={lang.filters.disableEverything}
        />

        {labels?.map((key: string, index: number) => (
          <FormControlLabel
            key={index}
            control={
              <Switch
                onChange={() => onChange(key)}
                size="small"
                name={key}
                checked={!hiddenColumns[key]}
                color="primary"
              />
            }
            label={gridModel ? gridModel[key]?.label || key : key}
          />
        ))}
      </ComponentMode>
    </>
  );
};

export default React.memo(FilterColumnsPanel);
