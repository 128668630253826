import React from "react";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useUser } from "../../../../domains/session/store/sessionStore";
import CreateUpdatePLCForm from "./CreateUpdatePLCForm";
import { useGetAllPLCs } from "../services/plcService";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";

interface SelectPLCProps {
  onChange: (value: any) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

const SelectPLC: React.FC<SelectPLCProps> = ({ onChange, defaultValue, error, disabled }) => {
  const { lang } = useLangLabels();
  const { data: user } = useUser();

  const { data: plcs } = useGetAllPLCs();

  const plcOptions = plcs?.map((plc: any) => ({ label: plc.plc || "" })) || [];

  return (
    <SimpleAutocompleteSelect
      error={error}
      items={plcOptions}
      defaultValue={defaultValue}
      label={lang.instruments.plc}
      onChange={onChange}
      showTitle={"label"}
      disabled={disabled}
    >
      {user?.type !== "O" && <CreateUpdatePLCForm actionType={CrudFormType.create} />}
    </SimpleAutocompleteSelect>
  );
};

export default SelectPLC;
