import React,{useState,useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import { useToken } from '../../session/store/sessionStore';
import { getAllResources, getAllResourcesByEmail } from '../../../shared/axios/utils';
import ResourceGrid from '../../../components/grids/ResourceGrid';

interface Props{
    color:string
    title:string
    action:(item: any) => void
    endpoint:string
    email?:string
    onLoad:React.ReactNode
    filterByEmail?:string[]
    refresh:boolean | null
}

const ResourceGridContainer:React.FC<Props> = ({color,title,action,endpoint,email,onLoad,filterByEmail,refresh}) => {

    const [allItems, setAllItems] = useState();
    const token = useToken()
    const location = useLocation()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading,setLoading] = useState(false)
    /* const factories = useAllFactoriesWithPlants()
    const plants = usePlants()
    const accountManagerFactories = useAccountManagerFactories(email)
    const engineerPlants = useEngineerPlants(email)
    const operatorPlants = useOperatorPlants(email) */

    const childrenComponent = <CircularProgress color="secondary" />
    

    useEffect(() => {

        if(endpoint === 'AllFactoriesWithPlants'){
            setLoading(true)
            getAllResources(endpoint,{token})
            .then(res => {
                let Resources = filterByEmail ? res.filter((item:any) => filterByEmail?.includes(item.referrer)) : res
                setAllItems(Resources)
                setLoading(false)
            })
            .catch(res => console.log(res))
        }

        if(endpoint === 'Plants'){
            setLoading(true)
            getAllResources(endpoint,{token})
            .then(res => {
                if(location.pathname.split('-').reverse()[0] === 'supervisor'){
                    let resources = res.filter((item:any) => filterByEmail?.includes(item.referrer))
                    setLoading(false)
                    setAllItems(resources)
                }else{setAllItems(res.data)}
            })
            .catch(res => console.log(res))
        }

        if((endpoint === 'AccountManagerFactories' ) && (!!email)){
            setLoading(true)
            getAllResourcesByEmail(endpoint, email, {token})
            .then(res => {
                setAllItems(res)
                setLoading(false)
            })
            .catch(err=> console.log(err))
        }

        if((endpoint === 'SupervisorPlants' ) && (!!email)){
            setLoading(true)
            getAllResourcesByEmail(endpoint, email, {token})
            .then(res => {
                setAllItems(res)
                setLoading(false)
            })
            .catch(err=> console.log(err))
        }
        if((endpoint === 'OperatorPlants' ) && (!!email)){
            setLoading(true)
            getAllResourcesByEmail(endpoint, email, {token})
            .then(res => {
                setAllItems(res)
                setLoading(false)
            })
            .catch(err=> console.log(err))
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, email, refresh]);

   
    return (
        <div>
            <ResourceGrid
                title={title}
                color={color}
                allResources={allItems}
                action={action}
/*                 filter={'desarrollo@tgdsa.com'}
 */                onLoad={onLoad}
                children={childrenComponent}
            />
            
        </div>
    );
}

export default ResourceGridContainer;
