import { TextField, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";
import FlexContainer from "../../../../components/containers/FlexContainer";
import FeatureStatusContainer, {
  useFeatures,
} from "../../../../shared/config/features/FeatureStatusContainer";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { FormRegExp } from "../../../../shared/regExp/regExp";
import { useGetAllAuxByPlantData } from "../../../allAux/services/allAuxServices";
import CUtypeForm from "../../../types/CUtypeForm";
import { enumTgdTypes } from "../../../types/models/types";
import { LubricationPointInterface } from "../../model/lubricationPointsInterface";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface selectSugestedQuantity {
  quantity: string;
  meassureUnit: LubricationPointInterface["suggestedQuantity"];
}

interface SuggestedQuantityParserResult {
  parsed: string | selectSugestedQuantity;
  original: string | selectSugestedQuantity;
}

const suggestedQuantityParser = (
  suggestedQuantity: selectSugestedQuantity | LubricationPointInterface["suggestedQuantity"]
): SuggestedQuantityParserResult => {
  const parsed = (() => {
    if (typeof suggestedQuantity === "string") {
      if (suggestedQuantity.length > 0) {
        const properties = suggestedQuantity.split(" ");
        return {
          quantity: properties[0],
          meassureUnit: properties[1],
        };
      } else
        return {
          quantity: "",
          meassureUnit: "",
        };
    } else {
      return `${suggestedQuantity?.quantity || ""} ${suggestedQuantity?.meassureUnit || ""}`;
    }
  })();

  return {
    parsed,
    original: suggestedQuantity,
  };
};

interface SelectSugestedQuantityProps {
  onChange: (value: LubricationPointInterface["suggestedQuantity"]) => void;
  defaultValue?: LubricationPointInterface["suggestedQuantity"];
  children?: React.ReactNode;
}

const SelectSuggestedQuantity: React.FC<SelectSugestedQuantityProps> = ({
  children,
  onChange,
  defaultValue,
}) => {
  const defaultSugestedCuantity = useMemo(
    () => suggestedQuantityParser(defaultValue || "").parsed as selectSugestedQuantity,
    [defaultValue]
  );
  const { data: types } = useGetAllAuxByPlantData("types");
  const measureUnits = types?.filter((t) => t.type === "UNIDAD");

  const { lang } = useLangLabels();

  const { register, watch, handleSubmit, errors, setValue } = useForm<selectSugestedQuantity>();

  useEffect(() => {
    register(
      { name: "quantity", type: "number" },
      {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
        pattern: {
          value: FormRegExp.number,
          message: lang.validations.messages.justNumbers,
        },
      }
    );
    register("meassureUnit", {
      required: {
        value: !!defaultSugestedCuantity.meassureUnit,
        message: lang.validations.messages.required,
      },
    });
    setValue("quantity", defaultSugestedCuantity.quantity);
    setValue("meassureUnit", defaultSugestedCuantity.meassureUnit);
  }, [defaultSugestedCuantity]);

  const handleValue = (key: keyof selectSugestedQuantity, value: any) => {
    setValue(key, value);
  };

  const handleChange = handleSubmit((data) => {
    const valueToElevate = suggestedQuantityParser(data).parsed as string;
    onChange(valueToElevate);
  });

  useEffect(() => {
    handleChange();
  }, [watch("quantity"), watch("meassureUnit")]);

  return (
    <FeatureStatusContainer feature={useFeatures().sugestedQuantity} hidden>
      <FlexContainer flexDirection="column" width={"100%"}>
        <FlexContainer
          width={"100%"}
          key={defaultSugestedCuantity.quantity + defaultSugestedCuantity.meassureUnit}
        >
          <TextField
            fullWidth
            name="quantity"
            placeholder={lang.incomeForm.quantity}
            defaultValue={defaultSugestedCuantity.quantity}
            type="number"
            variant="outlined"
            label={errors?.quantity?.message || lang.incomeForm.quantity}
            color={errors?.quantity?.message ? "primary" : "secondary"}
            size="small"
            onChange={(e) => handleValue("quantity", e.target.value)}
          />
          <SimpleAutocompleteSelect
            {...{
              label: lang.lubricantForm.measureUnit,
              disabled: true,
              error: errors?.meassureUnit?.message ? lang.validations.messages.required : undefined,
              items: measureUnits || [],
              defaultValue: !!defaultSugestedCuantity.meassureUnit
                ? defaultSugestedCuantity.meassureUnit
                : undefined,
              showTitle: "name",
              onChange: (value) => handleValue("meassureUnit", value),
            }}
          />

          <CUtypeForm
            actionType={CrudFormType.create}
            customTitle={lang.crudActions.create + " " + lang.lubricationPoints.measureUnit.toLowerCase()}
            defaultType={enumTgdTypes.unity}
          />
        </FlexContainer>

        <Typography variant="body2">{lang.lubricationPoints.suggestedQuantityIndicates}</Typography>

        {children && children}
      </FlexContainer>
    </FeatureStatusContainer>
  );
};

export default React.memo(SelectSuggestedQuantity);
