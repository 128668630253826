export enum enumTgdTypes {
  unity = "UNIDAD",
  equipment = "EQUIPO",
  lubricant = "LUBRICANTE",
  observation = "OBSERVACION",
  instrument = "INSTRUMENTO",
}

export const TgdTypes = [
  { label: enumTgdTypes.unity },
  { label: enumTgdTypes.equipment },
  { label: enumTgdTypes.lubricant },
  { label: enumTgdTypes.observation },
  { label: enumTgdTypes.instrument },
];
