import { SurveyInterface } from "../../../model/SurveyInterface";

export type SurveyPreviewProps =
  | {
      surveyId?: SurveyInterface["id"];
      item?: SurveyInterface;
    }
  | {
      surveyId?: SurveyInterface["id"];
      item: SurveyInterface;
    }
  | {
      surveyId: SurveyInterface["id"];
      item?: SurveyInterface;
    };

export const notShow: (keyof SurveyInterface | string)[] = [
  "tagFP",
  "id",
  "sector",
  "tagTGD",
  "accessible",
  "observationsQuantity",
  "startTime",
  "outOfRoute",
  "measureUnit",
  "equipment",
  "element",
  "component",
  "modified",
  "otnumber",
  "modifiedDate",
  "user",
  "manualRegistration",
  "cloudSynchronized",
  "assetCriticality",
  "loadingDate",
  "endTime",
  "routeDate",
];

export const principalItems: (keyof SurveyInterface | string)[] = [
  "id",
  "sector",
  "sampleExtraction",
  "lubricant",
  "measureUnit",
  "impossibleToLubricate",
  "temperature",
  "review",
  "route",
  "inaccessible",
  "outOfRoute",
  "needsLubrication",
  "label",
  "startTime",
];
