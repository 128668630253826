import { useCallback } from "react"
import { trunc } from "../../../../shared/globals/utils/trunc"

const coeficients = [
    {
        min:-1,
        max:3,
        description:'green',
        range:'0% - 3% '
    },
    {
        min:3,
        max:30,
        description:'orange',
        range:'3% - 30%'
    },
    {
        min:30,
        max:100,
        description:'red',
        range:'30% - 100%'
    },
    {
        min:100,
        max:Infinity,
        description:'black',
        range:'100% - 10000%'
    }
]


export const calculateLeakLevel = (_lastlubricantCuantity:number | undefined ,_pointCapacity:number | undefined ) => {

    const lastlubricantCuantity= _lastlubricantCuantity || 0
    const pointCapacity= _pointCapacity || 0

    const leak:number = Math.round((lastlubricantCuantity < 1)
        ? 0 
        : (pointCapacity && ((lastlubricantCuantity * 100)/pointCapacity)))
          
    return{
        ...coeficients.filter((c:any)=> ((leak > c.min) && (leak <= c.max)))[0],
        leakQuantity:leak,
        neededQuantity: trunc((leak/100) * pointCapacity,2)
    }

}

export const useLeakLevel = (params?:{lastlubricantCuantity:number,pointCapacity:number}) => {

    const results = calculateLeakLevel(params?.lastlubricantCuantity,params?.pointCapacity)

    return {
        ...results,
        calculateLeakLevel
    }
}
