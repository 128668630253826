import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

import FlexContainer from "../../../../../../../components/containers/FlexContainer";
import SelectContainerBy from "../../../../../../containers/components/SelectContainerBy";
import TGDRadioButton from "../../../../common/TGDRadioButton";
import { Container } from "react-dom";
import { truncateNumber } from "../../../../../utils/truncateNumber";
import { useLangLabels } from "../../../../../../../shared/lang/services/useLangLabels";

interface LubricantQuanntityCalculator {
  unit: any;
  quantity: number;
  computedQuantity?: number;
  setComputedQuantity?: Dispatch<SetStateAction<number>>;
  lubricant: string;
  onChange: (value: Container) => void;
  setIsBomb?: Dispatch<SetStateAction<boolean>>;
  error?: string;
}

const LubricantQuantityCalculator: React.FC<LubricantQuanntityCalculator> = ({
  unit,
  quantity,
  computedQuantity,
  setComputedQuantity,
  lubricant,
  onChange,
  setIsBomb,
  error,
}) => {
  const { lang } = useLangLabels();

  const [selected, setSelected] = useState<string>();
  const [containerCoeficient, setCoeficientContainer] = useState(0);
  const [container, setContainer] = useState();

  const bomb = lang.surveys.tasks.lubrication.bomb.toUpperCase();

  const [types] = useState<string[]>([bomb, unit]);

  const handleChange = (item: string) => {
    setSelected(item);
    setIsBomb?.(item === bomb ? true : false);
  };

  const handleContainerChange = (value: any) => {
    setCoeficientContainer(value?.coefficient || 0);
    setContainer(value?.name);
    onChange(value);
  };

  useEffect(() => {
    setComputedQuantity?.(truncateNumber(containerCoeficient * quantity, 2) || 0);
  }, [containerCoeficient, quantity]);

  return (
    <FlexContainer align="center">
      {selected === bomb && (
        <>
          <SelectContainerBy
            complete
            defaultValue={container}
            by={{ key: "lubricant", value: lubricant }}
            onChange={handleContainerChange}
            error={error}
          />
          <Typography style={{ width: "100px" }}>
            {computedQuantity} {unit}
          </Typography>
        </>
      )}
      <TGDRadioButton handleChange={handleChange} defaultOption={unit} items={types} />
    </FlexContainer>
  );
};

export default React.memo(LubricantQuantityCalculator);
