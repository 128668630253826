import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import PageContainer from "../../../components/containers/PageContainer";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface ColectorListProps {
  items?: any[];
  primaryText?: string[];
  secondaryText?: { label: string; key: string }[];
  buttonActions?: (item: any) => React.ReactNode;
  iterator?: string;
  customItemList?: (item: any) => React.ReactNode;
}

const ColectorList: React.FC<ColectorListProps> = ({
  items,
  primaryText,
  secondaryText,
  buttonActions,
  iterator,
  customItemList,
}) => {
  const { lang } = useLangLabels();

  return items ? (
    <>
      <PageContainer height="60vh">
        <List dense>
          {!items.length && <Alert severity="warning">{lang.table.noResults}</Alert>}
          {!items ? (
            <CircularProgress size="24px" />
          ) : (
            items.map((item, index) => (
              <div key={index + `${primaryText}`}>
                <ListItem>
                  {primaryText?.map((primary, index: number) => (
                    <ListItemText
                      style={{ width: "180px", paddingRight: "8px" }}
                      key={index}
                      primary={
                        iterator && primaryText
                          ? item[iterator][primaryText[index]]
                          : primaryText.map((text) => text)
                          ? item[primaryText[index]]
                          : item
                      }
                      secondary={
                        iterator && secondaryText
                          ? item[iterator][secondaryText[index].key]?.toUpperCase()
                          : secondaryText?.map((text) => text.key)
                          ? item[secondaryText[index].key]
                            ? item[secondaryText[index].key]?.toUpperCase()
                            : secondaryText[index].label?.toUpperCase()
                          : undefined
                      }
                    />
                  ))}

                  {customItemList && customItemList(item)}

                  {buttonActions && (
                    <ListItemSecondaryAction key={"static2"}>
                      {buttonActions(item)}
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <Divider />
              </div>
            ))
          )}
        </List>
      </PageContainer>
    </>
  ) : null;
};

export default React.memo(ColectorList);
