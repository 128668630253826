import React from "react";
import { useModal } from "../containers/modal/Modal/hooks/useModal";
import TGDModal from "../containers/modal/TGDModal/TGDModal";
import CustomButton from "./CustomButton";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ButtonColorType, ButtonVariant, CustomIconType } from "./types/CustomButtonTypes";

interface Props {
  children: React.ReactNode;
  variant?: ButtonVariant;
  icon: CustomIconType;
  buttonText?: string;
  title?: string;
  color?: ButtonColorType;
  popoverTitle?: string;
  onClose?: (item?: any) => void;
  fullwidth?: boolean;
  seze?: "small" | "default" | "large";
  disabled?: boolean;
  modalTitle?: boolean;
  style?: CSSProperties;
}
const ModalButton: React.FC<Props> = ({
  onClose,
  fullwidth,
  variant,
  children,
  icon,
  buttonText,
  color,
  title,
  popoverTitle,
  disabled,
  modalTitle,
  style,
}) => {
  const { isOpen, closeModal, openModal } = useModal();

  const defaultVariant: ButtonVariant = variant || "button";
  const defaultModalTitle = modalTitle !== undefined ? (modalTitle ? title : undefined) : title;

  const handleClose = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <>
      <CustomButton
        disabled={disabled || false}
        variant={defaultVariant}
        action={openModal}
        popoverTitle={popoverTitle || title}
        icon={icon}
        title={buttonText}
        color={color}
        styleProps={style}
      />

      <TGDModal
        fullWidth={fullwidth || true}
        title={defaultModalTitle}
        open={isOpen}
        handleClose={handleClose}
      >
        {children}
      </TGDModal>
    </>
  );
};

export default React.memo(ModalButton);
