import { Step, StepLabel, Stepper } from "@material-ui/core";
import { useState } from "react";

interface TaskStepperProps {
  tasks: any[];
  iterator: string;
  step: number;
}

export const TaskStepper: React.FC<TaskStepperProps> = ({
  tasks,
  step,
  iterator,
}) => {
  const [activeStep, setActiveStep] = useState(step);

  return (
    <Stepper nonLinear activeStep={activeStep}>
      {tasks.map((label, index) => (
        <Step
          color={"default"}
          completed={index < step ? true : false}
          active={index < step ? true : false}
          key={label[iterator] || label}
        >
          <StepLabel>{label[iterator] || label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
