import { Paper } from "@material-ui/core";
import FlexContainer from "../../../components/containers/FlexContainer";
import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../../components/containers/tabs/RouterTabs";
import { USER_ROUTES } from "../../../shared/routes/routes";
import ObservationsReport from "../../../domains/observations/reports/components/ObservationsReport";
import LossReport from "../../../domains/surveys/components/LossReport/components/LossReport";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const ReportsGenerationPage = () => {
  const { lang } = useLangLabels();

  const tabs: TabsInterface[] = [
    {
      label: lang.reportsGeneration.observations,
      route: `${USER_ROUTES.reports.observations}/*`,
      component: <ObservationsReport />,
    },
    {
      label: lang.reportsGeneration.losses,
      route: `${USER_ROUTES.reports.losses}/*`,
      component: <LossReport />,
    },
  ];

  const tabProps = useRouteTabProps(4, tabs);

  return (
    <Paper elevation={3}>
      <FlexContainer padding={"0px 16px"} flexDirection="column">
        <RouterTabs {...tabProps} />
      </FlexContainer>
    </Paper>
  );
};

export default ReportsGenerationPage;
