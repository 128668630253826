import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

export const useObservationReportGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    sector: { label: lang.sectors.title.singular },
    equipment: { label: lang.STIEquipments.title.singular },
    element: { label: lang.observations.element },
    component: { label: lang.observations.component },
    tagPlanta: { label: lang.reportsGeneration.tagPlanta },
    date: { label: lang.observations.observationDate },
    observation: { label: lang.observations.singular },
    user: { label: lang.observations.user },
    criticality: { label: lang.criticalities.title.singular },
    area: { label: lang.areas.title.singular },
  };

  return gridModel;
};
