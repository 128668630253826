import { PageContainer } from "../../../containers";
import { FieldFilterFormProps } from "../types/FieldFilterTypes";
import FilterButtons from "./FilterButtons";
import PeriodFilter from "./PeriodFilter";
import SearchInput from "./SearchInput";
import SelectedFieldsToFilter from "./SelectedFieldsToFilter";

const FieldFilterForm = ({
  searchValue,
  handleSearchValueChange,
  handleFilterValueChange,
  selectedFields,
  onSelectedFieldAdd,
  onSelectedFieldsClear,
  notSelectedOptions,
  onSelectedFieldDelete,
  period,
  setPeriod,
}: FieldFilterFormProps) => {
  const isSelectedFields: boolean = Object.keys(selectedFields).length > 0;
  return (
    <PageContainer padding="0">
      <SearchInput searchValue={searchValue} handleSearchValueChange={handleSearchValueChange} />
      {isSelectedFields && (
        <SelectedFieldsToFilter
          selectedFields={selectedFields}
          handleFilterValueChange={handleFilterValueChange}
          onSelectedFieldDelete={onSelectedFieldDelete}
        />
      )}
      {period && setPeriod && <PeriodFilter period={period} setPeriod={setPeriod} />}
      <FilterButtons
        notSelectedOptions={notSelectedOptions}
        selectedFields={selectedFields}
        onSelectedFieldAdd={onSelectedFieldAdd}
        onSelectedFieldsClear={onSelectedFieldsClear}
      />
    </PageContainer>
  );
};

export default FieldFilterForm;
