import { InputComponentTypes } from "../../../shared/form/types/FormTypes";
import { useForm } from "../../../shared/form/useForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { enumTgdTypes } from "../../types/models/types";
import { LubricantInterface } from "../model/LubricantInterface";

export const useLubricantForm = (tagFP: string, item?: LubricantInterface) => {
  const { lang } = useLangLabels();

  return useForm<LubricantInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      value: item?.id || 0,
      validations: {
        required: { value: !!item, message: lang.formMessages.required },
      },
      component: InputComponentTypes.none,
    },
    lubricant: {
      label: lang.lubricantForm.name,
      placeholder: lang.lubricantForm.name,
      value: item?.lubricant || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.text,
    },
    type: {
      label: lang.lubricantForm.type,
      placeholder: lang.lubricantForm.type,
      value: item?.type || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.typeBy,
      inputConfig: {
        tagFP: tagFP,
        by: {
          key: "type",
          value: enumTgdTypes.lubricant as enumTgdTypes,
        },
      },
    },
    measureUnit: {
      label: lang.lubricantForm.measureUnit,
      placeholder: lang.lubricantForm.measureUnit,
      value: item?.measureUnit || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.typeBy,
      inputConfig: {
        tagFP: tagFP,
        by: {
          key: "type",
          value: enumTgdTypes.unity as enumTgdTypes,
        },
      },
    },
    requiresObservation: {
      label: lang.lubricantForm.requiresObservation,
      placeholder: lang.lubricantForm.requiresObservation,
      value: item?.requiresObservation || "",
      component: InputComponentTypes.none,
    },
    brand: {
      label: lang.lubricantForm.brand,
      placeholder: lang.lubricantForm.brand,
      value: item?.brand || "",
      component: InputComponentTypes.text,
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: tagFP,
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.none,
    },
  });
};
