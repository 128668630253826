import { FlexContainer } from "../../../../components/containers";
import { useLubricationPointAndInfoByTagTGD } from "../../../lubricationPoints/services/lubricationPointsAdapters";
import EquipmentInfo from "../../../infoLubricationPoints/components/EquipmentInfo";
import { CircularProgress } from "@material-ui/core";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const LubricationPointInfo = ({ tagTGD }: { tagTGD: string }) => {
  const { lang } = useLangLabels();
  const {
    data: lubricationPoint,
    isFetching,
  } = useLubricationPointAndInfoByTagTGD(tagTGD);

  return (
    <FlexContainer justify="space-between">
      <FlexContainer align={"center"}>
        <EquipmentInfo propItem={lubricationPoint} variant="modal" />
        {isFetching ? (
          <CircularProgress size={24} />
        ) : lubricationPoint ? (
          <div>
            <strong>{lubricationPoint?.equipment}</strong> {`>>`}
            <strong>{lubricationPoint?.element}</strong> {`>>`}
            <strong>{lubricationPoint?.component}</strong>
          </div>
        ) : (
          <div>{lang.table.noResults}</div>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default LubricationPointInfo;
