import React, { useEffect, useRef, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import PageContainer from "../../../../components/containers/PageContainer";
import { Outlet } from "react-router-dom";
import FlexContainer from "../../../../components/containers/FlexContainer";
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { usePlantOperators } from "../../../../domains/person/services/personServices";
import { useRoutesByDate } from "../../../../domains/routes/services/service.routes";
import RouterTabs, { useRouteTabProps } from "../../../../components/containers/tabs/RouterTabs";
import CardTitle from "./components/CardTitle";
import { OperatorComponent } from "./components/OperatorComponent";
import { DelayedAndPendingRoutesTable } from "./components/DelayedAndPendingRoutesTable";
import { WeekRoutesList } from "./components/WeekRoutesList";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const SupervisorHomePage: React.FC<{}> = () => {
  const { tagFP } = useSessionContext();
  const { lang } = useLangLabels();

  const { data: operators } = usePlantOperators(tagFP);
  const { status: dayRoutesStatus, error } = useRoutesByDate();

  const secondElementRef = useRef<HTMLDivElement | null>(null);
  const [firstElementMaxHeight, setFirstElementMaxHeight] = useState<string>();

  const tabsConfig = [
    ...(operators
      ? operators.map((operator, i) => ({
          label: operator.name,
          route: operator.name,
          component: <OperatorComponent key={i} operator={operator} />,
          data: operator,
        }))
      : []),
  ];

  const routeTabProps = useRouteTabProps(3, tabsConfig);

  useEffect(() => {
    if (secondElementRef.current) {
      setFirstElementMaxHeight(`${secondElementRef.current.offsetHeight - 17}px`);
    }
  }, [secondElementRef?.current?.offsetHeight]);

  return (
    <PageContainer padding={"0px"}>
      <Grid container spacing={5}>
        <Grid item md={3} style={{ height: firstElementMaxHeight }}>
          <Paper style={{ height: "100%", overflowY: "scroll", padding: "8px" }}>
            <StatusAndMessage status={dayRoutesStatus} message="" error={error} />
            <CardTitle
              status={dayRoutesStatus}
              title={lang.routes.weekRoutes}
              popoverTitle={lang.routes.weekRoutesDescription}
            />
            <WeekRoutesList />
          </Paper>
        </Grid>
        <Grid item md={9} style={{ height: "fit-content" }} ref={secondElementRef}>
          <FlexContainer flexDirection="column">
            <Paper
              style={{
                height: "25vh",
                overflowY: "scroll",
                padding: "8px",
              }}
            >
              <StatusAndMessage status={dayRoutesStatus} message="" error={error} />
              <CardTitle
                status={dayRoutesStatus}
                title={lang.routes.delayedAndPendingRoutes}
                popoverChild={
                  <div style={{ maxWidth: "350px" }}>
                    <Typography variant="body2">
                      <span style={{ fontWeight: "bold" }}>{lang.routes.delayed.plural}:</span>{" "}
                      {lang.routes.delayedRoutesDescription}
                    </Typography>
                    <br />
                    <Typography variant="body2">
                      <span style={{ fontWeight: "bold" }}>{lang.routes.pending.plural}:</span>{" "}
                      {lang.routes.pendingRoutesDescription}
                    </Typography>
                  </div>
                }
              />
              <DelayedAndPendingRoutesTable />
            </Paper>
            <br />
            {operators && <RouterTabs {...routeTabProps} />}
            <Outlet />
          </FlexContainer>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default React.memo(SupervisorHomePage);
