import React from "react";
import HandleUndefined from "../../handleUndefined/HandleUndefined";
import CarrousellToolbar from "../../toolbar/CarrousellToolbar";
import Modal from "../Modal/Modal";
import { CarouselModalProps } from "./types/CarouselModalTypes";
import { useModal } from "../Modal/hooks/useModal";

export const CarouselModal: React.FC<CarouselModalProps> = ({
  children,
  title,
  carouselProps,
  showUndefinedError,
  buttonIcon,
  buttonVariant,
  buttonPopoverTitle,
  buttonColor,
  buttonDisabled,
}) => {
  const modalProps = useModal();

  return (
    <HandleUndefined
      showError={showUndefinedError}
      callback={modalProps.openModal}
    >
      {(callback) => (
        <Modal
          buttonIcon={buttonIcon}
          buttonVariant={buttonVariant}
          buttonPopoverTitle={buttonPopoverTitle}
          buttonColor={buttonColor}
          buttonDisabled={buttonDisabled}
          {...{
            ...modalProps,
            openModal: callback,
          }}
        >
          <CarrousellToolbar title={title} {...carouselProps} />
          {children && children}
        </Modal>
      )}
    </HandleUndefined>
  );
};

export default CarouselModal;
