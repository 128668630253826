import { Divider, Paper, Typography } from "@material-ui/core";
import FlexContainer from "../../../components/containers/FlexContainer";
import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../../components/containers/tabs/RouterTabs";
import { USER_ROUTES } from "../../../shared/routes/routes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import RevisionChangesPage from "../../../domains/plant-notifications/revision-changes/RevisionChangesPage";
import NewNotificationsPage from "../News/NewNotificationsPage";
import { PageContainer } from "../../../components/containers";
import { CustomIcon } from "../../../components/buttons/CustomIcon";

const PlantNotificationsPage = () => {
  const { lang } = useLangLabels();

  const tabs: TabsInterface[] = [
    {
      label: lang.revisionChange.title.plural,
      route: `${USER_ROUTES.revisionChange.index}/*`,
      component: <RevisionChangesPage />,
    },
    {
      label: lang.news.plural,
      route: `${USER_ROUTES.news}/*`,
      component: <NewNotificationsPage />,
    },
  ];

  const tabProps = useRouteTabProps(4, tabs, {
    orientation: "vertical",
  });

  return (
    <>
      <Paper elevation={3}>
        <PageContainer>
          <Typography variant="h5">
            <CustomIcon icon="notifications" style={{ margin: "0px 10px -5px 0" }} />
            {lang.notifications.title.plural}
          </Typography>
        </PageContainer>

        <Divider />

        <RouterTabs {...tabProps} style={{ overflow: "scroll", padding: "0 0 0 16px" }}  />
      </Paper>
    </>
  );
};

export default PlantNotificationsPage;
