import { getTodayDateTime, getTodayDay } from "../date/getTodayDay";
import { LocalDataInterface } from "./interfaces.tsx/interfaces";


//TypeError: cyclic object value (Firefox)
//TypeError: Converting circular structure to JSON (Chrome and Opera)
//TypeError: Circular reference in value argument not supported (Edge)

export const getCircularReplacer = () => {
	const seen = new WeakSet();
	return (key:string, value:any) => {
		/* if (typeof value === "object" && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		} */
		return value;
	};
};

//localStorageData
export const getLocalStorage = <TResult,>(key:string):LocalDataInterface<TResult> | null => {
    const stickyValue = localStorage.getItem(key);
    return  stickyValue && JSON.parse(stickyValue)
}

//manageLocalStorage
export  const setLocalStorage = (key:string,value:any) => {
	const {date,time,miliseconds} = getTodayDateTime(new Date().toISOString())
    const data = {date,time,miliseconds,value}
    const stringifyData = JSON.stringify(data,getCircularReplacer())
    localStorage.setItem(key, stringifyData );
}
