import { useMemo } from "react";
import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { SurveyInterface } from "../../model/SurveyInterface";

export const useSurveyGridModel = (config?: {
  type: "show" | "hide";
  options: (keyof SurveyInterface)[];
}) => {
  const { lang } = useLangLabels();

  const gridModel = useMemo<GridModelInterface>(
    () => ({
      equipment: { label: lang.surveys.equipment },
      element: { label: lang.surveys.element },
      component: { label: lang.surveys.component },
      id: { label: lang.globalentityValues.id },
      sector: { label: lang.surveys.sector },
      tagTGD: { label: lang.globalentityValues.tagTGD },
      review: { label: lang.surveys.review },
      impossibleToLubricate: {
        label: lang.surveys.impossibleToLubricate,
        options: [
          {
            name: lang.surveys.options.impossibleToLubricate.toUpperCase(),
            value: true,
          },
          { name: lang.surveys.options.normal.toUpperCase(), value: false },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      lubricates: {
        label: lang.surveys.lubricates,
        options: [
          { name: lang.surveys.options.lubricates.toUpperCase(), value: true },
          {
            name: lang.surveys.options.withoutLubricate.toUpperCase(),
            value: false,
          },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      routeDate: { label: lang.surveys.routeDate },
      loadingDate: { label: lang.surveys.loadingDate },
      startTime: { label: lang.surveys.startTime },
      endTime: { label: lang.surveys.endTime },
      sampleExtraction: {
        label: lang.surveys.sampleExtraction,
        options: [
          { name: lang.surveys.options.yes.toUpperCase(), value: true },
          { name: lang.surveys.options.no.toUpperCase(), value: false },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      consumables: { label: lang.surveys.consumables },
      lubricantType: { label: lang.surveys.lubricantType },
      lubricant: { label: lang.surveys.lubricant },
      quantity: { label: lang.surveys.quantity },
      measureUnit: { label: lang.surveys.measureUnit },
      reason: { label: lang.surveys.reason },
      anomalies: { label: lang.surveys.anomalies },
      outOfRoute: {
        label: lang.surveys.outOfRoute,
        options: [
          { name: lang.surveys.options.outOfRoute.toUpperCase(), value: true },
          { name: lang.surveys.options.inRoute.toUpperCase(), value: false },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      inaccessible: {
        label: lang.surveys.inaccessible,
        options: [
          {
            name: lang.surveys.options.inaccessible.toUpperCase(),
            value: true,
          },
          { name: lang.surveys.options.accessible.toUpperCase(), value: false },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      cleaning: { label: lang.surveys.cleaning },
      otNumber: { label: lang.surveys.otNumber },
      news: { label: lang.surveys.news },
      criticality: { label: lang.surveys.criticality },
      user: { label: lang.surveys.user },
      route: { label: lang.surveys.route },
      manualRegistration: {
        label: lang.surveys.manualRegistration,
        options: [
          {
            name: lang.surveys.options.manualCharge.toUpperCase(),
            value: true,
          },
          { name: lang.surveys.options.Colector.toUpperCase(), value: false },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      areas: { label: lang.surveys.areas },
      taskType: { label: lang.surveys.taskType },
      taskDone: {
        label: lang.surveys.taskDone,
        options: [
          { name: lang.surveys.options.done.toUpperCase(), value: true },
          { name: lang.surveys.options.notDone.toUpperCase(), value: false },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      assetCriticality: { label: lang.surveys.assetCriticality },
      needsLubrication: {
        label: lang.surveys.needsLubrication,
        options: [
          {
            name: lang.surveys.options.neededLubrication.toUpperCase(),
            value: true,
          },
          {
            name: lang.surveys.options.lubricationNotNeeded.toUpperCase(),
            value: false,
          },
          { name: lang.surveys.options.all.toUpperCase(), value: undefined },
        ],
      },
      temperature: { label: lang.surveys.temperature },
    }),
    [lang]
  );

  if (config?.type === "hide") {
    let result: GridModelInterface = {};
    Object.keys(gridModel).forEach((key) => {
      if (!config.options.includes(key as keyof SurveyInterface)) {
        result[key] = gridModel[key];
      }
    });
    return result;
  }

  if (config?.type === "show") {
    let result: GridModelInterface = {};
    config.options.forEach((key) => {
      result[key] = gridModel[key];
    });
    return result;
  } else {
    return {
      ...gridModel,
    };
  }
};
