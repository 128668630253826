
export const dateOperation = (operator:'sum' | 'minus', dimention: 'days' | 'hours', date: string , valuetoOperate: number): Date | undefined => {
    
    if(!!!date || !!!valuetoOperate) return undefined

    const milisecondX = Date.parse(date)
    const dimentionZ = (dimention === 'days') ? (24 * valuetoOperate) : ((dimention === 'hours') ? (1 * valuetoOperate) : 0)
    const milisecondY = dimentionZ * 3600 * 1000

    
    switch (operator) {
        case 'sum':
            return new Date(milisecondX + milisecondY)

        case 'minus':
            return new Date(milisecondX - milisecondY)
    }
}

export const getDate = () => {
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const nowDate = () => {
        if(month < 10){
            return `${year}-0${month}-${day}`
        }else{
            return `${year}-${month}-${day}`
        }
    }

    return {
        nowDate,
        nowDay:(day < 10) ? `0${day}` : day,
        nowMonth:(month < 10) ? `0${month}` : month,
        nowYear:year,
    }
}

function isObjEmpty(obj:object) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }

export const errorsLog = (errors:object) => {
    !isObjEmpty(errors) && console.log(errors)
}

export const blobToBase64 = async (blob:Blob,callback:Function) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
        const base64String:any = reader.result;
        const strArray = base64String?.split(',')
        const strArrayLength =strArray?.length
        const  result = strArray && strArray[strArrayLength - 1]
        callback(result)
    }
}

export const getBrowserLocales = (options = {}) => {
    const defaultOptions = {
        languageCodeOnly: false,
    };
    
    const opt = {
        ...defaultOptions,
        ...options,
    };
    
    const browserLocales =
        navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;
    
    if (!browserLocales) {
        return [];
    }
    
    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
    
        return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
}

export const verifyModifyMacAdress = (code:string) => {
    if(code){
        const array = code.split(':')
        const separators = array.length > 1 ? true : false
        
        const updateString = (str:string,char:string, n:number) => {
            var ret = [];
            var i;
            var len;
            
            for(i = 0, len = str.length; i < len; i += n) {
                ret.push(str.substr(i, n))
            }
            
            return ret.join(char)
        };
        
        
        if(separators) return array.join('')
        else return updateString(code,':',2)
    }
    
    return 'error'

}

export const objStringifyToServer = (object:{[key:string]:any}) => {
    return JSON.stringify(object).split('"').join("'")
}

export const objParserFromServer= (string:string | undefined) => {
    return string && JSON.parse(string.split("'").join('"'))
}