import { useEffect, useState } from "react";
import { useGetObservationReportMap } from "../services/ObservationReportService";
import { ComponentToPrint } from "./ComponentToPrint";
import { PeriodInterface } from "../../../../shared/reports/model/ReportInterface";
import { FieldFilterHookResult } from "../../../../components/filters/FieldFilter/types/FieldFilterTypes";
import { ObservationReport } from "../types/ObservationTypes";

interface SelectedItems {
  [key: string]: Array<ObservationReport>;
}

interface ReportProps {
  rendered: boolean;
  contentRef: React.MutableRefObject<null>;
  period: PeriodInterface;
  fieldFilter: FieldFilterHookResult<ObservationReport>;
  otherProps: Record<string, any>;
  selectedItems: Array<ObservationReport>;
}

const Report = ({
  rendered,
  contentRef,
  period,
  fieldFilter,
  otherProps,
  selectedItems,
}: ReportProps) => {
  const { data: groupedItems } = useGetObservationReportMap(period, {
    filters: fieldFilter.filterModel,
    searchValue: fieldFilter.searchValue,
  });

  const [groupedSelectedItems, setGroupedSelectedItems] = useState<ObservationReport[]>([]);

  const grouperItemsAsigner = () => {
    if (selectedItems.length > 0) {
      const selectedItemsWKeys = selectedItems.reduce<any>((acc, item) => {
        acc[item.sector] = acc[item.sector] || [];
        acc[item.sector].push(item);
        return acc;
      }, {} as SelectedItems[]);
      setGroupedSelectedItems(selectedItemsWKeys);
    } else {
      setGroupedSelectedItems(groupedItems);
    }
  };

  useEffect(() => {
    grouperItemsAsigner();
  }, [selectedItems, groupedItems]);

  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    setKeys(Object.keys(groupedSelectedItems ?? []));
  }, [groupedSelectedItems]);

  return (
    <div style={{ overflow: "hidden", height: 0 }}>
      {rendered && keys && groupedSelectedItems && (
        <ComponentToPrint
          ref={contentRef}
          keys={keys}
          data={groupedSelectedItems}
          otherProps={otherProps}
        />
      )}
    </div>
  );
};

export default Report;
