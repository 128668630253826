import React from "react";
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useSimpleAutocompleteProps } from "./useSimpleAutocompleteProps";
import { CustomIcon } from "../buttons/CustomIcon";
import FlexContainer from "../containers/FlexContainer";
import { CustomIconType } from "../buttons/types/CustomButtonTypes";

interface Props {
  items: any[];
  error?: string;
  label: string;
  defaultValue?: any;
  renderOption?: (option: any) => any;
  onChange: (value?: any) => void;
  extendInput?: boolean;
  showTitle: string;
  icon?: CustomIconType;
  iconColor?: string;
  disabled?: boolean;
  helper?: string;
  openOnFocus?: boolean;
  autoFocus?: boolean;
  children?: React.ReactNode;
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[];
  clearable?: boolean;
  getOptionSelected?: (option: any, value: any) => boolean
}

const AutocompleteSelect: React.FC<Props> = ({
  autoFocus,
  openOnFocus,
  filterOptions,
  helper,
  children,
  items,
  error,
  label,
  defaultValue,
  onChange,
  showTitle,
  icon,
  iconColor,
  extendInput,
  disabled,
  renderOption,
  clearable,
  getOptionSelected,
}) => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [value, setValue] = useState<any | null>(null);

  const autocomplete = useSimpleAutocompleteProps<any>({
    items,
    value,
    error,
    label,
    helper,
    autoFocus,
  });

  const resetValues = () => {
    setValue(null);
    setInputValue(null);
  };

  const handleInputChange = (e: any) => {
    const inputValue = e?.target?.value || null;

    if (inputValue) {
      setInputValue(inputValue);
    }
  };

  const handleAutocompleteChange = (value: any) => {
    if (value) {
      onChange(value[showTitle]);
    }

    if (!value && clearable) {
      onChange(value);
    }
  };

  const handleDefaultValue = () => {
    const findItem = items?.find((c: any) => {
      return c[showTitle] === defaultValue || null;
    });

    if (findItem) {
      setValue(findItem);
    } else {
      setValue({
        [showTitle]: defaultValue || "",
      });
    }
  };

  const onBlur = () => {
    if (extendInput && inputValue) {
      onChange(inputValue);
    } else {
      onChange(defaultValue || "");
    }
  };

  useEffect(() => {
    const isFalsyValue = !defaultValue ? true : false;
    if (isFalsyValue) {
      resetValues();
    } else {
      handleDefaultValue();
    }
  }, [defaultValue]);

  return (
    <FlexContainer style={{ width: "100%" }}>
      <Autocomplete
        filterOptions={filterOptions}
        openOnFocus={openOnFocus}
        {...autocomplete}
        onChange={(e, value) => handleAutocompleteChange(value)}
        getOptionLabel={(option) => option[showTitle] || ""}
        {...(getOptionSelected
          ? { getOptionSelected: (option: any, value: any) => getOptionSelected(option, value) }
          : null)}
        onBlur={onBlur}
        renderOption={
          renderOption
            ? renderOption
            : (option) =>
                icon ? (
                  <CustomIcon icon={icon} style={{ color: iconColor || option[showTitle] }} />
                ) : (
                  option[showTitle]
                )
        }
        {...(extendInput ? { onInputChange: handleInputChange } : null)}
        disabled={disabled}
      />
      {children && children}
    </FlexContainer>
  );
};

export default React.memo(AutocompleteSelect);
