import { FlexContainer } from "../../../../components/containers";
import CarrousellActions from "./CarrouselActions";
import DownloadXlsx from "../../DownloadXlsx.tsx/DownloadXlsx";
import SendEmailWithAttachment from "../../../../domains/emails/SendEmailWithAttachment";
import { VirtualGridActionsProps } from "../types/VirtualGridTypes";

const VirtualGridActions = ({
  selectedData,
  itemActions,
  bulkActions,
  carrousellComponents,
  documentExport,
  documentSendEmail,
  tagFP,
  gridModel,
  documentTitle,
  visibleColumns,
}: VirtualGridActionsProps) => {
  return (
    <FlexContainer justify="space-between" width={"100%"} flexWrap="wrap">
      <FlexContainer flexWrap="wrap">
        {selectedData &&
          selectedData.length === 1 &&
          itemActions &&
          itemActions(selectedData[0])}
        {selectedData &&
          selectedData.length > 0 &&
          bulkActions &&
          bulkActions(selectedData)}
        {selectedData && carrousellComponents && selectedData.length > 0 && (
          <CarrousellActions
            {...{ items: selectedData, carrousellComponents }}
          />
        )}
      </FlexContainer>

      <FlexContainer height={"38px"}>
        {documentExport !== false && (
          <DownloadXlsx
            {...{
              items: selectedData,
              gridModel,
              documentTitle,
              visibleColumns,
            }}
          />
        )}

        {documentSendEmail !== false && (
          <SendEmailWithAttachment
            {...{
              variant: "iconButton",
              items: selectedData,
              attachmentTitle: documentTitle,
              tagFP,
            }}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default VirtualGridActions;
