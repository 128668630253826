import React from "react";

import { useCreateUpdate } from "../../../../shared/form/hooks/useCreateUpdate";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { UIFormInterface } from "../../../../shared/form/types/CRUDTypes";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import { FlexContainer } from "../../../../components/containers";
import { CreateUpdateInstrumentProps, SaveInstrumentInterface } from "../types/instrumentTypes";
import {
  CreateInstrument,
  DeleteInstrument,
  saveInstrumentAdapter,
  UpdateInstrument,
} from "../services/instrumentService";
import { useInstrumentForm } from "../hooks/useInstrumentForm";

const CreateUpdateInstrumentForm: React.FC<CreateUpdateInstrumentProps> = ({
  actionType,
  item: instrument,
  isDataUpdated,
}) => {
  const {
    action: submitAction,
    resetStatus,
    status,
    error,
    message,
  } = useCreateUpdate(
    {
      create: CreateInstrument,
      update: UpdateInstrument,
      delete: DeleteInstrument,
    },
    actionType,
    ["GetAllInstruments"]
  );

  const { lang } = useLangLabels();
  const { tagFP } = useSessionContext();

  const { formattedUiModel, handleSubmit, resetForm } = useInstrumentForm(tagFP, instrument);

  const ui = (() => {
    const formName = lang.instruments.title.singular;

    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName}`,
          variant: "button",
          mode: "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName}`,
          variant: "iconButton",
          mode: "content",
        };

        return update;
    }
  })();

  //Methods
  const submitData = (data: SaveInstrumentInterface) => {
    const instrumentToSave = {
      ...data,
      tagFP,
      date: new Date(),
    };

    submitAction(saveInstrumentAdapter(instrumentToSave));
  };

  const resetData = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      buttonAction={handleSubmit(submitData)}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      onClose={resetData}
      resetForm={resetData}
      isDataUpdated={isDataUpdated}
    >
      <FlexContainer>
        {actionType === CrudFormType.create && (
          <FieldSelector fieldProps={{ ...formattedUiModel.tag } as any} />
        )}
        <FieldSelector fieldProps={{ ...formattedUiModel.name } as any} />
      </FlexContainer>
      <FieldSelector fieldProps={{ ...formattedUiModel.sciNumber } as any} />
      <FieldSelector fieldProps={{ ...formattedUiModel.type } as any} />
      <FieldSelector fieldProps={{ ...formattedUiModel.brand } as any} />
      <FieldSelector fieldProps={{ ...formattedUiModel.model } as any} />
      <FlexContainer>
        <FieldSelector fieldProps={{ ...formattedUiModel.rangeFrom } as any} />
        <FieldSelector fieldProps={{ ...formattedUiModel.rangeTo } as any} />
      </FlexContainer>
      <FieldSelector fieldProps={{ ...formattedUiModel.measureUnit } as any} />
      <FlexContainer>
        <FieldSelector fieldProps={{ ...formattedUiModel.plc } as any} />
        <FieldSelector fieldProps={{ ...formattedUiModel.assignation } as any} />
      </FlexContainer>
      <FlexContainer>
        <FieldSelector fieldProps={{ ...formattedUiModel.certificate } as any} />
        <FieldSelector fieldProps={{ ...formattedUiModel.calibrationDate } as any} />
      </FlexContainer>
      <FlexContainer>
        <FieldSelector fieldProps={{ ...formattedUiModel.patronCertificate } as any} />
        <FieldSelector fieldProps={{ ...formattedUiModel.patronDate } as any} />
      </FlexContainer>
    </DefaultFormLayout>
  );
};

export default React.memo(CreateUpdateInstrumentForm);
