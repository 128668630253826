import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Transition } from "./components/Transition";

interface FullScreenSurfaceProps {
  isOpen: boolean;
  children?: React.ReactNode;
}

const FullScreenSurface = ({ isOpen, children }: FullScreenSurfaceProps) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      TransitionComponent={Transition}
      style={{ zIndex: 1000 }}
    >
      {children && children}
    </Dialog>
  );
};

export default React.memo(FullScreenSurface);
