import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

export const useInstrumentGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    tag: { label: lang.instruments.tag },
    name: { label: lang.instruments.name },
    sciNumber: { label: lang.instruments.sciNumber },
    type: { label: lang.instruments.type },
    brand: { label: lang.instruments.brand },
    model: { label: lang.instruments.model },
    range: { label: lang.instruments.range },
    plc: { label: lang.instruments.plc },
    assignment: { label: lang.instruments.assignment },
    calibrationDate: { label: lang.instruments.calibrationDate },
    certificate: { label: lang.instruments.certificate },
    patronCertificate: { label: lang.instruments.patronCertificate },
    patronDate: { label: lang.instruments.patronDate },
  };

  return gridModel;
};