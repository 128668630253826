export const getViewDateDay = (date: string | undefined) => {
  //Date UTC
  if (date === undefined) return "date is undefined";
  return date.split("").splice(0, 10).join("");
};

export const getViewDateHour = (date: string | undefined) => {
  //Date UTC
  if (date === undefined) return "date is undefined";
  return date.split("").splice(11, 5).join("");
};

export const getViewDateDayHour = (date: string | undefined) => {
  //Date UTC
  if (date === undefined) return "date is undefined";
  const dateDay = getViewDateDay(date);
  const dateHour = getViewDateHour(date);
  return `${dateDay} ${dateHour}`;
};

export const calcDate = (date: any) => {
  let date2 = new Date(date);
  let date1 = new Date();
  let substract = date2.getTime() - date1.getTime();
  if (Math.round(substract / (1000 * 60 * 60 * 24)) === -1) return 0;
  return Math.round(substract / (1000 * 60 * 60 * 24));
};
