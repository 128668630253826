import { Paper } from "@material-ui/core";
import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { PeriodInterface } from "../../../../shared/reports/model/ReportInterface";
import CustomObservationsList from "../../../observations/CustomObservationsList";
import { useSurveyGridModel } from "../../services/lang/useSurveyGridModel";
import { usePaginationState } from "../../../../shared/react-table/Pagination";
import useFieldFilter from "../../../../components/filters/FieldFilter/hooks/useFieldFilter";
import { InfoSurveyInterface, SurveyInterface } from "../../model/SurveyInterface";
import { usePaginatedDaoSurveyLubricationPointByPeriod } from "../../services/paginatedSurveyServices";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import SurveyUpdateForm from "../SurveyUpdateForm/SurveyUpdateForm";
import { usePeriod } from "../../../../shared/date";
import { useSurveyAttrValues } from "../../services/surveyServices";
import { TaskTypeT } from "../../tasks/model/TaskInterface";

interface KnowledgeOfTheSurveysProps {
  title: string;
}

const KnowledgeOfTheSurveys = ({ title }: KnowledgeOfTheSurveysProps) => {
  const [surveyItems, setSurveyItems] = useState<SurveyInterface[]>();

  const periodEntityName = "reports-page";
  const { period, setPeriod } = usePeriod(7, periodEntityName);

  const { lang } = useLangLabels();
  const surveyGridModel = useSurveyGridModel();

  const { paginationOptions } = usePaginationState();
  const { data: surveyFilterOptions } = useSurveyAttrValues();
  const filterOptions = useFieldFilter<SurveyInterface>({
    items: surveyItems!,
    gridModel: surveyGridModel,
    config: {
      isServerSideFiltering: true,
      fields: {
        startTime: {
          hideField: true,
        },
        taskType: {
          options: (options) =>
            options.map((option) => ({
              name: lang.surveys.taskTypes[option.name as TaskTypeT]?.toUpperCase(),
              value: option.value,
            })),
        },
      },
    },
    entityName: title,
    customOptions: surveyFilterOptions,
    filterDependentActions: paginationOptions.onPaginationChange,
    filterDependentActionsArgs: {
      pageIndex: 0,
    },
  });

  const { data, isFetching, isLoading } = usePaginatedDaoSurveyLubricationPointByPeriod(
    period,
    paginationOptions,
    {
      filters: filterOptions.filterModel,
      searchValue: filterOptions.searchValue,
    }
  );

  const { items: surveys, pagination } = data || {};

  const surveyCarrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: SurveyInterface & InfoSurveyInterface) => (
        <SurveyUpdateForm item={item} isDataUpdated={!isFetching} />
      ),
    },
  ];

  useEffect(() => {
    if (!isLoading && surveys?.length) {
      setSurveyItems(surveys);
    }
  }, [surveys]);

  const VirtualGrid = useMemo(
    () => lazy(() => import("../../../../shared/react-table/VirtualGrid/VirtualGrid")),
    []
  );

  const handlePeriodChange = (period?: PeriodInterface) => {
    setPeriod(period);
    paginationOptions.onPaginationChange({
      pageIndex: 0,
    });
  };

  return (
    <Suspense fallback={"..."}>
      <VirtualGrid
        title={title}
        entityName={title}
        period={period}
        periodChange={handlePeriodChange}
        bulkActions={(items: any) => <CustomObservationsList queryType="survey" items={items} />}
        gridModel={surveyGridModel}
        items={surveys}
        paginationOptions={paginationOptions}
        totalItems={pagination?.count}
        customDynamicFilters={filterOptions}
        carrousellComponents={surveyCarrousellComponents}
        documentExportTitle={`reportes-${title}`}
        cellFormatter={{
          taskType: (value) => lang.surveys.taskTypes[value as TaskTypeT]?.toUpperCase(),
        }}
      />
    </Suspense>
  );
};

export default KnowledgeOfTheSurveys;
