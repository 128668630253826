import { CSSProperties } from "@material-ui/core/styles/withStyles";
import theme from "../../../assets/theme";
import { TaskType } from "../tasks/model/TaskInterface";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { QueryStatus } from "react-query";
import { LocalStateSupply } from "../../supply/model/SupplyInterface";
import { ObservationInterface } from "../../observations/models/ObservationTypes";
import { CustomIconType } from "../../../components/buttons/types/CustomButtonTypes";

export interface SurveyInterface {
  id: number;
  sector: string;
  equipment: string;
  tagTGD: string;
  tagFP: string;
  review: string;
  impossibleToLubricate: boolean;
  lubricates: boolean;
  routeDate: string;
  startTime: string;
  sampleExtraction: boolean;
  consumables: string;
  lubricantType: string;
  lubricant: string;
  quantity: number;
  measureUnit: string;
  reason: string;
  anomalies: string;
  outOfRoute: boolean;
  inaccessible: boolean;
  cleaning: boolean;
  otnumber: string;
  news: string;
  criticality: string;
  user: string;
  route: string;
  manualRegistration: boolean;
  areas: string;
  taskType: string;
  taskTime: string;
  taskDone: boolean;
  endTime: string;
  assetCriticality: string;
  needsLubrication: boolean;
  loadingDate: string;
  modified: boolean;
  modifiedDate: string;
}

export interface InfoSurveyInterface {
  updateReview: boolean;
  tagTGD: string;
  equipment: string;
  sector: string;
  measureUnit: string;
  tagFP: string;
  needsLubrication: boolean | undefined;
  inaccessible: boolean | undefined;
  manualRegistration: boolean;
  user: string;
  startTime: string;
  review: string;
  routeDate: string;
  outOfRoute: boolean | undefined;
  route: string;
  leak: boolean | undefined;
  temperature: number;
  labelReplacement: boolean | undefined;
  newComponent: boolean | undefined;
}

export interface CreateSurveyInterface {
  id?: number;
  taskDone: boolean;
  taskTime: string;
  user: string;
  impossibleToLubricate: boolean;
  startTime: string;
  endTime: string;
  quantity?: number;
  inaccessible: boolean;
  outOfRoute: boolean;
  OTNumber?: string;
  taskType: string;
  surveyDate?: string;
  loadingDate?: string;
  manualRegistration: boolean;
  tagTGD: string;
  tagFP: string;
  equipment: string;
  sector: string;
  review: string;
  lubricant: string;
  measureUnit: string;
  modifiedDate?: string;
  route: string;
  routeDate: string;
  lubricantType: string;
  sampleExtraction: boolean;
  lubricates: boolean;
  needsLubrication: boolean;
  reason: string;
  consumables: string;
}

export interface UpdateSurveyInterface {
  id: string;
  tagFP: string;
  tagTGD: string;
  taskType: TaskType;
  manualRegistration: boolean;
  startTime: string;
  endTime: string;
  review: string;
  temperature: number;
  taskDone: boolean;
  taskTime: string;
  needsLubrication: boolean;
  consumables: string;
  lubricates: boolean;
  impossibleToLubricate: boolean;
  reason: string;
  lubricant: string;
  quantity: number;
}

export interface LubricationSurveyControlerInterface {
  lastSurveyId: number | undefined;
  taskStep: number;
  customStep: number;
  loop: number;
  point: LubricationPointInterface | undefined;
  inspectionComplete: boolean;
  inspection: InfoSurveyInterface;
  lubrication: {
    sampleExtraction: boolean;
    lubricantType: string;
    impossibleToLubricate: boolean | undefined;
    lubricates: boolean | undefined;
    lubricant: string;
    taskType: TaskType;
    taskTime: number;
    taskDone: boolean | undefined;
    addedReason: string;
    quantity: number;
    surveyDone: boolean;
  };
  cleaning: {
    taskType: TaskType;
    taskTime: number;
    taskDone: boolean | undefined;
    surveyDone: boolean;
  };
  repairment: {
    taskType: TaskType;
    taskTime: number;
    taskDone: boolean | undefined;
    surveyDone: boolean;
  };
  puntuation: {
    assetCriticality: string;
  };
  fetchStatus: QueryStatus;
  neededSupplies: LocalStateSupply[];
  observations: ObservationInterface[] | [];
  anomalies: ObservationInterface[] | [];
  pending: boolean | undefined;
  globalStatus: QueryStatus;
  modal: boolean;
}

export const getSurveyIconProps = (key: keyof SurveyInterface, value: any) => {
  const danger = theme.palette.error.main;
  const success = theme.palette.success.main;

  const icon: CustomIconType | undefined = (() => {
    switch (key) {
      case "taskDone":
        return value ? "cancelOutlined" : "checkedCircle";
      case "inaccessible":
        return value ? "cancelOutlined" : "checkedCircle";
      case "sampleExtraction":
        return "lab";
      case "impossibleToLubricate":
        return "close";
      case "lubricant":
        return "leak";
      case "sector":
        return "pin";
      case "route":
        return "routes";
      default:
        return undefined;
    }
  })();

  const color: CSSProperties["color"] = (() => {
    switch (key) {
      case "inaccessible":
        return value ? danger : success;
      case "taskDone":
        return value ? danger : success;
      case "impossibleToLubricate":
        return value ? danger : success;
      case "sampleExtraction":
        return theme.palette.secondary.main;
      default:
        return theme.palette.secondary.main;
    }
  })();

  const margin: CSSProperties["margin"] = (() => {
    switch (key) {
      case "sampleExtraction":
        return "4px";
    }
  })();

  const style: CSSProperties = {
    color,
    margin,
  };

  return {
    icon,
    style,
  };
};
