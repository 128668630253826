import React from "react";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { CUEntityFormPropsType, UIFormInterface } from "../../../../shared/form/types/CRUDTypes";
import { enumTgdTypes } from "../../../../domains/types/models/types";
import { useCreateUpdate } from "../../../../shared/form/hooks/useCreateUpdate";
import {
  CreateType,
  deleteType,
  UpdateType,
} from "../../../../domains/types/services/service.types";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import { InstrumentTypeInterface, instrumentTypeEnum } from "../types/instrumentType";
import { useInstrumentTypeForm } from "../hooks/useInstrumentTypeForm";

type props = CUEntityFormPropsType<InstrumentTypeInterface> & {
  defaultType?: instrumentTypeEnum;
  item?: InstrumentTypeInterface;
  isDataUpdated?: boolean;
  customTitle?: string;
};

const CreateUpdateTypeForm: React.FC<props> = ({
  actionType,
  item,
  defaultType,
  isDataUpdated,
  customTitle,
}) => {
  const { tagFP } = useSessionContext();

  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateType,
      update: UpdateType,
      delete: deleteType,
    },
    actionType,
    ["PlantAux"]
  );

  //UI
  const { lang } = useLangLabels();

  const byValue = defaultType ? lang.enumTgdTypes[defaultType] : "";

  const createTypeLabel =
    lang.selectedLanguage === "es"
      ? `${lang.crudActions.create} ${lang.types.singular} ${byValue}`
      : `${lang.crudActions.create} ${byValue} ${lang.types.singular}`;

  const ui = (() => {
    const formName = lang.types.plural;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${customTitle ? customTitle : createTypeLabel}`,
          variant: "button",
          mode: "modal",
        };
        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: "iconButton",
          mode: "content",
        };
        return update;
    }
  })();

  const { uiModel, handleSubmit, resetForm } = useInstrumentTypeForm(
    tagFP,
    item ||
      (defaultType && {
        id: 0,
        name: "",
        type: defaultType,
        tagFP: tagFP,
      })
  );

  //Methods
  const submit = (data: InstrumentTypeInterface) => {
    let type;

    switch (data.type) {
      case lang.enumTgdTypes.UNIDAD.toUpperCase():
        type = enumTgdTypes.unity;
        break;
      case lang.enumTgdTypes.INSTRUMENTO.toUpperCase():
        type = enumTgdTypes.instrument;
        break;
    }

    const typeData = { ...data, type: type ?? data.type };

    action(typeData);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CreateUpdateTypeForm;
