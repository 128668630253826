import { Typography } from "@material-ui/core";
import React from "react";
import { Alert } from "@material-ui/lab";
import { useLubricationPointSurveyController } from "../../services/controllers/useLubricationPointSurveyController";
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";
import { SurveyTasksValues, TaskType } from "../../tasks/model/TaskInterface";
import FlexContainer from "../../../../components/containers/FlexContainer";
import theme from "../../../../assets/theme";
import CustomButton from "../../../../components/buttons/CustomButton";
import PageContainer from "../../../../components/containers/PageContainer";
import { LubricationSurveyControlerInterface } from "../../model/SurveyInterface";
import { TaskStepper } from "./TaskStepper";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const calculateStep = (survey: LubricationSurveyControlerInterface) => {
  if (survey.loop > 0) {
    return survey.puntuation.assetCriticality !== "" ? 3 : 2;
  }
  return 0;
};

const useGetSurveyTasks = (survey: LubricationSurveyControlerInterface) => {
  const { lang } = useLangLabels();
  const step = calculateStep(survey);

  return {
    step,
    tasks: [
      lang.surveys.tasks.inspection.title.toUpperCase(),
      lang.surveys.tasks.workOnTheEquipment.toUpperCase(),
      lang.surveys.tasks.assetScore.toUpperCase(),
    ],
  };
};

interface SurveyTasksProps {
  lubricationPoint?: LubricationPointInterface;
  outOfRoute: boolean;
}

const SurveyTasks: React.FC<SurveyTasksProps> = ({ lubricationPoint, outOfRoute }) => {
  const { lang } = useLangLabels();

  const { survey, nextTaskStep, handleFinishOutOfRoute, resetSurvey } =
    useLubricationPointSurveyController(lubricationPoint, outOfRoute);

  const surveyTasks = useGetSurveyTasks(survey || ({} as any));

  const taskType: TaskType = SurveyTasksValues.intervention;
  const inaccesible = survey?.inspection.inaccessible;

  return (
    <>
      {outOfRoute && survey?.taskStep === 0 && (
        <>
          <FlexContainer
            height="15vh"
            padding="24px"
            align="center"
            flexWrap="wrap"
            background={theme.palette.secondary.main}
          >
            <CustomButton
              disabled={inaccesible}
              action={() => nextTaskStep(taskType, 2)}
              popoverTitle={lang.surveys.tasks.inspectAndLubricate}
              styleProps={{
                backgroundColor: "#fff",
                margin: "0px 12px",
                color: "black",
              }}
              icon="oilCan"
              title={lang.surveys.tasks.lubrication.title}
            />
            <CustomButton
              disabled={inaccesible}
              action={() => nextTaskStep(taskType, 3)}
              popoverTitle={lang.surveys.tasks.inspectAndClean}
              styleProps={{
                backgroundColor: "#fff",
                padding: "8px",
                margin: "0px 12px",
                color: "black",
              }}
              icon="cleaning"
              title={lang.surveys.tasks.cleaning.title}
            />
            <CustomButton
              disabled={inaccesible}
              action={() => nextTaskStep(taskType, 4)}
              popoverTitle={lang.surveys.tasks.inspectAndRepair}
              styleProps={{
                backgroundColor: "#fff",
                margin: "0px 12px",
                color: "black",
              }}
              icon="tool"
              title={lang.surveys.tasks.repairment.title}
            />
          </FlexContainer>

          {!inaccesible ? (
            <>
              <TaskStepper tasks={surveyTasks.tasks} step={surveyTasks.step} iterator="type" />
              <FlexContainer height="40vh">
                <PageContainer>
                  <Alert severity="warning">
                    {lang.surveys.tasks.soonYouWillBeAbleToPerformOtherPersonalizedTasks}
                  </Alert>
                </PageContainer>
              </FlexContainer>
            </>
          ) : (
            <PageContainer>
              <Typography variant="h4" style={{ color: "#e6e6e6" }}>
                {lang.surveys.tasks.inaccessibleEquipment}
              </Typography>
            </PageContainer>
          )}

          <FlexContainer width={"100%"} justify="end">
            {outOfRoute && survey.puntuation.assetCriticality !== "" && (
              <CustomButton
                popoverTitle={lang.surveys.tasks.reset}
                variant="fab"
                right="20px"
                bottom="10px"
                color="primary"
                action={resetSurvey}
                icon={"refresh"}
              />
            )}

            <CustomButton
              variant="fab"
              right="20px"
              bottom="10px"
              color="primary"
              action={handleFinishOutOfRoute}
              icon={"next"}
            />
          </FlexContainer>
        </>
      )}
    </>
  );
};

export default SurveyTasks;
