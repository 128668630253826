import { getPeriodDate, useDate } from "../../../../../shared/date";
import { useCalendarRoutesByPeriod } from "../../../../../domains/routes/services/service.routes";
import { FlexContainer } from "../../../../../components/containers";
import FlagOutlined from "@material-ui/icons/FlagOutlined";
import { LabelReplacementByRoute } from "./LabelReplacementByRoute";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

export const WeekRoutesList = () => {
  const firstDate = getPeriodDate(new Date(), 0);
  const secondDate = getPeriodDate(new Date(), -7);

  const { getDateWithoutTime } = useDate();
  const { lang } = useLangLabels();

  const { data: weekRoutes } = useCalendarRoutesByPeriod(firstDate, secondDate);

  return (
    <>
      {weekRoutes?.map((route, i) => {
        const routeDate = new Date(route.calendarRouteDate);
        const routeDateWithoutTime = getDateWithoutTime(route.calendarRouteDate);

        return (
          <div key={route.routeName + "-" + i}>
            {i === 0 ? (
              getPeriodDate(routeDate, 0) === firstDate ? (
                <FlexContainer
                  padding={"8px 16px"}
                  margin={"0px 0px 8px 0px"}
                  style={{ borderBottom: "1px solid #f2f2f2" }}
                >
                  <p style={{ margin: "0" }}>{lang.routes.todayRoutes}</p>
                </FlexContainer>
              ) : (
                <FlexContainer
                  padding={"8px 16px"}
                  margin={"0px 0px 8px 0px"}
                  style={{ borderBottom: "1px solid #f2f2f2" }}
                >
                  <p style={{ margin: "0" }}>{routeDateWithoutTime}</p>
                </FlexContainer>
              )
            ) : (
              weekRoutes[i].calendarRouteDate !== weekRoutes[i - 1].calendarRouteDate && (
                <FlexContainer
                  padding={"8px 16px"}
                  margin={"16px 0px 8px 0px"}
                  style={{ borderBottom: "1px solid #f2f2f2" }}
                >
                  <p style={{ margin: "0" }}>{routeDateWithoutTime}</p>
                </FlexContainer>
              )
            )}

            <FlexContainer padding={"8px 16px"} justify="space-between">
              <FlexContainer align="center" justify="left">
                <FlagOutlined color="action" style={{ padding: "3px" }} />
                <p style={{ margin: "0" }}>{route.routeName}</p>
              </FlexContainer>

              {getPeriodDate(routeDate, 0) === firstDate && (
                <LabelReplacementByRoute route={route.routeName} />
              )}
            </FlexContainer>
          </div>
        );
      })}
    </>
  );
};
