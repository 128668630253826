import { FormInitialState, FORM_ACTION_TYPES, FormFields } from "../types/FormTypes";
import { validateFields } from "../utils/FormValidation";
import { parseFormFieldsToData } from "../utils/FormValidation";

export const formReducer = <T,>(
  state: FormInitialState<T>,
  action: FORM_ACTION_TYPES<T>
): FormInitialState<T> => {
  const { fields: formFields } = state;

  switch (action?.type) {
    case "SET_VALUE": {
      const { key, value } = action.payload;
      const currentField = formFields[key];

      const newFields: FormFields<T> = {
        ...formFields,
        [key]: {
          ...currentField,
          value,
        },
      };

      return {
        ...state,
        fields: newFields,
        errors: validateFields<T>(newFields),
      };
    }

    case "SET_VALIDATIONS": {
      const { key, validationName, validationValue } = action.payload;
      const currentField = formFields[key];

      const newFields: FormFields<T> = {
        ...formFields,
        [key]: {
          ...currentField,
          validations: {
            ...currentField?.validations,
            [validationName]:
              validationName === "validate"
                ? validationValue
                : {
                    ...currentField?.validations?.[validationName],
                    ...validationValue,
                  },
          },
        },
      };

      return {
        ...state,
        fields: newFields,
        errors: validateFields<T>(newFields),
      };
    }

    case "SUBMIT_FORM": {
      const [onSuccess, onError] = action.payload;
      const errorObject = validateFields(formFields);
      const formData = parseFormFieldsToData<T>(formFields);

      Object.entries(errorObject).length === 0
        ? onSuccess(formData)
        : onError
        ? onError(errorObject)
        : state.callbacks.callbackError(errorObject);

      return {
        ...state,
        errors: errorObject,
      };
    }

    case "RESET_ALL": {
      return action.payload;
    }

    default:
      return state;
  }
};
