
export const newMenuItemWithSubMenu = (itemId:number,subItems:any[],fMitem:string,itemIcon:string) =>{
    let AM:any = [] //new ItemArray to Create sub Items  
    const splitNameOn = (string:string,caracter:string) => {
        if(string.includes(caracter)){
            return string.split('.')
        }
        return string
    }

    subItems.forEach(item => { //create sub Items
        AM.push({
            name:splitNameOn(item.name,'.').slice(1),
            route:item.name,
            icon:"donut_large",
        })
        return AM
    })

    const isMultyMenu = () => {
        /* console.log(subItems) */
        const res = subItems.filter(item => item.name.includes('.'))
        /* console.log(res) */
        
        if(res.length > 0) return true
        if(res.length === 0) return false
    }
    
    const newMenuItemFiltered = () => {
        let newMenuItem = null

        if(isMultyMenu()){
            return newMenuItem = {//item to add
                id:itemId,
                name:splitNameOn(fMitem,'.'),
                route:fMitem,
                icon:itemIcon,
                type:'pbi_report',
                actionMenu:AM,
            }
        }else{
            return newMenuItem = {//item to add
                name:fMitem,
                route:fMitem,
                icon:itemIcon,
            }
        }
        
    }
    
    

    return newMenuItemFiltered()
}