import { useEffect } from 'react';
import{apiCall, mutateApiCall } from '../../../shared/axios/axios-config';
import { PrivateQueryData, PrivateQueryTagFPInterface } from '../../../shared/globals/utilsGlobalTypes';
import { useStatusProcessor } from '../../../shared/queries/StatusProcessor';
import { queryClient, useMutation, useQuery } from '../../../shared/react-query/react-query-conf';
import { useSessionContext } from '../../session/store/sessionContext';
import { useToken } from '../../session/store/sessionStore';
import { CategoriesAndSubCategoriesInterface } from '../categories/CategoriesIinterface';
import { StockInterface } from '../models/StockInterface';

import { useTodayNewPurchaseRequest } from '../persistance/useTodayNewPurchaseRequest';
import { stockAreaCategorySubcategoryInterface } from '../sub-categories/subCategoryInterface';





export const StockByTagFP = ({tagFP,token}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method: "get",
        url:`/StockByTagFP`,
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP:tagFP!
        }
    })
}

export const StockAreaCategorySubcategory = ({data,token}:PrivateQueryData<any>) => {
    return apiCall({
        method: "post",
        url:`/StockAreaCategorySubcategory`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const UpdateStockHiddenState = ({data,token}:PrivateQueryData<any>) => {
    return mutateApiCall({
        method: "post",
        url:`/UpdateStockHiddenState`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const CreateStock = ({data,token}:PrivateQueryData<any>) => {
    return mutateApiCall({
        method: "post",
        url:`/CreateStock`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
    
}

export const UpdateStock = ({data,token}:PrivateQueryData<any>) => {
    return mutateApiCall({
        method: "post",
        url:`/UpdateStock`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}






//------------------------------
//QUERY-------------------------
//------------------------------






export const useStockByTagFP = () =>{
   const {tagFP,token} = useSessionContext()
    return useQuery<CategoriesAndSubCategoriesInterface[]>({
        queryKey:'StockByTagFP',
        queryFn:()=>StockByTagFP({tagFP,token:token}),
        enabled:!!tagFP && !!token
    })
}



export const useStockAreaCategorySubcategory= (queryData?:stockAreaCategorySubcategoryInterface) => {
    const token = useToken()

    const query = useQuery<StockInterface[]>({
        queryKey:['StockAreaCategorySubcategory', queryData],
        queryFn:()=>StockAreaCategorySubcategory({data:queryData,token:token!}),
        enabled: !!queryData && !!token,
    })

    return query
}




//------------------------------
//MUTATION----------------------
//------------------------------


export const useCreateStock = () => {
    
    const token= useToken()
    const query = useMutation(CreateStock,{
        onSuccess:()=>{
            queryClient.invalidateQueries('StockAreaCategorySubcategory')
            queryClient.invalidateQueries('StockByTagFP')
        }
    })

    const status = useStatusProcessor(query)

    const createStock = (data:StockInterface) => {
        if (token)
        return query.mutate({
            token,
            data
        })
    }

    return {
        createStock,
        ...query,
        ...status
    }
}

export const useUpdateStock = () => {
    
    const token= useToken()
    const {varifyNewStockPurchase} = useTodayNewPurchaseRequest()

    const query = useMutation(UpdateStock,{
        onSuccess:()=>{
            queryClient.invalidateQueries('StockByTagFP')
            queryClient.invalidateQueries('StockAreaCategorySubcategory')

        }
    })

    const status = useStatusProcessor(query)

    const updateStock = (data:StockInterface) => {
        return query.mutateAsync({
            token,
            data
        }).then(()=>{
            varifyNewStockPurchase(data)
        })
    } 

    return {
        updateStock,
        ...query,
        ...status
    }
}


export const useUpdateStockHiddenState = ()=> {
    
    const token= useToken()
    const query = useMutation(UpdateStockHiddenState,{
        onSuccess:()=>{
            queryClient.invalidateQueries('StockByTagFP')
            queryClient.invalidateQueries('StockAreaCategorySubcategory')
        }
    })

    const status = useStatusProcessor(query)

    const archiveStock = (data:StockInterface) => {
        if(token){
            return query.mutate({
                token,
                data
            })
        }
    }

    return {
        archiveStock,
        ...query,
        ...status
    }
}










