import { useState } from "react"
import { useQueryPersistanceState } from "../../shared/react-query/hooks/useQueryPersistanceState"
import { SIDEBAR_WIDTH } from "../LayoutConstants"
import { layoutInterface } from "../models/layoutInterface"
import { getLayoutPersistance, LAYOUT_KEY, setPersistLayoutState } from "./persistance/layoutPersistance"

export const useLayoutStore = () => {
    const [layout,setLayout] = useQueryPersistanceState(LAYOUT_KEY,getLayoutPersistance())

    const handleModal = () =>{
        const data = {
            sidebarWidth:layout?.open ? 0 : SIDEBAR_WIDTH,
            open:!layout?.open
        }
        setPersistLayoutState(data,()=>setLayout(data))
    } 


    return {layout,handleModal}
} 