import { getTodayDay } from "../../../../shared/date";
import { InputComponentTypes } from "../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../shared/form/useForm";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";
import { useUser } from "../../../session/store/sessionStore";
import { FormattedUiModel } from "../../../surveys/components/SurveyUpdateForm/models/FormattedUiModel";
import { RevisionChangeInterface } from "../types/revisionChangeType";

const useUpdateEquipmentRevisionForm = (item: LubricationPointInterface) => {
  const { lang } = useLangLabels();
  const { data: user } = useUser();

  const updateEquipmentRevisionForm = useForm<RevisionChangeInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      component: InputComponentTypes.none,
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: item.tagFP,
      component: InputComponentTypes.none,
    },
    supervisionReason: {
      label: lang.revisionChange.supervisionReason,
      placeholder: lang.revisionChange.supervisionReason,
      value: "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.text,
    },
    supervisionDate: {
      label: lang.revisionChange.supervisionDate,
      placeholder: lang.revisionChange.supervisionDate,
      value: getTodayDay(),
      component: InputComponentTypes.none,
    },
    supervisionUser: {
      label: lang.revisionChange.supervisionUser,
      placeholder: lang.revisionChange.supervisionUser,
      value: user?.email || "",
      component: InputComponentTypes.none,
    },
    impactDate: {
      label: lang.revisionChange.impactDate,
      placeholder: lang.revisionChange.impactDate,
      value: getTodayDay(),
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.date,
    },
    requestDate: {
      label: lang.revisionChange.requestDate,
      placeholder: lang.revisionChange.requestDate,
      value: "",
      component: InputComponentTypes.none,
    },
    requestReason: {
      label: lang.revisionChange.requestReason,
      placeholder: lang.revisionChange.requestReason,
      value: "",
      component: InputComponentTypes.text,
    },
    requestUser: {
      label: lang.revisionChange.requestUser,
      placeholder: lang.revisionChange.requestUser,
      value: "",
      component: InputComponentTypes.none,
    },
    tagTGD: {
      label: lang.globalentityValues.tagTGD,
      placeholder: lang.globalentityValues.tagTGD,
      value: item?.tagTGD,
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.none,
    },
  });

  const { uiModel } = updateEquipmentRevisionForm;

  const formattedUiModel: FormattedUiModel<RevisionChangeInterface> = uiModel.reduce((acc, obj) => {
    acc[obj.name] = obj;
    return acc;
  }, {} as FormattedUiModel<RevisionChangeInterface>);

  return {
    ...updateEquipmentRevisionForm,
    formattedUiModel,
  };
};

export default useUpdateEquipmentRevisionForm;
