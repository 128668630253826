import { TextField } from "@material-ui/core";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface SearchInputProps {
  searchValue: string;
  handleSearchValueChange: (value: string) => void;
}

const SearchInput = ({ searchValue, handleSearchValueChange }: SearchInputProps) => {
  const { lang } = useLangLabels();

  return (
    <div style={{ marginTop: "24px" }}>
      <TextField
        fullWidth
        size="small"
        label={lang.filters.searchByTerm}
        type="search"
        variant="outlined"
        value={searchValue}
        onChange={(e: any) => handleSearchValueChange(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
