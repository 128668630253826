import { Paper } from "@material-ui/core";
import { PageContainer } from "../../../components/containers";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useInstrumentGridModel } from "../instruments/lang/useInstrumentGridModel";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import CreateUpdateInstrumentForm from "../instruments/components/CreateUpdateInstrumentForm";
import { useGetAllInstruments } from "../instruments/services/instrumentService";
import { DeleteInstrumentForm } from "../instruments/components/DeleteInstrumentForm";
import { QRCodeComponent } from "../instruments/components/QRCodeComponent";
import { useDate } from "../../../shared/date";

export const InstrumentsPage = () => {
  const { lang } = useLangLabels();
  const gridModel = useInstrumentGridModel();

  const { getDateWithoutTime } = useDate();

  const { data: instruments, isFetching } = useGetAllInstruments();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update + " " + lang.instruments.title.singular,
      component: (item) => (
        <CreateUpdateInstrumentForm
          actionType={CrudFormType.update}
          item={item}
          isDataUpdated={!isFetching}
        />
      ),
    },
  ];

  return (
    <>
      <Paper elevation={3}>
        <PageContainer padding={"8px"}>
          <VirtualGrid
            cellFormatter={{
              patronDate: (value) => getDateWithoutTime(value),
              calibrationDate: (value) => getDateWithoutTime(value),
            }}
            entityName={lang.instruments.title.plural}
            gridModel={gridModel}
            items={instruments}
            title={lang.instruments.title.plural}
            carrousellComponents={carrousellActions}
            headerActions={() => <CreateUpdateInstrumentForm actionType={CrudFormType.create} />}
            itemActions={(item) => <DeleteInstrumentForm item={item} />}
            renderOptionColumn={{
              headerName: lang.instruments.qrCode,
              renderOption: (item) => <QRCodeComponent instrument={item} />,
              size: 85,
            }}
          />
        </PageContainer>
      </Paper>
    </>
  );
};
