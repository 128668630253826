import styled from '@emotion/styled'


const Animation = styled('div')`

    .animation {
        animation-duration: 0.5s;
        animation-name: appear;
    }
    
    @keyframes appear {
        from {
            opacity: 0;
        }
    
        to {
            opacity: 1;
        }
  
    }
`

const EaseInOut:React.FC<{}> = ({children}) => {
    return <Animation>
        {children}
    </Animation>

}

export default EaseInOut