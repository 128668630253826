import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../../components/buttons/CustomButton";
import ComponentMode from "../../../../components/componentMode/ComponentMode";
import FeatureStatusContainer, {
  useFeatures,
} from "../../../../shared/config/features/FeatureStatusContainer";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import { useSessionContext } from "../../../session/store/sessionContext";
import { LubricationPointInterface } from "../../model/lubricationPointsInterface";
import { useUpdateEquipmentNoImpact } from "../../services/lubricationPointsAdapters";
import SelectSuggestedQuantity from "./SelectSuggestedQuantity";

const UpdateEquipmentSugestedQuantity: React.FC<{ item: LubricationPointInterface }> = ({
  item,
}) => {
  const { lang } = useLangLabels();
  const { token } = useSessionContext();
  const features = useFeatures();
  const title = lang.lubricationPoints.suggestedQuantity;
  const { handleSubmit, setValue, register, errors, watch } = useForm<{
    suggestedQuantity: LubricationPointInterface["suggestedQuantity"];
  }>();

  const updateQuery = useUpdateEquipmentNoImpact();

  const { status, error, message, reset } = useStatusProcessor(updateQuery);

  const updateEquipment = handleSubmit((data) => {
    updateQuery.mutate({
      data: {
        tagTGD: item.tagTGD,
        criticality: item.criticality,
        sector: item.sector,
        plantTag: item.plantTag,
        equipment: item.equipment,
        type: item.type,
        tagFP: item.tagFP,
        suggestedQuantity: data.suggestedQuantity,
      },
      token,
    });
  });

  const handleChange = (value: string) => {
    setValue("suggestedQuantity", value);
  };

  useEffect(() => {
    register("suggestedQuantity", {
      required: {
        value: true,
        message: lang.validations.messages.completeFields,
      },
    });
  }, [item]);

  const defaultValue =
    item.suggestedQuantity && item.suggestedQuantity.length > 0
      ? !isNaN(Number(item.suggestedQuantity))
        ? `${item.suggestedQuantity} ${item.measureUnit}`
        : item.suggestedQuantity
      : `0 ${item.measureUnit}`;

  return (
    <ComponentMode
      {...{
        popoverTitle: title,
        icon: "leak",
        variant: "iconButton",
        mode: "popover",
      }}
    >
      <FeatureStatusContainer feature={features.sugestedQuantity}>
        <Typography variant="h6">{title}</Typography>
        <SelectSuggestedQuantity onChange={handleChange} defaultValue={defaultValue}>
          {errors.suggestedQuantity?.message && (
            <Typography color="primary">{errors.suggestedQuantity.message}</Typography>
          )}

          <StatusAndMessage {...{ status, error: (error as any)?.message, message, reset }} />

          <CustomButton
            {...{
              status: error ? "error" : status,
              error,
              message,
              action: updateEquipment,
              icon: "save",
              popoverTitle: lang.crudActions.save,
            }}
          />
        </SelectSuggestedQuantity>
      </FeatureStatusContainer>
    </ComponentMode>
  );
};

export default UpdateEquipmentSugestedQuantity;
