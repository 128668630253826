import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"


export const useSectorsGridModel = () => {
    const {lang} = useLangLabels()
    const gridModelSector:GridModelInterface = {
        code:{label:lang.sectors.form.label.code}, 
        sector:{label:lang.sectors.form.label.sector}, 
        sectorName:{label:lang.sectors.form.label.sectorName}, 
        id:{label:lang.globalentityValues.id}, 
        date:{label:lang.globalentityValues.date}, 
        tagFP:{label:lang.globalentityValues.tagFP}, 
    }
    return gridModelSector
    
}