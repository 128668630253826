import React,{useContext} from 'react'
//context
//material
import Chip from '@material-ui/core/Chip'
import Icon from '@material-ui/core/Icon'
import { useSessionContext } from '../../../domains/session/store/sessionContext'
import { CustomIcon } from '../../../components/buttons/CustomIcon'


const MenuPlantSelector:React.FC<{classes?:any,color?:"primary" | "secondary" | "default" | undefined, icon?:string}> = ({classes,color,icon}) => {
    
    const {resetSelectedPlant,currentPbigroupName,plants} = useSessionContext() 

    const handleClick = () => {
        console.log('open/close multiplant')
        resetSelectedPlant()
    };

    return (
        <>
            {
                plants.length > 1 &&
                <Chip
                    className={classes}
                    variant="outlined"
                    style={{textTransform:'uppercase',margin:'0 16px',maxWidth:'200px', ...(color === 'default' ? {color:'#FFF', borderColor:'#FFF'} : {})}}
                    size="small"
                    label={currentPbigroupName.split('-')[1]}
                    clickable
                    color={color}
                    icon={<CustomIcon icon={'more-horizontal'} />}
                    aria-controls="simple-menu" 
                    aria-haspopup="true" 
                    onClick={handleClick}
                />
            }  
        </>       
    );   
    
}

export default MenuPlantSelector