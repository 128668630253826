

//------------------------------
//INTERFCAES--------------------
//------------------------------

import { queryByRole } from "@testing-library/react"
import { useMutation } from "react-query"
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config"
import { PrivateQueryData, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useQuery } from "../../../shared/react-query/react-query-conf"
import { useSessionContext } from "../../session/store/sessionContext"
import { useToken } from "../../session/store/sessionStore"
import { CategoriesAndSubCategoriesInterface, CategoryInterface } from "./CategoriesIinterface"



//------------------------------
//AXIOS----------------------
//------------------------------


export const CategoriesAndSubcategoriesByTagFP = ({token,tagFP}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method: "get",
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP:tagFP!
        },
        url:`/CategoriesAndSubcategoriesByTagFP`,
    })
   
}

export const CreateCategory = ({data,token}:PrivateQueryData<any>) => {

    return mutateApiCall({
        method: "post",
        headers:{
            Authorization:`Bearer ${token}`,
        },
        url:`/CreateCategory`,
        data
    })
}

export const UpdateCategory = ({data,token}:PrivateQueryData<any>) => {
    return mutateApiCall({
        method: "post",
        headers:{
            Authorization:`Bearer ${token}`,
        },
        url:`/UpdateCategory`,
        data
    })
    
}





//------------------------------
//QUERIES----------------------
//------------------------------


export const useCategoriesAndSubcategoriesByTagFP = (tagFP?:string) =>{
    const {token,currentPlant} = useSessionContext()
    const queryTagFP = tagFP || currentPlant?.tagFP!
    return useQuery<CategoriesAndSubCategoriesInterface[]>({
        queryKey:'CategoriesAndSubcategoriesByTagFP',
        queryFn:()=>CategoriesAndSubcategoriesByTagFP({tagFP:queryTagFP,token:token!}),
        enabled:!!queryTagFP && !!token, 
    })
}




//------------------------------
//MUTATION----------------------
//------------------------------

export const useCreateCategory = () => {
    
    const token= useToken()
    const query = useMutation(CreateCategory,{
        onSuccess:()=>{
            queryClient.invalidateQueries('CategoriesAndSubcategoriesByTagFP')
        }
    })
    
    const status = useStatusProcessor(query)

    const createCategory = (data:CategoryInterface) => {
        if(token){
            return query.mutate({
                token,
                data
            })
        }
    }

    return {
        createCategory,
        ...query,
        ...status
    }
}




export const useUpdateCategory = () => {
    
    const token = useToken()
    const query = useMutation(UpdateCategory,{
        onSuccess:()=>{
            queryClient.invalidateQueries('CategoriesAndSubcategoriesByTagFP')
        }
    })

    const updateCategory = (data:CategoryInterface) => {
        if(token){
            return query.mutate({
                token,
                data
            }) 
        }
    }

    return {updateCategory,query}
}