import { CircularProgress, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import TgdPopover from '../../components/popovers/TgdPopover';
import CustomButton from '../../components/buttons/CustomButton';
import { useSnackbar } from '../../components/snackbar/hooks/useSnackbar';
import { AddedReasonInterface } from './models/AdedReasonTypes';
import { useClickPosition } from '../../shared/globals/hooks/useClickPosition';
import { useDeleteAddedReason } from './services/service.addedReasons';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';


interface Props{
    item:AddedReasonInterface
}

const DeleteAddedReasonForm:React.FC<Props> = ({item}) => {

    const { deleteAddedReason, query:{status,message}} = useDeleteAddedReason()
    const { position, getClickPosition } = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(() => {
            if(status === 'success'){
                setData({
                    modal:true,
                    errors:message,
                    severity:status,
                    position
                })
            }
            if(status === 'error'){
                setData({
                    modal:true,
                    errors:message,
                    severity:'warning',
                    position
                })
            }

    }, [status]);

    const {lang} = useLangLabels()

    return (
        status === 'loading' 
            ? <CircularProgress size='20px' />
            :<CustomButton 
            variant='iconButton'
            status={status} 
            icon='delete' 
            popoverTitle={lang.actionTypes.deleteItem} 
            action={(e)=>getClickPosition(e,()=>deleteAddedReason(item))}
            />
    );
}

export default React.memo(DeleteAddedReasonForm);
