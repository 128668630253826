import { AppBar, Toolbar, Typography } from "@material-ui/core";
import theme from "../../../assets/theme";
import CustomButton from "../../../components/buttons/CustomButton";
import ComponentMode from "../../../components/componentMode/ComponentMode";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import { Plant } from "../../../domains/plants/PlantInterface";
import FeatureStatusContainer, {
  useFeatures,
} from "../../../shared/config/features/FeatureStatusContainer";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import ClientContent from "../ViewAsClient/components/ClientContent";

const ClientVisorPanel: React.FC<{ plant: Plant /*, baseUrl?:string */ }> = ({
  plant
}) => {
  const { lang } = useLangLabels();
  const features = useFeatures();

  //handle drawer open and close
  const { isOpen, toggleModal } = useModal();

  const currentPbiGroupName = `${plant.factory} ${plant.name}`;

  return (
    <ComponentMode
      popoverTitle={lang.client.viewAsClient}
      mode="drawer"
      variant="iconButton"
      icon="view"
      drawerProps={{
        open: isOpen,
        onClose: toggleModal,
        styles: {
          backgroundColor: theme.palette.background.default,
          drawerWidth: `100vw`,
          color: "#000",
        },
        anchor: "right",
      }}
    >
      <FeatureStatusContainer feature={features.supervisorClientVisor}>
        <AppBar color={"primary"} elevation={1}>
          <Toolbar>
            <CustomButton
              icon="close"
              color="inherit"
              action={toggleModal}
              variant="iconButton"
              popoverTitle={lang.actionTypes.exit}
            />
            <Typography variant="h6">{plant?.name}</Typography>
          </Toolbar>
        </AppBar>

        <ClientContent
          {...{
            userRole: "C",
            currentPbiGroupName,
            selectedPlant: plant,
          }}
        />
      </FeatureStatusContainer>
    </ComponentMode>
  );
};

export default ClientVisorPanel;
