import { GridModelInterface } from "../../../../shared/globals/utilsGlobalTypes";

export const PurchaseGridModel:GridModelInterface = {
    id:{label:'Id'},
    dateLoaded:{label:'Fecha de carga'},
    requestedDate:{label:'Fecha de solicitud'},
    dateDelivered:{label:'Fecha de entrega'},
    date:{label:'Fecha'},
    category:{label:'Categoría'},
    subcategory:{label:'Sub categoría'},
    brand:{label:'Marca'},
    requestedAmount:{label:'Cantidad pedida'},
    measureUnit:{label:'Unidad de medida'},
    user:{label:'Usuario'},
    observations:{label:'Observaciones'},
    tagFP:{label:'Planta'},
    product:{label:'Producto'},
    categoryArea:{label:'Área'},
    receivedQuantity:{label:'Cantidad recibida'},
    providerArea:{label:'Área proveedora'},
    complex:{label:'Reposición Compleja',options:[{name:'Simple',value:false},{name:'compleja',value:true}, {name:'Todos', value:undefined}]},
    state:{label:'Estado'},
    expirationDate:{label:'Fecha de vencimiento'},
    receivedAmount:{label:'Cantidad recibida'}
}