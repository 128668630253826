type Object = Record<string, any>;

export const getNestedKeys = (object: Object): string[] => {
  const keys = Object.keys(object || {});

  const nestedKeys = keys.reduce((acc: string[], key) => {
    const value = object[key];

    acc.push(
      ...(typeof value === "object"
        ? getNestedKeys(value).map((childKey) => `${key}.${childKey}`)
        : [key])
    );

    return acc;
  }, []);

  return nestedKeys;
};

export const getNestedValue = (object: Object, nestedKey: string): any => {
  const keys = nestedKey.split(".");
  let nestedObject: Object | undefined = object;

  keys.forEach((key) => {
    if (!nestedObject || typeof nestedObject !== "object") {
      nestedObject = undefined;
    } else {
      nestedObject = nestedObject[key];
    }
  });

  return nestedObject;
};

export const getNestedValueByPath = (obj: Record<string, any>, path: string): any => {
  return path.split('.').reduce((acc, key) => acc ? acc[key] : undefined, obj);
}

export const groupByKeysWithOptions = (items: Object[]): Record<string, any[]> => {
  const allKeys = getNestedKeys(items[0]);

  return items.reduce((groupedOptions, item) => {
    allKeys.forEach((key) => {
      const valueToAdd = getNestedValue(item, key);
      const setOfValues = new Set([...(groupedOptions[key] || []), valueToAdd]);
      groupedOptions[key] = [...setOfValues];
    });

    return groupedOptions;
  }, {});
};
