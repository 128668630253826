import CreateUpdatePLCForm from "../../instrument-plcs/components/CreateUpdatePLCForm";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useGetAllPLCs } from "../../instrument-plcs/services/plcService";
import { usePLCGridModel } from "../../instrument-plcs/lang/usePLCGridModel";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { DeletePLCForm } from "../../instrument-plcs/components/DeletePLCForm";

export const ManagePLCPage = ({ fixedHeight }: { fixedHeight?: number }) => {
  const { lang } = useLangLabels();
  const { data: plcs, isFetching } = useGetAllPLCs();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item) => (
        <CreateUpdatePLCForm
          item={item}
          actionType={CrudFormType.update}
          isDataUpdated={!isFetching}
        />
      ),
    },
  ];

  return (
    <VirtualGrid
      gridModel={usePLCGridModel()}
      title={lang.instruments.plcs.title.plural}
      fixedHeight={fixedHeight}
      entityName={lang.instruments.plcs.title.plural}
      items={plcs}
      carrousellComponents={carrousellActions}
      headerActions={(item: any) => <CreateUpdatePLCForm actionType={CrudFormType.create} />}
      itemActions={(item) => <DeletePLCForm item={item} />}
    />
  );
};
