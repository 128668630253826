import { Paper } from "@material-ui/core";
import FlexContainer from "../../../components/containers/FlexContainer";
import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../../components/containers/tabs/RouterTabs";
import { USER_ROUTES } from "../../../shared/routes/routes";
import KnowledgeOfTheLubPoints from "../../../domains/lubricationPoints/components/KnowledgeOfTheLubPoints/KnowledgeOfTheLubPoints";
import { usePeriod } from "../../../shared/date";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import KnowledgeOfTheSurveys from "../../../domains/surveys/components/KnowledgeOfTheSurveys/KnowledgeOfTheSurveys";
import KnowledgeOfTheObservations from "../../../domains/observations/components/KnowledgeOfTheObservations/KnowledgeOfTheObservations";
import KnowledgeOfTheRoutes from "../../../domains/routes/components/KnowledgeOfTheRoutes/KnowledgeOfTheRoutes";
import { VirtualGridProps } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import { useMemo } from "react";
import { CustomIconType } from "../../../components/buttons/types/CustomButtonTypes";

const KnowledgeBasePage = () => {
  const entityName = "knowledge-base-page";
  const { period, setPeriod } = usePeriod(7, entityName);
  const { lang } = useLangLabels();
  
  const knowledgeTabs: TabsInterface<VirtualGridProps>[] = useMemo(() => {
    return [
      {
        label: lang.reports.lubricationPoints,
        route: `${USER_ROUTES.knowledge.lubricationPoints}/*`,
        icon: "points" as CustomIconType,
        component: <KnowledgeOfTheLubPoints title={lang.reports.lubricationPoints} />,
      },
      {
        label: lang.reports.routes,
        route: `${USER_ROUTES.knowledge.routes}/*`,
        icon: "routes" as CustomIconType,
        component: <KnowledgeOfTheRoutes title={lang.reports.routes} />,
      },
      {
        label: lang.reports.observations,
        route: `${USER_ROUTES.knowledge.observations}/*`,
        icon: "observations" as CustomIconType,
        component: (
          <KnowledgeOfTheObservations
            title={lang.reports.observations}
          />
        ),
      },
      {
        label: lang.reports.surveys,
        route: `${USER_ROUTES.knowledge.surveys}/*`,
        icon: "survey" as CustomIconType,
        component: (
          <KnowledgeOfTheSurveys
            title={lang.reports.surveys}
          />
        ),
      },
    ];
  }, []);

  const tabProps = useRouteTabProps(4, knowledgeTabs);

  return (
      <Paper elevation={3}>
        <FlexContainer padding={"0px 16px"} flexDirection="column">
          <RouterTabs {...tabProps} />
        </FlexContainer>
      </Paper>
  );
};

export default KnowledgeBasePage;
