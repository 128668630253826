import React from "react";
import { enumTgdTypes } from "../models/types";
import SelectTypeBy from "./SelectTypeBy";

interface Props {
  tagFP: string;
  error?: string;
  onChange: (item?: string) => void;
  defaultValue?: string;
  clearable?: boolean;
}

const SelectTabulatedObservation: React.FC<Props> = ({ tagFP, error, onChange, defaultValue, clearable }) => {
  const onObservationChange = (value: any) => {
    const newValue = value ? value.toUpperCase() : null
    onChange(newValue)
  }

  return (
    <SelectTypeBy
      error={error}
      by={{ key: "type", value: enumTgdTypes.observation }}
      tagFP={tagFP}
      onChange={onObservationChange}
      extendInput={true}
      defaultValue={defaultValue}
      clearable={clearable}
    />
  );
};

export default React.memo(SelectTabulatedObservation);