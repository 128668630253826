import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useGetReferrer } from "../services/IncomeService";

interface SelectSurveyReviewsProps {
  tagFP: string;
  tagTGD?: string;
  onChange: any;
  defaultValue?: string | undefined;
  dimentionType?: string;
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
}

const SelectReferrer = ({
  onChange,
  defaultValue,
  error,
  disabled,
  clearable,
}: SelectSurveyReviewsProps) => {
  const { lang } = useLangLabels();
  const { data } = useGetReferrer();
  const referrerOptions = data?.person?.map((option: string) => ({ 
    label: option, 
    value: option 
  }))

  const label = lang.incomeForm.referrer;

  return (
    <>
      <SimpleAutocompleteSelect
        {...{
          label,
          error,
          onChange: onChange,
          items: referrerOptions || [],
          showTitle: "label",
          defaultValue,
          extendInput: true
        }}
      />
    </>
  );
};

export default SelectReferrer;
