import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { apiCall } from "../../../shared/axios/axios-config";
import { MutateResponse } from "../../../shared/axios/models/MutateResponse";
import { PrivateQuery, PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { useSessionContext } from "../../session/store/sessionContext";
import { ClusterCompanyInterface } from "../interfaces/clusterCompaniesInterfces";




interface AssignCancelInterface {company:string,email:string,state?:string}

//---------------------------
//----------------------axios
//---------------------------



export const AllClusterCompanies = ({token}:PrivateQuery) => {
    return apiCall({
        method: "GET",
        headers:{
            Authorization:`Bearer ${token}`
        },
        url:`/AllClusterCompanies`,
    })/* .then((res:AxiosResponse<ClusterCompanyInterface[] | []>)=>res.data) */
}

export const UserCompanies = ({token}:PrivateQuery) => {
    return apiCall({
        method: "get",
        headers:{
            Authorization: `Bearer ${token}`
        },
        url:`/UserCompanies`,
    })/* .then((res:AxiosResponse<ClusterCompanyInterface[] | []>)=>res.data) */
}


export const CreateClusterCompany = ({data, token}:PrivateQueryData<any>) => {
    return apiCall({
        method: "POST",
        headers:{
            Authorization: `Bearer ${token}`
        },
        url:`/CreateClusterCompany`,
        data
    })/* .then((res:AxiosResponse<ClusterCompanyInterface>)=>res.data) */
}

export const AssignCancelClusterCompany = ({data, token}:PrivateQueryData<any>) => {
    return apiCall({
        method: "POST",
        headers:{
            Authorization: `Bearer ${token}`
        },
        url:`/AssignCancelClusterCompany`,
        data
    })/* .then((res:AxiosResponse<MutateResponse>)=>res.data) */
}


//---------------------------
//----------------------Query
//---------------------------

export const useUserCompanies = () => {
    const {token} = useSessionContext()
    return useQuery<ClusterCompanyInterface[]>({
        queryKey:'UserCompanies',
        queryFn:()=>UserCompanies({token:token!}),
        staleTime:Infinity,
        enabled:!!token
    })
}

export const useAllClusterCompanies = () => {
    const {token} = useSessionContext()
    return useQuery<ClusterCompanyInterface[]>({
        queryKey:'AllClusterCompanies',
        queryFn:()=>AllClusterCompanies({token:token!}),
        staleTime:Infinity,
        enabled:!!token
    })
}

//---------------------------
//------------------Mutations
//---------------------------

export const useAssignCancelClusterCompany = () => {

    const {token} = useSessionContext()
    const query = useMutation(AssignCancelClusterCompany,{
        onSuccess:()=>queryClient.invalidateQueries('AllClusterCompanies')
    })
    const status = useStatusProcessor(query)

    const assignCompany = (data:AssignCancelInterface) => {
        token &&
        query.mutate({
            token,
            data:{
                ...data,
                state:'A'
            }
        })
    }

    const cancelCompany = (data:AssignCancelInterface) => {
        token &&
        query.mutate({
            token,
            data:{
                ...data,
                state:'C'
            }
        })
    }

    return {
        ...status,
        assignCompany,
        cancelCompany
    }

}