import React from "react";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import CUContainerForm from "../CUContainerForm";
import DeleteContainerForm from "../DeleteContainerForm";
import { useContainersGridModel } from "../lang/useContainersGridModel";

import { useAllContainersByTagFP } from "../services/service.containers";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageContainersPageProps {
  virtualGridWidth: string;
}

const ManageContainersPage: React.FC<ManageContainersPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: containers, isFetching } = useAllContainersByTagFP();

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item) => (
        <CUContainerForm actionType={CrudFormType.update} item={item} isDataUpdated={!isFetching} />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        title: lang.containers.title.plural,
        entityName: lang.containers.title.plural,
        width: virtualGridWidth,
        items: containers,
        gridModel: useContainersGridModel(),
        headerActions: () => (
          <>
            <CUContainerForm actionType={CrudFormType.create} />
          </>
        ),
        carrousellComponents,
      }}
    >
      {(items: any) =>
        items.length === 1 ? (
          <DeleteContainerForm typeLabel={lang.containers.title.singular} item={items[0]} />
        ) : (
          <></>
        )
      }
    </VirtualGrid>
  );
};

export default ManageContainersPage;
