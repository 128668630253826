import { Paper } from "@material-ui/core";
import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import CustomObservationsList from "../../../observations/CustomObservationsList";
import { useLubricationPointsGridModel } from "../../lang/useLubricationPointsGridModel";
import { usePaginationState } from "../../../../shared/react-table/Pagination";
import useFieldFilter from "../../../../components/filters/FieldFilter/hooks/useFieldFilter";
import { usePaginatedEquipmentsWithCompleteInfo } from "../../services/paginatedLubricationPointServices";
import { LubricationPointInterface } from "../../model/lubricationPointsInterface";
import EquipmentSurveyList from "../../../surveys/pages/EquipmentSurveyList/EquipmentSurveyList";
import CustomButton from "../../../../components/buttons/CustomButton";
import LubricationPointSurvey from "../../../surveys/components/LubricationPointSurvey/LubricationPointSurvey";
import EquipmentInfo from "../../../infoLubricationPoints/components/EquipmentInfo";
import PendingSuppliesToAssign from "../../../supply/PendingSuppliesToAssign";
import { ChangeReviewRequests } from "../../../infoLubricationPoints/components/ChangeReviewRequest";
import LeakLevelComponent from "../../../infoLubricationPoints/components/LeakLevelComponent";
import { useEquipmentAttrValues } from "../../services/lubricationPointsAdapters";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import RevisionChangeHistory from "../../../plant-notifications/revision-changes/components/RevisionChangeHistory";

interface KnowledgeOfTheLubPointsProps {
  title: string;
}

const KnowledgeOfTheLubPoints = ({ title }: KnowledgeOfTheLubPointsProps) => {
  const [lubricationPointItems, setLubricationPointItems] = useState<LubricationPointInterface[]>();

  const { lang } = useLangLabels();
  const lubricationPointsGridModel = useLubricationPointsGridModel();

  const { paginationOptions } = usePaginationState();
  const { data: equipmentFilterOptions } = useEquipmentAttrValues();
  const filterOptions = useFieldFilter<LubricationPointInterface>({
    items: lubricationPointItems!,
    gridModel: lubricationPointsGridModel,
    config: { isServerSideFiltering: true },
    entityName: title,
    customOptions: equipmentFilterOptions,
    filterDependentActions: paginationOptions.onPaginationChange,
    filterDependentActionsArgs: {
      pageIndex: 0,
    },
  });

  const {
    data: { items: lubricationPoints, pagination, isLoading },
  } = usePaginatedEquipmentsWithCompleteInfo(paginationOptions, {
    filters: filterOptions.filterModel,
    searchValue: filterOptions.searchValue,
  });

  useEffect(() => {
    if (!isLoading && lubricationPoints?.length) {
      setLubricationPointItems(lubricationPoints);
    }
  }, [lubricationPoints]);

  const itemActions = (item: any) => (
    <>
      <EquipmentSurveyList mode="modal" lubricationPoint={item} />
      {!item.info.pending && (
        <CustomButton
          icon="checkedCircle"
          variant="iconButton"
          styleProps={{ color: "green" }}
          popoverTitle={lang.reports.equipmentOnDate}
        />
      )}
    </>
  );

  const bulkActions = (items: any[]) => (
    <>
      <LubricationPointSurvey outOfRoute={true} items={items} />
      <EquipmentInfo items={items} variant="modal" />
      <CustomObservationsList queryType="lubricationPoint" items={items} />
      <PendingSuppliesToAssign mode="popover" items={items} />
      {/* <ChangeReviewRequests items={items} /> */}
    </>
  );

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "nextState",
      iconColor: "primary",
      popoverTitle: lang.revisionChange.revisionChangesHistory,
      component: (item) => <RevisionChangeHistory item={item} />,
    },
  ];

  const VirtualGrid = useMemo(
    () => lazy(() => import("../../../../shared/react-table/VirtualGrid/VirtualGrid")),
    []
  );

  return (
    <Suspense fallback={"..."}>
      <VirtualGrid
        title={title}
        entityName={title}
        gridModel={lubricationPointsGridModel}
        items={lubricationPoints}
        paginationOptions={paginationOptions}
        totalItems={pagination?.count}
        customDynamicFilters={filterOptions}
        itemActions={itemActions}
        bulkActions={bulkActions}
        carrousellComponents={carrousellActions}
        renderOptionColumn={{
          headerName: lang.surveys.loss,
          renderOption: (item: any) =>
            item.leakLevel && (
              <LeakLevelComponent
                lastlubricantCuantity={item.info.lubricantQuantity}
                pointCapacity={item.capacity}
              />
            ),
        }}
        documentExportTitle={`reportes-${title}`}
      />
    </Suspense>
  );
};

export default KnowledgeOfTheLubPoints;
