
import { useEffect, useState } from "react"
import { QueryStatus } from "react-query"
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config"
import { PrivateQuery, PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf"
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices"
import { useEquipmentsByElement } from "../../lubricationPoints/services/lubricationPointsAdapters"
import { useToken } from "../../session/store/sessionStore"
import { ElementInterface } from "../models/ElementInterface"




export const ElementCRUDDependencies = [
    'PlantAux'
]

//----------------------------
//repo
//---------------------------------------------


export const AllElements = ({token}:PrivateQuery) => {
    return apiCall({
        method:'GET',
        url:'AllElements',
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
}

export const CreateElement = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/CreateElement',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}
export const UpdateElement = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/UpdateElement',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const DeleteElement = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/DeleteElement',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}


//----------------------------
//reactQuery
//---------------------------------------------

export const usePlantElements = () => {
    return useGetAllAuxByPlantData('elements')
}


//----------------------------
//Mutation
//---------------------------------------------

export const useCreateElement = () => {
    const token = useToken()
    const query = useMutation(CreateElement,{
        onSuccess:() => ElementCRUDDependencies.forEach((dependency)=>{
            queryClient.invalidateQueries(dependency)
        })
    })

    const createElement = (data:ElementInterface) => {
        return query.mutate({
            data,
            token
        })
    }
    const status = useStatusProcessor(query)
    return {createElement,...query,...status}
}
export const useUpdateElement = () => {
    const token = useToken()
    const query = useMutation(UpdateElement,{
        onSuccess:() => ElementCRUDDependencies.forEach((dependency)=>{
            queryClient.invalidateQueries(dependency)
        })
    })

    const updateElement = (data:ElementInterface) => {
        return query.mutate({
            data,
            token
        })
    }
    const status = useStatusProcessor(query)
    return {updateElement,...query,...status}
}




//----------------------------
//delete
//---------------------------------------------

export const useDeleteElement = () => {
    return useMutation(DeleteElement,{
        onSuccess:() => ElementCRUDDependencies.forEach((dependency)=>{
            queryClient.invalidateQueries(dependency)
        })
    })
}

interface DeleteElementsErrors {
    equipments?:ElementInterface[] | undefined
}


export const useDeleteElementVerification = () => {
    const token = useToken()
    const query = useDeleteElement()
    const {mutate:deleteItem,data} = query
    const [status, setStatus] = useState<QueryStatus>('idle')
    const [validationElement,setValidationElement] = useState<ElementInterface | {} |undefined >()

    const {data:elementsByEquipments,status:elementsByEquipmentsStatus,remove:removeEquipmentsByElement} = useEquipmentsByElement(validationElement)
    const [errors, setErrors] = useState<DeleteElementsErrors>({})

    const handleResetValidations = () => {
        removeEquipmentsByElement()
    }

    const validate = (item:ElementInterface) => {
        handleResetValidations()
        setErrors({})
        setStatus('loading')
        setValidationElement(item)
    }

    const handleDelete= () =>{
        if(elementsByEquipmentsStatus === 'success'){
            if((elementsByEquipments && elementsByEquipments.length > 0)) {
                setErrors({equipments:elementsByEquipments})
                setValidationElement(undefined)
                setStatus('error')
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                },{
                    onSuccess:()=>{
                        setValidationElement(undefined)
                        setStatus('success')
                    },
                    onError:(err)=>console.log(err)
                })
            }
        }
    }
    
    useEffect(() => {
        validationElement && handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[elementsByEquipmentsStatus])

    return  {
        errors,
        status,
        validate,
        query
    }

}