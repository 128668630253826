import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"



export const useLubricantsGridModel = () => {

    const { lang } = useLangLabels()
    const gridModel: GridModelInterface = {
        id:{label:lang.globalentityValues.id}, 
        type:{label:lang.lubricantForm.type},
        brand:{label:lang.lubricantForm.brand}, 
        lubricant:{label:lang.lubricantForm.name}, 
        measureUnit:{label:lang.lubricantForm.measureUnit}, 
        tagFP:{label:lang.globalentityValues.tagFP}, 
        date:{label:lang.globalentityValues.date}, 
    }
    return gridModel
}