import { useDate } from "./../../../shared/date/useDate";
import { getViewDateDayHour } from "../../../shared/date/utils";
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import {
  PrivateQueryData,
  PrivateQueryDataTagFPInterface,
  PrivateQueryTagFPInterface,
} from "../../../shared/globals/utilsGlobalTypes";
import { useSessionContext } from "../../session/store/sessionContext";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { NewInterface } from "../model/NewsInterface";
import { useToken } from "../../session/store/sessionStore";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { useMemo } from "react";
import { PeriodInterface } from "../../../shared/date";

export const NewsByTagFP = ({ data, tagFP, token }: PrivateQueryDataTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/NewsByTagFP",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
    data,
  });
};

export const CreateNew = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/CreateNews",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const UpdateNew = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/UpdateNew",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//----------------------------
//useQuery
//---------------------------

export const useNewsByTagFP = (period?: PeriodInterface | undefined) => {
  const { token, tagFP } = useSessionContext();

  const canFetch = !!tagFP;

  const data = {
    firstDate: period?.firstDate,
    secondDate: period?.secondDate,
  };

  return useQuery<NewInterface[]>({
    queryKey: "PlantNews",
    queryFn: () => NewsByTagFP({ data, tagFP, token }),
    enabled: canFetch,
    staleTime: 900,
    select: (data) => {
      return data.map((n) => ({
        ...n,
        newsDate: getViewDateDayHour(n.newsDate),
      }));
    },
  });
};

export const useTodayNewsbyTagFP = () => {
  const news = useNewsByTagFP();

  const { nowYear, nowDay, nowMonth } = useDate();
  const date = `${nowYear}-${nowMonth}-${nowDay}`;
  const filteredNews = useMemo(
    () => news.data?.filter((n) => n.newsDate.includes(date)),
    [news.data]
  );
  
  return useMemo(
    () => ({
      ...news,
      data: filteredNews,
    }),
    [filteredNews]
  );
};

//----------------------------
//Mutation
//---------------------------

export const useCreateNew = () /* :{createNew:(data:NewInterface)=>void,data:any} */ => {
  const token = useToken();
  const query = useMutation(CreateNew, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantNews");
    },
  });

  const status = useStatusProcessor(query);

  const createNew = (New: NewInterface) => {
    return query.mutate({
      data: {
        ...New,
        equipment: New.equipment || "",
      },
      token,
    });
  };

  return {
    createNew,
    ...query,
    ...status,
  };
};

export const useUpdateNew = () => {
  const token = useToken();
  const query = useMutation(UpdateNew);

  const status = useStatusProcessor(query);

  const update = (New: NewInterface) => {
    query.mutate({
      data: New,
      token,
    });
  };

  return {
    update,
    ...query,
    ...status,
  };
};
