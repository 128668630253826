// import useBasicLocalStorage from "../../../shared/localStorage/useBasicLocalStorage";
import { useSessionContext } from "./sessionContext";

export const useToken = () => {
  const { token } = useSessionContext();
  return token;
};

export const usePlants = () => {
  const { plants } = useSessionContext();
  return plants;
};

export const usePerson = () => {
  const { person } = useSessionContext();
  return {
    data: person,
  };
};

export const useUser = () => {
  const { user } = useSessionContext();

  if (user) {
    localStorage.setItem("last-user", JSON.stringify(user));
  }

  return {
    data: user,
  };
};
