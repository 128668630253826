import { QueryClient, useQuery as useReactQuery, useMutation as useReactQueryMutation, QueryOptions, MutationOptions, QueryKey, UseQueryResult, UseQueryOptions, UseMutationResult, UseMutationOptions, QueryFunction, MutationFunction } from "react-query";
import { persistedSession } from "../../domains/session/persistance/sessionPersistance";
import { useSessionContext } from "../../domains/session/store/sessionContext";

export const queryClient = new QueryClient({
    queryCache:undefined,
    mutationCache:undefined,
    defaultOptions:{
        queries:{
            refetchOnWindowFocus:false,
            staleTime:20000,
        },
        mutations:{

        }
    }
})

export const useQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey> (options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError> => {
    
    const {sessionStale, token} = persistedSession()

    const enabled = options.enabled && !sessionStale && !!token && token !== '' && token !== 'session stale'
    
    return useReactQuery({
        ...options,
        enabled
    })
}

export const useMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(mutationFn: MutationFunction<TData, TVariables>, options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>) : UseMutationResult<TData, TError, TVariables, TContext>  => {
    return useReactQueryMutation(mutationFn, options)
}

