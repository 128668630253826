import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { QueryStatus } from "react-query";
import { Paper } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import CustomButton from "../../../components/buttons/CustomButton";
import { useClickPosition } from "../../../shared/globals/hooks/useClickPosition";
import PageContainer from "../../../components/containers/PageContainer";
import "./styles/syncBox.css";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import {
  CollectorsSync,
  lastDBSync,
} from "../../../domains/SyncBox/model/CollectorsSyncByTagFPType";
import { SVGButton } from "./components/SVGButton";
import { SyncBox } from "./components/SyncBox";
import { syncPanelDrawerWidth } from "./InstrumentDeviceSyncPanel";
import { ColectorsSyncList } from "./components/ColectorsSyncList";
import { SyncBoxNotification } from "./components/SyncBoxNotification";

export type SVGSyncPanelIconType =
  | "update"
  | "info2"
  | "syncAction"
  | "syncBoxStatus"
  | "notification";

interface Props {
  colectorSyncsStatus: boolean | null | undefined;
  leftColectorsSync: undefined | CollectorsSync[] | [];
  syncBoxStatusColor: string;
  pendingSyncs: lastDBSync[];
  pendincColectorsSync: CollectorsSync[];
  actionStatus: QueryStatus;
  actionUpdateStatus: QueryStatus;
  handleSyncClick: () => void;
  handleUpdateSyncClick: () => void;
}

const InstrumentSyncBoxIcon: React.FC<Props> = ({
  syncBoxStatusColor,
  handleSyncClick,
  handleUpdateSyncClick,
  actionStatus,
  actionUpdateStatus,
}) => {
  const { getClickPosition, resetClickPosition, position } = useClickPosition();
  const [hover, setHover] = useState(false);
  const [hoverElement, setHoverElement] = useState<SVGSyncPanelIconType | undefined>();
  const { lang } = useLangLabels();

  const handleHoverIn = (e: any, type: SVGSyncPanelIconType) => {
    getClickPosition(e, () => {
      setHover(true);
    });

    setHoverElement(type);
  };

  const handleHoverOut = () => {
    resetClickPosition(() => {
      setHover(false);
    });

    setHoverElement(undefined);
  };

  return (
    <>
      {hover && (
        <Paper
          onMouseLeave={handleHoverOut}
          style={{
            maxWidth: "350px",
            position: "absolute",
            top: `calc( ${position.positionY}px - 50px )`,
            left: `calc( ${position.positionX}px - ${100 - syncPanelDrawerWidth}vw - 80px )`,
            zIndex: 10000,
          }}
          elevation={2}
        >
          <PageContainer padding="16px">
            {hoverElement === "update" && (
              <>
                <Typography variant="h6">{lang.collectorSync.updateSyncBoxData}</Typography>
                <Divider />
                <br />
                <Typography variant="body1">
                  {lang.collectorSync.updateSyncBoxDataDescription}
                </Typography>
                <br />
                <Typography variant="h6">
                  {lang.collectorSync.howToSynchronizeTheSyncBox}
                </Typography>

                <Typography variant="body1">
                  {lang.collectorSync.howToSynchronizeTheSyncBoxDescription}
                </Typography>
              </>
            )}

            {hoverElement === "info2" && (
              <>
                <Typography variant="h6">{lang.collectorSync.synchronizeCollectors}</Typography>
                <Divider />
                <br />
                <Typography variant="body1">
                  {lang.collectorSync.synchronizeCollectorsDescription}
                </Typography>
              </>
            )}
          </PageContainer>
        </Paper>
      )}

      <svg xmlns="http://www.w3.org/2000/svg" height="40vh" viewBox="-50 0 550 300">
        <g className="cls-1">
          <g id="Capa_1-2" data-name="Capa 1">
            <SVGButton
              type="update"
              handleHoverIn={handleHoverIn}
              handleHoverOut={handleHoverOut}
              handleSyncClick={handleSyncClick}
              actionStatus={actionStatus}
              actionUpdateStatus={actionUpdateStatus}
              handleUpdateSyncClick={handleUpdateSyncClick}
            />

            <SyncBox
              backgroundColor={syncBoxStatusColor}
              actionStatus={actionStatus}
            />

            {/* SyncBox lines Info 1 */}
            <g>
              {/* Line */}
              <polyline className="cls-4" points="180 160 50 160" />

              {/* Arrow */}
              <polygon
                className="cls-5"
                points="178.9 163.86 180.47 160.15 178.9 156.45 187.68 160.15 178.9 163.86"
              />

              {/* Vertical line */}
              <line className="cls-4" x1="108" y1="159.49" x2="108" y2="224.9" />
            </g>

            {/* Phone line & Arrows */}
            <g>
              <line
                className="cls-4"
                x1="326.06"
                y1="150.45"
                x2="406.71"
                y2="150.45"
                transform="translate(0, 10)"
              />
              <polygon
                transform="translate(0, 10)"
                className="cls-5"
                points="403.9 154.16 405.47 150.45 403.9 146.75 412.68 150.45 403.9 154.16"
              />
            </g>

            <SVGButton
              type="info2"
              handleHoverIn={handleHoverIn}
              handleHoverOut={handleHoverOut}
              handleSyncClick={handleSyncClick}
              actionStatus={actionStatus}
              actionUpdateStatus={actionUpdateStatus}
              handleUpdateSyncClick={handleUpdateSyncClick}
            />

            {/* Desktop & Phone icons */}
            <g>
              {/* Desktop */}
              <path
                transform="translate(0, 7)"
                className="syncbox-border"
                d="M39,134.3H3.9A3.91,3.91,0,0,0,0,138.2v23.38a3.91,3.91,0,0,0,3.9,3.9H17.54v3.9h-3.9v3.89H29.23v-3.89h-3.9v-3.9H39a3.91,3.91,0,0,0,3.9-3.9V138.2A3.91,3.91,0,0,0,39,134.3Zm0,27.28H3.9V138.2H39Z"
              />

              {/* Phone */}
              <path
                transform="translate(0, 10)"
                className="syncbox-border"
                d="M449.45,128.49l-17.95,0a3.8,3.8,0,0,0-3.59,4v35.67a3.8,3.8,0,0,0,3.59,4h17.95a3.8,3.8,0,0,0,3.58-4V132.44A3.78,3.78,0,0,0,449.45,128.49Zm-4.93,38.84h-8.38a.85.85,0,0,1-.86-.86v-.35a.85.85,0,0,1,.86-.86h8.38a.86.86,0,0,1,.86.86v.35A.86.86,0,0,1,444.52,167.33Zm4.31,2.42H432.11a2,2,0,0,1-1.82-2.09V133.24a2,2,0,0,1,1.82-2.08h16.72a2,2,0,0,1,1.83,2.08v34.42A2,2,0,0,1,448.83,169.75Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default InstrumentSyncBoxIcon;
