import { PaginationOptions, PaginationResult } from "../types/PaginationTypes";

export const usePagination = (
  { pageIndex, pageSize, onPaginationChange }: PaginationOptions,
  totalItems: number
): PaginationResult => {
  const totalPages = Math.ceil(totalItems / pageSize);
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < totalPages - 1;

  const previousPage = () => {
    if (canPreviousPage) {
      onPaginationChange({
        pageIndex: pageIndex - 1,
      });
    }
  };

  const nextPage = () => {
    if (canNextPage) {
      onPaginationChange({
        pageIndex: pageIndex + 1,
      });
    }
  };

  const handlePageIndexChange = (value: number) => {
    if (value < totalPages && value >= 0) {
      onPaginationChange({
        pageIndex: value,
      });
    }
  };

  const handlePageSizeChange = (value: number) => {
    onPaginationChange({
      pageIndex: 0,
      pageSize: value,
    });
  };

  return {
    pageIndex,
    totalItems,
    pageSize,
    totalPages,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    handlePageIndexChange,
    handlePageSizeChange,
    onPaginationChange,
  };
};
