import React from "react";
import ComponentMode from "../../../../components/componentMode/ComponentMode";
import { ComponentUIModes } from "../../../../components/componentMode/types/componentModesInterfaces";
import ColectorList from "../../../surveys/components/ColectorList";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const LubricationPointsList: React.FC<{ type: ComponentUIModes; item: any; title?: string }> = ({
  type,
  item,
  title,
}) => {
  const { lang } = useLangLabels();

  const primaryText: string[] = ["equipment", "element", "sector", "lubricant"];

  const secondaryText = [
    {
      key: "plantTag",
      label: lang.lubricationPoints.plantTag,
    },
    {
      key: "component",
      label: lang.lubricationPoints.component,
    },
    {
      key: "sector en planta",
      label: lang.sectors.form.label.sector,
    },
    {
      key: "lubricantType",
      label: lang.lubricationPoints.lubricantType,
    },
  ];

  const trashTitle: string =
    (item.lubricant
      ? `${lang.lubricationPoints.helpers.lubricationPointsThatUse}: ${item.lubricant.type} ${item.lubricant.lubricant}`
      : "") ||
    (item.supply
      ? `${lang.lubricationPoints.helpers.lubricationPointsThatUse}: ${item.supply.supply}`
      : "") ||
    (item.routeName
      ? `${lang.lubricationPoints.helpers.routeLubricationPoint}: ${item.routeName}`
      : "") ||
    (item.permission
      ? `${lang.lubricationPoints.helpers.routeLubricationPoint}: ${item.permission.permissionName}`
      : "");

  return (
    <>
      <ComponentMode
        mode={type}
        variant="iconButton"
        icon="more"
        title={title?.toUpperCase() || trashTitle.toUpperCase()}
      >
        <ColectorList
          items={item.lubricationPoints}
          primaryText={primaryText}
          secondaryText={secondaryText}
        />
      </ComponentMode>
    </>
  );
};

export default React.memo(LubricationPointsList);
