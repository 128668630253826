import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

export const useRevisionChangeGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    review: { label: lang.revisionChange.review },
    tagTGD: { label: lang.globalentityValues.tagTGD },
    equipment: { label: lang.STIEquipments.title.singular },
    element: { label: lang.elements.title.singular },
    component: { label: lang.components.title.singular },
    requestDate: { label: lang.revisionChange.requestDate },
    requestReason: { label: lang.revisionChange.requestReason },
    requestUser: { label: lang.revisionChange.requestUser },
    supervisionDate: { label: lang.revisionChange.supervisionDate },
    supervisionReason: { label: lang.revisionChange.supervisionReason },
    supervisionUser: { label: lang.revisionChange.supervisionUser },
    impactDate: { label: lang.revisionChange.impactDate },
  };

  return gridModel;
};
