import { LubricationPointInterface } from "../../../../lubricationPoints/model/lubricationPointsInterface";

export const CountPendingEquipments: React.FC<{ item: any }> = ({ item }) => {
  const equipmentsDone = item.lubricationPoints?.filter(
    (point: LubricationPointInterface) => point.info?.pending === false
  );

  const totalEquipments =
    item.lubricationPoints?.length > 9
      ? item.lubricationPoints?.length
      : `0${item.lubricationPoints?.length}`;

  return <>{`${equipmentsDone.length || 0}/${totalEquipments}`}</>;
};
