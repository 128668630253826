import { Box, Typography } from "@material-ui/core";
import FlexContainer from "../../../../../components/containers/FlexContainer";
import { useDate } from "../../../../../shared/date/useDate";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

interface PageBodyContentInfoProps {
  otherProps?: Record<string, any>;
}

const PageBodyContentInfo = ({ otherProps }: PageBodyContentInfoProps) => {
  const { lang } = useLangLabels();
  const { formatISODate } = useDate();

  return (
    <div style={{ padding: "36px 0 32px 0" }}>
      <Typography
        variant="h2"
        style={{
          fontSize: "21px",
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: "4px",
        }}
      >
        {lang.reportsGeneration.lossReport}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          paddingTop: "16px",
        }}
      >
        <FlexContainer gap={"32px"}>
          <Typography variant="h3" style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>{lang.filters.from}:</span>{" "}
            {formatISODate(otherProps?.firstDate)}
          </Typography>
          <Typography variant="h3" style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>{lang.filters.to}:</span>{" "}
            {formatISODate(otherProps?.secondDate)}
          </Typography>
        </FlexContainer>

        <FlexContainer style={{ fontSize: "16px" }} gap={"8px"}>
          <Typography variant="h3" style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>{lang.reportsGeneration.dateOfIssue}:</span>{" "}
            {formatISODate(new Date().toISOString())}
          </Typography>
        </FlexContainer>

        <FlexContainer
          padding={"16px 0 0 0"}
          align="center"
          gap={"32px"}
          style={{ fontSize: "16px" }}
        >
          <FlexContainer align="center" gap={"8px"}>
            <Box width={16} height={16} bgcolor="green" />
            <Typography>{lang.reportsGeneration.lessThan} 30%</Typography>
          </FlexContainer>
          <FlexContainer align="center" gap={"8px"}>
            <Box width={16} height={16} bgcolor="orange" />
            <Typography>{lang.reportsGeneration.greaterThan} 30%</Typography>
          </FlexContainer>
          <FlexContainer align="center" gap={"8px"}>
            <Box width={16} height={16} bgcolor="red" />
            <Typography>{lang.reportsGeneration.greaterThan} 40%</Typography>
          </FlexContainer>
        </FlexContainer>
      </div>
    </div>
  );
};

export default PageBodyContentInfo;
