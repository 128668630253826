import { SyncFileErrors } from "../../../domains/SyncBox/model/CollectorsSyncByTagFPType";
import { LangType } from "./interfaces";

export interface language {
  sidebarMenu: {
    home: string;
    explore: string;
    reports: string;
    calendar: string;
    knowledgeBase: string;
    news: string;
    notifications: string;
    lubricationSection: string;
    plantConfig: string;
    client: string;
  };
  sidebar: {
    config: {
      accountSettings: string;
      userUpdatedSuccessFully: string;
      atLeastSix: string;
      newPassword: string;
      doesNotMatch: string;
      repeatPassword: string;
      changePassword: string;
      showPassword: string;
    };
    createTicket: {
      support: string;
      criticality: string;
      priority: string;
      low: string;
      medium: string;
      high: string;
      urgent: string;
      unselected: string;
      whatWasTheProblem: string;
      couldNotBeNotified: string;
      thanksForNotifying: string;
      dislikeOfTheProblem: string;
      doYouLikeTGDSoft: string;
    };
  };
  plantConfig: {
    users: string;
    configurations: string;
  };
  client: {
    viewAsClient: string;
    noFeaturedGraphics: string;
    selectAFactory: string;
    newNotification: string;
    publicationDate: string;
    message: string;
    readAll: string;
    notifications: string;
    emptyNotificationTray: string;
    unread: string;
    read: string;
    connectingServices: string;
  };
  explore: {
    previous: string;
    next: string;
    cleanSearch: string;
    thereAreNoSurveys: string;
    lubricated: string;
    failedToLubricate: string;
    checked: string;
    uncontrolled: string;
    systemInconsistency: string;
  };
  selectedLanguage: string;
  stringDefaultValues: {
    withOutSupplies: string;
    notSpecified: string;
    withOutRoutes: string;
    withoutObservations: String;
  };
  select: {
    category: string;
    subcategory: string;
    items: string;
    lubricationPoint: string;
  };
  words: {
    info: string;
    and: string;
    of: string;
    thisMasc: string;
    thisfem: string;
    available: string;
    availables: string;
    more: string;
    all: string;
    yes: string;
    no: string;
    active: string;
    inactive: string;
    request: string;
    close: string;
    without: string;
    from: string;
    to: string;
  };
  globalentityValues: {
    id: string;
    tagFP: string;
    tagTGD: string;
    tagSTI: string;
    date: string;
    identifier: string;
  };
  enumTgdTypes: {
    UNIDAD: string;
    EQUIPO: string;
    LUBRICANTE: string;
    OBSERVACION: string;
    INSTRUMENTO: string;
  };
  collectorSync: {
    title: string;
    itWasFind: string;
    databasesWithProblems: string;
    basesFoundWithProblems: string;
    errors: {
      /*  [SyncFileErrors.Corrupted]:string, */
      [SyncFileErrors.failed]: string;
      [SyncFileErrors.expired]: string;
    };
    helpers: {
      /* [SyncFileErrors.Corrupted]:string, */
      [SyncFileErrors.failed]: string;
      [SyncFileErrors.expired]: string;
      unknown: string;
    };
    synchronizeSyncBox: string;
    updatedSyncBoxData: string;
    synchronizeSyncBoxDescription: string;
    syncBoxState: string;
    syncBoxStateDescription: string;
    unsynchronizedCollectors: string;
    pendingSyncs: string;
    pendingSyncsDescription: string;
    toKeepSoftUpdated: string;
    synchronizeCollectors: string;
    synchronizeCollectorsDescription: string;
    updateSyncBoxData: string;
    updateSyncBoxDataDescription: string;
    howToSynchronizeTheSyncBox: string;
    howToSynchronizeTheSyncBoxDescription: string;
    lastSynchronization: string;
    someCollectorsHaveBeenSynchronized: string;
    missingCollectors: string;
    collectorsWereSynchronizedAtLeastOnce: string;
  };
  messages: {
    unspecified: string;
    copiedToClipboard: string;
    featureNotAvailableOffline: string;
    featureNotAvailableOnline: string;
    abilityNotAllowed: string;
    lastTenSurveys: string;
    lastTenEquipmentSurveys: string;
    ofThisLubricationPoint: string;
    lastTenObservations: string;
    lastObservations: string;
    latestIncome: string;
    surveyObservations: string;
    lubricationPointObsevetions: string;
    nothingSelected: string;
    notRegistered: string;
    cannotBeRemoved: string;
    becauseItsUsedOn: string;
    features: {
      collectorConfig: string;
      supervisorSurvey: string;
      supervisorClientVisor: string;
    };
    status: {
      success: string;
      error: string;
      loading: string;
    };
    anErrorHasOccurred: string;
  };
  backResponseMessages: {
    REGISTRATION_PROBLEM_OCCURRED: string;
    DELETION_PROBLEM_OCCURRED: string;
    NOT_EXISTS: string;
    COULD_NOT_EDIT: string;
    ALL_FIELDS_REQUIRED: string;
    SYNCHRONIZED_SUCCESSFULLY: string;
    ASSIGNED_SUCCESSFULLY: string;
    COULD_NOT_ASSIGN: string;
    ALREADY_EXISTS: string;
    OBSOLETE_DATA_UPDATING: string;
    NO_FIELD_MODIFIED: string;
    COULD_NOT_UPDATE: string;
    REGISTRATION_FAILED: string;
    COULD_NOT_DELETE: string;
    SUCCESSFUL_UPDATE: string;
    SUCCESSFUL_CREATION: string;
    SUCCESSFUL_DELETE: string;
    COLLECTORS_SUCCESSFUL_SYNCHRONIZATION: string;
    GENERATING_DATA_SYNCHRONIZATION: string;
    SYNCHRONIZATION_FAILED: string;
  };
  grid: {
    actionsAlert: string;
    noRowsLabel: string;
    errorOverlayDefaultLabel: string;
    toolbarColumns: string;
    toolbarColumnsLabel: string;
    toolbarFilters: string;
    toolbarFiltersLabel: string;
    toolbarFiltersTooltipHide: string;
    toolbarFiltersTooltipShow: string;
    columnsPanelTextFieldLabel: string;
    columnsPanelTextFieldPlaceholder: string;
    columnsPanelDragIconLabel: string;
    columnsPanelShowAllButton: string;
    columnsPanelHideAllButton: string;
    toolbarExport: string;
    toolbarExportLabel: string;
    toolbarExportCSV: string;
  };
  filters: {
    search: string;
    filterColumns: string;
    disableEverything: string;
    title: string;
    addFilter: string;
    deleteFilters: string;
    add: string;
    searchByTerm: string;
    closeFilters: string;
    downloadData: string;
    filterData: string;
    all: string;
    select: string;
    queryDateRange: string;
    from: string;
    to: string;
    resetDateRange: string;
    and: string;
    andHelper: string;
    or: string;
    orHelper: string;
  };
  sendEmail: {
    title: string;
    att: string;
    charge: string;
    recipient: string;
    emailBody: string;
  };
  crudActions: {
    update: string;
    create: string;
    delete: string;
    archive: string;
    desactivate: string;
    activate: string;
    save: string;
  };
  inputFields: {
    autocomplete: {
      clearText: string;
      closeText: string;
      openText: string;
      loadingText: string;
      email: string;
    };
  };
  validations: {
    messages: {
      macAddress: string;
      justNumbers: string;
      invalidNumber: string;
      justText: string;
      required: string;
      minLength: string;
      maxLength: string;
      allReadyExist: string;
      pattern: string;
      completeFields: string;
    };
  };
  ui: {
    components: {
      viewMode: {
        title: string;
      };
      name: string;
    };
  };
  pages: {
    realTime: {
      sensors: string;
      equipments: string;
    };
  };
  login: {
    form: {
      label: {
        user: string;
        password: string;
      };
    };
    navigation: {
      name: string;
    };
    legend: {
      years: string;
    };
    yearText: string;
  };
  demo: {
    form: {
      completeName: string;
      phone: string;
      company: string;
      email: string;
    };
    prospects: string;
  };
  persons: {
    newSupervisor: string;
    newOperator: string;
    newAdministrator: string;
    newAccountManager: string;
    newClient: string;
    userAdministration: string;

    createstep1: string;
    createstep2: string;
    createFinishStep: string;

    plural: string;
    singular: string;
    name: string;
    lastName: string;
    email: string;
    client: string;
    clients: string;
    operator: string;
    operators: string;
    supervisor: string;
    supervisors: string;
    superAdmin: string;
    superAdmins: string;
    admin: string;
    admins: string;
    accountManager: string;
    accountManagers: string;

    adress: string;
    phone: string;
    password: string;
    referer: string;
  };
  factories: {
    singular: string;
    plural: string;
    location: string;
    name: string;
    businessName: string;
    referrer: string;
    tagF: string;
    accountManagerFactories: string;
    manageFactories: string;
    noFactories: string;
  };
  plants: {
    asignCancelPlants: string;
    clickToAssign: string;
    clickToCancel: string;
    singular: string;
    plural: string;
    factoryId: string;
    factory: string;
    location: string;
    name: string;
    plantId: string;
    referrer: string;
    tagFP: string;
    state: string;
  };
  language: {
    label: string;
    spanish: string;
    english: string;
  };
  formMessages: {
    required: string;
    float: string;
    invalidEmail: string;
  };
  stock: {
    product: string;
    brand: string;
    actualStock: string;
    minimumRequired: string;
    category: string;
    subcategory: string;
    measureUnit: string;
    categoryArea: string;
    providerArea: string;
    complex: string;
    hidden: string;
    options: {
      simple: string;
      complex: string;
      archived: string;
      unArchived: string;
    };
  };
  lubricationSection: {
    title: string;
    incomeManagement: string;
    manageStock: string;
    lubricants: string;
    allIncome: string;
    stock: string;
    configureCategory: string;
    purchaseRequests: string;
    newPurchaseRequest: string;
    categories: string;
    addNewCategory: string;
    subcategories: string;
    addNewSubcategory: string;
    subcategory: string;
    allStock: string;
    allStockItems: string;
    lossAmount: string;
    categoryName: string;
    newStockProduct: string;
    seeItems: string;
    seeArchived: string;
    archive: string;
    archived: string;
  };
  actionTypes: {
    activate: string;
    desactivate: string;
    view: string;
    apply: string;
    login: string;
    logout: string;
    create: string;
    deleteItem: string;
    update: string;
    assign: string;
    save: string;
    exit: string;
    next: string;
    before: string;
    cancel: string;
    configure: string;
    add: string;
    new: string;
  };
  types: {
    plural: string;
    singular: string;
    form: {
      name: string;
      type: string;
    };
    typeCannotBeRemoved: string;
  };
  lubricationPoints: {
    title: {
      singular: string;
      plural: string;
    };
    delayedPoints: string;
    todayPoints: string;
    equipments: string;
    equipmentName: string;
    equipmentTag: string;
    equipmentCriticality: string;
    equipmentIdentification: string;
    sector: string;
    plantTag: string;
    lowReason: string;
    labelId: string;
    label: string;
    state: string;
    criticality: string;
    egressDate: string;
    function: string;
    equipment: string;
    review: string;
    location: string;
    lubricantType: string;
    routes: string;
    supplies: string;
    type: string;
    brand: string;
    componentBrand: string;
    componentModel: string;
    componentFunction: string;
    componentLocation: string;
    model: string;
    element: string;
    component: string;
    lubricant: string;
    capacity: string;
    lubricantCapacity: string;
    measureUnit: string;
    oilAnalysis: string;
    admissionDate: string;
    observations: string;
    tagTGDBefore: string;
    equipmentState: string;
    allActivePoints: string;
    youAreActivatingAllPoints: string;
    youAreDecommissioningEquipment: string;
    youAreDecommissioningComponent: string;
    youAreDecommissioningElement: string;
    thereWasAnErrorActivating: string;
    equipmentHasBeenDecommissioned: string;
    dismantle: string;
    activateThis: string;
    again: string;
    activateAgain: string;
    options: {
      active: string;
      disabled: string;
      all: string;
      oilAnalisys: string;
      withoutOilAnalisys: string;
    };
    helpers: {
      whyAreEditingThisPoint: string;
      lubricationPointsThatUse: string;
      lubricationPointsThatUseThisPermission: string;
      routeLubricationPoint: string;
      pointsThatNeedLabelChange: string;
      needLabelChange: string;
      labelInCorrectCondition: string;
    };
    suggestedQuantity: string;
    suggestedQuantityIndicates: string;
    lastLubricantAdmission: string;
  };
  revisionChange: {
    title: {
      singular: string;
      plural: string;
    };
    revisionChangeMadeFromPlantConfiguration: string;
    unsupervised: string;
    pendingRevisionChanges: string;
    supervisedRevisionChanges: string;
    revisionChangesHistory: string;
    pending: string;
    supervised: string;
    requestDate: string;
    requestReason: string;
    requestUser: string;
    supervisionDate: string;
    supervisionReason: string;
    supervisionUser: string;
    impactDate: string;
    request: string;
    supervision: string;
    review: string;
    approve: string;
    approved: string;
    reject: string;
    rejected: string;
    state: string;
  };
  notifications: {
    title: {
      singular: string;
      plural: string;
    };
  };
  infoLubricationPoints: {
    supplies: string;
    requiredSupplies: string;
    equipmentsWithRequests: string;
    thereAreNoSuppliesToAssign: string;
    labelReplacement: string;
    lastLubrication: string;
    lubricantQuantity: string;
    leak: string;
    pending: string;
    updatedToday: string;
    user: string;
    lastSurveyId: string;
    assetCriticality: string;
    inaccessible: string;
    lastInspectionDate: string;
    updateReview: string;
    automaticReviewChange: string;
    toUpdateReview: string;
    changeToNextReview: string;
    rejectRequest: string;
    youAreChangingToTheNextComponentRevision: string;
    manageReviewChanges: string;
    equipmentsWithReviewChangeRequest: string;
    isRecommendedToMakeChangesIf: string;
    allSynchronizationsHaveBeenProcessed: string;
    thereWillBeNoSynchronizationsIn: string;
    otherwiseWhenSynchronizing: string;
    temperature: string;
    leakLevelRange: string;
    withoutSupplies: string; //'SIN INSUMOS'
    assetGeneralScore: string; //'Puntuación general del activo.'
    assetPriority: string; //'Importancia del activo'
    lastEquipmentState: string; //'ULTIMO ESTADO DEL EQUIPO'
    withLeak: string; //'Con Perdida'
    withoutLeak: string; //'Sin pérdida'
    lastAgregate: string; //'ULTIMO AGREGADO'
    added: string;
    cannotBeCalculated: string;
    notPending: string; //'Al Día'
    accessedToday: string; //'SE LO INTERVINO HOY',
    lastInspection: string; //'ULTIMA INSPECCION'
    accesible: string; //'Accesible'
    lastTemperature: string; //'ULTIMA TEMPERATURA'
    missings: string; //'FALTANTES'
    capacity: string; //'CAPACIDAD'
    equipmentinformation: string; //'Información del Equipo'
    lastSurveys: string; //Últimos relevamientos
  };
  observations: {
    observationTypes: {
      INACCESIBLE: string;
      "IMPOSIBLE DE LUBRICAR": string;
      "NO LUBRICA": string;
      ANOMALIA: string;
      OBSERVACION: string;
    };
    addedReasons: {
      leak: string;
    };
    observationsQuantity: string;
    singular: string;
    plural: string;
    delete: string;
    surveyId: string;
    equipment: string;
    modified: string;
    modifiedDate: string;
    observationDate: string;
    OTnumber: string;
    state: string;
    taskId: string;
    taskRequestId: string;
    taskType: string;
    type: string;
    element: string;
    component: string;
    user: string;
  };
  addedReasons: {
    plural: string;
    singular: string;
    reason: string;
  };
  anomalies: {
    singular: string;
    plural: string;
    anomalyName: string;
  };
  news: {
    singular: string;
    plural: string;
    sector: string;
    area: string;
    equipment: string;
    type: string;
    newsDate: string;
    description: string;
    criticality: string;
    user: string;
    createNew: string;
    linkToAEquipment: string;
    newNovelty: string;
    all: string;
    environment: string;
    plant: string;
  };
  collectors: {
    plural: string;
    singular: string;
    activate: string;
    desactivate: string;
    desactivatedColectorWarning: string;
    qrCode: string;
    collectorCode: string;
    form: {
      active: string;
      disabled: string;
      name: string;
      serialNumber: string;
      collectorCode: string;
      id: string;
      tagFP: string;
      state: string;
      admissionDate: string;
      egressDate: string;
    };
  };
  criticalities: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      descripton: string;
      number: string;
      periodicity: string;
      color: string;
      type: string;
      days: string;
    };
    criticalityCannotBeRemoved: string;
  };
  lubricantForm: {
    singular: string;
    plural: string;
    type: string;
    name: string;
    brand: string;
    measureUnit: string;
    requiresObservation: string;
    points: string;
    approximateNeeded: string;
    lubricantCannotBeRemoved: string;
  };
  incomeForm: {
    singular: string;
    plural: string;
    lotNumber: string;
    quantity: string;
    measurementUnits: string;
    admissionDate: string;
    referrer: string;
    confirmMessage: string;
  };
  containers: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      name: string;
      label: {
        name: string;
        lubricantType: string;
        lubricant: string;
        capacity: string;
        capacityHelper: string;
        coefficient: string;
        code: string;
        type: string;
      };
    };
  };
  sectors: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      name: string;
      label: {
        sector: string;
        sectorName: string;
        code: string;
      };
    };
    sectorCannotBeRemoved: string;
  };
  areas: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      name: string;
      label: {
        area: string;
        email: string;
        emailHelper: string;
        referrer: string;
      };
    };
  };
  components: {
    seeComponent: string;
    activeComponents: string;
    inactiveComponents: string;
    activePoint: string;
    inactivePoint: string;
    componentCannotBeRemoved: string;
    assets: {
      plural: string;
    };
    inactive: {
      plural: string;
    };
    title: {
      singular: string;
      plural: string;
    };
    form: {
      name: "";
      label: {
        component: string;
        code: string;
      };
    };
  };
  elements: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      name: "";
      label: {
        element: string;
        code: string;
      };
    };
    elementCannotBeRemoved: string;
  };
  supplies: {
    assignmentsMade: string;
    selectEquipmentToAssign: string;
    nextStep: string;
    backReset: string;
    errorGetingSupplies: string;
    noConsumablesHaveBeenLoaded: string;
    missing: string;
    missingPlural: string;
    used: string;
    request: string;
    requestAddedInput: string;
    missingInputsForTheTask: string;
    suppliesUsed: string;
    title: {
      singular: string;
      plural: string;
      form: {
        name: string;
        label: {
          type: string;
          supply: string;
          description: string;
        };
      };
    };
    HERRAMIENTA: {
      assignToEquipment: string;
      manageLubricationPointSupply: string;
      title: {
        singular: string;
        plural: string;
      };
      form: {
        name: "";
        label: {
          type: string;
          supply: string;
          description: string;
        };
      };
    };
    CONSUMIBLE: {
      assignToEquipment: string;
      manageLubricationPointSupply: string;
      title: {
        singular: string;
        plural: string;
      };
      form: {
        name: "";
        label: {
          type: string;
          supply: string;
          description: string;
        };
      };
    };
    PROTECCION: {
      assignToEquipment: string;
      manageLubricationPointSupply: string;
      title: {
        singular: string;
        plural: string;
      };
      form: {
        name: "";
        label: {
          type: string;
          supply: string;
          description: string;
        };
      };
    };
    OBSERVACION: {
      manageLubricationPointSupply: string;
      title: {
        singular: string;
        plural: string;
      };
      form: {
        name: "";
        label: {
          type: string;
          supply: string;
          description: string;
        };
      };
    };
    LUBRICANTE: {
      title: {
        singular: string;
        plural: string;
      };
    };
    PERMISO: {
      title: {
        singular: string;
        plural: string;
      };
    };
  };
  permissions: {
    plural: string;
    singular: string;
    permissionName: string;
    assignToEquipment: string;
    manageEquipmentPermissions: string;
    description: string;
    code: string;
    area: string;
    sector: string;
    sectorHelper: string;
    areaHelper: string;
    permissionCannotBeRemoved: string;
  };
  routes: {
    plural: string;
    singular: string;
    operator: string;
    periodicity: string;
    route: string;
    scheduledDate: string;
    newScheduledDate: string;
    special: string;
    routeName: string;
    startDate: string;
    state: string;
    manageEquipmentRoutes: string;
    getRoutesError: string;
    assignRouteToEquipment: string;
    todayRoutes: string;
    weekRoutes: string;
    weekRoutesDescription: string;
    delayedAndPendingRoutes: string;
    dailyRoute: string;
    canNotBeBeforeToday: string;
    futureDatesAreNotAllowed: string;
    invalid: string;
    veryCloseToRoute: string;
    requireDate: string;
    date: string;
    rescheduleRoute: string;
    schedule: string;
    routeWillEnd: string;
    routeCannotBeRemoved: string;
    survey: {
      outOfRoute: string;
      inRoute: string;
    };
    delayed: {
      plural: string;
      singular: string;
    };
    pending: {
      plural: string;
      singular: string;
    };
    delayedRoutesDescription: string;
    pendingRoutesDescription: string;
    dateAccordingToSchedule: string;
    lastRegistrationDate: string;
    daysSinceLastLubrication: string;
    labelsToReplace: string;
    delayedRoute: string;
    scheduledRoute: string;
    routeThatTheLubricatorDidNotPerform: string;
    scheduledRouteThatTheLubricatorDidNotPerform: string;
    anticipateRoute: string;
    validations: {
      allRouteExistance: string;
      lubricatorRouteExistance: string;
      ableToCreateRoute: string;
    };
    states: {
      ATRASADA: string;
      INCUMPLIDA: string;
      INICIADA: string;
      "PROGRAMADA INCUMPLIDA": string;
      PENDIENTE: string;
      "AL DIA": string;
    };
  };
  surveys: {
    taskTypes: {
      LUBRICACION: string;
      INSPECCION: string;
      LIMPIEZA: string;
      REPARACION: string;
      CONTROL: string;
      PUNTUACION: string;
      INTERVENCION: string;
    };
    tasks: {
      lubrication: {
        title: string;
        inaccessible: string;
        accessible: string;
        bomb: string;
      };
      inspection: {
        title: string;
        componentChange: string;
        needLubricant: string;
        isLossIdentified: string;
        replaceLabel: string;
        temperatureInC: string;
        whyWasTheEquipmentInaccessible: string;
        newAnomaly: string;
        noAnomaly: string;
        noAnomalies: string;
      };
      cleaning: {
        title: string;
        noCleaning: string;
        cancelCleaning: string;
      };
      repairment: {
        title: string;
        noRepairment: string;
        cancelRepairment: string;
      };
      score: string;
      intervention: string;
      control: string;
      inspectAndLubricate: string;
      inspectAndClean: string;
      inspectAndRepair: string;
      soonYouWillBeAbleToPerformOtherPersonalizedTasks: string;
      workOnTheEquipment: string;
      assetScore: string;
      reset: string;
      inaccessibleEquipment: string;
      noObservations: string;
    };
    options: {
      impossibleToLubricate: string;
      normal: string;
      all: string;
      lubricates: string;
      withoutLubricate: string;
      yes: string;
      no: string;
      outOfRoute: string;
      inRoute: string;
      inaccessible: string;
      accessible: string;
      manualCharge: string;
      Colector: string;
      done: string;
      notDone: string;
      neededLubrication: string;
      lubricationNotNeeded: string;
    };
    title: {
      plural: string;
      singular: string;
    };
    componentChange: string;
    observationsQuantity: string;
    equipment: string;
    element: string;
    component: string;
    sector: string;
    review: string;
    impossibleToLubricate: string;
    doesNotLubricate: string;
    whyWasntItPossible: string;
    whyDontYouLubricate: string;
    lubricates: string;
    routeDate: string;
    loadingDate: string;
    startTime: string;
    endTime: string;
    sampleExtraction: string;
    consumables: string;
    lubricantType: string;
    lubricant: string;
    quantity: string;
    measureUnit: string;
    reason: string;
    anomalies: string;
    outOfRoute: string;
    inRoute: string;
    inaccessible: string;
    cleaning: string;
    otNumber: string;
    news: string;
    criticality: string;
    user: string;
    route: string;
    manualRegistration: string;
    areas: string;
    taskType: string;
    taskTime: string;
    inMinutes: string;
    consumablesUsed: string;
    taskDone: string;
    assetCriticality: string;
    needsLubrication: string;
    temperature: string;
    loss: string;
    losses: string;
    pointAlreadySurveyedToday: string;
    surveyOffRouteAgain: string;
  };
  iotDevices: {
    actions: {
      desactivate: string;
      activate: string;
    };
    helpers: {
      youAreDesactivating: string;
      youAreActivating: string;
      youCanNotWorkWithDifrentKindOfItemStates: string;
      selectedItems: string;
      cleanState: string;
      dontForgetToSpecifyLowReason: string;
    };
    title: {
      singular: string;
      plural: string;
    };
    form: {
      label: {
        device: string;
        deviceCode: string;
        admissionDate: string;
        egressDate: string;
        state: string;
        location: string;
        lowReason: string;
        batteryCapacity: string;
        lowBatteryAlarm: string;
      };
    };
  };
  iotSensorTypes: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      label: {
        sensorType: string;
        sensorTypeCode: string;
      };
    };
  };
  STISensors: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      label: {
        sensorType: string;
        sensorTypeCode: string;
        sensorAddress: string;
        channelQuantity: string;
        permanent: string;
        batteryCapacity: string;
      };
    };
    booleanSelector: {
      data: {
        fixed: string;
        mobile: string;
      };
    };
  };
  STIEquipments: {
    title: {
      singular: string;
      plural: string;
    };
    manageEquipments: string;
    form: {
      messages: {
        mustLoadAtLeastOneEquipment: string;
        sureToResetTemplateData: string;
        createAndConfigureNewEquipment: string;
        somePointsCouldNotBeCreated: string;
        equipmentLoadedSuccessfully: string;
        resetData: string;
        initialEquipmentConfiguration: string;
        successfullyCreatedEquipment: string;
      };
      label: {
        equipment: string;
        equipments: string;
        labelId: string;
        sector: string;
        plantTag: string;
        tagSTI: string;
        type: string;
        brand: string;
        model: string;
        function: string;
        location: string;
        observations: string;
        admissionDate: string;
        egressDate: string;
        state: string;
        lowReason: string;
        criticality: string;
      };
    };
  };
  STISensorizedEquipment: {
    title: {
      singular: string;
      plural: string;
    };
    form: {
      label: {
        sensorTypeCode: string;
        sensorType: string;
        sensorAddress: string;
        iotDeviceCode: string;
        plantTag: string;
        equipment: string;
        tagSTI: string;
        element: string;
        sector: string;
        location: string;
        criticality: string;
        observations: string;
        state: string;
        lowReason: string;
        admissionDate: string;
        egressDate: string;
        temperature: string;
        vibration: string;
        xAxis: string;
        yAxis: string;
        zAxis: string;
        channels: string;
        pressure: string;
      };
    };
  };
  reports: {
    report: string;
    reports: string;
    equipmentInfo: string;
    observations: string;
    surveys: string;
    actions: string;
    lubricationPoints: string;
    equipmentOnDate: string;
    routes: string;
  };
  reportsGeneration: {
    itemsQuantity: string;
    reportsGeneration: string;
    generateReport: string;
    generateLossReport: string;
    generateObservationReport: string;
    losses: string;
    observations: string;
    generatingReport: string;
    reportGenerated: string;
    observationDocumentTitle: string;
    lossDocumentTitle: string;
    printReport: string;
    tagPlanta: string;
    lubricationArea: string;
    lubricationDepartmentReport: string;
    lossReport: string;
    observationReport: string;
    dateOfIssue: string;
    lessThan: string;
    greaterThan: string;
    totalLeak: string;
  };
  dates: {
    today: string;
    day: {
      singular: string;
      plural: string;
    };
    week: {
      singular: string;
      plural: string;
    };
    month: {
      singular: string;
      plural: string;
    };
    year: {
      singular: string;
      plural: string;
    };
    to: string;
    notRegistered: string;
  };
  table: {
    noResults: string;
  };

  // Instruments:
  instruments: {
    title: {
      singular: string;
      plural: string;
    };
    configuration: string;
    qrCode: string;
    tag: string;
    description: string;
    name: string;
    sciNumber: string;
    type: string;
    brand: string;
    brands: {
      title: {
        singular: string;
        plural: string;
      };
    };
    model: string;
    models: {
      title: {
        singular: string;
        plural: string;
      };
    };
    range: string;
    rangeFrom: string;
    rangeTo: string;
    plc: string;
    plcs: {
      title: {
        singular: string;
        plural: string;
      };
      plcName: string;
    };
    assignment: string;
    calibrationDate: string;
    certificate: string;
    patronCertificate: string;
    patronDate: string;
    measureUnit: string;
  };
}

export const useLang = (lang: LangType): language => {
  const es: language = {
    sidebarMenu: {
      home: "Home",
      explore: "Explorar",
      reports: "Reportes",
      calendar: "Calendario",
      knowledgeBase: "Base de conocimiento",
      news: "Novedades",
      notifications: "Notificaciones",
      lubricationSection: "Sala de Lubricación",
      plantConfig: "Config. Planta",
      client: "Cliente",
    },
    sidebar: {
      config: {
        accountSettings: "Configuraciones de la cuenta",
        atLeastSix: "Debe tener al menos 6 caracteres",
        doesNotMatch: "No coincide",
        newPassword: "Nueva contraseña",
        repeatPassword: "Repetir contraseña",
        userUpdatedSuccessFully: "Usuario actualizado exitosamente",
        changePassword: "Cambiar contraseña",
        showPassword: "Mostrar contraseña",
      },
      createTicket: {
        support: "Soporte - Ayúdanos a mejorar!",
        criticality: "Criticidad",
        priority: "Prioridad",
        low: "Baja",
        medium: "Media",
        high: "Alta",
        urgent: "Urgente",
        unselected: "Sin seleccionar",
        whatWasTheProblem: "¿Cuál fue el problema?",
        couldNotBeNotified: "No se pudo informar del problema",
        thanksForNotifying: "Muchas gracias por notificar el problema",
        dislikeOfTheProblem: "Disgusto del problema",
        doYouLikeTGDSoft: "¿Te gusta TGD Soft?",
      },
    },
    plantConfig: {
      users: "Usuarios",
      configurations: "Configuraciones",
    },
    explore: {
      previous: "Anterior",
      next: "Siguiente",
      cleanSearch: "Limpiar busqueda",
      thereAreNoSurveys: "No hay relevamientos para este activo, prueba con otro periodo",
      lubricated: "Lubricado",
      failedToLubricate: "Faltó lubricar",
      checked: "Controlado",
      uncontrolled: "No controlado",
      systemInconsistency:
        "Inconsistencia en el sistema, se agregó lubricante cuando no era necesario",
    },
    client: {
      viewAsClient: "Visualizar como cliente",
      noFeaturedGraphics:
        "No tiene gráficos destacados, para ver gráficos destacados debes verificar con un administrador que tu servicio de trazabilidad de lubricantes esté activo",
      selectAFactory: "Selecciona una fábrica",
      newNotification: "Nueva notificación",
      publicationDate: "Fecha de publicación",
      message: "Mensaje",
      readAll: "Leer todo",
      notifications: "Notificaciones",
      emptyNotificationTray: "¡Su bandeja de notificaciones está vacía!",
      unread: "Sin leer",
      read: "Leido",
      connectingServices: "Conectando servicios...",
    },
    selectedLanguage: "es",
    stringDefaultValues: {
      withOutSupplies: "SIN INSUMOS",
      notSpecified: "SIN ESPECIFICAR",
      withOutRoutes: "SIN RUTAS",
      withoutObservations: "SIN OBSERVACION",
    },
    select: {
      category: "Seleccionar categoría",
      subcategory: "Seleccionar subcategoría",
      items: "Seleccionar items",
      lubricationPoint: "Seleccionar punto de lubricación",
    },
    words: {
      info: "Info",
      and: "y",
      of: "de",
      thisMasc: "Este",
      thisfem: "Esta",
      available: "disponible",
      availables: "disponibles",
      more: "Más",
      all: "Todos",
      yes: "Si",
      no: "No",
      active: "Activo",
      inactive: "Inactivo",
      request: "Solicitud",
      close: "Cerrar",
      without: "Sin",
      from: "De",
      to: "A",
    },
    globalentityValues: {
      id: "Id",
      tagFP: "Planta",
      tagSTI: "Tag STI",
      tagTGD: "Tag TGD",
      date: "Fecha",
      identifier: "Identificador",
    },
    enumTgdTypes: {
      UNIDAD: "Unidad",
      EQUIPO: "Equipo",
      LUBRICANTE: "Lubricante",
      OBSERVACION: "Observación",
      INSTRUMENTO: "Instrumento",
    },
    messages: {
      unspecified: "Sin especificar",
      copiedToClipboard: "¡Copiado al portapapeles!",
      nothingSelected: "No has seleccionado ningun item",
      abilityNotAllowed: "No tienes los permisos necesarios para usar esta funcionalidad.",
      featureNotAvailableOffline:
        "Esta funcion solo se encuentra habilitada en la aplicación de la nube",
      featureNotAvailableOnline:
        "Esta funcion solo se encuentra habilitada en la aplicación local de cada planta",
      lastTenSurveys: "Últimos 10 relevamientos",
      lastTenEquipmentSurveys: "Últimos 10 relevamientos del equipo",
      ofThisLubricationPoint: "De este punto de lubricación",
      lastTenObservations: "Últimas 10 observaciones",
      lastObservations: "Últimas observaciones",
      latestIncome: "Últimos Ingresos",
      surveyObservations: "Observaciones del relevamiento",
      lubricationPointObsevetions: "Observaciones del punto",
      notRegistered: "No registrado",
      cannotBeRemoved: "No se puede eliminar",
      becauseItsUsedOn: "Porque está siendo usado/a en",
      features: {
        collectorConfig: "Pronto podrá sincronizar y configurar el nuevo colector de TGD",
        supervisorSurvey: "Pronto podrá relevar los datos de un lubricador",
        supervisorClientVisor: "Pronto podrá ver el panel de un cliente",
      },
      status: {
        success: "Todo ha ido bien",
        error: "Hubo un error",
        loading: "Cargando",
      },
      anErrorHasOccurred: "Se ha producido un error",
    },
    backResponseMessages: {
      ALL_FIELDS_REQUIRED: "Todos los campos son obligatorios",
      ASSIGNED_SUCCESSFULLY: "Asignado correctamente",
      COULD_NOT_ASSIGN: "No se pudo asignar",
      COULD_NOT_EDIT: "No se pudo editar",
      DELETION_PROBLEM_OCCURRED: "Hubo un problema al eliminar",
      NOT_EXISTS: "No existe",
      REGISTRATION_PROBLEM_OCCURRED: "Hubo un problema al crear",
      SYNCHRONIZED_SUCCESSFULLY: "Sincronizado correctamente",
      ALREADY_EXISTS: "Ya existe",
      OBSOLETE_DATA_UPDATING: "Datos obsoletos. Actualizando...",
      NO_FIELD_MODIFIED: "Ningún campo modificado",
      COULD_NOT_UPDATE: "No se pudo actualizar",
      REGISTRATION_FAILED: "No se pudo crear",
      COULD_NOT_DELETE: "No se pudo eliminar",
      SUCCESSFUL_UPDATE: "Se actualizó correctamente",
      SUCCESSFUL_CREATION: "Se creó correctamente",
      SUCCESSFUL_DELETE: "Se eliminó correctamente",
      COLLECTORS_SUCCESSFUL_SYNCHRONIZATION: "Los colectores ya pueden ser sincronizados",
      GENERATING_DATA_SYNCHRONIZATION: "Generando datos para la sincronización",
      SYNCHRONIZATION_FAILED: "Hubo un error al sincronizar",
    },
    collectorSync: {
      title: "Panel de sincronización de dispositivos",
      itWasFind: "Se encontraron",
      databasesWithProblems: "bases con los siguientes problemas:",
      basesFoundWithProblems: "bases encontradas con los siguientes problemas:",
      errors: {
        /* [SyncFileErrors.Corrupted]:'Defectuosa', */
        [SyncFileErrors.failed]: "Fallida",
        [SyncFileErrors.expired]: "Expirada",
      },
      helpers: {
        /* [SyncFileErrors.Corrupted]:'La base de datos se encuentra defectuosa.', */
        [SyncFileErrors.failed]: "Contacte a soporte, la sincronización de esta base falló",
        [SyncFileErrors.expired]:
          "La base de datos tiene mas de 7 días. Por seguridad de los datos contacte a soporte para solucionar este problema",
        unknown: "Se desconoce la causa del error.",
      },
      synchronizeSyncBox: "Sincronizar SyncBox",
      updatedSyncBoxData: "Los datos del SyncBox han sido actualizados",
      synchronizeSyncBoxDescription:
        "Al sincronizar con SyncBox, podemos obtener los datos de aquellos colectores que han sido sincronizados.",
      syncBoxState: "Estado de SyncBox",
      syncBoxStateDescription:
        "No hay nuevas sincronizaciones pendientes (ningún colector se ha sincronizado hoy)",
      unsynchronizedCollectors: "Colectores no sincronizados",
      pendingSyncs: "Sincronizaciones pendientes",
      pendingSyncsDescription: "Hay sincronizaciones pendientes de los siguientes colectores",
      toKeepSoftUpdated:
        "Para mantener el software actualizado, se debe sincronizar la PC con SyncBox",
      synchronizeCollectors: "Sincronizar colectores",
      synchronizeCollectorsDescription:
        "Los datos ingresarán a SyncBox una vez que se inicie la sincronización desde la aplicación del colector. Para acceder a ella se debe ingresar al menú de la aplicación y dirigirse a la pestaña de sincronización",
      updateSyncBoxData: "Actualizar datos de SyncBox",
      updateSyncBoxDataDescription:
        "El supervisor podrá trabajar en el software y, si es necesario, actualizar los datos del colector el mismo día tantas veces como sea necesario. Este botón procederá a actualizar los datos que utilizará el colector. (No olvide volver a sincronizar los colectores para seguir trabajando en la planta).",
      howToSynchronizeTheSyncBox: "¿Cómo se sincroniza SyncBox?",
      howToSynchronizeTheSyncBoxDescription:
        "Cada día con el primer inicio de sesión, TGDSoft sincronizará automáticamente los datos con SyncBox para posteriormente poder sincronizar todos los colectores y comenzar a trabajar.",
      lastSynchronization: "Última sincronización",
      someCollectorsHaveBeenSynchronized: "Algunos colectores se han sincronizado",
      missingCollectors: "Colectores faltantes",
      collectorsWereSynchronizedAtLeastOnce:
        "Hoy, todos los colectores se sincronizaron al menos una vez",
    },
    grid: {
      actionsAlert: "Seleccione uno o mas item para trabajar con los datos.",
      noRowsLabel: "No hay datos.",
      errorOverlayDefaultLabel: "Ha ocurrido un error",
      toolbarColumns: "Columnas",
      toolbarColumnsLabel: "Seleccionar columnas",
      toolbarFilters: "Filtros",
      toolbarFiltersLabel: "Mostrar filtros",
      toolbarFiltersTooltipHide: "Esconder filtros",
      toolbarFiltersTooltipShow: "Mostrar filtros",
      columnsPanelTextFieldLabel: "Buscar columna",
      columnsPanelTextFieldPlaceholder: "Titulo de columna",
      columnsPanelDragIconLabel: "Reordenar columna",
      columnsPanelShowAllButton: "Mostrar todo",
      columnsPanelHideAllButton: "Esconder todo",
      toolbarExport: "Exportar",
      toolbarExportLabel: "Exportar",
      toolbarExportCSV: "Descargar as CSV",
    },
    filters: {
      search: "Buscar",
      filterColumns: "Filtrar columnas",
      disableEverything: "Desactivar todo",
      title: "Buscar y filtrar",
      addFilter: "Agregar filtro",
      deleteFilters: "Eliminar filtros",
      add: "Agregar",
      searchByTerm: "Buscar por término",
      closeFilters: "Cerrar filtros",
      downloadData: "Descargar datos como .xlsx ",
      filterData: "Filtrar datos",
      all: "Todos",
      select: "Seleccionar",
      resetDateRange: "Limpiar rango de fecha",
      queryDateRange: "Consultar Rango de fecha",
      from: "Desde",
      to: "Hasta",
      and: "Y",
      andHelper:
        'Nuevo filtro "Y" (filtrará de manera estricta entre los registros ya seleccionados)',
      or: "O",
      orHelper:
        'Nuevo filtro "O" (filtrará y/o traerá todos los registros que cumplan con alguna de las características seleccionadas, al usar varios filtros "O", estaremos acumulando registros que cumplan con reglas)',
    },
    sendEmail: {
      title: "Enviar por email",
      att: "atte.",
      charge: "Area de lubricación de TGD Company",
      recipient: "Este email está siendo enviado a",
      emailBody: "Aquí tu mensaje...",
    },
    crudActions: {
      update: "Editar",
      create: "Crear",
      delete: "Eliminar",
      archive: "Archivar",
      desactivate: "Desactivar",
      activate: "Activar",
      save: "Guardar",
    },
    inputFields: {
      autocomplete: {
        email: "Email inválido",
        clearText: "Limpiar",
        closeText: "Cerrar",
        openText: "Abrir",
        loadingText: "Cargando..",
      },
    },
    validations: {
      messages: {
        macAddress: "Mac address inválida",
        justNumbers: "Solo números",
        invalidNumber: "El número no es valido",
        justText: "Solo texto",
        required: "Requerido",
        minLength: "Demasiado corto",
        maxLength: "Demasiado largo",
        allReadyExist: "Ya existe",
        pattern: "invalido",
        completeFields: "Complete los campos",
      },
    },
    ui: {
      components: {
        viewMode: {
          title: "Cambiar vista",
        },
        name: "Nombre",
      },
    },
    pages: {
      realTime: {
        sensors: "Sensores",
        equipments: "Equipos",
      },
    },
    login: {
      form: {
        label: {
          user: "Email",
          password: "Contraseña",
        },
      },
      navigation: {
        name: "Solicitar demo",
      },
      legend: {
        years: "Años",
      },
      yearText: "AÑOS 1990",
    },
    demo: {
      form: {
        completeName: "Nombre completo",
        phone: "Teléfono",
        company: "Compañía",
        email: "Email",
      },
      prospects: "Prospectos",
    },
    persons: {
      newSupervisor: "Nuevo supervisor",
      newOperator: "Nuevo operador",
      newAdministrator: "Nuevo administrador",
      newAccountManager: "Nuevo gerente regional",
      newClient: "Nuevo cliente",
      userAdministration: "Administrar usuarios",

      createstep1: "Crea un gerente regional",
      createstep2: "Asigna fábricas a su cargo",
      createFinishStep: "Todos los pasos completados",

      accountManager: "Gerente regional",
      accountManagers: "Gerentes regionales",

      referer: "Referente",
      phone: "Teléfono",
      password: "Contraseña",

      plural: "Personas",
      singular: "Persona",
      name: "Nombre",
      lastName: "Apellido",
      adress: "Dirección",
      email: "Email",
      client: "Cliente",
      clients: "Clientes",
      operator: "Lubricador",
      operators: "Lubricadores",
      supervisor: "Supervisor",
      supervisors: "Supervisores",
      superAdmin: "Super administrador",
      superAdmins: "Super administradores",
      admin: "Administrador",
      admins: "Administradoress",
    },
    language: {
      label: "Idioma",
      spanish: "Español",
      english: "Ingles",
    },
    formMessages: {
      required: "Requerido",
      float: "Solo Numeros",
      invalidEmail: "Email no válido",
    },
    stock: {
      product: "Producto",
      brand: "Marca",
      actualStock: "Stock actual",
      minimumRequired: "Mínimo",
      category: "Categoría",
      subcategory: "Subcategoría",
      measureUnit: "Unidad de medida",
      categoryArea: "Area de la categoría",
      providerArea: "Area del proveedor",
      complex: "Reposición compleja",
      hidden: "Archivados",
      options: {
        simple: "Simple",
        complex: "Compleja",
        archived: "Archivados",
        unArchived: "Sin archivar",
      },
    },
    lubricationSection: {
      stock: "Stock",
      purchaseRequests: "Solicitudes de compra",
      newPurchaseRequest: "Nueva solicitud de compra",
      configureCategory: "Configurar categoría",
      categories: "Categorías",
      addNewCategory: "Agregar una nueva categoría",
      subcategories: "Subcategorías",
      addNewSubcategory: "Agregar una nueva subcategoría",
      subcategory: "Subcategoría",
      allStock: "Todo el stock",
      allStockItems: "Todos los elementos de stock",
      allIncome: "Todos los ingresos",
      incomeManagement: "Gestion de ingresos",
      manageStock: "Gestionar stock",
      lubricants: "Lubricantes",
      title: "Sala de Lubricación",
      lossAmount: "Cantidad perdida",
      categoryName: "Nombre de la categoría",
      newStockProduct: "Nuevo producto de stock",
      seeItems: "Ver elementos",
      seeArchived: "Ver archivados",
      archive: "Archivar",
      archived: "Archivado",
    },
    actionTypes: {
      activate: "Activar",
      desactivate: "Desactivar",
      view: "Ver",
      apply: "Solicitar",
      login: "Ingresar",
      logout: "Salir",
      create: "Crear",
      deleteItem: "Eliminar",
      update: "Editar",
      assign: "Asignar",
      save: "Guardar",
      exit: "Salir",
      before: "Atrás",
      next: "Próximo",
      cancel: "Cancelar",
      configure: "Configurar",
      add: "Agregar",
      new: "Nuevo",
    },
    lubricationPoints: {
      title: {
        singular: "Punto de lubricación",
        plural: "Puntos de lubricación",
      },
      delayedPoints: "Puntos atrasados",
      todayPoints: "Puntos de hoy",
      equipments: "Equipos",
      equipmentName: "Nombre del equipo",
      equipmentTag: "Tag del equipo",
      equipmentCriticality: "Criticidad del equipo",
      equipmentIdentification: "Identificación del equipo",
      sector: "Sector",
      plantTag: "Tag planta",
      lowReason: "Razón de baja",
      labelId: "Id de etiqueta",
      label: "Etiqueta",
      state: "Estado del punto",
      criticality: "Criticidad",
      egressDate: "Fecha de egreso",
      function: "Funcion",
      equipment: "Equipo",
      review: "Revision",
      location: "Ubicación",
      lubricantType: "Tipo de lubricante",
      routes: "Rutas",
      supplies: "Insumos",
      type: "Tipo",
      brand: "Marca",
      componentBrand: "Marca del componente",
      componentModel: "Modelo del componente",
      componentFunction: "Funcion del componente",
      componentLocation: "Ubicación del componente",
      model: "Modelo",
      element: "Elemento",
      component: "Componente",
      lubricant: "Lubricante",
      capacity: "Capacidad",
      lubricantCapacity: "Capacidad de lubricante",
      measureUnit: "Unidad de medida",
      oilAnalysis: "Analisis de aceite",
      admissionDate: "Fecha de admisión",
      observations: "Observaciones",
      tagTGDBefore: "Tag TGD anterior",
      equipmentState: "Estado del equipo",
      allActivePoints: "Todos los puntos activos",
      youAreActivatingAllPoints:
        "Estás activando todos los puntos de lubricación que este equipo puede tener desactivados, ¿estás seguro?",
      youAreDecommissioningEquipment:
        "Estás dando de baja un equipo, por lo tanto todos sus elementos y puntos de lubricación también serán dados de baja.",
      youAreDecommissioningComponent: "Estás desmantelando este componente, ¿estás seguro?",
      youAreDecommissioningElement:
        "Estás dando de baja un elemento, por lo tanto todos sus puntos de lubricación también serán dados de baja",
      thereWasAnErrorActivating: "Hubo un error al activar",
      equipmentHasBeenDecommissioned: "El equipo ha sido dado de baja",
      dismantle: "Dar de baja",
      activateThis: "Activar este",
      again: "Nuevamente",
      activateAgain: "Activar nuevamente este",
      options: {
        active: "Activo",
        disabled: "Inactivo",
        all: "Todos",
        oilAnalisys: "Si",
        withoutOilAnalisys: "No",
      },
      helpers: {
        whyAreEditingThisPoint: "¿Porqué estas editando este punto?",
        lubricationPointsThatUse: "Puntos de lubricación que utilizan",
        lubricationPointsThatUseThisPermission: "Puntos de lubricación que utilizan este permiso",
        routeLubricationPoint: "Puntos de lubricación de la ruta",
        pointsThatNeedLabelChange: "Puntos que necesitan cambio de etiqueta",
        needLabelChange: "Necesita cambio de etiqueta",
        labelInCorrectCondition: "Etiqueta en correcto estado",
      },
      suggestedQuantity: "Cantidad sugerida",
      suggestedQuantityIndicates:
        "Este valor le indicará al lubricador cuál es la cantidad sugerida de aplicación de lubricante en este punto",
      lastLubricantAdmission: "Último ingreso de Lubricante",
    },
    revisionChange: {
      title: {
        singular: "Cambio de revisión",
        plural: "Cambios de revisión",
      },
      revisionChangeMadeFromPlantConfiguration:
        "Cambio de revisión hecho desde configuración de la planta",
      unsupervised: "No supervisado",
      pendingRevisionChanges: "Cambios de revisión pendientes",
      supervisedRevisionChanges: "Cambios de revisión supervisados",
      revisionChangesHistory: "Historial de cambios de revisión",
      pending: "Pendientes",
      supervised: "Supervisados",
      requestDate: "Fecha de solicitud",
      requestReason: "Motivo de solicitud",
      requestUser: "Usuario que solicita",
      supervisionDate: "Fecha de supervisión",
      supervisionReason: "Motivo de supervisión",
      supervisionUser: "Usuario que supervisa",
      impactDate: "Fecha de imapcto",
      request: "Solicitud",
      supervision: "Supervisión",
      review: "Revisión",
      approve: "Aprobar",
      approved: "Aprobado",
      reject: "Rechazar",
      rejected: "Rechazado",
      state: "Estado",
    },
    notifications: {
      title: {
        singular: "Notificación",
        plural: "Notificaciones",
      },
    },
    infoLubricationPoints: {
      supplies: "Insumos faltantes",
      requiredSupplies: "Insumos solicitados",
      equipmentsWithRequests: "Equipos con solicitudes",
      thereAreNoSuppliesToAssign: "No hay insumos para asignar",
      labelReplacement: "Reemplazo de etiqueta",
      lastLubrication: "Ultima lubricación",
      lubricantQuantity: "Cantidad de lubricante",
      leak: "Pérdida",
      pending: "Pendiente",
      updatedToday: "Actualizado hoy",
      user: "Usuario",
      lastSurveyId: "Id último relevamiento",
      assetCriticality: "Criticidad del activo",
      inaccessible: "Inaccesible",
      lastInspectionDate: "Fecha de la última inspección",
      updateReview: "Cambio de revision",
      automaticReviewChange: "Cambio automático de revision",
      toUpdateReview: "Cambiar revision",
      changeToNextReview: "Cambiar a la siguiente revision",
      rejectRequest: "Rechazar solicitud",
      youAreChangingToTheNextComponentRevision:
        "Está cambiando a la siguiente revision de este componente, está seguro?. No se podrá volver a una revision anterior, deberá contactar al equipo de soporte para realizarlo",
      manageReviewChanges: "Gestionar cambios de revision",
      equipmentsWithReviewChangeRequest: "Equipos con solicitud de cambio de revision",
      isRecommendedToMakeChangesIf: "Se recomienda realizar cambios solo si",
      allSynchronizationsHaveBeenProcessed:
        "Todas las sincronizaciones del dia han sido procesadas con éxito",
      thereWillBeNoSynchronizationsIn: "No habrá posteriores sincronizaciones en el día",
      otherwiseWhenSynchronizing:
        "De lo contrario, al sincronizar datos de un colector que relevó equipos en ruta o fuera de ruta que están dentro de esta lista, podría causar una pérdida de trazabilidad en el cambio de revisión y generar solicitudes de cambio innecesarias. (Esto causaría varios cambios de revisión innecesarios).",
      temperature: "Temperatura",
      leakLevelRange: "Rango de pérdida",
      withoutSupplies: "SIN INSUMOS",
      assetGeneralScore: "Puntuación general del activo",
      assetPriority: "Importancia del activo",
      lastEquipmentState: "ULTIMO ESTADO DEL EQUIPO",
      withLeak: "Con pédida",
      withoutLeak: "Sin pérdida",
      lastAgregate: "ULTIMO AGREGADO",
      added: "Agregado",
      cannotBeCalculated: "No se puede calcular",
      notPending: "Al Día",
      accessedToday: "SE LO INTERVINO HOY",
      lastInspection: "ULTIMA INSPECCION",
      accesible: "Accesible",
      lastTemperature: "ULTIMA TEMPERATURA",
      missings: "FALTANTES",
      capacity: "CAPACIDAD",
      equipmentinformation: "Información del equipo",
      lastSurveys: "Últimos relevamientos",
    },
    types: {
      singular: "Tipo",
      plural: "Tipos",
      form: {
        name: "Nombre",
        type: "Tipo",
      },
      typeCannotBeRemoved: "Este tipo no se puede eliminar porque está siendo usado en",
    },
    factories: {
      manageFactories: "Manejar los accesos a fábrica",
      noFactories: "No se ha encontrado ninguna fábrica",
      accountManagerFactories: "Fábricas del manager regional",
      singular: "Fábrica",
      plural: "Fábricas",
      location: "ubicación",
      name: "Nombre fábrica",
      businessName: "Razón social",
      referrer: "Referente",
      tagF: "Tag de fábrica",
    },
    plants: {
      asignCancelPlants: "Administrar acceso a plantas",
      clickToAssign: "Click para asignar",
      clickToCancel: "Click para cancelar",
      factory: "Fábrica",
      singular: "Planta",
      plural: "Plantas",
      factoryId: "Id de fábrica",
      location: "Dirección",
      name: "Nombre",
      plantId: "Id de planta",
      referrer: "Referente",
      tagFP: "Tag de planta Tgd",
      state: "Estado",
    },
    observations: {
      observationTypes: {
        INACCESIBLE: "Inaccesible",
        "IMPOSIBLE DE LUBRICAR": "Imposible de Lubricar",
        "NO LUBRICA": "No Lubrica",
        ANOMALIA: "Anomalia",
        OBSERVACION: "Observación",
      },
      addedReasons: {
        leak: "Por perdida",
      },
      observationsQuantity: "Cantidad de observaciones",
      singular: "Observación",
      plural: "Observaciones",
      delete: "Eliminar observación",
      surveyId: "Id relevamiento",
      equipment: "Id equipo",
      modified: "Modificdo",
      modifiedDate: "Fecha modificación",
      observationDate: "Fecha de la observación",
      OTnumber: "Nº OT",
      state: "Estado",
      taskId: "Id tarea",
      taskRequestId: "Id solicitud de tarea",
      taskType: "Tipo de tarea",
      type: "Tipo",
      element: "Elemento",
      component: "Componente",
      user: "Usuario",
    },
    addedReasons: {
      plural: "Motivos agregados",
      singular: "Motivo agregado",
      reason: "Nombre motivo agregado",
    },
    anomalies: {
      singular: "Anomalía",
      plural: "Anomalias",
      anomalyName: "Nombre de anomalia",
    },
    news: {
      user: "Usuario",
      criticality: "Criticidad",
      singular: "Novedad",
      plural: "Novedades",
      sector: "Sector de la novedad",
      area: "Area",
      equipment: "Equipo",
      type: "Tipo de novedad",
      newsDate: "Fecha de novedad",
      description: "Descripción",
      createNew: "Crear novedad",
      linkToAEquipment: "Enlazar a un equipo",
      newNovelty: "Nueva novedad",
      all: "Todas",
      environment: "Entorno",
      plant: "Planta",
    },
    criticalities: {
      title: {
        singular: "Criticidad",
        plural: "Criticidades",
      },
      form: {
        descripton: "Nombre",
        number: "Nº de prioridad",
        periodicity: "Periodicidad",
        color: "Color",
        type: "Tipo de criticidad",
        days: "Prioridad en días",
      },
      criticalityCannotBeRemoved:
        "Esta criticidad no se puede eliminar porque está siendo usado en",
    },
    collectors: {
      plural: "Colectores",
      singular: "Colector",
      activate: "Activar colector",
      desactivate: "Desactivar colector",
      desactivatedColectorWarning: "Este colector está desactivado",
      qrCode: "Código QR",
      collectorCode: "Código",
      form: {
        active: "Activo",
        disabled: "Desactivado",
        id: "Id",
        name: "Nombre",
        serialNumber: "Numero de serie",
        collectorCode: "Código de colector",
        tagFP: "Planta",
        state: "Estado",
        admissionDate: "Fecha de ingreso",
        egressDate: "Fecha de Egreso",
      },
    },
    lubricantForm: {
      singular: "Lubricante",
      plural: "Lubricantes",
      type: "Tipo de lubricante",
      name: "Nombre",
      brand: "Marca",
      measureUnit: "Unidad de medida",
      requiresObservation: "Requiere observacion",
      points: "Puntos",
      approximateNeeded: "Necesarios (aproximadamente)",
      lubricantCannotBeRemoved: "Este lubricante no se puede eliminar porque está siendo usado en",
    },
    incomeForm: {
      singular: "Ingreso",
      plural: "Ingresos",
      lotNumber: "Número de lote",
      quantity: "Cantidad",
      measurementUnits: "Litros / Kilogramos",
      admissionDate: "Fecha de ingreso",
      referrer: "Referente",
      confirmMessage: "¿Está seguro de que desea eliminar este ingreso?",
    },
    containers: {
      title: {
        singular: "Recipiente",
        plural: "Recipientes",
      },
      form: {
        name: "",
        label: {
          name: "Nombre",
          lubricantType: "Tipo Lubricante",
          lubricant: "Lubricante",
          capacity: "Capacidad",
          coefficient: "Coeficiente",
          code: "Código",
          type: "Tipo",
          capacityHelper: "La capacidad está basada en la unidad de medida del lubricante",
        },
      },
    },
    sectors: {
      title: {
        singular: "Sector",
        plural: "Sectores",
      },
      form: {
        name: "",
        label: {
          sector: "Sector en planta",
          sectorName: "Sector",
          code: "Código",
        },
      },
      sectorCannotBeRemoved: "Este sector no se puede eliminar porque está siendo usado en",
    },
    areas: {
      title: {
        singular: "Área",
        plural: "Áreas",
      },
      form: {
        name: "",
        label: {
          area: "Nombre área",
          email: "Emails",
          emailHelper: "Se pueden asignar varios emails, separados por coma y sin espacios",
          referrer: "Nombre referente",
        },
      },
    },
    components: {
      activeComponents: "Componentes activos",
      inactiveComponents: "Componentes inactivos",
      componentCannotBeRemoved: "Este componente no se puede eliminar porque está siendo usado en",
      assets: {
        plural: "Activos",
      },
      inactive: {
        plural: "Inactivos",
      },
      seeComponent: "Ver componente",
      activePoint: "Punto activo",
      inactivePoint: "Punto inactivo",
      title: {
        singular: "Componente",
        plural: "Componentes",
      },
      form: {
        name: "",
        label: {
          component: "Nombre",
          code: "Código",
        },
      },
    },
    elements: {
      title: {
        singular: "Elemento",
        plural: "Elementos",
      },
      form: {
        name: "",
        label: {
          element: "Nombre",
          code: "Código",
        },
      },
      elementCannotBeRemoved: "Este elemento no se puede eliminar porque está siendo usado en",
    },
    supplies: {
      assignmentsMade: "Asignaciones realizadas",
      selectEquipmentToAssign: "Seleccionar un equipo para asignar",
      backReset: "Atrás (resetear)",
      nextStep: "Siguiente paso",
      errorGetingSupplies: "Ha ocurrido un error consultando los insumos de esta planta",
      noConsumablesHaveBeenLoaded: "No se han cargado insumos",
      missing: "Faltante",
      missingPlural: "Faltantes",
      used: "Utilizado",
      request: "Solicitar",
      requestAddedInput: "Solicitar agregado de insumo a este equipo",
      missingInputsForTheTask: "Insumos faltantes para la tarea",
      suppliesUsed: "Insumos utilizados",
      title: {
        singular: "Insumo",
        plural: "Insumos",
        form: {
          name: "",
          label: {
            type: "Tipo",
            supply: "Nombre",
            description: "Descripción",
          },
        },
      },
      HERRAMIENTA: {
        assignToEquipment: "Asignar herramientas a equipos",
        manageLubricationPointSupply: "Gestionar herramientas del punto",
        title: {
          singular: "Herramienta",
          plural: "Herramientas",
        },
        form: {
          name: "",
          label: {
            type: "Tipo",
            supply: "Nombre",
            description: "Descripción de la herramienta ",
          },
        },
      },
      CONSUMIBLE: {
        assignToEquipment: "Asignar consumibles a equipos",
        manageLubricationPointSupply: "Gestionar consumibles del punto",
        title: {
          singular: "Consumible",
          plural: "Consumibles",
        },
        form: {
          name: "",
          label: {
            type: "Tipo",
            supply: "Nombre",
            description: "Descripción del consumible ",
          },
        },
      },
      PROTECCION: {
        assignToEquipment: "Asignar protecciones a equipos",
        manageLubricationPointSupply: "Gestionar protecciones del punto",
        title: {
          singular: "Proteccion",
          plural: "Protecciones",
        },
        form: {
          name: "",
          label: {
            type: "Tipo",
            supply: "Nombre",
            description: "Descripción de la protección ",
          },
        },
      },
      OBSERVACION: {
        manageLubricationPointSupply: "Not Setted",
        title: {
          singular: "Observacion",
          plural: "Observaciones",
        },
        form: {
          name: "",
          label: {
            type: "Tipo",
            supply: "Nombre",
            description: "Descripción de la observaciones",
          },
        },
      },
      LUBRICANTE: {
        title: {
          singular: "Lubricante",
          plural: "Lubricantes",
        },
      },
      PERMISO: {
        title: {
          singular: "Permiso",
          plural: "Permisos",
        },
      },
    },
    permissions: {
      plural: "Permisos",
      singular: "Permiso",
      permissionName: "Nombre del permiso",
      assignToEquipment: "Asignar permisos al equipo",
      manageEquipmentPermissions: "Gestionar permisos del equipo",
      description: "Descripcion",
      code: "codigo",
      area: "Area",
      sector: "Sector",
      sectorHelper:
        "Convierte este permiso en un permiso global para el sector seleccionado (todos los equipos del sector requeriran este permiso),de lo contrario al dejar este campo vacio podras usar este permiso de manera aislada",
      areaHelper:
        "Ésta es el área a la cual se le debera pedir la solicitud o la emision del permiso solicitado",
      permissionCannotBeRemoved: "Este permiso no se puede eliminar porque está siendo usado en",
    },
    routes: {
      scheduledDate: "Fecha programada",
      newScheduledDate: "Nueva fecha programada",
      special: "Ruta especial",
      singular: "Ruta",
      plural: "Rutas",
      operator: "Operador",
      periodicity: "Periodicidad en días",
      route: "Ruta",
      routeName: "Nombre de ruta",
      startDate: "Fecha de inicio",
      state: "Estado",
      manageEquipmentRoutes: "Gestionar rutas del equipo",
      getRoutesError: "Ha ocurrido un error al consultar las rutas de esta planta",
      assignRouteToEquipment: "Asignar rutas al equipo",
      todayRoutes: "Rutas de hoy",
      weekRoutes: "Rutas de la semana",
      dateAccordingToSchedule: "Fecha según cronograma",
      daysSinceLastLubrication: "Días desde la última lubricación",
      routeCannotBeRemoved: "Esta ruta no se puede eliminar porque está siendo usada en",
      survey: {
        outOfRoute: "Relevar fuera de ruta",
        inRoute: "Relevar en ruta",
      },
      delayed: {
        singular: "Atrasada",
        plural: "Atrasadas",
      },
      delayedAndPendingRoutes: "Rutas atrasadas y pendientes",
      delayedRoutesDescription:
        "Rutas que no pudieron realizarse, y no fueron asignadas como pendientes del dia. Causas comunes: Parada de planta, feriados, etc.",
      labelsToReplace: "Etiquetas a reemplazar",
      lastRegistrationDate: "Fecha del último registro",
      pending: {
        singular: "Pendiente",
        plural: "Pendientes",
      },
      pendingRoutesDescription:
        "Rutas asignadas como pendientes a un operador, pero no comenzadas en el día programado.",
      weekRoutesDescription: "Rutas que tocan esta semana",
      delayedRoute: "Ruta atrasada",
      scheduledRoute: "Ruta programada",
      routeThatTheLubricatorDidNotPerform: "Ruta que el lubricador no realizó",
      scheduledRouteThatTheLubricatorDidNotPerform: "Ruta programada que el lubricador no realizó",
      anticipateRoute: "Adelantar ruta",
      dailyRoute: "Ruta diaria",
      canNotBeBeforeToday: "No puede ser anterior a hoy",
      futureDatesAreNotAllowed: "No se permiten fechas futuras",
      invalid: "Inválida",
      veryCloseToRoute: "Muy cerca de la ruta - La ruta será adelantada",
      requireDate: "Requiere una fecha",
      date: "Fecha",
      rescheduleRoute: "Reprogramar ruta",
      schedule: "Programar",
      routeWillEnd: "Esta ruta caerá",
      validations: {
        allRouteExistance: "Esta ruta ya existe para todos los operadores de esta planta",
        lubricatorRouteExistance: "Este nombre de ruta ya existe para el lubricador",
        ableToCreateRoute: "Solo podra crearla para lubricadores faltantes",
      },
      states: {
        ATRASADA: "Atrasada",
        INCUMPLIDA: "Incumplida",
        INICIADA: "Iniciada",
        "PROGRAMADA INCUMPLIDA": "Programada incumplida",
        PENDIENTE: "Pendiente",
        "AL DIA": "Al día",
      },
    },
    surveys: {
      taskTypes: {
        LUBRICACION: "Lubricación",
        INSPECCION: "Inspección",
        LIMPIEZA: "Limpieza",
        REPARACION: "Reparación",
        CONTROL: "Control",
        PUNTUACION: "Puntuación",
        INTERVENCION: "Intervención",
      },
      tasks: {
        lubrication: {
          title: "Lubricación",
          inaccessible: "Inaccesible",
          accessible: "Accesible",
          bomb: "Bomb.",
        },
        inspection: {
          title: "Inspección",
          componentChange: "Cambio de componente",
          needLubricant: "¿Necesita lubricante?",
          isLossIdentified: "¿Se identifica perdida?",
          replaceLabel: "Reemplazar etiqueta",
          temperatureInC: "Temperatura en Cº",
          whyWasTheEquipmentInaccessible: "¿Por qué el equipo estaba inaccesible?",
          newAnomaly: "Nueva anomalía",
          noAnomaly: "Sin anomalía",
          noAnomalies: "Sin anomalías",
        },
        cleaning: {
          title: "Limpieza",
          noCleaning: "Sin limpieza",
          cancelCleaning: "Cancelar limpieza",
        },
        repairment: {
          title: "Reparación",
          noRepairment: "Sin reparación",
          cancelRepairment: "Cancelar reparación",
        },
        score: "Puntuación",
        intervention: "Intervención",
        control: "Control",
        inspectAndLubricate: "Inspeccionar y lubricar",
        inspectAndClean: "Inspeccionar y limpiar",
        inspectAndRepair: "Inspeccionar y reparar",
        soonYouWillBeAbleToPerformOtherPersonalizedTasks:
          "Próximamente podrás contar con otras tareas personalizadas para Puntos de Lubricación",
        workOnTheEquipment: "Trabajo sobre el equipo",
        assetScore: "Puntuación del activo",
        inaccessibleEquipment: "Equipo inaccesible",
        reset: "Reiniciar",
        noObservations: "Sin observaciones",
      },
      options: {
        impossibleToLubricate: "IMPOSIBLE DE LUBRICAR",
        normal: "NORMAL",
        all: "TODOS",
        lubricates: "SI",
        withoutLubricate: "NO",
        yes: "SI",
        no: "NO",
        outOfRoute: "FUERA DE RUTA",
        inRoute: "EN RUTA",
        inaccessible: "INACCESIBLE",
        accessible: "ACCESIBLE",
        manualCharge: "CARGA MANUAL",
        Colector: "COLECTOR",
        done: "REALIZADA",
        notDone: "SIN REALIZAR",
        neededLubrication: "SI",
        lubricationNotNeeded: "NO",
      },
      title: {
        plural: "Relevamientos",
        singular: "Relevamiento",
      },
      componentChange: "Cambio de componente",
      observationsQuantity: "Cantidad de observaciones",
      equipment: "Equipo",
      element: "Elemento",
      component: "Componente",
      sector: "Sector",
      review: "Review",
      impossibleToLubricate: "Imposible de lubricar",
      doesNotLubricate: "No lubrica",
      whyWasntItPossible: "¿Por qué no fue posible lubricar?",
      whyDontYouLubricate: "¿Por qué no se lubricó?",
      lubricates: "Se lubricó",
      routeDate: "Fecha de Ruta",
      loadingDate: "Fecha de carga",
      startTime: "Inicio",
      endTime: "Fin",
      sampleExtraction: "Extracción de muestra",
      consumables: "Consumibles",
      lubricantType: "Tipo de lubricante",
      lubricant: "Lubricante",
      quantity: "Cantidad de lubricante",
      measureUnit: "Unidad de medida",
      reason: "Motivo",
      anomalies: "Anomalías",
      outOfRoute: "Fuera de ruta",
      inRoute: "En ruta",
      inaccessible: "Inaccesible",
      cleaning: "Limpieza del punto",
      otNumber: "Nº de OT",
      news: "Novedades",
      criticality: "Criticidad",
      user: "Usuario",
      route: "Ruta",
      manualRegistration: "Registro manual",
      areas: "Area",
      taskType: "Tipo de Tarea",
      taskTime: "Tiempo de tarea",
      inMinutes: "En minutos",
      consumablesUsed: "Consumibles utilizados",
      taskDone: "Tarea realizada",
      assetCriticality: "Criticidad del activo",
      needsLubrication: "Necesitó lubricación",
      temperature: "Temperatura",
      loss: "Perdida",
      losses: "Perdidas",
      pointAlreadySurveyedToday: "Este punto ya fue relevado hoy",
      surveyOffRouteAgain: "¿Desea relevarlo otra vez fuera de ruta?",
    },
    iotDevices: {
      actions: {
        desactivate: "Desactivar dispositivo",
        activate: "Activar dispositivo",
      },
      helpers: {
        youAreDesactivating: "Estás desactivando:",
        youAreActivating: "Estás activando:",
        youCanNotWorkWithDifrentKindOfItemStates:
          "No puedes trabajar sobre un grupo de dispositivos que tegan diferentes estados",
        selectedItems: "Items seleccionados",
        cleanState: "Seguir trabajando",
        dontForgetToSpecifyLowReason: "No olvides especificar una nueva razón de baja",
      },
      title: {
        singular: "Dispositivo",
        plural: "Dispositivos",
      },
      form: {
        label: {
          device: "Nombre dispositivo",
          deviceCode: "Mac address",
          admissionDate: "Fecha de admisión",
          egressDate: "Fecha de egreso",
          state: "Desactivado",
          location: "Ubicación",
          lowReason: "Motivo de baja",
          batteryCapacity: "Capacidad de batería",
          lowBatteryAlarm: "Alarma de batería baja",
        },
      },
    },
    iotSensorTypes: {
      title: {
        singular: "Tipo de sensor",
        plural: "Tipos de sensores",
      },
      form: {
        label: {
          sensorType: "Tipo de sensor",
          sensorTypeCode: "Código",
        },
      },
    },
    STISensors: {
      title: {
        singular: "Sensor",
        plural: "Sensores",
      },
      form: {
        label: {
          sensorType: "Tipo de Sensor",
          sensorTypeCode: "Código del tipo de sensor",
          sensorAddress: "Mac address",
          channelQuantity: "Cantidad de canales",
          permanent: "Ubicación permamnente",
          batteryCapacity: "Valor de batería máximo",
        },
      },
      booleanSelector: {
        data: {
          fixed: "PERMANENTE",
          mobile: "TEMPORAL",
        },
      },
    },
    STIEquipments: {
      title: {
        singular: "Equipo ",
        plural: "Equipos STI",
      },
      manageEquipments: "Gestionar equipos",
      form: {
        messages: {
          mustLoadAtLeastOneEquipment:
            "Debe cargar al menos un componente en el equipo para poder crearlo",
          sureToResetTemplateData:
            "¿Está seguro de que desea restablecer los datos de la plantilla?",
          createAndConfigureNewEquipment: "Crear y configurar un nuevo equipo.",
          somePointsCouldNotBeCreated:
            "Algunos puntos de lubricación no se pudieron crear satisfactoriamente, verifique los errores para terminar de cargarlos.",
          equipmentLoadedSuccessfully:
            "¡El equipo y todos sus puntos de lubricación han sido cargados exitosamente!",
          resetData: "Reestablecer datos",
          initialEquipmentConfiguration: "Configuración inicial del equipo",
          successfullyCreatedEquipment: "¡El equipo se ha creado exitosamente!",
        },
        label: {
          equipment: "Equipo",
          equipments: "Equipos",
          labelId: "Nº de etiqueta",
          sector: "Sector",
          plantTag: "Tag de planta",
          tagSTI: "Tag STI",
          type: "Tipo",
          brand: "Marca",
          model: "Modelo",
          function: "Función",
          location: "Ubicación",
          observations: "Observaciones",
          admissionDate: "Fecha de admision",
          egressDate: "Fecha de egreso",
          state: "Estado",
          lowReason: "Motivo de baja",
          criticality: "Criticidad",
        },
      },
    },
    STISensorizedEquipment: {
      title: {
        singular: "Sensorización",
        plural: "Sensorizaciones",
      },
      form: {
        label: {
          sensorTypeCode: "Código del typo de sensor",
          vibration: "Vibración",
          sensorType: "Tipo de sensor",
          sensorAddress: "Mac address del sensor",
          iotDeviceCode: "Codigo Gateway",
          plantTag: "Tag de planta",
          equipment: "Equipo",
          tagSTI: "Tag STI",
          element: "Elemento",
          sector: "Sector",
          location: "Ubicación",
          criticality: "Criticidad",
          observations: "Observaciones",
          state: "Estado",
          lowReason: "Motivo de baja",
          admissionDate: "Fecha de admisión",
          egressDate: "Fecha de egreso",
          temperature: "Temperatura",
          xAxis: "Eje X",
          yAxis: "Eje Y",
          zAxis: "Eje Z",
          channels: "Canales",
          pressure: "Presion",
        },
      },
    },
    reports: {
      report: "Informe",
      reports: "Informes",
      equipmentInfo: "Información del punto",
      observations: "Observaciones",
      surveys: "Relevamientos",
      actions: "Acciones",
      lubricationPoints: "Puntos de lubricación",
      equipmentOnDate: "Punto de lubricación al día",
      routes: "Rutas",
    },
    reportsGeneration: {
      itemsQuantity: "Cantidad de elementos",
      reportsGeneration: "Generación de informes",
      generateReport: "Generar Informe",
      generateLossReport: "Generar reporte de perdidas",
      generateObservationReport: "Generar reporte de observaciones",
      losses: "Perdidas",
      observations: "Observaciones",
      generatingReport: "Generando Informe",
      reportGenerated: "¡Informe Generado!",
      printReport: "Imprimir Informe",
      observationDocumentTitle: "InformeObservaciones",
      lossDocumentTitle: "InformePerdidas",
      tagPlanta: "Planta",
      lubricationArea: "Area de Lubricación",
      lubricationDepartmentReport: "Informe del Departamento de Lubricación",
      lossReport: "Informe de perdidas",
      observationReport: "Informe de observaciones",
      dateOfIssue: "Fecha de emisión",
      lessThan: "Menor que",
      greaterThan: "Mayor que",
      totalLeak: "Total de perdida de",
    },
    dates: {
      today: "hoy",
      day: {
        singular: "día",
        plural: "días",
      },
      week: {
        singular: "semana",
        plural: "semanas",
      },
      month: {
        singular: "mes",
        plural: "meses",
      },
      year: {
        singular: "año",
        plural: "años",
      },
      to: "a",
      notRegistered: "no registrado",
    },
    table: {
      noResults: "No hay elementos que mostrar",
    },
    instruments: {
      configuration: "Configuración",
      title: {
        singular: "Instrumento",
        plural: "Instrumentos",
      },
      qrCode: "Código QR",
      tag: "Tag",
      description: "Descripción",
      name: "Nombre",
      sciNumber: "Número SCI",
      type: "Tipo",
      brand: "Marca",
      brands: {
        title: {
          singular: "Marca",
          plural: "Marcas",
        },
      },
      model: "Modelo",
      models: {
        title: {
          singular: "Modelo",
          plural: "Modelos",
        },
      },
      range: "Rango",
      rangeFrom: "Rango desde",
      rangeTo: "Rango hasta",
      plc: "PLC",
      plcs: {
        title: {
          singular: "PLC",
          plural: "PLCs",
        },
        plcName: "Nombre del PLC",
      },
      assignment: "Asignación",
      calibrationDate: "Fecha de calibración",
      certificate: "Certificado",
      patronCertificate: "Certificado patrón",
      patronDate: "Fecha patrón",
      measureUnit: "Unidad de medida",
    },
  };

  const en: language = {
    sidebarMenu: {
      home: "Home",
      explore: "Explore",
      reports: "Reports",
      calendar: "Calendar",
      knowledgeBase: "Knowledge Base",
      news: "News",
      notifications: "Notifications",
      lubricationSection: "Lubrication Section",
      plantConfig: "Plant Configuration",
      client: "Client",
    },
    sidebar: {
      config: {
        accountSettings: "Account Settings",
        atLeastSix: "At least 6 characters",
        doesNotMatch: "Does not match",
        newPassword: "New Password",
        repeatPassword: "Repeat Password",
        userUpdatedSuccessFully: "User updated successfully",
        changePassword: "Change Password",
        showPassword: "Show Password",
      },
      createTicket: {
        support: "Support - Help us improve!",
        criticality: "Criticality",
        priority: "Priority",
        low: "Low",
        medium: "Medium",
        high: "High",
        urgent: "Urgent",
        unselected: "Unselected",
        whatWasTheProblem: "What was the problem?",
        couldNotBeNotified: "The problem could not be reported",
        thanksForNotifying: "Thank you very much for reporting the problem",
        dislikeOfTheProblem: "Dislike of the problem",
        doYouLikeTGDSoft: "Do you like TGD Soft?",
      },
    },
    plantConfig: {
      users: "Users",
      configurations: "Configurations",
    },
    client: {
      viewAsClient: "View as Client",
      noFeaturedGraphics:
        "It does not have featured graphics, to view featured graphics, you must verify with an administrator that your lubrication traceability service is active",
      selectAFactory: "Select a Factory",
      newNotification: "New Notification",
      publicationDate: "Publication Date",
      message: "Message",
      readAll: "Read All",
      notifications: "Notifications",
      emptyNotificationTray: "Your notification tray is empty!",
      unread: "Unread",
      read: "Read",
      connectingServices: "Connecting services...",
    },
    explore: {
      previous: "Previous",
      next: "Next",
      cleanSearch: "Clean search",
      thereAreNoSurveys: "There are no surveys for this asset, try another period",
      checked: "Checked",
      lubricated: "Lubricated",
      systemInconsistency:
        "Inconsistency in the system, lubricant was added when it was not necessary",
      uncontrolled: "Uncontrolled",
      failedToLubricate: "Was not lubricated",
    },
    selectedLanguage: "en",
    stringDefaultValues: {
      withOutSupplies: "WITHOUT SUPPLIES",
      notSpecified: "NOT SPECIFIED",
      withOutRoutes: "WITHOUT ROUTES",
      withoutObservations: "WITHOUT OBSERVATIONS",
    },
    select: {
      category: "Select category",
      subcategory: "Select subcategory",
      items: "Select items",
      lubricationPoint: "Select lubrication point",
    },
    words: {
      info: "Info",
      and: "and",
      of: "of",
      thisMasc: "This",
      thisfem: "This",
      available: "available",
      availables: "availables",
      more: "More",
      all: "All",
      yes: "Yes",
      no: "No",
      active: "Active",
      inactive: "Inactive",
      request: "Request",
      close: "Close",
      without: "No",
      from: "From",
      to: "To",
    },
    globalentityValues: {
      id: "Id",
      tagFP: "Plant",
      tagSTI: "STI Tag",
      tagTGD: "TGD Tag",
      date: "Date",
      identifier: "Identifier",
    },
    enumTgdTypes: {
      UNIDAD: "Unity",
      EQUIPO: "Equipment",
      LUBRICANTE: "Lubricant",
      OBSERVACION: "Observation",
      INSTRUMENTO: "Instrument",
    },
    messages: {
      unspecified: "Unspecified",
      copiedToClipboard: "Copied to clipboard!",
      nothingSelected: "Nothing selected",
      abilityNotAllowed: "you do not have the necessary permissions to use this function",
      featureNotAvailableOffline: "This feature is an online only feature",
      featureNotAvailableOnline: "This feature is a local only feature",
      lastTenSurveys: "Last 10 surveys",
      lastTenEquipmentSurveys: "Last 10 equipment surveys",
      ofThisLubricationPoint: "Of this lubrication point",
      lastTenObservations: "Last 10 observations",
      lastObservations: "Last observations",
      latestIncome: "Latest Income",
      surveyObservations: "Survey observations",
      lubricationPointObsevetions: "Lubrication point observations",
      notRegistered: "Not registered",
      cannotBeRemoved: "Cannot be removed",
      becauseItsUsedOn: "Because its used on",
      features: {
        collectorConfig:
          "We sorry! Soon you will be able to sync and config your new TGD Collector",
        supervisorSurvey: "We sorry! Soon you will be able to survey as Lubricator user",
        supervisorClientVisor: "We sorry! Soon you will be able to se your client dashboard",
      },
      status: {
        success: "Everithing it`s been successfully",
        error: "we found a problem",
        loading: "loading",
      },
      anErrorHasOccurred: "An error has occurred",
    },
    backResponseMessages: {
      ALL_FIELDS_REQUIRED: "All fields are required",
      ASSIGNED_SUCCESSFULLY: "Assigned successfully",
      COULD_NOT_ASSIGN: "Could not assign",
      COULD_NOT_EDIT: "Could not edit",
      DELETION_PROBLEM_OCCURRED: "There was a problem deleting",
      NOT_EXISTS: "Does not exists",
      REGISTRATION_PROBLEM_OCCURRED: "There was a problem creating",
      SYNCHRONIZED_SUCCESSFULLY: "Synchronized successfully",
      ALREADY_EXISTS: "Already exists",
      OBSOLETE_DATA_UPDATING: "Obsolete data. Updating...",
      NO_FIELD_MODIFIED: "No field modified",
      COULD_NOT_UPDATE: "Could not update",
      REGISTRATION_FAILED: "Could not create",
      COULD_NOT_DELETE: "Could not delete",
      SUCCESSFUL_UPDATE: "Successfully updated",
      SUCCESSFUL_CREATION: "Successfully created",
      SUCCESSFUL_DELETE: "Successfully deleted",
      COLLECTORS_SUCCESSFUL_SYNCHRONIZATION: "Collectors can now be synchronized",
      GENERATING_DATA_SYNCHRONIZATION: "Generating data for synchronization",
      SYNCHRONIZATION_FAILED: "An error occurred during synchronization",
    },
    collectorSync: {
      title: "Device sync panel",
      itWasFind: "Were found",
      databasesWithProblems: "with the following problems",
      basesFoundWithProblems: "bases were found with the following problems",
      errors: {
        /* [SyncFileErrors.Corrupted]:'Corrupted', */
        [SyncFileErrors.failed]: "Failed",
        [SyncFileErrors.expired]: "Expired",
      },
      helpers: {
        /* [SyncFileErrors.Corrupted]:'The data base is broken.', */
        [SyncFileErrors.failed]: "Syncronization has failed, contact support to fix this issue.",
        [SyncFileErrors.expired]:
          "Database is more than 7 days older, it considered too old to make impact in the TGD database, contact support to fix this problem.",
        unknown: "Unknown error.",
      },
      synchronizeSyncBox: "Synchronize SyncBox",
      updatedSyncBoxData: "SyncBox data has been successfully updated",
      synchronizeSyncBoxDescription:
        "When syncing with SyncBox, we can obtain the data from those collectors that have been synchronized.",
      syncBoxState: "SyncBox state",
      syncBoxStateDescription:
        "There are no new pending synchronizations (no collector has been synchronized today)",
      unsynchronizedCollectors: "Unsynchronized collectors",
      pendingSyncs: "Pending synchronizations",
      pendingSyncsDescription: "There are pending synchronizations from the following collectors",
      toKeepSoftUpdated: "To keep the software updated, the PC must be synchronized with SyncBox",
      synchronizeCollectors: "Synchronize collectors",
      synchronizeCollectorsDescription:
        "The data will enter SyncBox once the synchronization is started from the collector's application. To access it, go to the application menu and navigate to the synchronization tab",
      updateSyncBoxData: "Update SyncBox data",
      updateSyncBoxDataDescription:
        "The supervisor can work on the software and, if necessary, update the collector's data the same day as many times as needed. This button will proceed to update the data that the collector will use. (Don't forget to resynchronize the collectors to continue working in the plant).",
      howToSynchronizeTheSyncBox: "How to synchronize SyncBox?",
      howToSynchronizeTheSyncBoxDescription:
        "Each day with the first login, TGDSoft will automatically synchronize the data with SyncBox to subsequently synchronize all the collectors and start working.",
      lastSynchronization: "Last synchronization",
      someCollectorsHaveBeenSynchronized: "Some collectors have been synchronized",
      missingCollectors: "Missing collectors",
      collectorsWereSynchronizedAtLeastOnce:
        "Today, all collectors were synchronized at least once",
    },
    grid: {
      actionsAlert: "Select one or more items to work.",
      noRowsLabel: "No data",
      errorOverlayDefaultLabel: "An error has occourred",
      toolbarColumns: "Columns",
      toolbarColumnsLabel: "Select Columns",
      toolbarFilters: "Filters",
      toolbarFiltersLabel: "Show Filters",
      toolbarFiltersTooltipHide: "Hide filters",
      toolbarFiltersTooltipShow: "Show Filters",
      columnsPanelTextFieldLabel: "Search column",
      columnsPanelTextFieldPlaceholder: "Column title",
      columnsPanelDragIconLabel: "Reorder Column",
      columnsPanelShowAllButton: "Show all",
      columnsPanelHideAllButton: "Hide all",
      toolbarExport: "Export",
      toolbarExportLabel: "Export",
      toolbarExportCSV: "Download Csv",
    },
    filters: {
      search: "Search",
      filterColumns: "Filter columns",
      disableEverything: "Disable everything",
      title: "Search and filter",
      addFilter: "Add filter",
      deleteFilters: "Delete filters",
      add: "Add",
      searchByTerm: "Search by term",
      closeFilters: "Close filters",
      downloadData: "Download data as .xlsx ",
      filterData: "Filter data",
      all: "All",
      select: "Select",
      resetDateRange: "Clear date range",
      queryDateRange: "Query date range ",
      from: "From",
      to: "To",
      and: "AND",
      andHelper:
        '"AND" filter permits specify data entries to obtain an exact data group that matches every "AND" filter',
      or: "OR",
      orHelper: '"OR" filters permits accumulate data entries to obtain mutch larger data matches',
    },
    sendEmail: {
      title: "Send via email",
      att: "att.",
      charge: "Lubrication area, TGD company",
      recipient: "This email will be sent to",
      emailBody: "Your message here...",
    },
    crudActions: {
      update: "Update",
      create: "Create",
      delete: "Delete",
      archive: "Archive",
      desactivate: "Desactivate",
      activate: "Activate",
      save: "Save",
    },
    inputFields: {
      autocomplete: {
        email: "invalid email",
        clearText: "Clear",
        closeText: "Close",
        openText: "Open",
        loadingText: "Loading..",
      },
    },
    validations: {
      messages: {
        macAddress: "Invalid MAC address",
        justNumbers: "Just numbers",
        invalidNumber: "Invalid number",
        justText: "Just text",
        required: "Required",
        minLength: "To short",
        maxLength: "To long",
        allReadyExist: "Already exist",
        pattern: "invalid",
        completeFields: "Complete the fields",
      },
    },
    ui: {
      components: {
        viewMode: {
          title: "ChangeView",
        },
        name: "Name",
      },
    },
    pages: {
      realTime: {
        sensors: "sensors",
        equipments: "equipments",
      },
    },
    login: {
      form: {
        label: {
          user: "Email",
          password: "Password",
        },
      },
      navigation: {
        name: "Get trial demo",
      },
      legend: {
        years: "years",
      },
      yearText: "YEARS 1990",
    },
    demo: {
      form: {
        completeName: "Complete name",
        phone: "Phone",
        company: "Company",
        email: "Email",
      },
      prospects: "Prospects",
    },
    persons: {
      newSupervisor: "New supervisor",
      newOperator: "New operator",
      newAdministrator: "New administrator",
      newAccountManager: "New account manager",
      newClient: "New client",
      userAdministration: "Manage users",

      accountManager: "Account manager",
      accountManagers: "Account managers",

      createstep1: "add a account mannager",
      createstep2: "Manage factories at his name",
      createFinishStep: "All steps finished",

      referer: "Referer",
      phone: "Phone",
      password: "Password",
      plural: "Persons",
      singular: "Person",
      name: "Name",
      lastName: "Last name",
      adress: "Adress",
      email: "Email",
      client: "Client",
      clients: "Clients",
      operator: "Lubricator",
      operators: "Lubricators",
      supervisor: "Supervisor",
      supervisors: "Supervisors",
      superAdmin: "Super Administrator",
      superAdmins: "Super Administrators",
      admin: "Administrator",
      admins: "Administrators",
    },
    language: {
      label: "Language",
      spanish: "Spanish",
      english: "English",
    },
    formMessages: {
      required: "Required",
      float: "Only a number",
      invalidEmail: "Invalid email",
    },
    stock: {
      product: "Product",
      brand: "Brand",
      actualStock: "Actual stock",
      minimumRequired: "Minimum",
      category: "Category",
      subcategory: "Sub category",
      measureUnit: "Meassure unit",
      categoryArea: "Category area",
      providerArea: "Provider Area",
      complex: "Complex",
      hidden: "hidden",
      options: {
        simple: "Simple reposition",
        complex: "Complex reposition",
        archived: "Hidden",
        unArchived: "Showed",
      },
    },
    lubricationSection: {
      stock: "Stock",
      purchaseRequests: "Purchase requests",
      newPurchaseRequest: "New purchase request",
      configureCategory: "Configure category",
      categories: "Categories",
      addNewCategory: "Add new category",
      subcategories: "Subcategories",
      addNewSubcategory: "Add new subcategory",
      subcategory: "Subcategory",
      allStock: "All stock",
      allStockItems: "All stock items",
      allIncome: "All income",
      incomeManagement: "Income management",
      manageStock: "Manage stock",
      lubricants: "Lubricants",
      title: "Lubrication Section",
      lossAmount: "Leak amount",
      categoryName: "Category name",
      newStockProduct: "New stock product",
      seeItems: "See items",
      seeArchived: "See archived",
      archive: "Archive",
      archived: "Archived",
    },
    actionTypes: {
      activate: "Activate",
      desactivate: "Desactivate",
      view: "View",
      apply: "Apply",
      login: "Login",
      logout: "Logout",
      create: "Create",
      deleteItem: "Delete",
      update: "Update",
      assign: "Assign",
      save: "save",
      exit: "Exit",
      before: "Before",
      next: "Next",
      cancel: "Cancel",
      configure: "Configure",
      add: "Add",
      new: "New",
    },
    factories: {
      manageFactories: "manage access to factories",
      noFactories: "No items found",
      accountManagerFactories: "Account manager factories",
      singular: "Factory",
      plural: "Factories",
      location: "Location",
      name: "FactoryName",
      businessName: "Business name",
      referrer: "Referer",
      tagF: "Factory tag",
    },
    plants: {
      asignCancelPlants: "Manage allowed plants",
      clickToAssign: "Click to assign",
      clickToCancel: "Click to cancel",
      factory: "Factory",
      singular: "Plant",
      plural: "Plants",
      factoryId: "Factory id",
      location: "Adress",
      name: "Name",
      plantId: "PlantId",
      referrer: "Referrer",
      tagFP: "Tgd plant tag",
      state: "State",
    },
    types: {
      singular: "Type",
      plural: "Types",
      form: {
        name: "Name",
        type: "Type",
      },
      typeCannotBeRemoved: "This type cannot be removed because it is being used on",
    },
    lubricationPoints: {
      title: {
        singular: "Lubrication point",
        plural: "Lubrication points",
      },
      delayedPoints: "Delayed points",
      todayPoints: "Today points",
      equipments: "Equipments",
      equipmentName: "Equipment name",
      equipmentTag: "Equipment tag",
      equipmentCriticality: "Equipment criticality",
      equipmentIdentification: "Equipment identification",
      sector: "Sector",
      plantTag: "Plant tag ",
      lowReason: "Low Reason",
      labelId: "Label id",
      label: "Label",
      state: "Lubrication point state",
      criticality: "Criticality",
      egressDate: "Egress date",
      function: "Function",
      equipment: "Equipment",
      review: "Review",
      location: "Location",
      lubricantType: "Lubricant type",
      routes: "Routes",
      supplies: "Supplies",
      type: "Type",
      brand: "Brand",
      componentBrand: "Component brand",
      componentModel: "Component model",
      componentFunction: "Component function",
      componentLocation: "Component location",
      model: "Model",
      element: "Element",
      component: "Component",
      lubricant: "Lubricant",
      capacity: "Capacity",
      lubricantCapacity: "Lubricant capacity",
      measureUnit: "Meassure unit",
      oilAnalysis: "Oil analysis",
      admissionDate: "Admission date",
      observations: "Observations",
      tagTGDBefore: "Tag TGD before",
      equipmentState: "Equipment state",
      allActivePoints: "All active points",
      youAreActivatingAllPoints:
        "You are activating all the lubrication points that this equipment may have deactivated, are you sure?",
      youAreDecommissioningEquipment:
        "You are decommissioning a piece of equipment, therefore all its elements and lubrication points will also be decommissioned",
      youAreDecommissioningComponent: "You are decommissioning this component, are you sure?",
      youAreDecommissioningElement:
        "You are decommissioning an element, therefore all its lubrication points will also be decommissioned",
      thereWasAnErrorActivating: "There was an error activating",
      equipmentHasBeenDecommissioned: "The equipment has been decommissioned",
      dismantle: "Dismantle",
      activateThis: "Activate this",
      again: "Again",
      activateAgain: "",
      options: {
        active: "Active",
        disabled: "Disabled",
        all: "All",
        oilAnalisys: "Yes",
        withoutOilAnalisys: "No",
      },
      helpers: {
        whyAreEditingThisPoint: "¿Why are you editinh this component?",
        lubricationPointsThatUse: "Lubrication points that use",
        lubricationPointsThatUseThisPermission: "Lubrication points that use this permission",
        routeLubricationPoint: "Route lubrication point",
        pointsThatNeedLabelChange: "Points that need label change",
        needLabelChange: "Need label change",
        labelInCorrectCondition: "Label in correct condition",
      },
      suggestedQuantity: "Suggested quantity",
      suggestedQuantityIndicates:
        "This value will tell the lubricator what the suggested amount of lubricant application is at this point",
      lastLubricantAdmission: "Last lubricant admission",
    },
    revisionChange: {
      title: {
        singular: "Revision change",
        plural: "Revision changes",
      },
      revisionChangeMadeFromPlantConfiguration: "Revision change made from plant configuration",
      unsupervised: "Unsupervised",
      pendingRevisionChanges: "Pending revision changes",
      supervisedRevisionChanges: "Supervised revision changes",
      revisionChangesHistory: "Revision changes history",
      pending: "Pending",
      supervised: "Supervised",
      requestDate: "Request date",
      requestReason: "Request reason",
      requestUser: "User requesting",
      supervisionDate: "Supervision date",
      supervisionReason: "Supervision reason",
      supervisionUser: "Supervising user",
      impactDate: "Impact date",
      request: "Request",
      supervision: "Supervision",
      review: "Review",
      approve: "Approve",
      approved: "Approved",
      reject: "Reject",
      rejected: "Rejected",
      state: "State",
    },
    notifications: {
      title: {
        singular: "Notification",
        plural: "Notifications",
      },
    },
    infoLubricationPoints: {
      supplies: "Missing supplies",
      requiredSupplies: "Required supplies",
      equipmentsWithRequests: "Equipments with requests",
      thereAreNoSuppliesToAssign: "There are no supplies to assign",
      labelReplacement: "Label replacement",
      lastLubrication: "Last lubrication",
      lubricantQuantity: "Lubricant quantity",
      leak: "Leak",
      pending: "Pending",
      updatedToday: "Updated today",
      user: "User",
      lastSurveyId: "Last survey id",
      assetCriticality: "Asset criticality",
      inaccessible: "Inaccessible",
      lastInspectionDate: "Last inspection date",
      updateReview: "Update review",
      automaticReviewChange: "Automatic review change",
      toUpdateReview: "Update review",
      changeToNextReview: "Change to next review",
      rejectRequest: "Reject request",
      youAreChangingToTheNextComponentRevision:
        "You are changing to the next review of this component, are you sure? You will not be able to go back to a previous review, you must contact the support team to do so",
      manageReviewChanges: "Manage review changes",
      equipmentsWithReviewChangeRequest: "Equipments with review change request",
      isRecommendedToMakeChangesIf: "It is recommended to make changes only if",
      allSynchronizationsHaveBeenProcessed:
        "All synchronizations of the day have been processed successfully",
      thereWillBeNoSynchronizationsIn: "There will be no subsequent synchronizations on the day",
      otherwiseWhenSynchronizing:
        "Otherwise, by synchronizing data from a collector that surveyed on-route or off-route equipment that is within this list, it could cause a loss of traceability in the review change and generate unnecessary change requests. (This would cause several unnecessary review changes).",
      temperature: "temperature",
      leakLevelRange: "Leak level range",
      withoutSupplies: "WITHOUT SUPPLIES",
      assetGeneralScore: "Asset general score",
      assetPriority: "Asset importance",
      lastEquipmentState: "LAST EQUIPMENT STATE",
      withLeak: "With leak",
      withoutLeak: "Without leak",
      lastAgregate: "LAST QUANTITY",
      added: "ADDED",
      cannotBeCalculated: "Cannot be calculated",
      notPending: "UP TO DATE",
      accessedToday: "ACCESSED TODAY",
      lastInspection: "LAST INSPECTION",
      accesible: "Accessible",
      lastTemperature: "LAST TEMPERATURE",
      missings: "MISSINGS",
      capacity: "CAPACITY",
      equipmentinformation: "Equipment information",
      lastSurveys: "Last surveys",
    },
    observations: {
      observationTypes: {
        INACCESIBLE: "Inaccessible",
        "IMPOSIBLE DE LUBRICAR": "Impossible to Lubricate",
        "NO LUBRICA": "Does not Lubricate",
        ANOMALIA: "Anomaly",
        OBSERVACION: "Observation",
      },
      addedReasons: {
        leak: "Leak",
      },
      observationsQuantity: "Number of observations",
      singular: "Observation",
      plural: "Observations",
      delete: "Delete observation",
      surveyId: "Survey Id",
      equipment: "Equipment id",
      modified: "Modified",
      modifiedDate: "Modified date",
      observationDate: "Observation date",
      OTnumber: "OT number",
      state: "State",
      taskId: "Task id",
      taskRequestId: "Task request id",
      taskType: "Task type",
      type: "Type",
      element: "Element",
      component: "Component",
      user: "User",
    },
    addedReasons: {
      plural: "Added reasons",
      singular: "Added reason",
      reason: "Added reason name",
    },
    anomalies: {
      singular: "Anomaly",
      plural: "Anomalies",
      anomalyName: "Anomaly name",
    },
    news: {
      singular: "New",
      plural: "News",
      sector: "New Sector",
      area: "Area",
      equipment: "Lubrication point",
      type: "New type",
      newsDate: "New date",
      description: "Description",
      user: "User",
      criticality: "Criticality",
      createNew: "Create new",
      linkToAEquipment: "Link to a equipment",
      newNovelty: "New novelty",
      all: "All",
      environment: "Environment",
      plant: "Plant",
    },
    collectors: {
      plural: "Collectors",
      singular: "Collector",
      activate: "Activate collector",
      desactivate: "Desactivate collector",
      desactivatedColectorWarning: "This collector is desactivated",
      qrCode: "QR Code",
      collectorCode: "Code",
      form: {
        active: "Activated",
        disabled: "Desactivated",
        id: "Id",
        name: "Name",
        serialNumber: "Serial number",
        collectorCode: "Collector",
        tagFP: "Plant",
        state: "State",
        admissionDate: "Admission date",
        egressDate: "Egress date",
      },
    },
    lubricantForm: {
      singular: "Lubricant",
      plural: "Lubricants",
      type: "Type",
      name: "Name",
      brand: "Brand",
      measureUnit: "Measure unit",
      requiresObservation: "Requires observation",
      points: "Points",
      approximateNeeded: "Needed (approximate)",
      lubricantCannotBeRemoved: "This lubricant cannot be removed because it is being used on",
    },
    incomeForm: {
      singular: "Income",
      plural: "Incomes",
      lotNumber: "Lot number",
      quantity: "Quantity",
      measurementUnits: "Liters / Kilograms",
      admissionDate: "Admission date",
      referrer: "Referrer",
      confirmMessage: "Are you sure you want to delete this income?",
    },
    criticalities: {
      title: {
        singular: "Criticality",
        plural: "Criticalities",
      },
      form: {
        descripton: "Name",
        number: "Priority number",
        periodicity: "Periodicity",
        color: "Color",
        type: "Criticality Type",
        days: "Priority in days",
      },
      criticalityCannotBeRemoved: "This criticality cannot be removed because it is being used on",
    },
    containers: {
      title: {
        singular: "Container",
        plural: "Containers",
      },
      form: {
        name: "",
        label: {
          name: "Name",
          lubricantType: "LubricantType",
          lubricant: "Lubricant",
          capacity: "Capacity",
          coefficient: "Coefficient",
          code: "Code",
          type: "Type",
          capacityHelper: "Capacity unit need to match lubricant meassure unit",
        },
      },
    },
    sectors: {
      title: {
        singular: "Sector",
        plural: "Sectors",
      },
      form: {
        name: "",
        label: {
          sector: "Plant sector",
          sectorName: "Sector",
          code: "Code",
        },
      },
      sectorCannotBeRemoved: "This sector cannot be removed because it is being used on",
    },
    areas: {
      title: {
        singular: "Area",
        plural: "Areas",
      },
      form: {
        name: "",
        label: {
          area: "Area name",
          email: "Email list",
          emailHelper: "you can write a few email coma separated, to send email to",
          referrer: "Referrer name",
        },
      },
    },
    components: {
      activeComponents: "Active components",
      inactiveComponents: "Inactive components",
      componentCannotBeRemoved: "This component cannot be removed because it is being used on",
      assets: {
        plural: "Assets",
      },
      inactive: {
        plural: "Inactive",
      },
      seeComponent: "See component",
      activePoint: "Active point",
      inactivePoint: "Inactive point",
      title: {
        singular: "Component",
        plural: "Components",
      },
      form: {
        name: "",
        label: {
          component: "Name",
          code: "Code",
        },
      },
    },
    elements: {
      title: {
        singular: "Element",
        plural: "Elements",
      },
      form: {
        name: "",
        label: {
          element: "Name",
          code: "Code",
        },
      },
      elementCannotBeRemoved: "This element cannot be removed because it is being used on",
    },
    supplies: {
      assignmentsMade: "Assignments made",
      nextStep: "Next step",
      selectEquipmentToAssign: "Select equipment to assign",
      backReset: "Back (reset)",
      errorGetingSupplies: "Error fetching plants supplies",
      noConsumablesHaveBeenLoaded: "No supplies have been loaded",
      missing: "Missing",
      missingPlural: "Missing",
      used: "Used",
      request: "Request",
      requestAddedInput: "Request addition of input to this equipment",
      missingInputsForTheTask: "Missing inputs for the task",
      suppliesUsed: "Supplies used",
      title: {
        singular: "Supply",
        plural: "Supplies",
        form: {
          name: "",
          label: {
            type: "Type",
            supply: "Name",
            description: "Description",
          },
        },
      },
      HERRAMIENTA: {
        assignToEquipment: "Assign tools to equipments",
        manageLubricationPointSupply: "Manage tools of",
        title: {
          singular: "Tool",
          plural: "Tools",
        },
        form: {
          name: "",
          label: {
            type: "Type",
            supply: "Name",
            description: "Tool description",
          },
        },
      },
      CONSUMIBLE: {
        assignToEquipment: "Assign consumables to equipments",
        manageLubricationPointSupply: "Manage consumable of",
        title: {
          singular: "Consumable",
          plural: "Consumables",
        },
        form: {
          name: "",
          label: {
            type: "Type",
            supply: "Name",
            description: "Consumable description",
          },
        },
      },
      PROTECCION: {
        assignToEquipment: "Assign protections to equipments",
        manageLubricationPointSupply: "Manage protection elements of",
        title: {
          singular: "Protection",
          plural: "Protections",
        },
        form: {
          name: "",
          label: {
            type: "Type",
            supply: "Name",
            description: "Protection description",
          },
        },
      },
      OBSERVACION: {
        manageLubricationPointSupply: "Not setted",
        title: {
          singular: "Observation",
          plural: "Observations",
        },
        form: {
          name: "",
          label: {
            type: "Type",
            supply: "Name",
            description: "Observation description",
          },
        },
      },
      LUBRICANTE: {
        title: {
          singular: "Lubricant",
          plural: "Lubricants",
        },
      },
      PERMISO: {
        title: {
          singular: "Permission",
          plural: "Permissions",
        },
      },
    },
    permissions: {
      plural: "Permissions",
      singular: "Permission",
      permissionName: "Permission Name",
      assignToEquipment: "Assign permissions to equipments",
      manageEquipmentPermissions: "Manage equipment permissions",
      description: "Description",
      code: "Code",
      area: "Area",
      sector: "Sector",
      sectorHelper:
        "Make this permission sector global (all components with the selected sector gonna require this permission)",
      areaHelper: "This will be the current permission issuance area",
      permissionCannotBeRemoved: "This permission cannot be removed because it is being used on",
    },
    routes: {
      scheduledDate: "scheduledDate",
      newScheduledDate: "newScheduledDate",
      special: "Special route",
      singular: "Route",
      plural: "Routes",
      operator: "Operator",
      periodicity: "Periodicity",
      route: "Route",
      routeName: "Route name",
      startDate: "Start date",
      state: "State",
      manageEquipmentRoutes: "Manage lubrication point routes",
      getRoutesError: "An error occurred while fetching plant routes data",
      assignRouteToEquipment: "Assign routes to lubrication point",
      todayRoutes: "Today routes",
      weekRoutes: "Week routes",
      dateAccordingToSchedule: "Date according to schedule",
      daysSinceLastLubrication: "Days since last lubrication",
      routeCannotBeRemoved: "This route cannot be removed because it is being used on",
      survey: {
        outOfRoute: "Out of route survey",
        inRoute: "In route survey",
      },
      delayed: {
        singular: "Delayed",
        plural: "Delayed",
      },
      delayedAndPendingRoutes: "Delayed and pending routes",
      delayedRoutesDescription:
        "Routes that could not be performed, and not assigned as pending routes. Common causes: Plant stop, holidays, etc.",
      labelsToReplace: "Labels to replace",
      lastRegistrationDate: "Last registration date",
      pending: {
        singular: "Pending",
        plural: "Pending",
      },
      pendingRoutesDescription:
        "Routes that are assigned as pending to an operator, but not started on the scheduled date.",
      weekRoutesDescription: "Routes that can be performed on this week",
      delayedRoute: "Delayed route",
      scheduledRoute: "Scheduled route",
      routeThatTheLubricatorDidNotPerform: "Route that the lubricator did not perform",
      scheduledRouteThatTheLubricatorDidNotPerform:
        "Scheduled route that the lubricator did not perform",
      anticipateRoute: "Anticipate route",
      dailyRoute: "Daily route",
      canNotBeBeforeToday: "It can't be before today",
      futureDatesAreNotAllowed: "Future dates are not allowed",
      invalid: "Invalid",
      veryCloseToRoute: "Very close to route - The route will be anticipated",
      requireDate: "Requires a date",
      date: "Date",
      rescheduleRoute: "Reschedule route",
      schedule: "Program",
      routeWillEnd: "The route will end",
      validations: {
        allRouteExistance:
          "You can not create this rioute, because allredy exist for all of operators in the sistem",
        lubricatorRouteExistance: "This route exist for operators",
        ableToCreateRoute: "you can create it to another operators",
      },
      states: {
        ATRASADA: "Delayed",
        INCUMPLIDA: "Incompleted",
        INICIADA: "Started",
        "PROGRAMADA INCUMPLIDA": "Scheduled incomplete",
        PENDIENTE: "Pending",
        "AL DIA": "Up to date",
      },
    },
    surveys: {
      taskTypes: {
        LUBRICACION: "Lubrication",
        INSPECCION: "Inspection",
        LIMPIEZA: "Cleaning",
        REPARACION: "Repairment",
        CONTROL: "Control",
        PUNTUACION: "Puntuation",
        INTERVENCION: "Intervention",
      },
      tasks: {
        lubrication: {
          title: "Lubrication",
          inaccessible: "Inaccessible",
          accessible: "Accessible",
          bomb: "Pumps",
        },
        inspection: {
          title: "Inspection",
          componentChange: "Component change",
          needLubricant: "Need lubricant?",
          isLossIdentified: "Is leak identified?",
          replaceLabel: "Replace label",
          temperatureInC: "Temperature in Cº",
          whyWasTheEquipmentInaccessible: "Why was the equipment inaccessible?",
          newAnomaly: "New anomaly",
          noAnomaly: "No anomaly",
          noAnomalies: "No anomalies",
        },
        cleaning: {
          title: "Cleaning",
          noCleaning: "No cleaning",
          cancelCleaning: "Cancel cleaning",
        },
        repairment: {
          title: "Repairment",
          noRepairment: "No repairment",
          cancelRepairment: "Cancel repairment",
        },
        score: "Score",
        intervention: "Intervention",
        control: "Control",
        inspectAndLubricate: "Inspect and lubricate",
        inspectAndClean: "Inspect and clean",
        inspectAndRepair: "Inspect and repair",
        soonYouWillBeAbleToPerformOtherPersonalizedTasks:
          "Soon you will be able to count on other personalized tasks for Lubrication Points",
        workOnTheEquipment: "Work on the equipment",
        assetScore: "Asset Score",
        inaccessibleEquipment: "Inaccessible Equipment",
        reset: "Reset",
        noObservations: "No observations",
      },
      options: {
        impossibleToLubricate: "Impossible to lubricate",
        normal: "normal",
        all: "All",
        lubricates: "Lubricated",
        withoutLubricate: "Without lubricate",
        yes: "yes",
        no: "no",
        outOfRoute: "Out of route",
        inRoute: "In route",
        inaccessible: "Inaccessible",
        accessible: "Accessible",
        manualCharge: "Manual charge",
        Colector: "Collector",
        done: "Done",
        notDone: "Not done",
        neededLubrication: "Neded lubrication",
        lubricationNotNeeded: "lubrication not neded",
      },
      title: {
        plural: "Surveys",
        singular: "Survey",
      },
      componentChange: "Component has changed",
      observationsQuantity: "ObservationQuantity",
      equipment: "Equipment",
      element: "Element",
      component: "Component",
      sector: "Sector",
      review: "Review",
      impossibleToLubricate: "Impossible to lubricate",
      doesNotLubricate: "Does not lubricate",
      whyDontYouLubricate: "Why don't you lubricate?",
      whyWasntItPossible: "Why wasn't it possible?",
      lubricates: "Lubricates",
      routeDate: "Route date",
      loadingDate: "Loading date",
      startTime: "Start",
      endTime: "Finish",
      sampleExtraction: "Sample extraction",
      consumables: "Consumables",
      lubricantType: "LubricantTipe",
      lubricant: " Lubricant",
      quantity: "Lubricant quantity",
      measureUnit: "Meassure unit",
      reason: "Added reason",
      anomalies: "Anomalies",
      outOfRoute: "Out of Route",
      inRoute: "In route",
      inaccessible: "Inaccessible",
      cleaning: "Cleaning",
      otNumber: "OT Number ",
      news: "News",
      criticality: "Criticality",
      user: "User",
      route: "Route",
      manualRegistration: "Manual registrationm",
      areas: "Area",
      taskType: "Task type",
      taskTime: "Task time",
      inMinutes: "In minutes",
      consumablesUsed: "Consumables used",
      taskDone: "Task done",
      assetCriticality: "Asset criticality",
      needsLubrication: "Needs lubrication",
      temperature: "Temperature",
      loss: "Leak",
      losses: "Leaks",
      pointAlreadySurveyedToday: "This point was already surveyed today",
      surveyOffRouteAgain: "Do you want to survey again off route?",
    },
    iotDevices: {
      actions: {
        desactivate: "Desactivate device",
        activate: "Activate device",
      },
      helpers: {
        youAreDesactivating: "You are desactivating:",
        youAreActivating: "You are activating:",
        youCanNotWorkWithDifrentKindOfItemStates: "You can not work with items with difrent states",
        selectedItems: "Selected Items",
        cleanState: "Continue working",
        dontForgetToSpecifyLowReason: "Don`t forget to specify a new lowReason",
      },
      title: {
        singular: "Device",
        plural: "Devices",
      },
      form: {
        label: {
          device: "Device name",
          deviceCode: "Device code",
          admissionDate: "Admission date",
          egressDate: "Egress date",
          state: "State",
          location: "Location",
          lowReason: "Low Reason",
          batteryCapacity: "Battery capacity",
          lowBatteryAlarm: " Low battery alarm",
        },
      },
    },
    iotSensorTypes: {
      title: {
        singular: "Sensor type",
        plural: "Sensor types",
      },
      form: {
        label: {
          sensorType: "Sensor type",
          sensorTypeCode: "Sensor type code",
        },
      },
    },
    STISensors: {
      title: {
        singular: "Sensor",
        plural: "Sensors",
      },
      form: {
        label: {
          sensorType: "Sensor tipe",
          sensorTypeCode: "Type sensor code",
          sensorAddress: "MAC Address",
          channelQuantity: "Chanel quantity",
          permanent: "Permanet location",
          batteryCapacity: "Max battery value",
        },
      },
      booleanSelector: {
        data: {
          fixed: "PERMANENT",
          mobile: "TEMPORARY",
        },
      },
    },
    STIEquipments: {
      title: {
        singular: "Equipment",
        plural: "STI Equipments",
      },
      manageEquipments: "Manage Equipments",
      form: {
        messages: {
          mustLoadAtLeastOneEquipment:
            "You must upload at least one component in the equipment before you can create it",
          sureToResetTemplateData: "Are you sure you want to reset the template data?",
          createAndConfigureNewEquipment: "Create and configure a new equipment",
          somePointsCouldNotBeCreated:
            "Some lubrication points could not be created successfully, please check for errors to finish uploading them",
          equipmentLoadedSuccessfully:
            "The equipment and all its lubrication points have been loaded successfully!",
          resetData: "Reset data",
          initialEquipmentConfiguration: "Initial equipment configuration",
          successfullyCreatedEquipment: "Successfully created equipment",
        },
        label: {
          equipment: "Equipment",
          equipments: "Equipments",
          labelId: "Label Id",
          sector: "Sector",
          plantTag: "Plant Tag",
          tagSTI: "Tag STI",
          type: "Type",
          brand: "Brand",
          model: "Model",
          function: "Function",
          location: "Location",
          observations: "Observation",
          admissionDate: "Admission date",
          egressDate: "Egress date",
          state: "State",
          lowReason: "LowReason",
          criticality: "Criticidad",
        },
      },
    },
    STISensorizedEquipment: {
      title: {
        singular: "Sensorization",
        plural: "Sensorizations",
      },
      form: {
        label: {
          sensorTypeCode: "sensorTypeCode",
          vibration: "Vibration",
          sensorType: "SensorType",
          sensorAddress: "Sensor`s MAC Address",
          iotDeviceCode: "Gateway Code",
          plantTag: "Plant Tag",
          equipment: "Equipment",
          tagSTI: "Tag STI",
          element: "Element",
          sector: "Sector",
          location: "Location",
          criticality: "Criticality",
          observations: "Observations",
          state: "State",
          lowReason: "Low reason",
          admissionDate: "Admission date",
          egressDate: "Egress date",
          temperature: "Temperature",
          xAxis: "X axis",
          yAxis: "Y axis",
          zAxis: "Z axis",
          channels: "chanels",
          pressure: "Pressure",
        },
      },
    },
    reports: {
      report: "Report",
      reports: "Reports",
      equipmentInfo: "Lubrication point info",
      observations: "Observations",
      surveys: "Surveys",
      actions: "Actions",
      lubricationPoints: "Lubrication Points",
      equipmentOnDate: "Lubrication point on date",
      routes: "Routes",
    },
    reportsGeneration: {
      itemsQuantity: "Number of items",
      reportsGeneration: "Reports generation",
      generateReport: "Generate Report",
      generateLossReport: "Generate leak report",
      generateObservationReport: "Generate observation report",
      losses: "Leaks",
      observations: "Observations",
      generatingReport: "Generating Report",
      reportGenerated: "Report Generated!",
      printReport: "Print Report",
      observationDocumentTitle: "ObservationReport",
      lossDocumentTitle: "LeakReport",
      tagPlanta: "Plant",
      lubricationArea: "Lubrication Area",
      lubricationDepartmentReport: "Lubrication Department Report",
      lossReport: "Leak Report",
      observationReport: "Observation Report",
      dateOfIssue: "Date of issue",
      lessThan: "Less than",
      greaterThan: "Greater than",
      totalLeak: "Total leak",
    },
    dates: {
      today: "today",
      day: {
        singular: "day",
        plural: "days",
      },
      week: {
        singular: "week",
        plural: "weeks",
      },
      month: {
        singular: "month",
        plural: "months",
      },
      year: {
        singular: "year",
        plural: "years",
      },
      to: "to",
      notRegistered: "not registered",
    },
    table: {
      noResults: "There are no items to show",
    },
    instruments: {
      configuration: "Configuration",
      title: {
        singular: "Instrument",
        plural: "Instruments",
      },
      qrCode: "QR Code",
      tag: "Tag",
      description: "Description",
      name: "Name",
      sciNumber: "SCI Number",
      type: "Type",
      brand: "Brand",
      brands: {
        title: {
          singular: "Brand",
          plural: "Brands",
        },
      },
      model: "Model",
      models: {
        title: {
          singular: "Model",
          plural: "Models",
        },
      },
      range: "Range",
      rangeFrom: "Range from",
      rangeTo: "Range to",
      plc: "PLC",
      plcs: {
        title: {
          singular: "PLC",
          plural: "PLCs",
        },
        plcName: "PLC Name",
      },
      assignment: "Assignment",
      calibrationDate: "Calibration date",
      certificate: "Certificate",
      patronCertificate: "Patron certificate",
      patronDate: "Patron date",
      measureUnit: "Measure unit",
    },
  };

  switch (lang) {
    case "es":
      return es;

    case "en":
      return en;

    default:
      return es;
  }
};
