export const truncateNumber = (
  inputNumber: number | undefined,
  decimalPositions: number = 0
) => {
  if (inputNumber !== undefined) {
    const scalingFactor = Math.pow(10, decimalPositions);
    const tempNumber = Math.abs(inputNumber) * scalingFactor;
    const truncatedTempNumber = Math.floor(tempNumber);
    const finalNumber =
      (truncatedTempNumber / scalingFactor) * (inputNumber < 0 ? -1 : 1);
    return finalNumber;
  }
};
