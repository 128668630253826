import { FlexContainer, PageContainer } from "../../../containers";
import { SelectedFieldsToFilterProps } from "../types/FieldFilterTypes";
import SelectedFieldInput from "./SelectedFieldInput";

const SelectedFieldsToFilter = ({
  selectedFields,
  handleFilterValueChange,
  onSelectedFieldDelete,
}: SelectedFieldsToFilterProps) => {
  return (
    <div style={{ marginTop: "24px" }}>
      <PageContainer padding="0px">
        <FlexContainer flexDirection="column" gap={"12px"}>
          {Object.entries(selectedFields)?.map(([fieldKey, fieldValue]) => (
            <SelectedFieldInput
              {...fieldValue}
              fieldKey={fieldKey}
              handleFilterValueChange={handleFilterValueChange}
              onSelectedFieldDelete={onSelectedFieldDelete}
            />
          ))}
        </FlexContainer>
      </PageContainer>
    </div>
  );
};

export default SelectedFieldsToFilter;
