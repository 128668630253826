export const extendedDateFormats = [
  /* "2023-07-04T00:00:00.000+00:00" - (DB) */
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}$/,

  /* "2023-07-04T00:00:00.000Z" - (ISO) */
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,

  /* "Tue, 04 Jul 2023 00:00:00 GMT" - (UTC) */
  /^\w{3}, \d{2} \w{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/,

  /* "2023-07-04T00:00:00" */
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,

  /* "2023-07-04T00:00:00Z" */
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/,

  /* "2023-07-04T00:00:00+00:00" */
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/,

  /* "2023-07-04 00:00" */
  /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/,
];

export const basicDateFormats = [
  /* "2023-07-04" */
  /^\d{4}-\d{2}-\d{2}$/,

  /* "2023/07/04" */
  /^\d{4}\/\d{2}\/\d{2}$/,
];
