import React from "react";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";

import FieldSelector from "../../shared/form/components/FieldSelector";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { LubricantInterface } from "./model/LubricantInterface";

import { CreateLubricant, UpdateLubricant, DeleteLubricant } from "./services/service.lubricants";
import { useLubricantForm } from "./services/useLubricantForm";

type CreateUpdateLubricantProps =
  | {
      tagFP: string;
      actionType: CrudFormType.create;
      item?: LubricantInterface;
      isDataUpdated?: boolean;
    }
  | {
      tagFP: string;
      actionType: CrudFormType.update;
      item: LubricantInterface;
      isDataUpdated?: boolean;
    };

const CULubricantForm: React.FC<CreateUpdateLubricantProps> = ({
  tagFP,
  actionType,
  item,
  isDataUpdated,
}) => {
  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateLubricant,
      update: UpdateLubricant,
      delete: DeleteLubricant,
    },
    actionType,
    ["PlantAux"]
  );

  //UI
  const { lang } = useLangLabels();
  const { uiModel, handleSubmit, resetForm } = useLubricantForm(tagFP, item);
  const ui = (() => {
    const formName = lang.lubricantForm.singular;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName}`,
          variant: "button",
          mode: "modal",
        };
        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName}`,
          variant: "iconButton",
          mode: "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: LubricantInterface) => {
    action(data);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default React.memo(CULubricantForm);
