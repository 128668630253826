export enum instrumentTypeEnum {
  unity = "UNIDAD",
  instrument = "INSTRUMENTO",
}

export const instrumentTypeOptions = [
  { label: instrumentTypeEnum.unity },
  { label: instrumentTypeEnum.instrument },
];

export interface InstrumentTypeInterface {
  id: number;
  type: instrumentTypeEnum;
  name: string;
  tagFP: string;
}