import { lastDBSync, SyncFileErrors } from "../model/CollectorsSyncByTagFPType"

export const parsePendingSyncs = (pendingSyncs:any[]) =>{

    const pending = pendingSyncs.map((item:lastDBSync)=>{
        const splited = item.split('-')[3] || null
        const cleanedValue = splited?.split('.')[0] || null
        return cleanedValue
    })

    return pending
}

export const filterCorruptedPendingSyncs = (pendingSyncs:lastDBSync[]) => {
    const pending = parsePendingSyncs(pendingSyncs)
    return pendingSyncs.filter((i,index)=>pending[index] !== SyncFileErrors.Corrupted)
}