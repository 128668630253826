import { useMemo } from "react";
import { apiCall, httpRequest, mutateApiCall } from "../../../../shared/axios/axios-config";
import { ONLINE_MODE } from "../../../../shared/config/appConfig";
import { useFeatures } from "../../../../shared/config/features/FeatureStatusContainer";
import { useDate } from "../../../../shared/date/useDate";
import {
  PrivateQuery,
  PrivateQueryDataTagFPInterface,
} from "../../../../shared/globals/utilsGlobalTypes";

import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import {
  queryClient,
  useMutation,
  useQuery,
} from "../../../../shared/react-query/react-query-conf";
import { ColectorCode } from "../../../../domains/collectors/models/CollectorInterfaces";
import { useCollectorsByTagFP } from "../../../../domains/collectors/services/collectorsServices";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { useToken } from "../../../../domains/session/store/sessionStore";
import {
  CollectorsSync,
  lastDBSync,
} from "../../../../domains/SyncBox/model/CollectorsSyncByTagFPType";
import { filterCorruptedPendingSyncs } from "../../../../domains/SyncBox/utiils/parsePendingSyncs";

const collectorsDependencies = ["PendingSyncs", "CollectorsSyncByTagFP", "InstrumentResetDatabase"];

export const invalidateCollectorsData = () => {
  collectorsDependencies.forEach((q) => {
    queryClient.invalidateQueries(q);
  });
};

const PendingSyncs = (token: string) => {
  return httpRequest({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/PendingSyncs`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

const CollectorsSyncByTagFP = (tagFP: string, token: string) => {
  return httpRequest({
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
    url: `/CollectorsSyncByTagFP`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

const InstrumentResetDatabase = ({ data, token, tagFP }: PrivateQueryDataTagFPInterface) => {
  return apiCall({
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
    method: "POST",
    url: `/InstrumentResetDatabase`,
    data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

const SyncCollectorDatabases = ({ data, token }: { token: string; data: { tagFP: string } }) => {
  return httpRequest({
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP: data.tagFP,
    },
    method: "GET",
    url: `/SyncCollectorDatabases`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

const GetIp = ({ token }: PrivateQuery) => {
  return mutateApiCall({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    url: `/GetActualIp`,
  });
};

// QUERIES ADAPTERS

export const useLocalDeviceData = () => {
  const { collectorFeature } = useFeatures();
  const token = useToken();

  const allowedQueries = [!ONLINE_MODE, !!collectorFeature.state, !!token].includes(false)
    ? false
    : true;

  return useQuery({
    queryKey: "localIp",
    enabled: allowedQueries,
    queryFn: () => GetIp({ token }),
    select: (data) => {
      return (
        data?.success && {
          hostname: data.success.split("/")[0],
          ip: data.success.split("/")[1],
        }
      );
    },
  });
};

export const useReadyForCollectorSync = () => {
  const token = useToken();
  const query = useMutation(() =>
    httpRequest({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      url: `/ReadyForCollectorSync`,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      })
  );

  const isReadyForCollectorSync = () => {
    return query.mutate();
  };

  const status = useStatusProcessor(query);

  return {
    isReadyForCollectorSync,
    status,
    data: query.data,
  };
};

export const usePendingSyncs = () => {
  const { collectorFeature } = useFeatures();
  const token = useToken();

  const query = useQuery<lastDBSync[]>({
    queryKey: "PendingSyncs",
    queryFn: () => PendingSyncs(token!),
    enabled: !!token && !ONLINE_MODE && !!collectorFeature.state,
  });

  const filtertedPEndingSyncs = useMemo(
    () => query.data && filterCorruptedPendingSyncs(query.data),
    [query.data]
  );

  return {
    ...query,
    data: filtertedPEndingSyncs,
  };
};

export const useCollectorsSyncByTagFP = (tagFP?: string) => {
  const { collectorFeature } = useFeatures();
  const token = useToken();
  return useQuery<CollectorsSync[]>({
    queryKey: "CollectorsSyncByTagFP",
    queryFn: () => CollectorsSyncByTagFP(tagFP!, token!),
    enabled: !!token && !ONLINE_MODE && !!collectorFeature.state && !!tagFP,
  });
};

export const useCollectorSyncStatus = (tagFP?: string) => {
  const { formatISODate } = useDate();
  const { data: localData } = useLocalDeviceData();

  const { data: AllPendingSyncs, isLoading: AllPendingSyncsIsLoading } = usePendingSyncs();
  const { data: AllcolectorsSync, isLoading: AllcolectorsSyncDataIsLoading } =
    useCollectorsSyncByTagFP(tagFP);

  const collectorsQuantity = AllcolectorsSync?.length;

  const AllcolectorsSyncData = !!localData
    ? AllcolectorsSync?.map((colector) => {
        return {
          ...colector,
          ip: localData?.ip,
        };
      })
    : undefined;

  const leftCollectorsSync = () => {
    return (
      AllcolectorsSyncData?.filter((collector) => {
        return collector.sync === false;
      }) || []
    );
  };

  const allReadySyncCollectors = () => {
    return (
      AllcolectorsSyncData?.filter((collector) => {
        return collector.sync === true;
      }) || []
    );
  };

  const collectorsSyncStatus = (): boolean | null | undefined => {
    //colectors from
    const collectors = allReadySyncCollectors();

    if (collectorsQuantity && collectors) {
      if (collectors?.length === 0) {
        //noCollectors went sync
        return null;
      } else if (collectors?.length < collectorsQuantity) {
        //colector lefts to sync
        return false;
      } else if (collectorsQuantity === collectors.length) {
        //all collectors syncs
        return true;
      }
    } else {
      return undefined;
    }
  };

  const collectorSyncDate = (colectorCode: ColectorCode, day?: boolean) => {
    const colector = AllcolectorsSyncData?.find((colector) => {
      return colector.collectorCode === colectorCode;
    });

    const exportDate = colector?.date
      ? day
        ? formatISODate(colector.date) // ? getViewDateDay(colector.date)
        : formatISODate(colector.date, true) // : getViewDateDayHour(colector.date)
      : "loading..";
    return exportDate;
  };

  const pendingColectorsSync = () => {
    const colectors = AllcolectorsSyncData?.filter((colector) => {
      return AllPendingSyncs?.includes(colector.lastDBSync);
    });
    return colectors;
  };

  const isLoading = [AllPendingSyncsIsLoading, AllcolectorsSyncDataIsLoading].includes(true);

  return {
    AllcolectorsSyncData,
    AllPendingSyncs,
    isLoading,
    collectorsSyncStatus,
    leftCollectorsSync,
    collectorSyncDate,
    pendingColectorsSync,
  };
};

export const useSetSyncBoxDailySync = (validations?: boolean[]) => {
  const { token, plants, currentPlant, tagFP } = useSessionContext();

  //feature Dependency
  const { collectorFeature } = useFeatures();
  // const { isReadyForCollectorSync, status } = useReadyForCollectorSync();

  //date
  const { nowDay, nowMonth, nowYear } = useDate();

  const { data: Colectors, isLoading: colectorsIsLoading } = useCollectorsByTagFP(tagFP);
  const { collectorSyncDate, isLoading: colectorSyncStatusIsLoading } = useCollectorSyncStatus(
    currentPlant?.tagFP!
  );

  //Last sync date
  const colectorDate = Colectors && collectorSyncDate(Colectors[0]?.collectorCode, true); //.split('/').join('-')

  //Calc dependencies
  const allowed = [
    ...(validations ? validations : []),
    !!token,
    !!Colectors,
    !!colectorDate,
    !(`${nowYear}-${nowMonth}-${nowDay}` === colectorDate),
    !!collectorFeature.state,
    !ONLINE_MODE,
    !!currentPlant?.tagFP,
  ].includes(false)
    ? false
    : true;

  //query Syncroization
  const query = useQuery({
    queryKey: "InstrumentResetDatabase",
    enabled: !!allowed,
    queryFn: () =>
      InstrumentResetDatabase({
        data: { updateDB: false },
        tagFP,
        token,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries("PendingSyncs");
      // isReadyForCollectorSync();
    },
    onError: () => {
      queryClient.invalidateQueries("PendingSyncs");
    },
  });

  //calc Multiple Sync status
  const isLoading = [
    plants.length === 0,
    colectorSyncStatusIsLoading,
    colectorsIsLoading,
    query.isLoading,
  ].includes(true);

  const {
    updateSyncDataBase,
    status: updateSyncDataBaseStatus,
    message,
    context,
  } = useUpdateSyncDatabase();

  return {
    ...query,
    // collectorSyncStatus: status,
    isLoading,
    updateSyncDataBase,
    updateResult: message,
    updateSyncDataBaseStatus,
    updateTime: context?.date,
  };
};

export const useSyncCollectorDatabases = (tagFP?: string) => {
  const token = useToken();

  const query = useMutation(SyncCollectorDatabases, {
    onSuccess: invalidateCollectorsData,
  });

  const status = useStatusProcessor(query);

  const syncCollectorsData = () => {
    token &&
      tagFP &&
      query.mutate({
        token: token,
        data: {
          tagFP,
        },
      });
  };

  return {
    ...status,
    syncCollectorsData,
  };
};

export const useUpdateSyncDatabase = () => {
  const { token, tagFP } = useSessionContext();
  const { formatISODate } = useDate();
  // const { isReadyForCollectorSync } = useReadyForCollectorSync();
  const query = useMutation(InstrumentResetDatabase, {
    onMutate: () => ({ date: formatISODate(new Date().toISOString(), true) }),
    onSuccess: () => {
      queryClient.invalidateQueries("PendingSyncs");
      // isReadyForCollectorSync();
    },
  });

  const status = useStatusProcessor(query);

  const updateSyncDataBase = () => {
    return query.isLoading
      ? () => {}
      : query.mutateAsync({
          data: { updateDB: true },
          tagFP,
          token,
        });
  };

  return {
    updateSyncDataBase,
    ...query,
    ...status,
  };
};
