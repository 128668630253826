import { CircularProgress } from "@material-ui/core";
import { CustomIcon } from "../../../../components/buttons/CustomIcon";
import { objectMapper } from "../../../../shared/globals/utils/objectMapper";
import { CriticalityInterface } from "../../../criticalities/models/criticalityInterface";
import { usePlantCriticality } from "../../../criticalities/services/service.criticality";

const CustomCriticalityField = (criticality: string | undefined) => {
  const { data: criticalities } = usePlantCriticality();
  const criticalitiesMap = objectMapper(criticalities, "description");

  const color = criticalitiesMap && criticality && criticalitiesMap[criticality]?.color;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <div style={{ width: "16px", height: "16px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {color ? <CustomIcon icon="state" style={{ color }} /> : <CircularProgress size={16} />}
      </div>

      {criticality}
    </div>
  );
};

export default CustomCriticalityField;
