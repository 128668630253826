import { useState } from "react";
import { useUser } from "../../domains/session/store/sessionStore";

type CallBack<T> = (value: T) => T;
type SetValue<T> = (value: T | CallBack<T>) => void;
type UseBasicLocalStorage<T> = [T, SetValue<T>];

interface BasicLocalStorageHookProps<T> {
  key: string; // The key under which the value will be stored in localStorage
  initialValue: T; // The initial value if nothing is stored
  storeLocal?: boolean; // Indicates whether to store in localStorage or not (default value: true)
  storeWithEmail?: boolean;
  getStoredValueMiddleware?: (value: any) => any;
}

const useBasicLocalStorage = <T extends any>({
  key,
  initialValue,
  storeLocal = true,
  storeWithEmail = true,
  getStoredValueMiddleware = (value) => value,
}: BasicLocalStorageHookProps<T>): UseBasicLocalStorage<T> => {
  const { data: user } = useUser();

  const lastUserString = localStorage.getItem("last-user");
  const lastUser = lastUserString ? JSON.parse(lastUserString) : null;

  const userEmail = user?.email ?? lastUser?.email;

  const cacheKey = storeLocal ? (storeWithEmail ? `${userEmail}-${key}` : `${key}`) : null;

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (cacheKey) {
        const storedItem = localStorage.getItem(cacheKey);
        const parsedItem = storedItem
          ? getStoredValueMiddleware(JSON.parse(storedItem))
          : initialValue;
        return parsedItem;
      }
      return initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  /*
  This function checks and removes the key from Local Storage if its value is:
  - An empty object
  - An empty array
  - An empty text string
  - An object with all attributes being falsy
  */
  const clearKeyIfEmpty = () => {
    if (cacheKey) {
      const storedItem = localStorage.getItem(cacheKey);
      const parsedItem = storedItem ? JSON.parse(storedItem) : null;
      if (parsedItem) {
        if (
          (Array.isArray(parsedItem) && parsedItem.length === 0) ||
          (typeof parsedItem === "object" && Object.keys(parsedItem).length === 0) ||
          Object.values(parsedItem).every((value) => value === false) ||
          (typeof parsedItem === "string" && parsedItem === "")
        ) {
          localStorage.removeItem(cacheKey);
        }
      }
    }
  };

  // Function to set the value, which can be a direct value or a transformation function
  const setValue: SetValue<T> = (value) => {
    try {
      const newValue =
        typeof value === "function"
          ? (value as CallBack<T>)(storedValue) // If it's a function, apply the transformation
          : value;

      setStoredValue(newValue);

      if (cacheKey) {
        localStorage.setItem(cacheKey, JSON.stringify(newValue));
        clearKeyIfEmpty();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export default useBasicLocalStorage;
