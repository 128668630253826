import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import { Divider } from '../../../Divider';

export const TabContainer = styled('div')`
  display: flex;
  /* width: 100%; */
  /* background: red; */
  /* align-items: stretch; */
  margin-bottom:-2px;
`;


const Tabs:React.FC<{onTabSelected:(item:number)=>void}> = ({ children, onTabSelected }) => {
    const [itemId, setItemId] = useState(0);
  
    useEffect(() => {
      onTabSelected && onTabSelected(itemId);
    }, [itemId]);
  
    return (<>
      <TabContainer>
        {React.Children.map(children, (child:any, index:number) => {
          return React.cloneElement(child, {
            onClick: () => {
              setItemId(index);
            },
            selected: itemId === index
          });
        })}
      </TabContainer>
      <Divider/>
      </>
    );
  };
  
  
  
  export default Tabs;
