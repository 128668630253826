import { useEffect, useMemo, useState } from "react";
import { QueryStatus, UseMutationResult, UseQueryResult } from "react-query";
import { useLangLabels } from "../lang/services/useLangLabels";

export interface statusObject<TResult, TError> {
  reset: () => void;
  status: QueryStatus;
  message: string | undefined;
  error: TError | null;
  dataStatus: TResult | null;
}

export const useStatusProcessor = <TResult, TError, TVariables, TContext>(
  queryResult:
    | UseMutationResult<TResult, TError, TVariables, TContext>
    | UseQueryResult<TResult, TError>
): statusObject<TResult, TError> => {
  const { lang } = useLangLabels();
  const [status, setStatus] = useState<QueryStatus>("idle");
  const [message, setMessage] = useState<string | undefined>();

  const serverResult: any = useMemo(() => queryResult?.data, [queryResult?.data]);

  useEffect(() => {
    if (queryResult.isLoading) {
      setStatus("loading");
      setMessage(undefined);
    }
    if (queryResult.isError) {
      //error Message
      setStatus("success");
      setMessage(
        serverResult?.error || `${lang.messages.anErrorHasOccurred}: ${queryResult?.error}`
      );
    }
    if (queryResult.isSuccess) {
      //success Message
      if (typeof serverResult?.error === "string") {
        setStatus("error");
        setMessage(serverResult?.error);
        return;
      }
      if (typeof serverResult?.success === "string") {
        setStatus("success");
        setMessage(serverResult?.success);
        return;
      } else {
        setStatus("success");
        setMessage(`${lang.messages.status.success}`);
        return;
      }
    }
  }, [
    queryResult.data,
    queryResult.error,
    queryResult.isError,
    queryResult.isLoading,
    queryResult.isSuccess,
    serverResult,
  ]);

  const reset = () => {
    setStatus("idle");
    setMessage(undefined);
  };

  return useMemo(
    () => ({
      reset,
      status,
      message,
      error: queryResult?.error,
      dataStatus: queryResult?.data || null,
    }),
    [message, queryResult?.data, queryResult?.error, status]
  );
};
