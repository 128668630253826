import { Typography } from "@material-ui/core";
import FlexContainer from "../../../../components/containers/FlexContainer";
import PageBodyContentInfo from "./PageBodyContentInfo";
import { lang } from "moment";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface PageBodyContentProps {
  pageIndex: number;
  otherProps?: Record<string, any>;
}

const PageBodyContent = ({ pageIndex, otherProps }: PageBodyContentProps) => {
  const { lang } = useLangLabels();
  const { containerNumber, sector, numberOfObservations } = otherProps || {};

  return (
    <>
      {!containerNumber && containerNumber === 0 && pageIndex === 0 && (
        <PageBodyContentInfo otherProps={otherProps} />
      )}
      {pageIndex === 0 && (
        <FlexContainer
          justify="space-between"
          padding={"24px 0 8px 0"}
          style={{
            borderBottom: "2px solid #D9D9D9",
          }}
        >
          <Typography variant="h2" style={{ fontSize: "15px", fontWeight: "bold" }}>
            {sector}
          </Typography>
          <Typography variant="body2" style={{ fontSize: "12px" }}>
            <span style={{ fontWeight: "bolder" }}>{lang.observations.observationsQuantity}:</span>{" "}
            {numberOfObservations}
          </Typography>
        </FlexContainer>
      )}
    </>
  );
};

export default PageBodyContent;
