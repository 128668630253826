import { FormDataResult, FormFields } from "../types/FormTypes";

/**
 * Constructs an object representing the current form data based on the fields state.
 * @param formFields - The form fields containing the current values.
 * @returns {T} An object with field names as keys and their current values as values.
 */
export const parseFormFieldsToData = <T,>(formFields: FormFields<T>): FormDataResult<T> => {
  // Use Object.keys and reduce to iterate through form fields
  return Object.keys(formFields).reduce((data, fieldKey) => {
    const fieldName = fieldKey as Extract<keyof T, string>;
    const field = formFields[fieldName];
    return {
      ...data,
      [fieldName]: field?.value,
    };
  }, {} as T);
};

/**
 * Updates a field with a specific error message and maintains global error tracking.
 * @param fields - The current form fields.
 * @param fieldKey - The key of the field to be updated.
 * @param errors - The current errors object.
 * @param errorMessage - The error message to be applied.
 * @returns An object with updated form fields and errors.
 */
const updateResultAndErrors = <T,>(
  fields: FormFields<T>,
  fieldKey: keyof T,
  errors: Record<keyof T, string> | {},
  errorMessage: string
) => {
  return {
    updatedFields: {
      ...fields,
      [fieldKey]: {
        ...fields[fieldKey],
        error: errorMessage,
      },
    },
    updatedErrors: {
      ...errors,
      [fieldKey]: errorMessage,
    },
  };
};

/**
 * Validates form fields based on various criteria and returns an object
 * representing validation errors for each field.
 * @param fields - The current form fields to be validated.
 * @returns An object with field names as keys and their validation errors as values.
 */
export const validateFields = <T,>(fields: FormFields<T>) => {
  let errors = {};

  for (const fieldKey in fields) {
    const currentField = fields[fieldKey];
    const fieldValue = currentField?.value;

    // Messages for different validations
    const requiredMessage = currentField?.validations?.required?.message || "";
    const patternMessage = currentField?.validations?.pattern?.message || "";
    const maxMessage = currentField?.validations?.max?.message || "";
    const minMessage = currentField?.validations?.min?.message || "";

    // Required Validation
    if (currentField?.validations?.required?.value) {
      const isValueEmpty =
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === "" ||
        (Array.isArray(fieldValue) && fieldValue.length === 0) ||
        (typeof fieldValue === "object" && Object.keys(fieldValue).length === 0);

      if (isValueEmpty) {
        const { updatedFields, updatedErrors } = updateResultAndErrors(
          fields,
          fieldKey,
          errors,
          requiredMessage
        );

        fields = updatedFields;
        errors = updatedErrors;
      }
    }

    // Min Validation
    if (currentField?.validations?.min) {
      const minValue = currentField?.validations.min.value;
      const isLowerValue =
        (typeof fieldValue === "string" && fieldValue.length < minValue) ||
        (typeof fieldValue === "number" && fieldValue < minValue);

      if (isLowerValue) {
        const { updatedFields, updatedErrors } = updateResultAndErrors(
          fields,
          fieldKey,
          errors,
          minMessage
        );

        fields = updatedFields;
        errors = updatedErrors;
      }
    }

    // Max Validation
    if (currentField?.validations?.max) {
      const maxValue = currentField?.validations.max.value;
      const isHigherValue =
        (typeof fieldValue === "string" && fieldValue.length > maxValue) ||
        (typeof fieldValue === "number" && fieldValue > maxValue);

      if (isHigherValue) {
        const { updatedFields, updatedErrors } = updateResultAndErrors(
          fields,
          fieldKey,
          errors,
          maxMessage
        );

        fields = updatedFields;
        errors = updatedErrors;
      }
    }

    // Pattern Validation
    if (currentField?.validations?.pattern && typeof fieldValue === "string") {
      const doesNotMatchPattern = !fieldValue.match(currentField?.validations.pattern.value);

      if (doesNotMatchPattern) {
        const { updatedFields, updatedErrors } = updateResultAndErrors(
          fields,
          fieldKey,
          errors,
          patternMessage
        );

        fields = updatedFields;
        errors = updatedErrors;
      }
    }

    // Custom Validation
    if (currentField?.validations?.validate) {
      const validationValue = currentField?.validations.validate(fieldValue);

      if (validationValue !== true) {
        const { updatedFields, updatedErrors } = updateResultAndErrors(
          fields,
          fieldKey,
          errors,
          validationValue
        );

        fields = updatedFields;
        errors = updatedErrors;
      }
    }
  }

  return errors;
};
