import styled from "@emotion/styled";
import React from "react";
import theme from "../../../../assets/theme";

const selectedColor = theme?.palette?.secondary?.main;
const defaultColor = "rgba(0,0,0,0.01)";

export const TabItem = styled<any>('div')`
    font-size:14px;
    text-transform:uppercase;
    color:rgba(0,0,0,0.6);
    width: auto;
    padding: 12px 16px;
    cursor: pointer;
    transition: 0.4s;
    border-bottom: 2px solid ${(props) => (props.selected ? selectedColor : defaultColor)};
`;

const TabOption = React.memo(({ children, ...restProps }) => (
    <TabItem {...restProps}>{children}</TabItem>
));

export default TabOption