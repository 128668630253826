import React from 'react';
import { useUser } from '../../domains/session/store/sessionStore';
import { filterAccessTo } from '../../shared/globals/utils/filterAccessTo';
import RouterTabs, { TabsInterface, useRouteTabProps } from '../../components/containers/tabs/RouterTabs';
import AddClientRefactor from '../../domains/person/components/AddClient';
import { Typography } from '@material-ui/core';
import DemoUsersPage from './DemoUsersPage';
import AddOperatorRefactor from '../../domains/person/components/AddOperator';
import AddSupervisorrRefactor from '../../domains/person/components/AddSupervisorPage';
import AddAccounManager from '../../domains/person/components/AddAccountManager';
import { USER_ROUTES } from '../../shared/routes/routes';
import AddAdminPageRefactor from '../../domains/person/components/AddAdminPageRefactor';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';


const AddUser:React.FC = () => {

  const {data:user} = useUser()

  const {lang} = useLangLabels()

  const tabs:TabsInterface<number>[] = [
    {
      label:lang.persons.newAdministrator,
      route:USER_ROUTES.administrator,
      component:<AddAdminPageRefactor/>,
      data:110,
    },
    {
      label:lang.persons.newAccountManager,
      route:USER_ROUTES.accountManager,
      component:<AddAccounManager/> ,
      data:110,
    },
    {
      label:lang.persons.newClient,
      route:USER_ROUTES.client, 
      component:<AddClientRefactor/>,
      data:160,
    },
    {
      label:lang.demo.prospects,
      route:USER_ROUTES.demoUsers,
      component:<DemoUsersPage />,
      data:163,
    },
    {
      label:lang.persons.newSupervisor,
      route:USER_ROUTES.supervisor,
      component:<AddSupervisorrRefactor/>,
      data:160,
    },
    {
      label:lang.persons.newOperator,
      route:'',
      component:<AddOperatorRefactor />,
      data:163,
    },
  ]

  const userTabs = filterAccessTo(user?.type!,tabs,'data')

  return (
    <>
      <Typography variant='h6'>
        {lang.persons.userAdministration}
      </Typography>
      <RouterTabs {...useRouteTabProps(4,userTabs,{
        variant:'chip'
      })} />
    </>            
  );
}

export default AddUser