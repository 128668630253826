
import { makeStyles } from '@material-ui/core';


export const useTgdDrawerStyles = makeStyles((theme) => ({
    drawer: {
        width: (props:any)=>props.drawerWidth,
        flexShrink: 0,
        zIndex:1300
    },
    drawerPaper:{
        width: (props:any)=>props.drawerWidth,
        color:(props:any)=>props.color || '#f3f3f3',
        border:'none',
        backgroundColor:(props:any)=>props.backgroundColor,
        backgroundImage:(props:any)=>props.backgroundImg ? `url(${props.backgroundImg})` : undefined,
        backgroundSize:(props:any)=>props.backgroundImg ? 'cover' : undefined,
        backgroundPosition: (props:any)=>props.backgroundImg ? 'center' : undefined,
        overflow: (props:any)=>props.backgroundImg ? 'hidden' : undefined
    },
}))