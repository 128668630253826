import React from "react";
import { Paper, Typography } from "@material-ui/core";

import FlexContainer from "../../../components/containers/FlexContainer";
import PageContainer from "../../../components/containers/PageContainer";
import CollectorQrCode from "./CollectorQrCode";
import theme from "../../../assets/theme";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CollectorInterface } from "../models/CollectorInterfaces";
import ComponentMode from "../../../components/componentMode/ComponentMode";

const ViewQrCode: React.FC<{ item: CollectorInterface & { ip: string } }> = ({ item }) => {
  const { lang } = useLangLabels();

  return (
    <ComponentMode
      icon="view"
      mode="popover"
      variant="iconButton"
      popoverTitle={lang.collectors.qrCode}
    >
      <FlexContainer flexDirection="column" width={"100%"}>
        <FlexContainer padding={`${theme.spacing(3)}px`} align="center" justify={"center"}>
          <Paper elevation={3}>
            <PageContainer>
              <CollectorQrCode colector={item} />
            </PageContainer>
          </Paper>
          <FlexContainer padding={`${theme.spacing(3)}`} align="center">
            <FlexContainer flexDirection="column" padding={`${theme.spacing(3)}`}>
              <Typography>
                <strong>{lang.collectors.form.id}:{" "}</strong>
                {item.id}
              </Typography>
              <Typography>
                <strong>{lang.collectors.form.name}:{" "}</strong>
                {item.name}
              </Typography>
              <Typography>
                <strong>{lang.collectors.collectorCode}:{" "}</strong>
                {item.collectorCode}
              </Typography>
              <Typography>
                <strong>{lang.collectors.form.serialNumber}:{" "}</strong>
                {item.serialNumber}
              </Typography>
              <Typography>
                <strong>{lang.collectors.form.state}:{" "}</strong>
                {item.state ? lang.words.active.toUpperCase() : lang.words.inactive.toUpperCase()}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </ComponentMode>
  );
};

export default ViewQrCode;
