import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AssignCancelPermission from "./AssignCancelPermission";
import { usePermisionsByEquipment, usePermissionsByTagFP } from "../services/service.permission";
import ModalButton from "../../../components/buttons/ModalButton";
import { PermissionInterface } from "../model/PermissionInterface";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { useLubricationPointByTagTGD } from "../../lubricationPoints/services/lubricationPointsAdapters";
import TgdTable, { TgdTableInterface } from "../../../components/grids/TgdTable";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  item: LubricationPointInterface;
}

const useLubricationPointWithPermissions = (tagTGD: string) => {
  const lubricationPoint = useLubricationPointByTagTGD(tagTGD);
  const permissions = usePermisionsByEquipment(tagTGD);

  const lubricationPointWithPermissions = {
    ...lubricationPoint,
    status: permissions.status,
    data: {
      ...lubricationPoint.data,
      permissions: permissions.data?.map((permission) => permission.permissionName),
    },
  };

  return lubricationPointWithPermissions;
};

const AssignCancelPermissionForm: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();
  const { data: permissions, status, error } = usePermissionsByTagFP();
  const { data: LubricationPoint } = useLubricationPointWithPermissions(item.tagTGD);

  const columns: TgdTableInterface[] = [
    { label: lang.globalentityValues.id, atribute: "id" },
    { label: lang.permissions.permissionName, atribute: "permissionName" },
  ];

  return (
    <ModalButton
      variant="chip"
      popoverTitle={lang.permissions.manageEquipmentPermissions}
      icon="config"
      buttonText={lang.permissions.plural}
      title={`${lang.permissions.assignToEquipment}: ${LubricationPoint?.plantTag}`}
    >
      <>
        {error && (
          <>
            <Alert severity="error"> {lang.messages.anErrorHasOccurred} </Alert>
            <br />
          </>
        )}
        {status === "loading" ? (
          <CircularProgress size="small" />
        ) : (
          <TgdTable
            title={lang.permissions.plural}
            items={permissions}
            columns={columns}
            type={"permission"}
            itemActions={(permission: PermissionInterface) => (
              <AssignCancelPermission equipment={LubricationPoint} permission={permission} />
            )}
          />
        )}
      </>
    </ModalButton>
  );
};

export default React.memo(AssignCancelPermissionForm);
