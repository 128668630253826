import React, { useEffect } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDeletePermissionVerification } from "./services/service.permission";
import TgdPopover from "../../components/popovers/TgdPopover";

import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { PermissionInterface } from "./model/PermissionInterface";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface Props {
  item: PermissionInterface;
}

const DeletePermissionForm: React.FC<Props> = ({ item }) => {
  const { validate, errors, query, status: validationStatus } = useDeletePermissionVerification();
  const { position, getClickPosition } = useClickPosition();
  const { status, message } = useStatusProcessor(query);
  const { setData } = useSnackbar();

  const { lang } = useLangLabels();

  useEffect(() => {
    if (validationStatus === "error") {
      const permissionsErrors = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";
      setData({
        modal: true,
        errors: `${lang.permissions.permissionCannotBeRemoved}: ${permissionsErrors}`,
        severity: "warning",
        position: position,
      });
    }

    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position: position,
      });
    }
  }, [validationStatus, status, errors, message]);

  return status === "loading" ? (
    <CircularProgress size="20px" />
  ) : (
    <TgdPopover mode="hover" title={lang.crudActions.delete + " " + lang.permissions.singular}>
      <IconButton size="small" onClick={(e: any) => getClickPosition(e, () => validate(item))}>
        <DeleteIcon />
      </IconButton>
    </TgdPopover>
  );
};

export default React.memo(DeletePermissionForm);
