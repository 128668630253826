import React, { useMemo } from "react";
import { capitalize, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AssignCancelSupply from "./AssignCancelSupply";
import { useAllSupplies } from "../services/service.supplies";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useLubricationPointByTagTGD } from "../../lubricationPoints/services/lubricationPointsAdapters";
import { SupplyInterface, SupplyType } from "../model/SupplyInterface";
import ComponentMode from "../../../components/componentMode/ComponentMode";
import { supplyParser } from "../utils/supplyParser";
import TgdTable from "../../../components/grids/TgdTable";

interface Props {
  item?: LubricationPointInterface;
  type: SupplyType;
}

const AssignCancelSupplyPage: React.FC<Props> = ({ item, type }) => {
  const { lang } = useLangLabels();
  const { data: allSupplies, status } = useAllSupplies();
  const filteredSupplies = allSupplies?.filter((item) => item.type === type);

  const { data: lubricationPoint } = useLubricationPointByTagTGD(item?.tagTGD);

  const { tools, consumables, securityItems } = useMemo(
    () => supplyParser(lubricationPoint?.supplies),
    [lubricationPoint?.supplies]
  );

  const title = lang.supplies[type].title.plural;

  const componentSupplies =
    (type === "CONSUMIBLE" && consumables) ||
    (type === "HERRAMIENTA" && tools) ||
    (type === "PROTECCION" && securityItems) ||
    [];

  const columns = [
    { label: lang.globalentityValues.id, atribute: "id" },
    { label: lang.supplies[type].form.label.type, atribute: "type" },
    { label: lang.supplies[type].form.label.supply, atribute: "supply" },
    { label: lang.supplies[type].form.label.description, atribute: "description" },
  ];

  const assignSuppliesGridModel = {
    id: { label: lang.globalentityValues.id },
    type: { label: lang.supplies[type].form.label.type },
    supply: { label: lang.supplies[type].form.label.supply },
    description: { label: lang.supplies[type].form.label.description },
  };

  return (
    <ComponentMode
      popoverTitle={`${lang.supplies[type].manageLubricationPointSupply}: ${lubricationPoint?.plantTag}`}
      icon="config"
      variant="chip"
      buttonTittle={title}
      modalTitle={false}
    >
      {status === "error" && (
        <>
          <Alert severity="error"> {lang.supplies.errorGetingSupplies} </Alert>
          <br />
        </>
      )}
      {status === "loading" ? (
        <CircularProgress size="small" />
      ) : (
        <TgdTable
          gridModel={assignSuppliesGridModel}
          headerTitle={`${lang.supplies[type].manageLubricationPointSupply}: ${lubricationPoint?.plantTag}`}
          title={title}
          items={filteredSupplies}
          columns={columns}
          type={"supply"}
          itemActions={(supply: SupplyInterface) => (
            <AssignCancelSupply
              equipment={{ ...lubricationPoint, supplies: componentSupplies }}
              supply={supply}
            />
          )}
        />
      )}
    </ComponentMode>
  );
};

export default React.memo(AssignCancelSupplyPage);
