export enum CrudFormType {
  create = "create",
  delete = "delete",
  update = "update",
}

export enum InputComponentTypes {
  area = "area",
  color = "color",
  component = "component",
  criticality = "criticality",
  criticalityType = "criticalityType",
  date = "date",
  dateTime = "dateTime",
  description = "description",
  element = "element",
  lubricant = "lubricant",
  measureUnit = "measureUnit",
  none = "none",
  number = "number",
  observation = "observation",
  oilAnalysis = "oilAnalysis",
  operator = "operator",
  periodicity = "periodicity",
  reason = "reason",
  referrer = "referrer",
  review = "review",
  sector = "sector",
  selectWithOptions = "selectWithOptions",
  suggestedQuantity = "suggestedQuantity",
  supply = "supply",
  supplyType = "SupplyType",
  text = "text",
  typeBy = "typeBy",
  types = "types",
  instrumentBrand = "instrumentBrand",
  instrumentModel = "instrumentModel",
  instrumentPLC = "instrumentPLC",
  instrumentType = "instrumentType",
}

// Form Validation

type FormValueRequired = {
  value: boolean;
  message: string;
};

type FormValuePattern = {
  value: RegExp;
  message: string;
};

type FormValueValidate = (formValue?: any) => true | string;

type FormValueMaxMin = {
  value: number;
  message: string;
};

export type FormErrors<T> = {
  [k in keyof T]?: string;
};

export type FormValidations = {
  required?: FormValueRequired;
  pattern?: FormValuePattern;
  validate?: FormValueValidate;
  min?: FormValueMaxMin;
  max?: FormValueMaxMin;
};

// Form Data

export interface BaseFormField<T> {
  name?: T;
  value: any;
  label?: string;
  placeholder?: string;
  helper?: string; // VERIFICAR SI SE USA
  disabled?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  validations?: FormValidations;
  error?: string;
}

interface InputConfig {
  tagFP: string;
  tagTGD?: string;
  complete?: boolean; // VER SI SE USA Y PARA QUÉ
  by?: {
    value: string;
    key: string;
  };
}

type DefaultInputFormFieldComponents =
  | InputComponentTypes.text
  | InputComponentTypes.number
  | InputComponentTypes.date
  | InputComponentTypes.dateTime;

type InputFormFieldComponents =
  | InputComponentTypes.color
  | InputComponentTypes.criticalityType
  | InputComponentTypes.types
  | InputComponentTypes.supplyType
  | InputComponentTypes.periodicity
  | InputComponentTypes.oilAnalysis
  | InputComponentTypes.suggestedQuantity
  | InputComponentTypes.instrumentType;  

type InputFormFieldWithConfigComponents =
  | InputComponentTypes.lubricant
  | InputComponentTypes.criticality
  | InputComponentTypes.typeBy
  | InputComponentTypes.sector
  | InputComponentTypes.area
  | InputComponentTypes.component
  | InputComponentTypes.supply
  | InputComponentTypes.operator
  | InputComponentTypes.element
  | InputComponentTypes.component
  | InputComponentTypes.reason
  | InputComponentTypes.review
  | InputComponentTypes.observation
  | InputComponentTypes.referrer
  | InputComponentTypes.instrumentBrand
  | InputComponentTypes.instrumentModel
  | InputComponentTypes.instrumentPLC

interface DefaultInputFormField<T> extends BaseFormField<T> {
  component: DefaultInputFormFieldComponents;
}

interface InputFormField<T> extends BaseFormField<T> {
  label: string;
  component: InputFormFieldComponents;
}

interface InputFormFieldWithConfig<T> extends BaseFormField<T> {
  component: InputFormFieldWithConfigComponents;
  inputConfig: InputConfig;
}

interface SelectOption {
  label: string;
  value: string | number | boolean;
}

interface SelectWithOptionsFormField<T> extends BaseFormField<T> {
  label: string;
  component: InputComponentTypes.selectWithOptions;
  options: SelectOption[];
}

interface DescriptionFormField<T> extends BaseFormField<T> {
  component: InputComponentTypes.description;
  rows: number;
}

export type FormField<T> =
  | { value?: any; component: InputComponentTypes.none; validations?: FormValidations }
  | (BaseFormField<T> & { component: InputComponentTypes.none })
  | DefaultInputFormField<T>
  | InputFormField<T>
  | InputFormFieldWithConfig<T>
  | SelectWithOptionsFormField<T>
  | DescriptionFormField<T>;

export type FormFields<T> = {
  [k in keyof T]?: FormField<k>;
};

// useForm

export type FormDataResult<T> = T;

type onSuccesCallback<T> = (formData: T) => void;
type onErrorCallback<T> = (formErrors: FormErrors<T>) => void;

export type FormConfigCallbacks<T> = [onSuccesCallback<T>, onErrorCallback<T> | undefined];

export type FormInitialState<T> = {
  fields: FormFields<T>;
  errors: FormErrors<T>;
  callbacks: {
    callbackSuccess: onSuccesCallback<T>;
    callbackError: onErrorCallback<T>;
  };
};

export type FormReducerType<T> = (
  state: FormInitialState<T>,
  action: FORM_ACTION_TYPES<T>
) => FormInitialState<T>;

export type UIModelInterface<T> = FormField<T> & {
  onChange: (value?: any) => void;
};

export type FORM_ACTION_TYPES<T> =
  | {
      type: "SET_VALUE";
      payload: {
        key: keyof T;
        value: T[keyof T];
      };
    }
  | {
      type: "SET_VALIDATIONS";
      payload: {
        key: keyof T;
        validationName: keyof FormValidations;
        validationValue: any;
      };
    }
  | {
      type: "SUBMIT_FORM";
      payload: FormConfigCallbacks<T>;
    }
  | {
      type: "RESET_ALL";
      payload: FormInitialState<T>;
    };
