import { Divider, Paper, Typography } from "@material-ui/core";
import { PageContainer, RouterTabs } from "../../../../components/containers";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { CustomIcon } from "../../../../components/buttons/CustomIcon";
import { TabsInterface, useRouteTabProps } from "../../../../components/containers/tabs/RouterTabs";
import { ManageBrandPage } from "./ManageBrandPage";
import { USER_ROUTES } from "../../../../shared/routes/routes";
import { ManageModelPage } from "./ManageModelPage";
import { ManagePLCPage } from "./ManagePLCPage";
import { ManageInstrumentTypesPage } from "./ManageInstrumentTypesPage";

export const ConfigurationPage = () => {
  const { lang } = useLangLabels();

  const fixedHeight = 14;

  const tabs: TabsInterface[] = [
    {
      label: lang.instruments.brands.title.plural,
      route: USER_ROUTES.instruments.brands,
      component: <ManageBrandPage fixedHeight={fixedHeight} />,
    },
    {
      label: lang.instruments.models.title.plural,
      route: USER_ROUTES.instruments.models,
      component: <ManageModelPage fixedHeight={fixedHeight} />,
    },
    {
      label: lang.instruments.plcs.title.plural,
      route: USER_ROUTES.instruments.plc,
      component: <ManagePLCPage fixedHeight={fixedHeight} />,
    },
    {
      label: lang.types.plural,
      route: USER_ROUTES.plantManagement.types,
      component: <ManageInstrumentTypesPage fixedHeight={fixedHeight} />,
    },
  ];

  const tabProps = useRouteTabProps(4, tabs, {
    orientation: "vertical",
  });

  return (
    <Paper elevation={3}>
      <PageContainer padding={"26px 24px 27px 24px"}>
        <Typography variant="h5">
          <CustomIcon icon="config" style={{ margin: "0px 10px -5px 0" }} />
          {lang.instruments.configuration}
        </Typography>
      </PageContainer>
      <Divider />

      <RouterTabs {...tabProps} />
    </Paper>
  );
};
