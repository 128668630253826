import { useLayoutEffect, useState } from "react";


function createWrapperAndAppendToBody(key:string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute("id", key);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

export const usePortal = (key:string) => {
  const [wrapperElement, setWrapperElement] = useState<any>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(key);
    let systemCreated = false;
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(key);
    }
    
    element && setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (systemCreated && element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    }
  }, [key]);

  return wrapperElement
}