import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useLabelReplacementGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    tagTGD: { label: lang.globalentityValues.tagTGD },
    sector: { label: lang.lubricationPoints.sector },
    equipment: { label: lang.lubricationPoints.equipment },
    element: { label: lang.lubricationPoints.element },
    component: { label: lang.lubricationPoints.component },
    labelId: { label: lang.lubricationPoints.labelId },
  };

  return gridModel;
};
