import { createTheme, ThemeOptions } from "@material-ui/core"

const themeOptions:ThemeOptions = {
    palette:{
        primary:{
            main: '#DA291C',//Rojo
            light:'#fff'
        },
        secondary:{
            main: '#002F6C',//azul
            
        },
        info:{
            main:'#D98918',//dorado
            light:'#06D5FF' //celeste
        },
        success:{
            main:'#4caf50'
        },
        action:{
            selected: '#ED8B00',
            hover: 'rgba(255, 255, 255, 0.6)',
            disabledBackground:'rgba(255, 255, 255, 0.6)',
        },
        background:{
            paper:'#fff',
            default:'#f3f3f3'
        },
    }
}

const theme = createTheme(themeOptions)

export default theme