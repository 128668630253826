import { useToken } from "../../../domains/session/store/sessionStore";
import { useStatusProcessor } from "../../queries/StatusProcessor";
import { queryClient, useMutation } from "../../react-query/react-query-conf";
import { CrudFormType } from "../types/FormTypes";
import { AxiosCallType, EndpointType } from "../types/CRUDTypes";

export const useCreateUpdate = <T,>(
  axiosCall: AxiosCallType,
  actionType: CrudFormType,
  updateDependencies?: EndpointType[]
) => {
  const token = useToken();

  // Create --------
  const createQuery = useMutation(axiosCall.create, {
    onSuccess: () => {
      updateDependencies?.forEach((d) => {
        queryClient.invalidateQueries(d);
      });
    },
  });
  const createStatus = useStatusProcessor(createQuery);
  const create = (data: T) => {
    if (token)
      return createQuery.mutate({
        token,
        data,
      });
  };

  // Update --------
  const updateQuery = useMutation(axiosCall.update, {
    onSuccess: () => {
      updateDependencies?.forEach((d) => {
        queryClient.invalidateQueries(d);
      });
    },
  });

  const updateStatus = useStatusProcessor(updateQuery);

  const update = (data: T) => {
    if (token)
      return updateQuery.mutate({
        token,
        data,
      });
  };

  // Delete --------
  const deleteQuery = useMutation(axiosCall.delete, {
    onSuccess: () => {
      updateDependencies?.forEach((d) => {
        queryClient.invalidateQueries(d);
      });
    },
  });

  const deleteStatus = useStatusProcessor(deleteQuery);

  const deleteItem = (data: T) => {
    if (token)
      return deleteQuery.mutate({
        token,
        data,
      });
  };

  return (() => {
    switch (actionType) {
      case CrudFormType.create:
        return {
          ...createQuery,
          status: createStatus.status,
          error: createStatus.error,
          message: createStatus.message,
          resetStatus: createStatus.reset,
          action: create,
        };

      case CrudFormType.update:
        return {
          ...updateQuery,
          status: updateStatus.status,
          error: updateStatus.error,
          message: updateStatus.message,
          resetStatus: updateStatus.reset,
          action: update,
        };

      case CrudFormType.delete:
        return {
          ...deleteQuery,
          status: deleteStatus.status,
          error: deleteStatus.error,
          message: deleteStatus.message,
          resetStatus: deleteStatus.reset,
          action: deleteItem,
        };
    }
  })();
};
