import { Alert } from '@material-ui/lab';
import React from 'react';

import { Hidden } from '@material-ui/core';
import { ONLINE_MODE } from '../appConfig';
import { useLangLabels } from '../../lang/services/useLangLabels';

interface Props {
    children:any,
    message?:string,
    warning?:boolean,
    reverse?:boolean,
    hidden?:boolean
}

const OnlineOfflineMode:React.FC<Props> = ({children,message,warning, reverse, hidden}) => {

    const  {lang}  = useLangLabels()
    const _mode = reverse ? !ONLINE_MODE : ONLINE_MODE

    const alertText = reverse
        ? lang.messages.featureNotAvailableOnline
        : lang.messages.featureNotAvailableOffline

    return (
        _mode 
        ?  children 
            ? children 
            : null 
        : (message)
            ? <Alert severity='warning'> {message} </Alert>
            : warning !== false
                ? hidden 
                    ? null 
                    : <Alert severity='warning'>{alertText}</Alert>
                : null
    );
}

export default OnlineOfflineMode;
