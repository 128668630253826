import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PageContainer from "../../../../components/containers/PageContainer";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    heroNavPlant: {
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(-8),
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(-10),
        marginBottom: theme.spacing(0),
      },
      "& > *": {
        width: "calc(100% - 48px)",
        marginTop: theme.spacing(-10),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: "#f3f3f3",
        textTransform: "uppercase",
        fontWeight: "900",
        letterSpacing: "1px",
      },
    },
    heroNavTitles: {
      width: "160px",
      textTransform: "uppercase",
      fontWeight: 700,
      color: "#333",
    },
    hero: {
      [theme.breakpoints.down("sm")]: {
        height: "15vh",
        width: "100%",
        backgroundPosition: "center",
      },
      height: "20vh",
      width: "100%",
      backgroundPosition: "center",
    },
  })
);

interface Props {
  img: string;
  title?: string;
  date?: string;
}
const HomeHero: React.FC<Props> = ({ img, date, title }) => {
  const classes = useStyles();
  const { person, user, currentPbigroupName } = useSessionContext();

  return (
    <>
      <PageContainer padding={"0px 0px 32px"}>
        <Paper
          className={classes.hero}
          style={{ backgroundColor: "#f3f3f3", backgroundImage: `url(${img})` }}
          elevation={4}
        />
        <div className={classes.heroNavPlant}>
          <Typography variant="h5" component="h5">
            {currentPbigroupName || "-"}
          </Typography>
          {user?.type === "O" && (
            <>
              <Typography variant="body1">{person?.name}</Typography>
              <Typography variant="body1">{`${date}`}</Typography>
            </>
          )}
        </div>
      </PageContainer>
    </>
  );
};

export default React.memo(HomeHero);
