import { Divider, Typography } from "@material-ui/core";
import FlexContainer from "../../containers/FlexContainer";
import { useGetUserData } from "../../../domains/session/services/sessionAdapters";
import TGDCompany from "../../../assets/img/compressed/tgdcompany.png"
import TGDIsotipo from "../../../assets/img/compressed/isotipo.svg"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const PageFooterContent = () => {
  const { lang } = useLangLabels();
  const { data: userData } = useGetUserData()

  return (
    <>
      <img
        style={{
          height: "60px",
        }}
        src={TGDIsotipo}
        alt="TGD Company"
      />
      <FlexContainer
        justify="space-between"
        align="center"
        width={"100%"}
      >
        <div>
          <Typography style={{ textAlign: "left", fontWeight: "bold", fontSize: "11px" }}>
            {lang.reportsGeneration.lubricationArea}
          </Typography>
          <Typography style={{ textAlign: "left", fontSize: "11px" }}>
            {userData?.email}
          </Typography>
        </div>
        <div>
          <Typography style={{ fontSize: "11px" }} >info@tgdcompany.com</Typography>
          <Typography style={{ fontSize: "11px" }} >www.tgdcompany.com</Typography>
        </div>
      </FlexContainer>
      <Divider orientation="vertical" variant="middle" style={{ margin: 0, backgroundColor: "#D9D9D9" }} />
      <img
        style={{
          height: "50px",
        }}
        src={TGDCompany}
        alt="TGD Company"
      />
    </>
  );
};

export default PageFooterContent;
