import ObservationItem from "./ObservationItem";
import PageBodyContent from "./PageBodyContent";
import {
  Page,
  PageBody,
  PageBodyVariableContent,
  PageFooter,
  PageFooterContent,
  PageHeader,
  PageHeaderContent,
} from "../../../../components/print-pdf/components";
import useReactPDFState from "../../../../components/print-pdf/hooks/useReactPDFState";
import { ObservationReport } from "../types/ObservationTypes";

interface ContainerProps {
  data: ObservationReport[];
  otherProps?: Record<string, any>;
}

const Container: React.FC<ContainerProps> = ({ data, otherProps }) => {
  const {
    pageState,
    lastDataPosition,
    handleNextDataPosition,
    handlePrevDataPosition,
    handleSkipPage,
    handlePageStateDataPush,
    handlePageStateDataPop,
  } = useReactPDFState<ObservationReport>();

  const numberOfObservations = data?.length

  return (
    <>
      {pageState.map((singlePage, index) => {
        return (
          <Page<ObservationReport>
            key={index}
            data={data}
            pageIndex={singlePage.pageIndex}
            pageData={singlePage.pageData}
            lastDataPosition={lastDataPosition}
            handleNextDataPosition={handleNextDataPosition}
            handlePrevDataPosition={handlePrevDataPosition}
            handleSkipPage={handleSkipPage}
            handlePageStateDataPush={handlePageStateDataPush}
            handlePageStateDataPop={handlePageStateDataPop}
            otherProps={otherProps}
          >
            {({
              variableContentRef,
              pageIndex,
              pageData,
              handleVariableSizeChange,
              handleTriggerOnResizing,
              otherProps,
            }) => (
              <>
                <PageHeader>
                  <PageHeaderContent />
                </PageHeader>
                <PageBody>
                  <PageBodyContent
                    pageIndex={pageIndex}
                    otherProps={{...otherProps, numberOfObservations}}
                  />
                  <PageBodyVariableContent contentRef={variableContentRef}>
                    {pageData.map((pageDataItem, index) => {
                      return (
                        <ObservationItem
                          key={index}
                          data={pageDataItem}
                          handleVariableSizeChange={handleVariableSizeChange}
                          handleTriggerOnResizing={handleTriggerOnResizing}
                          style={
                            index !== pageData.length - 1
                              ? {
                                  paddingTop: "20px",
                                  borderBottom: "2px solid #D9D9D9",
                                }
                              : { paddingTop: "20px" }
                          }
                          onItemRendered={otherProps?.onItemRendered}
                          onItemUnrendered={otherProps?.onItemUnrendered}
                        />
                      );
                    })}
                  </PageBodyVariableContent>
                </PageBody>
                <PageFooter>
                  <PageFooterContent />
                </PageFooter>
              </>
            )}
          </Page>
        );
      })}
    </>
  );
};

export default Container;
