import { useEffect, useState, useMemo } from "react";
import { useGetLossReportMap } from "../services/LossReportService";
import { ComponentToPrint } from "./ComponentToPrint";
import { PeriodInterface } from "../../../../../shared/reports/model/ReportInterface";
import {
  LossReportInterface,
  SelectedItems,
  GroupedSelectedItems,
  SectorMap,
} from "../types/LossReportTypes";
import { FieldFilterHookResult } from "../../../../../components/filters/FieldFilter/types/FieldFilterTypes";
import { ObservationReport } from "../../../../observations/reports/types/ObservationTypes";

interface ReportProps {
  rendered: boolean;
  contentRef: React.MutableRefObject<null>;
  period: PeriodInterface;
  fieldFilter: FieldFilterHookResult<LossReportInterface>;
  otherProps: Record<string, any>;
  selectedItems: Array<ObservationReport>;
}

const Report = ({
  rendered,
  contentRef,
  period,
  fieldFilter,
  otherProps,
  selectedItems,
}: ReportProps) => {
  const { data: groupedItems } = useGetLossReportMap(period, {
    filters: fieldFilter.filterModel,
    searchValue: fieldFilter.searchValue,
  });

  const [groupedSelectedItems, setGroupedSelectedItems] = useState<any>([]);

  const grouperItemsAsigner = () => {
    if (selectedItems.length > 0) {
      const selectedItemsWKeys = selectedItems.reduce<any>((acc, item) => {
        acc[item.sector] = acc[item.sector] || [];
        acc[item.sector].push(item);
        return acc;
      }, {} as SelectedItems[]);
      setGroupedSelectedItems(selectedItemsWKeys);
    } else {
      setGroupedSelectedItems(groupedItems);
    }
  };

  useEffect(() => {
    grouperItemsAsigner();
  }, [selectedItems, groupedItems]);

  const keys = useMemo(() => Object.keys(groupedSelectedItems ?? []), [groupedSelectedItems]);

  const generateTotalLeakSector = (groupedSelectedItems: GroupedSelectedItems | undefined): SectorMap => {
    if (!groupedSelectedItems) {
      return {};
    }
    const processedSurveyIds = new Set<number>();
    return Object.entries(groupedSelectedItems).reduce((sectorMap, [sector, reports]) => {
      const totalLeakSector = reports.reduce((total, item: LossReportInterface) => {
        if (!processedSurveyIds.has(item.surveyId)) {
          processedSurveyIds.add(item.surveyId);
          return total + item.quantity;
        }
        return total;
      }, 0);
      sectorMap[sector] = { totalLeakSector, reports };
      return sectorMap;
    }, {} as SectorMap);
  };

  const data = useMemo(
    () => generateTotalLeakSector(groupedSelectedItems),
    [groupedSelectedItems]
  );

  return (
    <div style={{ overflow: "hidden", height: 0 }}>
      {rendered && keys && groupedSelectedItems && (
        <ComponentToPrint ref={contentRef} keys={keys} data={data} otherProps={otherProps} />
      )}
    </div>
  );
};

export default Report;
