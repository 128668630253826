import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import CUAddedReasonForm from "../CUAddedReasonForm";
import DeleteAddedReasonForm from "../DeleteAddedReasonForm";
import { useAddedReasonGridModel } from "../lang/useAddedReasonGridModel";
import { AddedReasonInterface } from "../models/AdedReasonTypes";
import { usePlantAddedReasons } from "../services/service.addedReasons";

interface ManageAddedReasonsPageProps {
  virtualGridWidth: string;
}

const ManageAddedReasonsPage: React.FC<ManageAddedReasonsPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: addedReasons, isFetching } = usePlantAddedReasons();
  const addedReasonGridModel = useAddedReasonGridModel();

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: AddedReasonInterface) => (
        <CUAddedReasonForm item={item} actionType={CrudFormType.update} isDataUpdated={!isFetching} />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        items: addedReasons,
        gridModel: addedReasonGridModel,
        title: lang.addedReasons.plural,
        entityName: lang.addedReasons.plural,
        carrousellComponents,
        headerActions: (item: any) => <CUAddedReasonForm actionType={CrudFormType.create} />,
        itemActions: (item) => <DeleteAddedReasonForm item={item} />,
      }}
    />
  );
};

export default ManageAddedReasonsPage;
