import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useMemo } from "react";
import ComponentMode from "../../../../components/componentMode/ComponentMode";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { getGridModelkey, getGridModelValue } from "../../../../shared/gridModel/gridModelEntity";
import { useLubricationPointsGridModel } from "../../../lubricationPoints/lang/useLubricationPointsGridModel";
import { useLubricationPointByTagTGD } from "../../../lubricationPoints/services/lubricationPointsAdapters";
import { getSurveyIconProps, SurveyInterface } from "../../model/SurveyInterface";
import { useSurveyGridModel } from "../../services/lang/useSurveyGridModel";
import { PreviewField } from "./components/PreviewField";
import { SurveyPreviewProps, notShow, principalItems } from "./types/SurveyPreviewTypes";
import { TaskItem } from "./components/TaskItem";
import { CustomIcon } from "../../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const SurveyPreview: React.FC<SurveyPreviewProps> = ({ item }) => {
  const gridModel = useSurveyGridModel();
  const lubricationPointsGridModel = useLubricationPointsGridModel();
  const { data: lubricationPoint } = useLubricationPointByTagTGD(item?.tagTGD);
  const { lang } = useLangLabels();

  const previewItems =
    (!!item && Object.entries(item).filter((entry: any) => !notShow.includes(entry[0]))) || [];

  const filtered = useMemo(
    () => (previewItems ? previewItems.filter(([key]) => principalItems.includes(key)) : []),

    [previewItems]
  );

  return (
    <>
      <FlexContainer>
        <FlexContainer align={"center"}>
          <ComponentMode
            mode="popover"
            popoverTitle={lang.lubricationPoints.equipmentIdentification}
            icon="info"
            variant="iconButton"
          >
            <FlexContainer>
              <strong>{getGridModelkey("id", lubricationPointsGridModel).toUpperCase()}:</strong>{" "}
              {lubricationPoint?.id}
            </FlexContainer>
            <FlexContainer>
              <strong>
                {getGridModelkey("tagTGD", lubricationPointsGridModel).toUpperCase()}:{" "}
              </strong>
              {lubricationPoint?.tagTGD}
            </FlexContainer>
            <FlexContainer>
              <strong>
                {getGridModelkey("plantTag", lubricationPointsGridModel).toUpperCase()}:{" "}
              </strong>
              {lubricationPoint?.plantTag}
            </FlexContainer>
            <FlexContainer>
              {lubricationPoint?.tagTGDBefore && (
                <>
                  <strong>
                    {getGridModelkey("tagTGDBefore", lubricationPointsGridModel).toUpperCase()}
                  </strong>
                  {lubricationPoint?.tagTGDBefore}
                </>
              )}
            </FlexContainer>
          </ComponentMode>
          <strong>{lubricationPoint?.equipment}</strong> {`>>`}
          <strong>{lubricationPoint?.element}</strong> {`>>`}
          <strong>{lubricationPoint?.component}</strong>
        </FlexContainer>
      </FlexContainer>

      <br />
      <Divider />

      <List>
        <ListItem>
          <ListItemIcon>
            <CustomIcon icon="user" />
          </ListItemIcon>
          <ListItemText
            primary={item?.user}
            secondary={getGridModelValue("manualRegistration", item?.manualRegistration, gridModel)}
          />
          <ListItemText
            primary={item?.route}
            secondary={getGridModelValue("outOfRoute", item?.outOfRoute, gridModel)}
          />
          <ListItemText
            primary={getGridModelkey("startTime", gridModel)}
            secondary={getGridModelValue("startTime", item?.startTime, gridModel)}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <Grid container justifyContent="space-between">
            <Grid
              md={4}
              style={{
                borderRight: "1px solid #DedeDe",
                boxSizing: "border-box",
              }}
            >
              <Grid>ID: {item?.id}</Grid>
              <Grid>
                <PreviewField
                  {...{
                    title: getGridModelkey("inaccessible", gridModel),
                    iconProps: getSurveyIconProps("inaccessible", item?.inaccessible),
                    label: getGridModelValue("inaccessible", item?.inaccessible, gridModel),
                  }}
                />
              </Grid>
              <Grid>
                <PreviewField
                  {...{
                    title: getGridModelkey("sector", gridModel),
                    iconProps: getSurveyIconProps("sector", item?.sector),
                    label: getGridModelValue("sector", item?.sector, gridModel),
                  }}
                />
              </Grid>

              {filtered.map(([key, value]) => (
                <Grid>
                  {value !== undefined &&
                    value !== "" &&
                    getGridModelkey(key, gridModel) +
                      ": " +
                      getGridModelValue(key, item?.[key as keyof SurveyInterface], gridModel)}
                </Grid>
              ))}
            </Grid>

            <Grid
              container
              md={8}
              alignContent="flex-start"
              style={{ padding: "0 0 0 16px", boxSizing: "border-box" }}
            >
              <Grid md={12}>
                <TaskItem item={item} />
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </List>

      <br />
      <Divider />
      <br />
    </>
  );
};

export default SurveyPreview;
