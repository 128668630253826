import { useMemo } from "react";
import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useObservationsGridModel = () => {
  const { lang } = useLangLabels();
  const gridModelObservations: GridModelInterface = useMemo(
    () => ({
      tagTGD: { label: lang.globalentityValues.tagTGD },
      plantTag: { label: lang.lubricationPoints.plantTag },
      anomaly: { label: lang.anomalies.singular },
      observation: { label: lang.observations.singular },
      criticality: { label: lang.criticalities.title.singular },
      area: { label: lang.areas.title.singular },
      surveyId: { label: lang.observations.surveyId },
      equipment: { label: lang.observations.equipment },
      observationDate: { label: lang.observations.observationDate },
      OTnumber: { label: lang.observations.OTnumber },
      taskType: { label: lang.observations.taskType },
      type: { label: lang.observations.type },
      element: { label: lang.observations.element },
      component: { label: lang.observations.component },
      user: { label: lang.observations.user },
      sector: { label: lang.surveys.sector },
    }),
    [lang]
  );

  return gridModelObservations;
};
