import styled from "@emotion/styled"
import React, { HTMLAttributes } from "react"


const FormStyles = styled('div')`
    .form{
        flex-direction:column;
        gap:12px;
        display:flex;
        flex-wrap:wrap;
        padding:16px;
        box-sizing:border-box;
    }
`

interface FormProps {
    onSubmit?:()=>void
    onChange?:(data:any)=>void
}

const Form:React.FC<FormProps> = ({children,onSubmit,onChange}) => {

    const handleSubmit = (e:React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit && onSubmit()
    }

    const handleChange = (e:React.ChangeEvent<HTMLFormElement>) =>{
        onChange && onChange(e)
    }

    return(
        <FormStyles>
            <form className="form" onSubmit={handleSubmit} onChange={handleChange}>
                {children}
            </form>
        </FormStyles>
    )
}

export default Form