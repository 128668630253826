import { useEffect, useState } from "react"
import { MutateOptions, QueryStatus } from "react-query"
import { mutateApiCall } from "../../../shared/axios/axios-config"
import { MutateResponse } from "../../../shared/axios/models/MutateResponse"
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf"
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices"
import { useEquipmentsBySectorName } from "../../lubricationPoints/services/lubricationPointsAdapters"
import { useToken } from "../../session/store/sessionStore"
import { SectorInterface } from "../models/SectorInterface"



export const SectorCRUDDependencies = [
    'PlantAux',
    'sectorsByPlant'
]

//--------------
//REPO
//--------------

export const CreateSector = ({data,token}:PrivateQueryData)=> {
    return mutateApiCall({
        method:'POST',
        url:'/CreateSector',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}

export const UpdateSector = ({data,token}:PrivateQueryData)=> {
    return mutateApiCall({
        method:'POST',
        url:'/UpdateSector',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}

export const DeleteSector = ({data,token}:PrivateQueryData)=> {
    return mutateApiCall({
        method:'POST',
        url:'/DeleteSector',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}


//--------------
//Query
//--------------

export const usePlantSectors = ()=> {
    return useGetAllAuxByPlantData('sectors')
}

//--------------
//MUTATIONS
//--------------

export const useCreateSector = () => {
    const token = useToken()
    const query = useMutation(CreateSector,{
        onSuccess:() => {
            queryClient.invalidateQueries('PlantAux')
        }
    })

    const createSector = (data:SectorInterface) => {
        return query.mutate({
            data,
            token
        })
    }
    
    const status = useStatusProcessor(query)

    return {createSector,...query,...status}
}

export const useUpdateSector = () => {
    const token = useToken()
    const query = useMutation(UpdateSector,{
        onSuccess:() => {
            queryClient.invalidateQueries('PlantAux')
        }
    })

    const updateSector = (data:SectorInterface) => {
        return query.mutate({
            data,
            token
        })
    }
    
    const status = useStatusProcessor(query)

    return {updateSector,...query,...status}
}

export const useDeleteSector = () => {
    const token = useToken()
    const query = useMutation(DeleteSector,{
        onSuccess:() => {
            queryClient.invalidateQueries('PlantAux')
        }
    })

    const deleteSector = (data:any,config:MutateOptions<MutateResponse, unknown, PrivateQueryData<any>, unknown>) => {
        return query.mutate({
            data,
            token
        }, config)
    }
    
    const status = useStatusProcessor(query)

    return {deleteSector, ...query, ...status}
}

interface DeleteSectorErrors {
    equipments?:SectorInterface[] | undefined 
}

export const useDeleteSectorVerification = () => {


    const token = useToken()
    const query = useDeleteSector()
    const {deleteSector,data} = query
    const [status, setStatus] = useState<QueryStatus>('idle')
    const [validationElement,setValidationElement] = useState<SectorInterface | {} |undefined >()

    const {data:equipmentsBySectors,status:equipmentsBySectorStatus,remove:removeEquipmentsByName} = useEquipmentsBySectorName(validationElement)

    const [errors, setErrors] = useState<DeleteSectorErrors>({})

    const handleResetValidations = () => {
        removeEquipmentsByName()
    }

    const validate = (item:SectorInterface) => {
        setErrors({})
        handleResetValidations()
        setStatus('loading')
        setValidationElement(item)
        
    }

    const handleDelete= () =>{
        if(equipmentsBySectorStatus === 'success'){
            if((equipmentsBySectors && equipmentsBySectors.length > 0)) {
                setErrors({equipments:equipmentsBySectors})
                setStatus('error')
                setValidationElement(undefined)
            }
            else{
                deleteSector(validationElement,{
                    onSuccess:()=>{
                        setStatus('success')
                        setValidationElement(undefined)
                    },
                    onError:(err)=>console.log(err)
                })
            }
        }
    }
    
    useEffect(() => {
        validationElement && handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsBySectorStatus])

    return  {
        errors,
        status,
        validate,
        query
    }
}


