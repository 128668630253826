import { useMutation, useQuery } from "react-query"
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config"
import { getAllResources } from "../../../shared/axios/utils"
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes"
import { useToken } from "../../session/store/sessionStore"
import { DemoInterface, DemoRequestInterface } from "../demoInterface"

export const DemoRequest = (data:DemoInterface) => {
    return apiCall({
        url:'/DemoRequest',
        method:'POST',
        data
    })/* .then(res => {return res.data}).catch(res =>{return res.data}) */
}

export const createDemoClient = ({data, token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "post",
        url:`/CreateDemoClient`,
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}

export const desactivateDemoUser = ({data, token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "post",
        url:`/UpdateDemoUser`,
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}

export const updateHiddenState = ({data,token}:PrivateQueryData) =>{ //get object 'cause mutation query bring it that way
    return mutateApiCall({
        method: "post",
        url:`/UpdateHiddenState`,
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}


export const useActivateDemoRequest = (config?:any) => {
    return useMutation(createDemoClient,config)
}

export const useUpdateHiddenState = (config?:any) => {
    return useMutation(updateHiddenState,config)
}

export const useCreateDemoRequests = ( ) => {
    return useMutation((data:DemoInterface)=>DemoRequest(data))
}

export const useDemoRequests = () => {
    const token = useToken()
    const queryKey = 'AllDemoRequests'
    return useQuery({
        queryKey,
        queryFn:()=>getAllResources(queryKey,{token})
    })
}

export const useUpdateDemoRequest = (config:any) => {
    const token = useToken()
    const query = useUpdateHiddenState(config)

    const archive = (email:string) => {
        return query.mutate({
            data:{
                applicantsEmail:email,
                hidden:true
            },
            token
        })
    }

    const unArchive = (email:string) => {
        return query.mutate({
            data:{
                applicantsEmail:email,
                hidden:false
            },
            token
        })
    }
    
    return {
        ...query,
        archive,
        unArchive
    }
}

//hidden(archived)-Showed(pending) user Demos Requests
export const useShowedDemoRequests = () => {
    const demoRequestsQuery = useDemoRequests()
    const pendingDemoRequests = demoRequestsQuery.data ? demoRequestsQuery.data.filter((item:DemoRequestInterface )=> item.hidden === false):[]
    return [pendingDemoRequests,demoRequestsQuery]
}

export const useHiddenDemoRequests = () => {
    const demoRequestsQuery = useDemoRequests()
    const hiddenDemoRequests = demoRequestsQuery.data ? demoRequestsQuery.data.filter((item:DemoRequestInterface ) => item.hidden === true):[]
    return [hiddenDemoRequests,demoRequestsQuery]
}


export const useDesactivateDemoUser = (config?:any) => {
    return useMutation(desactivateDemoUser,config)
}




