import { createStyles, makeStyles } from "@material-ui/core";

export const useAutocompleteStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    input: {
      minWidth: (props: any) => props.width || "400px",
      width: (props: any) => props.width || " 100%",
      maxWidth: "100%",
    },
    option: {
      '&[data-focus="true"]': {
        backgroundColor: "#d8d8d8",
        borderColor: "transparent",
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.grey.A200,
        borderColor: "transparent",
      },
    },
  })
);
