import { TextField } from "@material-ui/core";
import { FlexContainer } from "../../../containers";
import CustomButton from "../../../buttons/CustomButton";
import { usePeriodForm } from "../../DynamicFilter/services/usePeriodForm";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { PeriodFilterProps } from "../types/FieldFilterTypes";

const PeriodFilter = ({ period, setPeriod }: PeriodFilterProps) => {
  const { lang } = useLangLabels();
  const {
    periodValues,
    errors: periodErrors,
    handlePeriod: handlePeriodChange,
    periodSubmit: submitPeriod,
    handleReset: handlePeriodReset,
  } = usePeriodForm(period, setPeriod);

  return (
    <div style={{ marginTop: "24px"}}>
      <FlexContainer padding={"0"} justify="space-between">
        <FlexContainer>
          <TextField
            focused={!!periodErrors?.firstDate}
            error={!!periodErrors?.firstDate}
            InputLabelProps={{ shrink: true }}
            placeholder={periodValues.firstDate || ""}
            size="small"
            variant="outlined"
            name="firstDate"
            label={periodErrors?.firstDate?.message || lang.filters.from}
            value={periodValues.firstDate}
            type="date"
            onChange={handlePeriodChange}
          />

          <TextField
            focused={!!periodErrors?.secondDate}
            error={!!periodErrors?.secondDate}
            InputLabelProps={{ shrink: true }}
            placeholder={periodValues.secondDate || ""}
            size="small"
            variant="outlined"
            name="secondDate"
            label={periodErrors?.secondDate?.message || lang.filters.to}
            //defaultValue={periodValues.secondDate}
            value={periodValues.secondDate}
            type="date"
            onChange={handlePeriodChange}
          />
        </FlexContainer>

        <FlexContainer>
          <CustomButton
            icon="search"
            variant="iconButton"
            action={submitPeriod}
            popoverTitle={lang.filters.queryDateRange}
          />

          <CustomButton
            icon="delete"
            variant="iconButton"
            action={handlePeriodReset}
            popoverTitle={lang.filters.resetDateRange}
          />
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

export default PeriodFilter;
