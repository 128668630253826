import { Typography } from "@material-ui/core";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { CriticalityInterface } from "../../../criticalities/models/criticalityInterface";
import { useDate } from "../../../../shared/date/useDate";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface PageBodyContentInfoProps {
  otherProps?: Record<string, any>;
}

const PageBodyContentInfo = ({ otherProps }: PageBodyContentInfoProps) => {
  const { lang } = useLangLabels();
  const { formatISODate } = useDate();
  return (
    <div style={{ padding: "36px 0 32px 0" }}>
      <Typography
        variant="h2"
        style={{ fontSize: "21px", textAlign: "center", fontWeight: "bold", paddingBottom: "4px" }}
      >
        {lang.reportsGeneration.observationReport}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          paddingTop: "16px",
        }}
      >
        <FlexContainer gap={"32px"}>
          <Typography variant="h3" style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>{lang.filters.from}:</span>{" "}
            {formatISODate(otherProps?.firstDate)}
          </Typography>
          <Typography variant="h3" style={{ fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>{lang.filters.to}:</span>{" "}
            {formatISODate(otherProps?.secondDate)}
          </Typography>
        </FlexContainer>
        <FlexContainer style={{ fontSize: "16px" }} gap={"8px"}>
          <Typography>
            <span style={{ fontWeight: "bold" }}>{lang.criticalities.title.plural}:</span>{" "}
            {otherProps?.criticalities.join(" | ")}
          </Typography>
        </FlexContainer>
      </div>
    </div>
  );
};

export default PageBodyContentInfo;
