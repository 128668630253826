
import { useMemo } from 'react';
import { GridModelInterface } from '../../../shared/gridModel/gridModelEntity';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';

export const useCriticalitiesGridModel = () => {
    
    const {lang} = useLangLabels()
    
    const gridModel:GridModelInterface = useMemo(()=>({
        id:{label:lang.globalentityValues.id},
        renderOption:{label:lang.globalentityValues.identifier},
        days:{label:lang.criticalities.form.days},
        number:{label:lang.criticalities.form.number},
        description:{label:lang.criticalities.form.descripton},
        criticalityType:{label:lang.criticalities.form.type},
        color:{label:lang.criticalities.form.color},
        date:{label:lang.globalentityValues.date},
        tagFP:{label:lang.globalentityValues.tagFP},
    }),[lang.criticalities.form.color, lang.criticalities.form.days, lang.criticalities.form.descripton, lang.criticalities.form.number, lang.criticalities.form.type, lang.globalentityValues.date, lang.globalentityValues.id, lang.globalentityValues.identifier, lang.globalentityValues.tagFP])
    
    return gridModel
}