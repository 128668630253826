import React, { useEffect, useMemo, useRef, useState } from "react";

import { Alert, Skeleton } from "@material-ui/lab";

import { FlexContainer, PageContainer } from "../../../components/containers";
import { useFilterColumns } from "../../../components/filters";

import { VirtualGridProps } from "./types/VirtualGridTypes";
import { flattenObjAllLevels } from "../../globals/utils/dataFiltering";
import { ClipboardProvider } from "../RightClick/context/ClipboardContext";

import { useVirtualGrid, useRenderOptionColumn } from "./hooks";
import { Pagination } from "../Pagination";
import { VirtualGridActions, VirtualGridHeader, VirtualGridAdapter } from "./components";

import { ROWS_CUANTITY, TableContainer } from "../TableContainer/TableContainer";
import useFieldFilter from "../../../components/filters/FieldFilter/hooks/useFieldFilter";
import { OnChangeFn, RowSelectionState } from "@tanstack/react-table";

import lodash from "lodash";
import { useLangLabels } from "../../lang/services/useLangLabels";

const VirtualGrid: React.FC<VirtualGridProps> = React.memo(
  ({
    period,
    periodChange,
    tagFP,
    width,
    height,
    fixedHeight,
    title,
    contained = true,
    items,
    gridModel,
    headerActions,
    itemActions,
    bulkActions,
    renderOptionColumn,
    documentExport,
    documentFiltering = true,
    documentSendEmail,
    documentExportTitle,
    carrousellComponents,
    paginationOptions,
    totalItems,
    customDynamicFilters,
    entityName,
    onSelectedDataChange,
    cellFormatter,
  }) => {
    const { lang } = useLangLabels();

    const [rowSelection, setRowSelection] = useState<{ [key: number]: boolean }>({});
    const selectedDataRef = useRef<any>([]);

    const selectedData = useMemo(() => {
      return Object.keys(rowSelection)
        .filter((key) => rowSelection[Number(key)])
        .map((key) => items?.find((item) => item.id === Number(key)))
        .filter((row) => row !== undefined);
    }, [rowSelection, items]);

    useEffect(() => {
      if (!lodash.isEqual(selectedData, selectedDataRef.current)) {
        onSelectedDataChange?.(selectedData);
        selectedDataRef.current = selectedData;
      }
    }, [selectedData, onSelectedDataChange]);

    const onRowSelectionChange: OnChangeFn<RowSelectionState> = (updaterOrValue) => {
      if (typeof updaterOrValue === "function") {
        setRowSelection((prev) => updaterOrValue(prev));
      } else {
        setRowSelection(updaterOrValue);
      }
    };

    const flatItem = useMemo(() => {
      const flatted = items && flattenObjAllLevels(items[0]);
      return flatted || {};
    }, [items]);

    const defaultDynamicFilters = useFieldFilter<any>({
      items: items || [],
      gridModel: gridModel,
      entityName: entityName,
    });

    const dynamicFilters = customDynamicFilters ?? defaultDynamicFilters;

    const {
      hiddenColumns,
      handleHiddenColumn,
      labels,
      handleActivateDesactivateAllColumns,
      visibleColumns,
    } = useFilterColumns(flatItem, gridModel, entityName);

    const rowsCount = useMemo(() => items?.length || 0, [items?.length]);

    const customColumns = useRenderOptionColumn(
      renderOptionColumn?.headerName,
      renderOptionColumn?.renderOption,
      renderOptionColumn?.size
    );

    const columns = useVirtualGrid({
      flatItem,
      gridModel,
      hiddenColumns,
      customColumns,
    });

    const componentHeight = () => {
      if (fixedHeight) {
        return fixedHeight;
      }

      if (height) {
        return rowsCount < height ? rowsCount : height;
      }
      
      return ROWS_CUANTITY;
    };

    const VirtualGridContent = (
      <>
        <VirtualGridHeader
          title={title}
          showFilters={documentFiltering}
          handleToggleColumnVisibility={handleHiddenColumn}
          columnLabels={labels}
          hiddenColumns={hiddenColumns}
          gridModel={gridModel}
          handleToggleAllColumns={handleActivateDesactivateAllColumns}
          period={period}
          setPeriod={periodChange}
          dynamicFilters={dynamicFilters}
          headerActions={headerActions}
        />

        {!items ? (
          <Skeleton animation="pulse" variant="rect" height={500} width="100%" />
        ) : items.length > 0 ? (
          <TableContainer width={width || "100%"}>
            <VirtualGridActions
              selectedData={selectedData}
              itemActions={itemActions}
              bulkActions={bulkActions}
              carrousellComponents={carrousellComponents}
              documentExport={documentExport}
              documentSendEmail={documentSendEmail}
              tagFP={tagFP}
              gridModel={gridModel}
              documentTitle={documentExportTitle || title}
              visibleColumns={visibleColumns}
            />

            <VirtualGridAdapter
              data={dynamicFilters?.filteredItems}
              columns={columns}
              rowSelection={rowSelection}
              onRowSelectionChange={onRowSelectionChange}
              componentWidth={width}
              componentHeight={componentHeight}
              entityName={entityName}
              cellFormatter={cellFormatter}
            />

            {rowsCount && (
              <FlexContainer align="center" justify="flex-end" padding="8px 0px">
                {paginationOptions && totalItems ? (
                  <Pagination
                    paginationOptions={paginationOptions}
                    totalItems={totalItems}
                    selectedData={selectedData}
                  />
                ) : (
                  `${selectedData?.length}/${
                    dynamicFilters?.filteredItems?.length || 0
                  }/${rowsCount}`
                )}
              </FlexContainer>
            )}
          </TableContainer>
        ) : (
          <PageContainer padding={"8px 0px"}>
            <Alert severity="info">{lang.table.noResults}</Alert>
          </PageContainer>
        )}
      </>
    );

    return (
      <ClipboardProvider>
        {contained === true ? (
          <PageContainer backgroundColor={"#FFF"} width="100%">
            {VirtualGridContent}
          </PageContainer>
        ) : (
          VirtualGridContent
        )}
      </ClipboardProvider>
    );
  }
);

export default React.memo(VirtualGrid);
