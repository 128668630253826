import React from "react";
import { makeStyles, TextField, Toolbar, Typography } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import PageContainer from "../containers/PageContainer";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

const useStyles = makeStyles((theme) =>
  createStyles({
    FactoryListTolbar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "0",
    },
  })
);

interface Props {
  title?: string;
  searchValue?: string;
  actions?: React.ReactNode;
  handleSearch?: any;
  searchBar?: boolean;
}
const ListToolbar: React.FC<Props> = ({ title, searchValue, handleSearch, actions, searchBar }) => {
  const classes = useStyles();
  const { lang } = useLangLabels();

  return (
    <>
      <Toolbar className={classes.FactoryListTolbar}>
        {title && (
          <PageContainer>
            <Typography variant="h6">{title}</Typography>
          </PageContainer>
        )}
        {searchBar && (
          <TextField
            style={{ width: "300px", margin: "0 16px" }}
            value={searchValue}
            type="search"
            onChange={handleSearch}
            placeholder={lang.filters.search}
          />
        )}
        {actions && actions}
      </Toolbar>
    </>
  );
};

export default ListToolbar;
