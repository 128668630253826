import { TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import theme from "../../../../assets/theme";
import CustomButton from "../../../../components/buttons/CustomButton";
import ModalButton from "../../../../components/buttons/ModalButton";
import { ComponentUIModes } from "../../../../components/componentMode/types/componentModesInterfaces";
import FlexContainer from "../../../../components/containers/FlexContainer";
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage";
import { useUpdateSubCategory } from "../service.subcategories";
import { SubCategoryInterface } from "../subCategoryInterface";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  mode: ComponentUIModes;
  defaultValue: SubCategoryInterface;
}

const UpdateSubCategoryForm: React.FC<Props> = ({ defaultValue, mode }) => {
  const { lang } = useLangLabels();
  const {
    updateSubcategory,
    query: { status, error, message },
  } = useUpdateSubCategory();
  const { register, errors, watch, handleSubmit } = useForm();
  const title = `${lang.crudActions.update} ${lang.lubricationSection.subcategory.toLowerCase()}: ${
    defaultValue.subcategory
  }`;

  useEffect(() => {
    defaultValue &&
      Object.entries(defaultValue).map(([key, value]: [string, any]) => {
        register(
          {
            name: key,
            value,
          },
          {
            required: {
              value: true,
              message: lang.formMessages.required,
            },
          }
        );
      });
  }, [defaultValue]);

  const submit = (item: SubCategoryInterface) => {
    updateSubcategory(item);
  };

  const form = (
    <>
      <TextField
        inputRef={register()}
        name="subcategory"
        size="small"
        variant="outlined"
        value={watch("subcategory")}
        label={errors?.subcategory?.message || lang.lubricationSection.subcategory}
        color={errors?.subcategory?.message ? "primary" : "secondary"}
      />
      <CustomButton status={status} action={handleSubmit(submit)}>
        {lang.crudActions.update}
      </CustomButton>
    </>
  );

  const content = (
    <>
      <Typography variant="h6">{mode === "content" && title}</Typography>
      <StatusAndMessage status={status} message={message} error={error} />

      {mode === "modal" && <FlexContainer flexDirection="column">{form}</FlexContainer>}

      {mode === "content" && (
        <FlexContainer padding={`${theme.spacing(3)}px 0px`}>{form}</FlexContainer>
      )}
    </>
  );

  return (
    <>
      {mode === "content" && content}

      {mode === "modal" && (
        <ModalButton icon="edit" title={title}>
          {content}
        </ModalButton>
      )}
    </>
  );
};

export default React.memo(UpdateSubCategoryForm);
