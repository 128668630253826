import FlexContainer from "../../containers/FlexContainer";

interface PageFooterProps {
  children: React.ReactNode;
}

const PageFooter = ({ children }: PageFooterProps) => {
  return (
    <FlexContainer
      width={"95%"}
      margin={"auto"}
      height={"56px"}
      align="center"
      justify="space-between"
      gap={"16px"}
      padding={"28px 0 0 0"}
      style={{
        borderTop: "2px solid #F39500",
      }}
    >
      {children}
    </FlexContainer>
  );
};

export default PageFooter;
