import SimpleAutocompleteSelect from "./SimpleAutocompleteSelect";

interface SelectWithOptionsProps {
  error?: string;
  onChange: (value: any) => void;
  label: string;
  value: any;
  disabled?: boolean;
  clearable?: boolean;
  options: {
    label: string;
    value: string | number | boolean;
  }[];
}

export const SelectWithOptions: React.FC<SelectWithOptionsProps> = ({
  error,
  onChange,
  label,
  value,
  options,
  disabled,
  clearable,
}) => {
  const parseDefaultValue = options.find(
    (option) => option.value === value || option.label === value
  )?.label;

  return (
    <SimpleAutocompleteSelect
      key={value}
      error={error}
      label={label}
      items={options}
      showTitle={"label"}
      onChange={onChange}
      defaultValue={parseDefaultValue}
      disabled={disabled ?? false}
      clearable={clearable}
    />
  );
};
