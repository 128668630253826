import React from "react";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useSessionContext } from "../../../session/store/sessionContext";
import { useColectorGridModel } from "../../lang/useColectorGridModel";
import { useCollectorsByTagFP } from "../../services/collectorsServices";
import CollectorStatus from "../CollectorStatus";
import CreateUpdateCollectorForm from "../CreateUpdateCollectorForm";
import ViewQrCode from "../ViewQrCode";

interface Props {
  virtualGridWidth: string;
}

const ManageCollectorsPage: React.FC<Props> = ({ virtualGridWidth }) => {
  const { tagFP } = useSessionContext();
  const { lang } = useLangLabels();
  const { data: collectors } = useCollectorsByTagFP(tagFP);

  return (
    <VirtualGrid
      width={virtualGridWidth}
      title={lang.collectors.plural}
      entityName={lang.collectors.plural}
      gridModel={useColectorGridModel()}
      items={collectors}
      headerActions={(item: any) => (
          <CreateUpdateCollectorForm tagFP={tagFP} type={CrudFormType.create} />
      )}
      renderOptionColumn={{
        headerName: "More",
        renderOption: (item) => (
          <>
            <CollectorStatus collector={item} />
            <ViewQrCode {...{ item }} />
          </>
        ),
      }}
    ></VirtualGrid>
  );
};

export default ManageCollectorsPage;
