import React from "react";
import { RevisionChangeInterface, RevisionChangeState } from "../types/revisionChangeType";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { CustomIcon } from "../../../../components/buttons/CustomIcon";
import theme from "../../../../assets/theme";

const RevisionChangeStatus = ({ item }: { item: RevisionChangeInterface }) => {
  const { lang } = useLangLabels();

  const itemState =
    item.state === RevisionChangeState.SOLICITADO
      ? lang.revisionChange.pending
      : item.state === RevisionChangeState.APROBADO
      ? lang.revisionChange.approved
      : lang.revisionChange.rejected;

  const itemColor =
    item.state === RevisionChangeState.SOLICITADO
      ? theme.palette.warning.main
      : item.state === RevisionChangeState.APROBADO
      ? theme.palette.success.main
      : theme.palette.primary.main;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <div
        style={{
          width: "16px",
          height: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {<CustomIcon icon="state" style={{ color: itemColor }} />}
      </div>

      {itemState}
    </div>
  );
};

export default RevisionChangeStatus;
