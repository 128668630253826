import React, { useState } from 'react'
import { LubricationPointsNavigation } from './components/LubricationPointsNavigation'
import { CircularProgress, Divider, Paper, Toolbar } from '@material-ui/core'
import { useLubricationPointsAndInfoByTagFP } from '../../domains/lubricationPoints/services/lubricationPointsAdapters'
import { EquipmentDetail } from './components/EquipmentDetail'
import FlexContainer from '../../components/containers/FlexContainer'
import { LubricationPointInterface } from '../../domains/lubricationPoints/model/lubricationPointsInterface'
import { SurveyFilters } from './components/SurveyFilters'
import { SurveyList } from './components/SurveyList'
import { SurveyInterface } from '../../domains/surveys/model/SurveyInterface'

export const ExplorePage:React.FC = () => {
  const {data: lubricationPoints} = useLubricationPointsAndInfoByTagFP()
  const [selectedPoint, setSelectedPoint] = useState<LubricationPointInterface | null | undefined>()
  const [surveys, setSurveys] = useState<SurveyInterface[]>([])

  const handleSelectedPoints = (point:LubricationPointInterface) =>{
    setSelectedPoint(point)
  }

  const handleSurveys = (surveys: SurveyInterface[]) => {
    setSurveys(surveys)
  }

  if(!lubricationPoints ){
    return <Paper>
      <FlexContainer align='center' height={'200px'} justify='center'>
        <CircularProgress/>
      </FlexContainer>
    </Paper> 
  }

  return (
    <FlexContainer flexDirection='column' >
      <LubricationPointsNavigation 
        lubricationPoints={lubricationPoints}
        handleChange={handleSelectedPoints}
      />
      <Paper >
          <EquipmentDetail 
            lubricationPoint={selectedPoint} 
            />
      </Paper>
      <Paper>
          <SurveyFilters 
            lubricationPoint={selectedPoint}
            handleChange={handleSurveys}
            />
      </Paper>
      <SurveyList 
        surveys={surveys} 
        lubricationPoint={selectedPoint}
      />
    </FlexContainer>
  )
}
