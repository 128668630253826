import { FactoryInterface } from "../../factories/model/FactoryInterface";
import { PersonInterface } from "../../person/models/PersonInterface";
import { Plant } from "../../plants/PlantInterface";
import { sessionFactory } from "./sessionFactory";
import { SessionInterface } from "./sessionInterface";

export const setToken = (session:SessionInterface,token:SessionInterface['token']):SessionInterface => {
    return {
        ...session,
        token
    }
}

export const setPbiToken = (session:SessionInterface,pbiToken:SessionInterface['token']):SessionInterface => {
    return {
        ...session,
        pbiToken
    }
}

export const setSessionStale = (session:SessionInterface,sessionStale:SessionInterface['sessionStale']):SessionInterface => {
    return {
        ...session,
        sessionStale,
        token:'session stale'
    }
}

export const setUser = (session:SessionInterface,user:SessionInterface['user']):SessionInterface => {
    return {
        ...session,
        user
    }
}
export const setPerson = (session:SessionInterface,person:SessionInterface['person']):SessionInterface => {
    return {
        ...session,
        person
    }
}

export const setStatus = (session:SessionInterface,status:SessionInterface['status']):SessionInterface => {
    return {
        ...session,
        status
    }
}

export const setMultiplant = (session:SessionInterface,multiplant:boolean):SessionInterface => {
    return {
        ...session,
        isMultiplePlantUser:multiplant
    }
}

export const  setPlants = (session:SessionInterface,plants:SessionInterface['plants']):SessionInterface => {
    const isMultiplant = (plants.length > 1)
    return {
        ...session,
        isMultiplePlantUser:isMultiplant,
        currentPlant:isMultiplant ? undefined : plants[0],
        tagFP:isMultiplant ? '' : plants[0].tagFP,
        plants:plants,
    }
}



export const resetCurrentPlant = (session:SessionInterface):SessionInterface => {
    return{
        ...session,
        currentPlant:undefined,
        isMultiplePlantUser:true,
        tagFP:''
    }
}

export const selectPlant = (session:SessionInterface,plant:Plant):SessionInterface => {
    return {
        ...session,
        isMultiplePlantUser:false,
        currentPlant:plant,
        tagFP:plant.tagFP
    }
}

export const setError = (session:SessionInterface,message:SessionInterface['message']):SessionInterface => {
    return{
        ...session,
        status:'error',
        message
    }
}

export const setLoading = (session:SessionInterface,message?:SessionInterface['message']):SessionInterface => {
    return {
        ...session,
        status:'loading',
        message:message || ''
    }
}

export const setSuccess = (session:SessionInterface,message?:SessionInterface['message']):SessionInterface=> {
    return{
        ...session,
        status:'success',
        message:message || ''
    }
}

export const sessionReset = () => {
    return {
        ...sessionFactory()
    }
}


export const setSessionOperator = (session:SessionInterface,operator?:PersonInterface) => {
    return {
        ...session,
        selectedOperator:operator
    }
}

export const setSessionFactory = (session:SessionInterface,factory:FactoryInterface):SessionInterface => {
    return {
        ...session,
        currentFactory:factory,
    }
}
