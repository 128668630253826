import React, { useState } from 'react';

//Materia
import { Paper, Typography, useTheme } from '@material-ui/core';
import { useAdministators } from '../../person/services/personServices';
import OnlineOfflineMode from '../../../shared/config/offline Mode/OnlineOfflineMode';
import SidebarContainer from '../../../components/containers/SidebarContainer';
import EntityList from '../../../components/grids/EntityList';
import AddPersonForm from './AddPersonForm';
import { PersonInterface } from '../models/PersonInterface';
import FlexContainer from '../../../components/containers/FlexContainer';
import PageContainer from '../../../components/containers/PageContainer';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';

const AddAdminPageRefactor: React.FC = () => {
    const theme = useTheme()
    const {lang} = useLangLabels()
    const {data:admins,query:{status:adminStatus}} = useAdministators()
    const [open,setOpen] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [personData,setPersonData] = useState<any>()

    const handleModal = () =>{
        setOpen(!open)
    }

    const handlePerson = (person:PersonInterface) => {
        /* console.log(e) */
        setPersonData(person)
    }

    return (
        <OnlineOfflineMode>
            <SidebarContainer>
                <EntityList
                    title={lang.persons.admins}
                    icon={'person'}
                    status={adminStatus}
                    items={admins}
                    />
                <Paper>
                    <FlexContainer border={'4px 0px 0px solid red'}/>
                    <PageContainer padding='16px 16px 0px'>
                        <Typography component={'h6'} >{lang.persons.newAdministrator}</Typography>
                    </PageContainer>
                    <AddPersonForm
                        role='Administrador'
                        handlePerson={handlePerson}
                    />
                </Paper>
            </SidebarContainer>
            
        </OnlineOfflineMode>
    );
}

export default AddAdminPageRefactor;
