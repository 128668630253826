import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";
import CustomObservationsList from "../../../observations/CustomObservationsList";
import { useSurveyGridModel } from "../../services/lang/useSurveyGridModel";
import { useSurveysByLubricationPoint } from "../../services/surveyServices";

interface EquipmentSurveyGrid {
  lubricationPoint: LubricationPointInterface;
  title: string;
}

const EquipmentSurveyGrid = ({
  lubricationPoint,
  title,
}: EquipmentSurveyGrid) => {
  const entity = useSurveysByLubricationPoint(lubricationPoint.tagTGD).data;
  const gridModel = useSurveyGridModel();

  return (
    <VirtualGrid
      items={entity}
      gridModel={gridModel}
      title={`${title}: ${lubricationPoint?.plantTag}`}
      bulkActions={(items: any[]) => (
        <CustomObservationsList queryType="survey" items={items} />
      )}
      documentExportTitle={`Reporte_${title}_${lubricationPoint?.plantTag}`}
    />
  );
};

export default EquipmentSurveyGrid;
