
import { GridModelInterface } from '../../../shared/gridModel/gridModelEntity';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';

export const useElementGridModel = () => {

    const {lang} = useLangLabels()

    const gridModel:GridModelInterface = {
        code:{label:lang.elements.form.label.element}, 
        element:{label:lang.elements.form.label.element}, 
        id:{label:lang.globalentityValues.id}, 
        date:{label:lang.globalentityValues.date},
        tagFP:{label:lang.globalentityValues.tagFP}, 
    }

    return gridModel
}