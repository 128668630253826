import { useEffect, useRef } from "react";
import React from "react";
import { LossReportInterface } from "../types/LossReportTypes";
import { Ref } from "../../../../../components/print-pdf/types/ReactPDFTypes";
import { Typography } from "@material-ui/core";
import FlexContainer from "../../../../../components/containers/FlexContainer";
import { useDate } from "../../../../../shared/date/useDate";
import { CustomIcon } from "../../../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

interface ObservationItemProps {
  data: LossReportInterface;
  handleVariableSizeChange: (newSize: number) => void;
  handleTriggerOnResizing: () => void;
  style?: React.CSSProperties;
  onItemRendered: () => void;
  onItemUnrendered: () => void;
}

const ObservationItem: React.FC<ObservationItemProps> = React.memo(
  ({
    data,
    handleVariableSizeChange,
    handleTriggerOnResizing,
    style,
    onItemRendered,
    onItemUnrendered,
  }) => {
    const { lang } = useLangLabels();

    const observationRef = useRef<Ref>(null);
    const { formatISODate } = useDate();

    const leakColor = !isNaN(data.leakPercentage)
      ? data.leakPercentage < 30
        ? "green"
        : data.leakPercentage < 40
        ? "orange"
        : "red"
      : "black";

    useEffect(() => {
      onItemRendered();
    }, [onItemRendered]);

    useEffect(() => {
      return () => {
        onItemUnrendered && onItemUnrendered();
      };
    }, [onItemUnrendered]);

    useEffect(() => {
      if (observationRef.current) {
        handleVariableSizeChange(observationRef.current.clientHeight);
        handleTriggerOnResizing();
      }
    }, [observationRef, handleVariableSizeChange, handleTriggerOnResizing]);

    return (
      <div style={style} ref={observationRef}>
        <FlexContainer style={{ padding: "8px", width: "100%" }}>
          <Typography variant="body1" style={{ fontSize: "15px", fontWeight: "bold" }}>
            {`${data.id}: ${data.equipment} >> ${data.element} >> ${data.component}`}
          </Typography>
        </FlexContainer>

        <FlexContainer style={{ padding: "4px 0 16px 0", width: "100%" }}>
          <div style={{ width: "calc(30% - 24px)", padding: "8px 16px 8px 8px" }}>
            <Typography variant="body1" style={{ fontSize: "15px", overflowWrap: "break-word" }}>
              <span style={{ fontWeight: "bold" }}>{lang.infoLubricationPoints.user}: </span>{" "}
              {data.user}{" "}
            </Typography>
            <Typography variant="body1" style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "bold" }}>{lang.globalentityValues.date}: </span>{" "}
              {formatISODate(data.date, true)}{" "}
            </Typography>
          </div>

          <div
            style={{
              width: "calc(35% - 32px)",
              padding: "8px 16px 8px 16px",
              borderLeft: "2px solid #D9D9D9",
            }}
          >
            <Typography variant="body1" style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "bold" }}>{lang.observations.singular}: </span>{" "}
              {data.observation}{" "}
            </Typography>
          </div>

          <div
            style={{
              width: "calc(35% - 24px)",
              padding: "8px 8px 8px 16px",
              borderLeft: `4px solid ${leakColor}`,
            }}
          >
            <Typography variant="body1" style={{ fontSize: "15px", fontWeight: "bold" }}>
              {data.lubricant}
            </Typography>
            <Typography variant="body1" style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "bold" }}>{lang.infoLubricationPoints.capacity}: </span>{" "}
              {data.equipmentCapacity}{" "}
            </Typography>
            <Typography variant="body1" style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "bold" }}>{lang.infoLubricationPoints.added}: </span>{" "}
              {data.quantity}{" "}
            </Typography>
            <FlexContainer gap={"10px"} align="center" padding={"8px 0 0 0"}>
              <CustomIcon icon="leak" style={{ color: leakColor }} />
              {!isNaN(data.leakPercentage) ? (
                <div style={{ color: leakColor }}>% {data.leakPercentage}</div>
              ) : (
                <Typography variant="body1" style={{ fontSize: "15px" }}>
                  {lang.infoLubricationPoints.cannotBeCalculated}
                </Typography>
              )}
            </FlexContainer>
          </div>
        </FlexContainer>
      </div>
    );
  }
);

export default ObservationItem;
