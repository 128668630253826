import { apiCall, mutateApiCall } from "../../shared/axios/axios-config";
import { getAllResourcesByEmail } from "../../shared/axios/utils";
import { PrivateQueryData } from "../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../shared/react-query/react-query-conf";
import { useToken } from "../session/store/sessionStore";
import { Plant } from "./PlantInterface";




const plantCRUDDependencies =[
    'AllPlants',
    'PlantsByTagF',
    'factoryPlants',
    'SupervisorPlants',
    'OperatorPlants',
    'UserPlantsState',
]

export const CreatePlant = ({data,token}:PrivateQueryData) => {
   return apiCall( {
        method: "POST",
        url:`/CreatePlant`,
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}

export const updateUserPlantState = ({data,token}:PrivateQueryData) =>{ //get object 'cause mutation query bring it that way
   return apiCall( {
        method: "POST",
        url:`/UpdatePlant`,
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
}

export const assignPlantToUser = ({data,token}:PrivateQueryData) => { 
    return mutateApiCall({
        method: "post",
        url:`/AssignPlant`,
        headers:{
            Authorization:`Bearer ${token}`
        },
        data
    })
   
}

export const PlantsByTagF = ({data:tagF,token}:PrivateQueryData) =>{ 
    return apiCall({
        method: "GET",
        url:`/PlantsByTagF/${tagF}`,
        headers:{
            Authorization:`Bearer ${token}`
        },

    })

}



export const useUpdatePlantsStateFromUser = (refetch:()=>void) =>{//update plant states for user plants state endpoint: /UpdatePlant
    return useMutation(updateUserPlantState,{
        onSuccess:refetch,
    })
}

export const useAssignPlant = (refetch:()=>void) => {
    return useMutation(assignPlantToUser,{
        onSuccess:refetch,        
    })
}

export const usePlantsByTagF = (tagF?:string) => {
    const token = useToken()
    return useQuery({
        queryKey:['factoryPlants',tagF],
        queryFn:()=>PlantsByTagF({data:tagF,token}),
        enabled:!!tagF && !!token
    })
}

export const useCreatePlant = () => {
    const token = useToken()
    const query = useMutation(CreatePlant,{
        onSuccess:()=>{
            plantCRUDDependencies.forEach((dependency) =>{
                queryClient.invalidateQueries(dependency)
            })
        }
    })

    const status =  useStatusProcessor(query)

    const createPlant = (data:Plant) => {
        return query.mutateAsync({
            token,
            data
        })
    }

    return {
        createPlant,
        ...query,
        ...status
    }

}




export const usePlantsByFactory = (tagF?:string) => {
    const token = useToken()
    return useQuery<Plant[]>({
        queryKey:['factoryPlants',tagF],
        queryFn:()=>PlantsByTagF({data:tagF,token}),
        enabled:!!tagF && !!token
    })
}

export const useUserActivatedCancelledPlants = (email:string | undefined | null) => {
    const token = useToken()
    return useQuery({
        queryKey:['UserPlantsState',email],
        queryFn:()=>getAllResourcesByEmail('UserPlantsState',email!,{token}),
        enabled:!!email && !!token
    })
}

export const useUserActivatedPlants = (email:string | undefined | null) => {
    const query = useUserActivatedCancelledPlants(email)
    let dataProcesed = query.data ? query.data.filter((item:Plant) => item.state ==='A') : null
    return {
        ...query,
        data:dataProcesed
    }
}

export const useUserCancelledPlants = (email:string | undefined | null) => {
    const query = useUserActivatedCancelledPlants(email)
    let dataProcesed = query.data ? query.data.filter((item:Plant) => item.state ==='C') : null 
    return {
        ...query,
        data:dataProcesed
    }
}

export const useAssignCancelUserPlant = (email:string | undefined | null) => {

    const token = useToken()
    const {refetch} = useUserActivatedCancelledPlants(email)
    const {data:userCanceledPlants} = useUserCancelledPlants(email)
    const {mutate:updatePlantState} = useUpdatePlantsStateFromUser(refetch)//useMutation
    const {mutate:assignPlantToUser} = useAssignPlant(refetch)//useMutation



    const handleUserPlants = (action:'assign' | 'cancel' ,data:any) => new Promise<any>(()=>{
        if(action==='assign'){
            if(userCanceledPlants){
                if((userCanceledPlants.filter((i:Plant) => i.tagFP === data.tagFP).length > 0)){
                    /* console.log('user's plant activation') */
                    updatePlantState({data,token})
                }
                else{
                   /*  console.log('assign plant to an user when plant doesn´t exist for the user') */
                    assignPlantToUser({data,token})
                }
            }
            else{
                /* console.log('user's plant activation') */
                updatePlantState({data,token})
            }
        }
        if(action === 'cancel'){
            /* console.log('user's plant cancelation') */
            updatePlantState({data,token})
        }
    })

    return { handleUserPlants }
}