import { useMemo } from "react";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { usePlantOperators } from "../../person/services/personServices";

import { useRoutesByTagFP } from "./service.routes";

export const useRouteExistance = (routeCompare: string, tagFP: string) => {
  const { lang } = useLangLabels();
  const { data: routes } = useRoutesByTagFP();
  const { data: operators } = usePlantOperators(tagFP);
  const operatorsCount = operators?.length;

  const compatibleRoutes = useMemo(
    () => routes?.filter((route) => route.route === routeCompare),
    [routes, routeCompare]
  );

  const compatibleRoutesCount = compatibleRoutes?.length || 0;
  const compatibleRoutesoperators = useMemo(
    () => compatibleRoutes?.map((r) => r.operator),
    [compatibleRoutes]
  );

  if (!compatibleRoutes) return undefined;
  if (compatibleRoutesCount === 0) return undefined;
  if (compatibleRoutesCount > 0) {
    if (operatorsCount === compatibleRoutesCount) {
      return {
        compatibleRoute: compatibleRoutes[0],
        canCreate: false,
        message: lang.routes.validations.allRouteExistance,
      };
    } else {
      return {
        compatibleRoute: compatibleRoutes[0],
        canCreate: true,
        message: `${
          lang.routes.validations.lubricatorRouteExistance
        } ${compatibleRoutesoperators?.join(` ${lang.words.and} `)}, ${
          lang.routes.validations.ableToCreateRoute
        }`,
      };
    }
  }
};
