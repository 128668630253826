import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

export const translateBoolean = (value: any) => {
  if (typeof value === "boolean") {
    if (value === true) return 1;
    else return 0;
  }

  return value;
};

export const useTranslateValues = () => {
  const { lang } = useLangLabels();

  const translateValue = (value: any) => {
    switch (value) {
      case lang.words.yes.toUpperCase():
        return true;

      case lang.words.no.toUpperCase():
        return false;

        case lang.words.active.toUpperCase():
        return true;

        case lang.words.inactive.toUpperCase():
        return false;

      case lang.surveys.options.done.toUpperCase():
        return true;

      case lang.surveys.options.notDone.toUpperCase():
        return false;

      case lang.surveys.options.accessible.toUpperCase():
        return true;

      case lang.surveys.options.inaccessible.toUpperCase():
        return false;

      case lang.surveys.options.all.toUpperCase():
        return undefined;

      case "TODO":
        return undefined;

      case lang.infoLubricationPoints.withLeak.toUpperCase():
        return true;

      case lang.infoLubricationPoints.withoutLeak.toUpperCase():
        return false;

      case lang.surveys.options.manualCharge.toUpperCase():
        return true;

      case lang.surveys.options.Colector.toUpperCase():
        return false;

      case lang.surveys.options.impossibleToLubricate.toUpperCase():
        return true;

      case lang.surveys.options.normal.toUpperCase():
        return false;

      case lang.surveys.options.outOfRoute.toUpperCase():
        return true;

      case lang.surveys.options.inRoute.toUpperCase():
        return false;

      default:
        return value;
    }
  };

  return { translateValue };
};
