import React from "react";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";

import FieldSelector from "../../shared/form/components/FieldSelector";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../session/store/sessionContext";
import { SectorInterface } from "./models/SectorInterface";
import {
  CreateSector,
  DeleteSector,
  UpdateSector,
  SectorCRUDDependencies,
} from "./services/service.sectors";
import { useSectorForm } from "./services/useSectorForm";

type CUSectorFormProps = CUEntityFormPropsType<SectorInterface> & { isDataUpdated?: boolean };

const CUSectorForm: React.FC<CUSectorFormProps> = ({
  item,
  actionType,
  icon,
  variant,
  mode,
  isDataUpdated,
}) => {
  const { tagFP } = useSessionContext();
  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateSector,
      update: UpdateSector,
      delete: DeleteSector,
    },
    actionType,
    SectorCRUDDependencies
  );

  //UI
  const { lang } = useLangLabels();
  const { uiModel, handleSubmit, resetForm } = useSectorForm(tagFP, item);

  const ui = (() => {
    const formName = lang.sectors.title.plural;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: icon || "add",
          title: `${lang.actionTypes.create} ${formName.toLowerCase()}`,
          variant: variant || "button",
          mode: mode || "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: icon || "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: variant || "iconButton",
          mode: mode || "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: SectorInterface) => {
    action(data);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUSectorForm;
