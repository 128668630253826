import useBasicLocalStorage from "../localStorage/useBasicLocalStorage";

export interface PeriodInterface {
  firstDate: string;
  secondDate: string;
}

type dayPeriods = number | 7 | 30 | 60 | 120 | 365;

const dateYYYYMMDD = (date: string) => date.split("T")[0];

const periodInTime = (period: dayPeriods) => {
  return period * 24 * 60 * 60 * 1000;
};

export const getPeriodDate = (date: Date, period?: number) => {
  const secondDateTime = date.getTime();
  const resultPeriodDate = secondDateTime - periodInTime(period ?? 7);
  const result = dateYYYYMMDD(new Date(resultPeriodDate).toISOString());
  return result;
};

const initialPeriod = (defaultPeriod?: dayPeriods) => {
  const date = new Date();
  const today = dateYYYYMMDD(date.toISOString());

  return {
    firstDate: getPeriodDate(date, defaultPeriod || 7),
    secondDate: today,
  };
};

export const usePeriod = (defaultPeriod?: dayPeriods, entityName?: string) => {
  const storeValue = entityName ? true : false;

  const [period, setPeriod] = useBasicLocalStorage<PeriodInterface>({
    key: `${entityName}-period`,
    initialValue: initialPeriod(defaultPeriod),
    storeLocal: storeValue,
  });

  const handlePeriod = (period: PeriodInterface | undefined): void => {
    setPeriod(period || initialPeriod(defaultPeriod));
  };

  return {
    period,
    setPeriod: handlePeriod,
  };
};
